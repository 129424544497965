import React from 'react';
import { useTranslation } from "react-i18next"
import { Title2, Text } from './../../../styles/textGeneral'
import { ReactComponent as PhoneSVG } from './../../../icons/phone.svg'
import { ReactComponent as WhatsappOneSVG } from './../../../icons/whatsapp-1.svg'
import { ReactComponent as LocationSVG } from './../../../icons/location.svg'
import { ReactComponent as FacebookSVG } from './../../../icons/facebook.svg'
import { ReactComponent as LinkedinSVG } from './../../../icons/linkedin.svg'
import { ReactComponent as InstagramSVG } from './../../../icons/instagram.svg'
import { ReactComponent as MessageSVG } from './../../../icons/message.svg'
import { Wrapper } from './../../../styles/generalStyles';
import { FooterLandingContainer, FooterLandingMapContent, FooterLandingCopyrightContent, FooterLandingWaveContent } from './styles/sFooterLanding'
import { ReactComponent as Wave4 } from './../../../images/waves/wave-4.svg'
import { Link } from 'react-router-dom'
import Maps from './../maps/Maps';

export default function FooterLanding() {
  const [t] = useTranslation("main");

  return (
    <footer>
      <FooterLandingWaveContent>
        <Wave4 className='wave' />
      </FooterLandingWaveContent>
      <FooterLandingContainer>
        <Wrapper>
          <Title2>¡Contáctenos!</Title2>
          <div className='footerLandingFlexDesktop'>
            <div>
              <Text>Nuestras centrales telefónicas</Text>
              <div className='footerLandingDetailsContent'>
                <PhoneSVG />
                <div>
                  <span>(511) 4712693</span>
                </div>
              </div>
              <div className='footerLandingDetailsContent'>
                <WhatsappOneSVG />
                <div>
                  <span>(+51) 969 711 789</span>
                </div>
              </div>
            </div>
            <div>
              <Text>Nuestras Oficinas </Text>
              <div className='footerLandingDetailsContent'>
                <LocationSVG />
                <div>
                  <span>Av. Esteban Campodonico 220 Urb. Monte Carmelo</span>
                </div>
              </div>
              <div className='footerLandingDetailsContent'>
                <MessageSVG />
                <div>
                  <span>ventas@serviciopuntual.com.pe</span>
                </div>
              </div>
            </div>
            <FooterLandingMapContent>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15605.694667470121!2d-77.0215147!3d-12.0831221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c99fb680a7c9%3A0x5c2c9c345c8286bc!2sServicio%20Puntual!5e0!3m2!1ses-419!2spe!4v1647294164479!5m2!1ses-419!2spe" width="auto" height="auto" allowfullscreen="" loading="lazy"></iframe>
            </FooterLandingMapContent>
          </div>
          <div>
            <Text className='footerLandingSocialIconsTitle'>{t("landing.footer.ourSocialMedia")}</Text>
            <div className='footerLandingSocialIconsContent'>
              <a href="https://www.facebook.com/Puntualdelivery" target="_blank" rel="noopener noreferrer">
                <FacebookSVG />
              </a>
              <a href="https://www.linkedin.com/company/serviciopuntual/" target="_blank" rel="noopener noreferrer">
                <LinkedinSVG />
              </a>
              <a href="https://www.instagram.com/serviciopuntualpe/" target="_blank" rel="noopener noreferrer">
                <InstagramSVG />
              </a>
            </div>
          </div>
        </Wrapper>
        <FooterLandingCopyrightContent>
          <Wrapper>
            <p>{t("landing.footer.copyright")}</p>
          </Wrapper>
        </FooterLandingCopyrightContent>
      </FooterLandingContainer>
    </footer>
  )
}
