import styled from 'styled-components';

export const TabHistoryButtonContent = styled.div`
  button {
    margin: 20px 0 0 0;
  }

  @media(min-width:1200px) {
    button {
      display: block;
      width: 265px;
      margin: 20px auto 0 auto;
    }
  }
`
