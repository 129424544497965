import styled from 'styled-components';

export const MainError404Container = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  margin: -70px 0 0 0;

  h1 {
    margin: 0 0 5px 0;
  }

  h2 {
    margin: 0 0 10px 0;
  }

  button {
    margin: 30px 0 20px 0;
  }

  a {
    color: ${props => props.theme.brandClassicBlueColor};
    font-weight: ${props => props.theme.weight.bold};
    display: block;
    text-align: center;
  }

  svg {
    display: none;
  }

  .secondH2 {
    margin: 0 0 30px 0;
  }

  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 120px;

    a {
      text-align: start;

      &:hover {
        text-decoration: underline;
      }
    }

    svg {
      display: block;
      width: 500px;
    }
  }
`
