export const nameFValidator = {
  required: "Campo obligatorio*",
  maxLength: {
    value: 60,
    message: "El nombre debe ser menor a 60 caracteres*",
  },
  pattern: {
    value: /^[a-záéíóúñ '-]+$/i,
    message: "Nombre inválido*",
  },
};

export const emailFValidator = {
  required: "Campo obligatorio*",
  max: 45,
  pattern: {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    message: "Correo electrónico inválido*",
  },
};

export const emailReceptorFValidator = {
  max: 45,
  pattern: {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    message: "Correo electrónico inválido*",
  },
};

export const phoneFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[90]\d{8}$/,
    message: "Número telefónico inválido*",
  },
};

export const DNIFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^\d{8}$/,
    message: "Documento de identidad inválido*",
  },
};

export const CEFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^\d{9}$/,
    message: "Carnet de extrangería inválido*",
  },
};

export const otherIDFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[A-Z\-0-9]{8,}$/,
    message: "Número de documento inválido*",
  },
}

export const CEOptionalFValidator = {
  pattern: {
    value: /^\d{9}$/,
    message: "Carnet de extrangería inválido*",
  },
};

export const otherIDOptionalFValidator = {
  pattern: {
    value: /^[A-Z\-0-9]{8,}$/,
    message: "Número de documento inválido*",
  },
}
export const DNIOptionalFValidator = {
  pattern: {
    value: /^\d{8}$/,
    message: "Documento de identidad inválido*",
  },
};

export const RUCFValidator = {
  required: "Campo obligatorio*",
  validate: {
    localValidator: (v) => rucValidator(parseInt(v)) || "RUC incorrecto*",
  },
};

export const passwordFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^(?=.*[A-Za-z])[A-Za-z@$!%*#?&\d]{8,}$/,
    message:
      "Tu contraseña es insegura, prueba a usar mayúsculas, minúsculas y números*",
  },
};

export const bussinesNameFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese un nombre de empresa correcto*",
  },
};

export const addressFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese una dirección correcta*",
  },
};

export const referenceFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese una referencia válida*",
  },
};

export const contentFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese una descripción*",
  },
};

export const measureFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^\d+\.?\d{0,3}$/g,
    message: "Ingrese una medida válida*",
  },
  validate: {
    localValidator: (v) => parseFloat(v) <= 100 || "Dimensión máxima superada*",
  },
};

export const weightFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^\d+\.?\d{0,3}$/g,
    message: "Ingrese una peso válido",
  },
  validate: {
    localValidator: (v) => parseFloat(v) <= 100 || "Peso máximo superado*",
  },
};

export const priceFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^\d+\.?\d{0,3}$/g,
    message: "Ingrese un valor válido*",
  },
};

export const numberOfPacketsFValidator = {
  required: "Campo obligatorio*",
  pattern: {
    value: /^[+]?[1-9][0-9]{0,3}$/g,
    message: "Ingrese un valor válido*",
  },
};

export const ecommerceNameFValidator = {
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese un nombre de Ecommerce válido*",
  },
};

export const commentFValidator = {
  pattern: {
    value: /^[a-záéíóúüñ0-9 '$"*()!@%#/?+&_,-.]+$/i,
    message: "Ingrese un comentario válido*",
  },
};
const rucValidator = (ruc) => {
  //11 dígitos y empieza en 10,15,16,17 o 20
  if (
    !(
      (ruc >= 1e10 && ruc < 11e9) ||
      (ruc >= 15e9 && ruc < 18e9) ||
      (ruc >= 2e10 && ruc < 21e9)
    )
  )
    return false;

  for (var sum = -(ruc % 10 < 2), i = 0; i < 11; i++, ruc = (ruc / 10) | 0)
    sum += (ruc % 10) * ((i % 7) + ((i / 7) | 0) + 1);
  return sum % 11 === 0;
};
