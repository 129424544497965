import { getAuth, signOut } from "firebase/auth";

export const closeSession = (
  navigate,
  setDataOfService,
  setLocalUniqueReceptorData,
  setPackageInfo,
  setCurrentUser,
  setDataOfUser
) => {
  const auth = getAuth();
  if (auth) {
    signOut(auth).then(() => {
      console.log("signOut");
      setCurrentUser(null);
      setDataOfUser(null);
      setDataOfService({
        serviceType: null,
        amount: null,
        senderData: null,
        packetAndReceptorData: null,
      });
      setLocalUniqueReceptorData({
        address: null,
        documentId: null,
        documentType: null,
        email: null,
        height: null,
        large: null,
        name: null,
        packetDescription: null,
        phone: null,
        reference: null,
        statesOfUbigeo: null,
        ubigeo: null,
        fragile: false,
        value: null,
        weight: null,
        width: null,
      });
      setPackageInfo({
        existData: null,
        amount: null,
        finished: false,
      });

      return navigate("/");
    });
  }
};
