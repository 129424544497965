import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Wrapper } from "./../../styles/generalStyles";
import { ErrorText } from "./../../styles/textGeneral";
import { InputContainer, FormContainer } from "./../../styles/inputGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginAndRegisterContainer,
  RegisterAnswerContent,
  LoginAndRegisterDesktop,
} from "./styles/sLoginAndRegister";
import logo from "./../../images/logos/image-24.png";
import background from "./../../images/image-21.webp";
import MainSpinner from "../../components/spinner/MainSpinner";

import useMounted from "../../hooks/useMounted";

//Import firebase utils
import { useFirestore } from "reactfire";

//Import ALGORITHMS
import { handleLoginWithEmail } from "./algorithms/handleLoginWithEmail";

//Validation Schema
import { emailFValidator, passwordFValidator } from "./objects/formValidators";

export default function MainLogin() {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const db = useFirestore();
  const [loading, setLoading] = useState(false);
  const { setCurrentUser, currentUser } = useContext(AppContext);
  const [loginErrors, setLoginErrors] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const mounted = useMounted()

  const onSubmit = async (data) => {
    setLoading(true);
    await handleLoginWithEmail(
      data,
      navigate,
      db,
      setCurrentUser,
      setLoading,
      setLoginErrors,
      mounted
    );
  };

  useEffect(() => {
    currentUser && currentUser.emailVerified && navigate("/home");
  }, []);

  if (loading) {
    return <MainSpinner />;
  }

  return (
    <main>
      <LoginAndRegisterDesktop>
        <div className="coverPageLoginAndRegisterContent">
          <img
            className="loginAndRegisterBackgroundImage"
            src={background}
            alt="Servicio Puntual"
          />
          <div>
            <p>Bienvenido a</p>
            <img src={logo} alt="Logo principal de Servicio Puntual" />
          </div>
        </div>
        <div></div>
        <Wrapper loginAndRegister>
          <LoginAndRegisterContainer>
            <h1>{t("signInAndUp.signInTitle")}</h1>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <label>{t("signInAndUp.email")}</label>
                <input
                  type={"email"}
                  id={"email"}
                  {...register("email", emailFValidator)}
                />
                <ErrorText>
                  {errors.email &&
                    errors.email.type === "required" &&
                    "Este campo es requerido*"}
                </ErrorText>
                <ErrorText>
                  {errors.email &&
                    errors.email.type === "pattern" &&
                    "Correo electrónico inválido*"}
                </ErrorText>
              </InputContainer>
              <InputContainer>
                <label>{t("signInAndUp.password")}</label>
                <input
                  type={"password"}
                  id={"password"}
                  {...register("password", passwordFValidator)}
                />
                <ErrorText>
                  {errors.password &&
                    errors.password.type === "required" &&
                    "Este campo es requerido*"}
                  {loginErrors &&
                    (loginErrors === "auth/wrong-password" ||
                      loginErrors === "auth/user-not-found") &&
                    "Correo electrónico o contraseña incorrecta*"}
                  {loginErrors &&
                    (loginErrors === "auth/network-request-failed") &&
                    "Revisa tu conexión a internet, falla en la red*"}
                  {loginErrors &&
                    (loginErrors === "auth/too-many-requests") &&
                    "Bloqueo temporal por exceso de intentos fallidos*"}
                </ErrorText>
              </InputContainer>
              <Link to="/recover-password" className="forgetPassword">
                ¿Olvidaste tu contraseña?
              </Link>
              <Button primary large>
                {t("signInAndUp.signInButton")}
              </Button>
            </FormContainer>
            <RegisterAnswerContent>
              <p>{t("signInAndUp.doYouHaveAnAccount")}</p>
              <Link to="/register">
                <Button secondary large>
                  {t("signInAndUp.createAccount")}
                </Button>
              </Link>
            </RegisterAnswerContent>
          </LoginAndRegisterContainer>
        </Wrapper>
      </LoginAndRegisterDesktop>
    </main>
  );
}
