import React from 'react'
import MainRecoverPassword from './../components/loginAndRegister/MainRecoverPassword'
import HeaderLanding from './../components/general/header/HeaderLanding';

export default function RecoverPassword() {
  return (
    <>
      <HeaderLanding />
      <MainRecoverPassword />
    </>
  )
}
