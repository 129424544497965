import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  doc,
  setDoc,
  getDocs,
  arrayUnion,
} from "firebase/firestore";

export const handleLoginWithEmail = async (
  data,
  navigate,
  db,
  setCurrentUser,
  setLoading,
  setLoginErrors,
  mounted
) => {
  const auth = await getAuth();
  await setPersistence(auth, browserSessionPersistence)
    .then(async () => {
      console.info("Persistence enabled");
      await signInWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const usersRef = collection(db, "users");
          if (user && user.emailVerified) {
            const recoverQuery = query(
              usersRef,
              where("email", "==", user.email)
            );
            const recoverUser = await getDocs(recoverQuery);
            if (!recoverUser.empty) {
              const thisUserRef = doc(db, "users", user.uid);
              await setDoc(
                thisUserRef,
                {
                  metadata: {
                    createdAt: parseInt(user.metadata.createdAt),
                    listOfLogins: arrayUnion(
                      parseInt(user.metadata.lastLoginAt)
                    ),
                  },
                },
                { merge: true }
              ).then(() => {
                mounted.current && setLoading(false);
                mounted.current && setLoginErrors(null);
                navigate("/home", { replace: true });
                setCurrentUser(auth.currentUser);
              });
            } else {
              signOut(auth).then(() => {
                mounted.current && setLoading(false);
                navigate("/register", { replace: true });
              });
              mounted.current && setCurrentUser(null);
            }
          } else {
            await signOut(auth).then(() => {
              navigate("/email-verify", {
                replace: true,
                state: {
                  email: user?.email ?? "Tu bandeja de entrada de correo",
                },
              });
            });
          }
        })
        .catch((error) => {
          mounted.current && setLoading(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          mounted.current && setLoginErrors(errorCode);
          console.log(errorCode);
          console.log(errorMessage);
        });
    })
    .catch((err) => {
      const errorMessage = err.message;
      console.log(errorMessage);
    });
};
