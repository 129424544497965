import React from "react";
import { ModalWrapper } from "./../../../styles/generalStyles";
import { Text } from "./../../../styles/textGeneral";

export default function ErrorSendXls({
  paddingModal,
  uploadedExcelError,
  dataOfService,
}) {
  return (
    <ModalWrapper error paddingModal={paddingModal}>
      <h2>¡Acaba de ocurrir un error!</h2>
      <div>
        <h3>Tenemos algunos problemas con el archivo cargado</h3>
        {uploadedExcelError.empty && (
          <Text center>
            Cargaste un archivo vacío, por favor llena los datos de tus clientes
            y vuelve a subir el archivo.
          </Text>
        )}
        {uploadedExcelError.typeServiceError &&
          uploadedExcelError.typeServiceError.filter((arr) => arr === false)
            .length > 0 && (
            <Text center>
              Nuestro servicio {dataOfService.serviceType} no acepta envíos
              fuera de la provincia Lima y Callao. Te recomendamos usar nuestro
              servicio envíos a provincia para fuera de Lima y Callao.
            </Text>
          )}
        {uploadedExcelError.excelDataErrors?.map((error, index) => (
          <Text center key={index}>
            Faltan datos en la fila <span>{error.row}</span> y en la columna{" "}
            <span>{error.column.toString().replace(",", ", ")}</span>.
          </Text>
        ))}
      </div>
    </ModalWrapper>
  );
}
