import styled, { css } from 'styled-components'

export const HeaderLandingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  img {
    height: 30px;
  }

  svg {
    fill: ${props => props.theme.blackColor};
  }
  
  .headerLandingMenuContent {
    display: flex;

    a {
      display: ${props => props.buttonLogin || 'block'};
    }

    svg {
      margin: 0 0 0 20px;
    }
  }

  .logoService {
    display: none;
  }

  ${props => props.home && css`
    padding: 15px 0;
    margin: 0;
    background: ${props => props.theme.brandClassicBlueColor};
    display: block;
    position: fixed;
    width: 100%;
    z-index: 2000;

    & > div {
      display: flex;
      justify-content: space-between;

      svg {
        fill: ${props => props.theme.backgroundColor};
      }
    }

    @media(min-width:1200px) {
      display: none !important;
    }
  `}

  @media(min-width:1024px) {
    a {
      display: flex;
      align-items: center;
    }

    .logoService {
      display: block;
      border-left: 2px solid ${props => props.theme.blackColor};
      margin: 0 0 0 22px;
      padding: 5px 0 5px 8px;
      font-weight: ${props => props.theme.weight.semiBold};
      color: ${props => props.theme.blackColor};
    }
  }

  @media(min-width:1200px) {
    display: ${props => props.displayDesktop || 'flex'};
  }
`
