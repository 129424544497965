import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AppContext } from '../../App';
import { useFirestore } from 'reactfire';
import useMounted from '../../hooks/useMounted';
import { CulqiProvider } from 'react-culqi';
import { sendDataCardToDB } from './algorithms/sendDataCardToDB';
import Spinner from '../../components/spinner/MainSpinner'

const MainCulqi = ({ children, totalPrice, metod, dataOfService }) => {
  const navigate = useNavigate()
  const mounted = useMounted()
  const db = useFirestore()
  const { dataOfUser, currentUser, setPackageInfo } = useContext(AppContext)
  const total = totalPrice * 100
  
  return (
    // "tkn_test_sCuRGRUXkU4SRYtR"
    <CulqiProvider
        publicKey={`${process.env.REACT_APP_PUBLIC_KEY_CULQI}`}
        amount={total}
        title=" Servicio Puntual"
        description=" Realiza el pago"
        onToken={async (token) => {
            const code = await token.id
            console.log(token.iin)

            // await sendDataCardToDB({
            //     dataOfService, 
            //     db,
            //     currentUser, 
            //     dataOfUser,
            //     code,
            //     totalPrice,
            //     metod,
            //     mounted,
            //     navigate,
            //     setPackageInfo
            // })
        }}
        onError={error => {
            console.error("something bad happened", error);
        }}
        // Uncomment `options` to see customizations take place
        options={{
            style: {
            maincolor: "#174F82",
            buttontext: "#ffff",
            maintext: "#818286",
            desctext: "#818286",
            logo: "//firebasestorage.googleapis.com/v0/b/servicio-puntual-b19fc.appspot.com/o/sp_images%2Fimage-4.png?alt=media&token=135a3de5-a7cc-4ce0-9ec0-9f009b77d584"
            }
        }}
        >
        <div>
            {children}
        </div>
    </CulqiProvider>
  )
}

export default MainCulqi