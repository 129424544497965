import { useLocation, Navigate } from "react-router-dom";

export function ProtectedTrackingRoute({ children, currentUser }) {
  const location = useLocation();
  return location.state?.status && location.state.status !== "NO ASIGNADO" ? (
    children
  ) : !location.state?.status && currentUser ? (
    <Navigate to="/tracking-home" replace />
  ) : (
    <Navigate to="/" replace />
  );
}
