import React from 'react';
import { ManagementCardContainer } from './styles/sManagementCard'
import { Text } from './../../styles/textGeneral';

export default function ManagementCard({ color, background, svg, title, text }) {
  return (
    <li>
      <ManagementCardContainer color={color} background={background}>
        <div className='managementCardCercleContainer'>
          {svg}
        </div>
        <h3>{title}</h3>
        <Text>{text}</Text>
      </ManagementCardContainer>
    </li>
  )
}
