import React from 'react';
import HeaderHome from './../general/header/HeaderHome';
import MainMenu from './../general/menu/MainMenu';
import { LayoutGridContainer } from './styles/sLayoutGrid'

export default function InHomeLayout({ children, displayDesktop }) {
  return (
    <>
      <HeaderHome />
      <MainMenu />
      <LayoutGridContainer>
        <div></div>
        {children}
      </LayoutGridContainer>
    </>
  )
}
