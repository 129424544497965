import React, { useEffect } from "react";
import { Wrapper } from "./../../styles/generalStyles";
import { MainTrackingContainer, DesktopGrid } from "./styles/sMainTracking";
import TrackingLineCard from "./TrackingLineCard";
import DetailsTrackingCard from "./DetailsTrackingCard";
import TrackingTimelineCard from "./TrackingTimelineCard";
import DeliveredDetailsCard from "./DeliveredDetailsCard";
import NoDeliveredCard from "./NoDeliveredCard";
import { useLocation } from "react-router-dom";

export default function MainTracking() {
  const location = useLocation();

  useEffect(() => {}, []);
  return (
    <main>
      <Wrapper morePages>
        <MainTrackingContainer>
          <h2 className="trackingTitle">
            Seguimiento del envío: <span>N° {location.state.receptorCode}</span>
          </h2>
          {(location.state?.status === "ENTREGADO" ||
            location.state?.status === "PARA DESPACHO" ||
            location.state?.status === "EN GESTION") && (
            <TrackingLineCard state={location.state} />
          )}
          <DesktopGrid>
            {location.state?.status !== "NO DISTRIBUIDO" && (
              <TrackingTimelineCard state={location.state} />
            )}
            <DetailsTrackingCard details={location.state} />
          </DesktopGrid>
          {((location.state?.url_cargo1 && location.state?.url_cargo1 !== "") ||
            (location.state?.url_cargo2 && location.state?.url_cargo2 !== "") ||
            (location.state?.url_cargo3 &&
              location.state?.url_cargo3 !== "")) && (
            <DesktopGrid>
              <DeliveredDetailsCard state={location.state} />
            </DesktopGrid>
          )}
          {(location.state?.status === "NO DISTRIBUIDO" ||
            location.state?.status === "MOTIVO") && (
            <NoDeliveredCard state={location.state} />
          )}
        </MainTrackingContainer>
      </Wrapper>
    </main>
  );
}
