import React, { useState, useEffect } from "react";
import { useReducedState } from "../../hooks/useReducedState";
import { Wrapper } from "./../../styles/generalStyles";
import { BoxGeneral, D50Percent, Details } from "./../../styles/boxesGeneral";
import { Title4, Text, Title5 } from "./../../styles/textGeneral";
import {
  InputContainer,
  FormContainer,
  InputSelect,
} from "./../../styles/inputGeneral";
import UserSearchCard from "./UserSearchCard";
import RemitoSearchCard from "./RemitoSearchCard";
import { Button } from "./../../styles/buttonGeneral";
import TabModShipping from "./TabModShipping";
import MainSpinner from "../spinner/MainSpinner";

//Import ALGORITHMS
import { createXlsxFromData } from "../../algorithms/general/createXlsxFromData";
import { searchSpecificUser } from "./algorithms/searchSpecificUser";
import { downloadAllUsers } from "./algorithms/downloadAllUsers";
import { timestampFromTimeCalculator } from "./algorithms/timestampFromTimeCalculator";
import { totalTimestampCalculator } from "./algorithms/totalTimestampCalculator";
import { recoverPacketsPerRange } from "./algorithms/recoverPacketsPerRange";
import { requestRemito } from "./algorithms/requestRemito.js";
import { recoverShipments } from "./algorithms/recoverShipments";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function MainAdministrator() {
  const db = useFirestore();
  const [localEmail, setLocalEmail] = useState("");
  const [localUid, setLocalUid] = useState("");
  const [userToChangeType, setUserToChangeType] = useState(null);
  const [remito, setRemito] = useReducedState({
    local: null,
    recovered: null,
    remitoId: null,
  });
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useReducedState({
    from: null,
    dateFrom: null,
    timeFrom: null,
    to: null,
    dateTo: null,
    timeTo: null,
  });
  const [shipments, setShipments] = useReducedState(null);
  const [renderTimestampController, setRenderTimestampController] =
    useState(false);
  useEffect(() => {
    if (renderTimestampController) {
      totalTimestampCalculator(timestamp, setTimestamp);
      setRenderTimestampController(false);
    }
  }, [timestamp, remito]);

  if (loading) return <MainSpinner />;

  return (
    <main className="base">
      <Wrapper home width="auto">
        <BoxGeneral>
          <Details>
            <summary>
              <Title4>Administración de usuarios</Title4>
            </summary>
            <div>
              <Text className="textBeforeTitle">
                Cambio de tipo de usuario B2B a B2C o viceversa.
              </Text>
              <Title5>Busqueda de usuario</Title5>
              <FormContainer>
                <div className="inputContainerTriple">
                  <InputContainer>
                    <label>Por correo electrónico</label>
                    <input
                      type="text"
                      onChange={(e) => setLocalEmail(e.target.value)}
                    />
                  </InputContainer>
                  <InputContainer>
                    <label>Por UID de usuario</label>
                    <input
                      type="text"
                      onChange={(e) => setLocalUid(e.target.value)}
                    />
                  </InputContainer>
                  {localUid.length === 28 || localEmail.length > 5 ? (
                    <Button
                      primary
                      large
                      onClick={(e) => {
                        e.preventDefault();
                        searchSpecificUser(
                          db,
                          localUid,
                          localEmail,
                          setUserToChangeType
                        );
                      }}
                    >
                      Buscar usuario
                    </Button>
                  ) : (
                    <Button primary large disabled>
                      Buscar usuario
                    </Button>
                  )}
                </div>
              </FormContainer>
              <div>
                {userToChangeType === null && (
                  <Text danger>Busca un usuario por su email o uid.</Text>
                )}
                {userToChangeType === false && (
                  <Text danger>
                    Usuario no encontrado, prueba a buscar otro email o uid.
                  </Text>
                )}
              </div>
              {userToChangeType && (
                <ul>
                  <UserSearchCard
                    userToChangeType={userToChangeType}
                    setUserToChangeType={setUserToChangeType}
                  />
                </ul>
              )}
              <Title5>Descarga log de todos los usuarios</Title5>
              <D50Percent top10>
                <Button
                  primary
                  large
                  onClick={async () => {
                    setLoading(true);
                    await downloadAllUsers(db);
                    setLoading(false);
                  }}
                >
                  Descargar xlsx
                </Button>
              </D50Percent>
            </div>
          </Details>
        </BoxGeneral>
        <BoxGeneral>
          <Details>
            <summary>
              <Title4>Administración de envíos</Title4>
            </summary>
            <div>
              <Text className="textBeforeTitle">
                Descarga de archivos xlsx y modificación de envíos.
              </Text>
              <Title5>Descarga de archivos por fechas</Title5>
              <FormContainer>
                <div className="inputContainerTriple">
                  <InputContainer>
                    <label>De</label>
                    <input
                      className="firstInputBlock"
                      type="date"
                      onChange={(e) => {
                        setTimestamp({
                          dateFrom:
                            new Date(e.target.value).getTime() + 18000000,
                          timeFrom: timestampFromTimeCalculator("00:00"),
                        });
                        setRenderTimestampController(true);
                      }}
                    />
                    <input
                      type="time"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setTimestamp({
                          timeFrom: timestampFromTimeCalculator(e.target.value),
                        });
                        setRenderTimestampController(true);
                      }}
                    />
                  </InputContainer>
                  <InputContainer>
                    <label>Hasta</label>
                    <input
                      className="firstInputBlock"
                      type="date"
                      onChange={(e) => {
                        setTimestamp({
                          dateTo: new Date(e.target.value).getTime() + 18000000,
                          timeTo: timestampFromTimeCalculator("23:59"),
                        });
                        setRenderTimestampController(true);
                      }}
                    />
                    <input
                      type="time"
                      onChange={(e) => {
                        setTimestamp({
                          timeTo: timestampFromTimeCalculator(e.target.value),
                        });
                        setRenderTimestampController(true);
                      }}
                    />
                  </InputContainer>
                  {timestamp.from &&
                  timestamp.to &&
                  timestamp.to > timestamp.from ? (
                    <Button
                      primary
                      large
                      onClick={(e) => {
                        e.preventDefault();
                        recoverPacketsPerRange(db, timestamp);
                      }}
                    >
                      Descargar xlsx
                    </Button>
                  ) : (
                    <Button primary large disabled>
                      Descargar xlsx
                    </Button>
                  )}
                </div>
              </FormContainer>
              <Title5>Modificación de estado de pago</Title5>
              <FormContainer>
                <div className="inputContainerDuplo">
                  <InputContainer>
                    <label>Número de remito</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setRemito({ local: e.target.value.trim() });
                      }}
                    />
                    {remito.local ? (
                      <Button
                        primary
                        large
                        onClick={(e) => {
                          e.preventDefault();
                          requestRemito(db, remito.local, setRemito);
                        }}
                      >
                        Buscar remito
                      </Button>
                    ) : (
                      <Button primary large disabled>
                        Buscar remito
                      </Button>
                    )}
                  </InputContainer>
                </div>
              </FormContainer>
              {remito.recovered && (
                <ul>
                  <RemitoSearchCard
                    remito={remito}
                    setRemito={setRemito}
                    setShipments={setShipments}
                    recoverShipments={recoverShipments}
                  />
                </ul>
              )}
              {shipments && (
                <TabModShipping
                  shipments={shipments}
                  setShipments={setShipments}
                  realTime={false}
                />
              )}
              {shipments && (
                <Button
                  secondary
                  large
                  onClick={(e) => {
                    e.preventDefault();
                    createXlsxFromData(
                      Object.values(shipments),
                      remito.recovered.senderCode,
                      "admin"
                    );
                  }}
                >
                  Descargar xlsx
                </Button>
              )}
            </div>
          </Details>
        </BoxGeneral>
        <BoxGeneral>
          <Details>
            <summary>
              <Title4>Pedidos EN ESPERA de precios en tiempo real</Title4>
            </summary>
            <div>
              <Text className="textBeforeTitle">
                Pedidos a la espera de ser atendidos en tiempo real.
              </Text>
              <TabModShipping realTime={true} tableType="wait" />
            </div>
          </Details>
        </BoxGeneral>
        <BoxGeneral>
          <Details>
            <summary>
              <Title4>Pedidos por CONFIRMAR pago en tiempo real</Title4>
            </summary>
            <div>
              <Text className="textBeforeTitle">
                Pedidos en espera de confirmación en tiempo real.
              </Text>
              <TabModShipping realTime={true} tableType="confirmation" />
            </div>
          </Details>
        </BoxGeneral>
      </Wrapper>
    </main>
  );
}
