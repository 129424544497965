import React from "react";
import { ModalWrapper } from "./../../../styles/generalStyles";
import { Button } from "../../../styles/buttonGeneral";
import { sendDataToDB } from "../../newPackage/algorithms/sendDataToDB";
import { Text, Title3 } from "../../../styles/textGeneral";

export default function UploadDataStatus({
  paddingModal,
  sendedStatus,
  closeModal,
  dataOfService,
  db,
  currentUser,
  setLoading,
  setSendedStatus,
  dataOfUser,
  setPackageInfo
}) {

  const handleRetry = (e) => {
    if (!(dataOfUser.typeOfUser === "B2C")) {
      sendDataToDB(
        e,
        dataOfService,
        db,
        currentUser,
        setLoading,
        setSendedStatus,
        dataOfUser
      );
    } else if (dataOfUser.typeOfUser === "B2C" &&
      dataOfService.serviceType === "province"
    ) {
      sendDataToDB(
        e,
        dataOfService,
        db,
        currentUser,
        setLoading,
        setSendedStatus,
        dataOfUser
      );
    }
    setPackageInfo({ amount: dataOfService?.amount })
  }

  return (
    <ModalWrapper paddingModal={paddingModal}>
      {sendedStatus ? (
        <Title3 style={{ color: "#22bb33" }}>¡Envío exitoso!</Title3>
      ) : (
        <Title3 style={{ color: "#bb2124" }}>¡Acaba de ocurrir un error!</Title3>
      )}
      <div>
        {sendedStatus ? (
          <Text center>El envío de los datos fue exitoso.</Text>
        ) : (
          <Text center>
            Lo sentimos, ocurrió un problema. Presiona el botón de abajo para
            reintentar.
          </Text>
        )}
      </div>
      <div>
        {sendedStatus ? (
          <Button primary large onClick={closeModal}>
            Aceptar
          </Button>
        ) : (
          <Button
            primary
            large
            onClick={handleRetry}>
            Reintentar
          </Button>
        )}
      </div>
    </ModalWrapper>
  );
}
