import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { createXlsxFromData } from "../../../algorithms/general/createXlsxFromData";

export const recoverShipments = async (db, remito, setShipments) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, "receptors"),
      where("senderCode", "==", remito.recovered.senderCode)
    )
  );

  const listOfqueries = [];

  await querySnapshot.docs.map((q) => {
    listOfqueries.push({
      data: q.data(),
      id: q.id,
    });
  });
  setShipments(listOfqueries);
  //   setRemito({
  //     recovered: querySnapshot.docs[0].data(),
  //     remitoId: querySnapshot.docs[0].id,
  //   });
};
