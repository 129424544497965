export const districts = [
  { district: "CHACHAPOYAS", provId: 0 },
  { district: "ASUNCION", provId: 0 },
  { district: "BALSAS", provId: 0 },
  { district: "CHETO", provId: 0 },
  { district: "CHILIQUIN", provId: 0 },
  { district: "CHUQUIBAMBA", provId: 0 },
  { district: "GRANADA", provId: 0 },
  { district: "HUANCAS", provId: 0 },
  { district: "LA JALCA", provId: 0 },
  { district: "LEIMEBAMBA", provId: 0 },
  { district: "LEVANTO", provId: 0 },
  { district: "MAGDALENA", provId: 0 },
  { district: "MARISCAL CASTILLA", provId: 0 },
  { district: "MOLINOPAMPA", provId: 0 },
  { district: "MONTEVIDEO", provId: 0 },
  { district: "OLLEROS", provId: 0 },
  { district: "QUINJALCA", provId: 0 },
  { district: "SAN FRANCISCO DE DAGUAS", provId: 0 },
  { district: "SAN ISIDRO DE MAINO", provId: 0 },
  { district: "SOLOCO", provId: 0 },
  { district: "SONCHE", provId: 0 },
  { district: "BAGUA", provId: 1 },
  { district: "LA PECA", provId: 1 },
  { district: "ARAMANGO", provId: 1 },
  { district: "COPALLIN", provId: 1 },
  { district: "EL PARCO", provId: 1 },
  { district: "IMAZA", provId: 1 },
  { district: "JUMBILLA", provId: 2 },
  { district: "CHISQUILLA", provId: 2 },
  { district: "CHURUJA", provId: 2 },
  { district: "COROSHA", provId: 2 },
  { district: "CUISPES", provId: 2 },
  { district: "FLORIDA", provId: 2 },
  { district: "JAZAN", provId: 2 },
  { district: "RECTA", provId: 2 },
  { district: "SAN CARLOS", provId: 2 },
  { district: "SHIPASBAMBA", provId: 2 },
  { district: "VALERA", provId: 2 },
  { district: "YAMBRASBAMBA", provId: 2 },
  { district: "NIEVA", provId: 3 },
  { district: "EL CENEPA", provId: 3 },
  { district: "RIO SANTIAGO", provId: 3 },
  { district: "LAMUD", provId: 4 },
  { district: "CAMPORREDONDO", provId: 4 },
  { district: "COCABAMBA", provId: 4 },
  { district: "COLCAMAR", provId: 4 },
  { district: "CONILA", provId: 4 },
  { district: "INGUILPATA", provId: 4 },
  { district: "LONGUITA", provId: 4 },
  { district: "LONYA CHICO", provId: 4 },
  { district: "LUYA", provId: 4 },
  { district: "LUYA VIEJO", provId: 4 },
  { district: "MARIA", provId: 4 },
  { district: "OCALLI", provId: 4 },
  { district: "OCUMAL", provId: 4 },
  { district: "PISUQUIA", provId: 4 },
  { district: "PROVIDENCIA", provId: 4 },
  { district: "SAN CRISTOBAL", provId: 4 },
  { district: "SAN FRANCISCO DEL YESO", provId: 4 },
  { district: "SAN JERONIMO", provId: 4 },
  { district: "SAN JUAN DE LOPECANCHA", provId: 4 },
  { district: "SANTA CATALINA", provId: 4 },
  { district: "SANTO TOMAS", provId: 4 },
  { district: "TINGO", provId: 4 },
  { district: "TRITA", provId: 4 },
  { district: "SAN NICOLAS", provId: 5 },
  { district: "CHIRIMOTO", provId: 5 },
  { district: "COCHAMAL", provId: 5 },
  { district: "HUAMBO", provId: 5 },
  { district: "LIMABAMBA", provId: 5 },
  { district: "LONGAR", provId: 5 },
  { district: "MARISCAL BENAVIDES", provId: 5 },
  { district: "MILPUC", provId: 5 },
  { district: "OMIA", provId: 5 },
  { district: "SANTA ROSA", provId: 5 },
  { district: "TOTORA", provId: 5 },
  { district: "VISTA ALEGRE", provId: 5 },
  { district: "BAGUA GRANDE", provId: 6 },
  { district: "CAJARURO", provId: 6 },
  { district: "CUMBA", provId: 6 },
  { district: "EL MILAGRO", provId: 6 },
  { district: "JAMALCA", provId: 6 },
  { district: "LONYA GRANDE", provId: 6 },
  { district: "YAMON", provId: 6 },
  { district: "HUARAZ", provId: 7 },
  { district: "COCHABAMBA", provId: 7 },
  { district: "COLCABAMBA", provId: 7 },
  { district: "HUANCHAY", provId: 7 },
  { district: "INDEPENDENCIA", provId: 7 },
  { district: "JANGAS", provId: 7 },
  { district: "LA LIBERTAD", provId: 7 },
  { district: "OLLEROS", provId: 7 },
  { district: "PAMPAS", provId: 7 },
  { district: "PARIACOTO", provId: 7 },
  { district: "PIRA", provId: 7 },
  { district: "TARICA", provId: 7 },
  { district: "AIJA", provId: 8 },
  { district: "CORIS", provId: 8 },
  { district: "HUACLLAN", provId: 8 },
  { district: "LA MERCED", provId: 8 },
  { district: "SUCCHA", provId: 8 },
  { district: "LLAMELLIN", provId: 9 },
  { district: "ACZO", provId: 9 },
  { district: "CHACCHO", provId: 9 },
  { district: "CHINGAS", provId: 9 },
  { district: "MIRGAS", provId: 9 },
  { district: "SAN JUAN DE RONTOY", provId: 9 },
  { district: "CHACAS", provId: 10 },
  { district: "ACOCHACA", provId: 10 },
  { district: "CHIQUIAN", provId: 11 },
  { district: "ABELARDO PARDO LEZAMETA", provId: 11 },
  { district: "ANTONIO RAYMONDI", provId: 11 },
  { district: "AQUIA", provId: 11 },
  { district: "CAJACAY", provId: 11 },
  { district: "CANIS", provId: 11 },
  { district: "COLQUIOC", provId: 11 },
  { district: "HUALLANCA", provId: 11 },
  { district: "HUASTA", provId: 11 },
  { district: "HUAYLLACAYAN", provId: 11 },
  { district: "LA PRIMAVERA", provId: 11 },
  { district: "MANGAS", provId: 11 },
  { district: "PACLLON", provId: 11 },
  { district: "SAN MIGUEL DE CORPANQUI", provId: 11 },
  { district: "TICLLOS", provId: 11 },
  { district: "CARHUAZ", provId: 12 },
  { district: "ACOPAMPA", provId: 12 },
  { district: "AMASHCA", provId: 12 },
  { district: "ANTA", provId: 12 },
  { district: "ATAQUERO", provId: 12 },
  { district: "MARCARA", provId: 12 },
  { district: "PARIAHUANCA", provId: 12 },
  { district: "SAN MIGUEL DE ACO", provId: 12 },
  { district: "SHILLA", provId: 12 },
  { district: "TINCO", provId: 12 },
  { district: "YUNGAR", provId: 12 },
  { district: "SAN LUIS", provId: 13 },
  { district: "SAN NICOLAS", provId: 13 },
  { district: "YAUYA", provId: 13 },
  { district: "CASMA", provId: 14 },
  { district: "BUENA VISTA ALTA", provId: 14 },
  { district: "COMANDANTE NOEL", provId: 14 },
  { district: "YAUTAN", provId: 14 },
  { district: "CORONGO", provId: 15 },
  { district: "ACO", provId: 15 },
  { district: "BAMBAS", provId: 15 },
  { district: "CUSCA", provId: 15 },
  { district: "LA PAMPA", provId: 15 },
  { district: "YANAC", provId: 15 },
  { district: "YUPAN", provId: 15 },
  { district: "HUARI", provId: 16 },
  { district: "ANRA", provId: 16 },
  { district: "CAJAY", provId: 16 },
  { district: "CHAVIN DE HUANTAR", provId: 16 },
  { district: "HUACACHI", provId: 16 },
  { district: "HUACCHIS", provId: 16 },
  { district: "HUACHIS", provId: 16 },
  { district: "HUANTAR", provId: 16 },
  { district: "MASIN", provId: 16 },
  { district: "PAUCAS", provId: 16 },
  { district: "PONTO", provId: 16 },
  { district: "RAHUAPAMPA", provId: 16 },
  { district: "RAPAYAN", provId: 16 },
  { district: "SAN MARCOS", provId: 16 },
  { district: "SAN PEDRO DE CHANA", provId: 16 },
  { district: "UCO", provId: 16 },
  { district: "HUARMEY", provId: 17 },
  { district: "COCHAPETI", provId: 17 },
  { district: "CULEBRAS", provId: 17 },
  { district: "HUAYAN", provId: 17 },
  { district: "MALVAS", provId: 17 },
  { district: "CARAZ", provId: 18 },
  { district: "HUALLANCA", provId: 18 },
  { district: "HUATA", provId: 18 },
  { district: "HUAYLAS", provId: 18 },
  { district: "MATO", provId: 18 },
  { district: "PAMPAROMAS", provId: 18 },
  { district: "PUEBLO LIBRE", provId: 18 },
  { district: "SANTA CRUZ", provId: 18 },
  { district: "SANTO TORIBIO", provId: 18 },
  { district: "YURACMARCA", provId: 18 },
  { district: "PISCOBAMBA", provId: 19 },
  { district: "CASCA", provId: 19 },
  { district: "ELEAZAR GUZMAN BARRON", provId: 19 },
  { district: "FIDEL OLIVAS ESCUDERO", provId: 19 },
  { district: "LLAMA", provId: 19 },
  { district: "LLUMPA", provId: 19 },
  { district: "LUCMA", provId: 19 },
  { district: "MUSGA", provId: 19 },
  { district: "OCROS", provId: 20 },
  { district: "ACAS", provId: 20 },
  { district: "CAJAMARQUILLA", provId: 20 },
  { district: "CARHUAPAMPA", provId: 20 },
  { district: "COCHAS", provId: 20 },
  { district: "CONGAS", provId: 20 },
  { district: "LLIPA", provId: 20 },
  { district: "SAN CRISTOBAL DE RAJAN", provId: 20 },
  { district: "SAN PEDRO", provId: 20 },
  { district: "SANTIAGO DE CHILCAS", provId: 20 },
  { district: "CABANA", provId: 21 },
  { district: "BOLOGNESI", provId: 21 },
  { district: "CONCHUCOS", provId: 21 },
  { district: "HUACASCHUQUE", provId: 21 },
  { district: "HUANDOVAL", provId: 21 },
  { district: "LACABAMBA", provId: 21 },
  { district: "LLAPO", provId: 21 },
  { district: "PALLASCA", provId: 21 },
  { district: "PAMPAS", provId: 21 },
  { district: "SANTA ROSA", provId: 21 },
  { district: "TAUCA", provId: 21 },
  { district: "POMABAMBA", provId: 22 },
  { district: "HUAYLLAN", provId: 22 },
  { district: "PAROBAMBA", provId: 22 },
  { district: "QUINUABAMBA", provId: 22 },
  { district: "RECUAY", provId: 23 },
  { district: "CATAC", provId: 23 },
  { district: "COTAPARACO", provId: 23 },
  { district: "HUAYLLAPAMPA", provId: 23 },
  { district: "LLACLLIN", provId: 23 },
  { district: "MARCA", provId: 23 },
  { district: "PAMPAS CHICO", provId: 23 },
  { district: "PARARIN", provId: 23 },
  { district: "TAPACOCHA", provId: 23 },
  { district: "TICAPAMPA", provId: 23 },
  { district: "CHIMBOTE", provId: 24 },
  { district: "CACERES DEL PERU", provId: 24 },
  { district: "COISHCO", provId: 24 },
  { district: "MACATE", provId: 24 },
  { district: "MORO", provId: 24 },
  { district: "NEPEÑA", provId: 24 },
  { district: "SAMANCO", provId: 24 },
  { district: "SANTA", provId: 24 },
  { district: "NUEVO CHIMBOTE", provId: 24 },
  { district: "SIHUAS", provId: 25 },
  { district: "ACOBAMBA", provId: 25 },
  { district: "ALFONSO UGARTE", provId: 25 },
  { district: "CASHAPAMPA", provId: 25 },
  { district: "CHINGALPO", provId: 25 },
  { district: "HUAYLLABAMBA", provId: 25 },
  { district: "QUICHES", provId: 25 },
  { district: "RAGASH", provId: 25 },
  { district: "SAN JUAN", provId: 25 },
  { district: "SICSIBAMBA", provId: 25 },
  { district: "YUNGAY", provId: 26 },
  { district: "CASCAPARA", provId: 26 },
  { district: "MANCOS", provId: 26 },
  { district: "MATACOTO", provId: 26 },
  { district: "QUILLO", provId: 26 },
  { district: "RANRAHIRCA", provId: 26 },
  { district: "SHUPLUY", provId: 26 },
  { district: "YANAMA", provId: 26 },
  { district: "ABANCAY", provId: 27 },
  { district: "CHACOCHE", provId: 27 },
  { district: "CIRCA", provId: 27 },
  { district: "CURAHUASI", provId: 27 },
  { district: "HUANIPACA", provId: 27 },
  { district: "LAMBRAMA", provId: 27 },
  { district: "PICHIRHUA", provId: 27 },
  { district: "SAN PEDRO DE CACHORA", provId: 27 },
  { district: "TAMBURCO", provId: 27 },
  { district: "ANDAHUAYLAS", provId: 28 },
  { district: "ANDARAPA", provId: 28 },
  { district: "CHIARA", provId: 28 },
  { district: "HUANCARAMA", provId: 28 },
  { district: "HUANCARAY", provId: 28 },
  { district: "HUAYANA", provId: 28 },
  { district: "KISHUARA", provId: 28 },
  { district: "PACOBAMBA", provId: 28 },
  { district: "PACUCHA", provId: 28 },
  { district: "PAMPACHIRI", provId: 28 },
  { district: "POMACOCHA", provId: 28 },
  { district: "SAN ANTONIO DE CACHI", provId: 28 },
  { district: "SAN JERONIMO", provId: 28 },
  { district: "SAN MIGUEL DE CHACCRAMPA", provId: 28 },
  { district: "SANTA MARIA DE CHICMO", provId: 28 },
  { district: "TALAVERA", provId: 28 },
  { district: "TUMAY HUARACA", provId: 28 },
  { district: "TURPO", provId: 28 },
  { district: "KAQUIABAMBA", provId: 28 },
  { district: "ANTABAMBA", provId: 29 },
  { district: "EL ORO", provId: 29 },
  { district: "HUAQUIRCA", provId: 29 },
  { district: "JUAN ESPINOZA MEDRANO", provId: 29 },
  { district: "OROPESA", provId: 29 },
  { district: "PACHACONAS", provId: 29 },
  { district: "SABAINO", provId: 29 },
  { district: "CHALHUANCA", provId: 30 },
  { district: "CAPAYA", provId: 30 },
  { district: "CARAYBAMBA", provId: 30 },
  { district: "CHAPIMARCA", provId: 30 },
  { district: "COLCABAMBA", provId: 30 },
  { district: "COTARUSE", provId: 30 },
  { district: "HUAYLLO", provId: 30 },
  { district: "JUSTO APU SAHUARAURA", provId: 30 },
  { district: "LUCRE", provId: 30 },
  { district: "POCOHUANCA", provId: 30 },
  { district: "SAN JUAN DE CHACÑA", provId: 30 },
  { district: "SAÑAYCA", provId: 30 },
  { district: "SORAYA", provId: 30 },
  { district: "TAPAIRIHUA", provId: 30 },
  { district: "TINTAY", provId: 30 },
  { district: "TORAYA", provId: 30 },
  { district: "YANACA", provId: 30 },
  { district: "TAMBOBAMBA", provId: 31 },
  { district: "COTABAMBAS", provId: 31 },
  { district: "COYLLURQUI", provId: 31 },
  { district: "HAQUIRA", provId: 31 },
  { district: "MARA", provId: 31 },
  { district: "CHALLHUAHUACHO", provId: 31 },
  { district: "CHINCHEROS", provId: 32 },
  { district: "ANCO-HUALLO", provId: 32 },
  { district: "COCHARCAS", provId: 32 },
  { district: "HUACCANA", provId: 32 },
  { district: "OCOBAMBA", provId: 32 },
  { district: "ONGOY", provId: 32 },
  { district: "URANMARCA", provId: 32 },
  { district: "RANRACANCHA", provId: 32 },
  { district: "CHUQUIBAMBILLA", provId: 33 },
  { district: "CURPAHUASI", provId: 33 },
  { district: "GAMARRA", provId: 33 },
  { district: "HUAYLLATI", provId: 33 },
  { district: "MAMARA", provId: 33 },
  { district: "MICAELA BASTIDAS", provId: 33 },
  { district: "PATAYPAMPA", provId: 33 },
  { district: "PROGRESO", provId: 33 },
  { district: "SAN ANTONIO", provId: 33 },
  { district: "SANTA ROSA", provId: 33 },
  { district: "TURPAY", provId: 33 },
  { district: "VILCABAMBA", provId: 33 },
  { district: "VIRUNDO", provId: 33 },
  { district: "CURASCO", provId: 33 },
  { district: "AREQUIPA", provId: 34 },
  { district: "ALTO SELVA ALEGRE", provId: 34 },
  { district: "CAYMA", provId: 34 },
  { district: "CERRO COLORADO", provId: 34 },
  { district: "CHARACATO", provId: 34 },
  { district: "CHIGUATA", provId: 34 },
  { district: "JACOBO HUNTER", provId: 34 },
  { district: "LA JOYA", provId: 34 },
  { district: "MARIANO MELGAR", provId: 34 },
  { district: "MIRAFLORES", provId: 34 },
  { district: "MOLLEBAYA", provId: 34 },
  { district: "PAUCARPATA", provId: 34 },
  { district: "POCSI", provId: 34 },
  { district: "POLOBAYA", provId: 34 },
  { district: "QUEQUEÑA", provId: 34 },
  { district: "SABANDIA", provId: 34 },
  { district: "SACHACA", provId: 34 },
  { district: "SAN JUAN DE SIGUAS", provId: 34 },
  { district: "SAN JUAN DE TARUCANI", provId: 34 },
  { district: "SANTA ISABEL DE SIGUAS", provId: 34 },
  { district: "SANTA RITA DE SIGUAS", provId: 34 },
  { district: "SOCABAYA", provId: 34 },
  { district: "TIABAYA", provId: 34 },
  { district: "UCHUMAYO", provId: 34 },
  { district: "VITOR", provId: 34 },
  { district: "YANAHUARA", provId: 34 },
  { district: "YARABAMBA", provId: 34 },
  { district: "YURA", provId: 34 },
  { district: "JOSE LUIS BUSTAMANTE Y RIVERO", provId: 34 },
  { district: "CAMANA", provId: 35 },
  { district: "JOSE MARIA QUIMPER", provId: 35 },
  { district: "MARIANO NICOLAS VALCARCEL", provId: 35 },
  { district: "MARISCAL CACERES", provId: 35 },
  { district: "NICOLAS DE PIEROLA", provId: 35 },
  { district: "OCOÑA", provId: 35 },
  { district: "QUILCA", provId: 35 },
  { district: "SAMUEL PASTOR", provId: 35 },
  { district: "CARAVELI", provId: 36 },
  { district: "ACARI", provId: 36 },
  { district: "ATICO", provId: 36 },
  { district: "ATIQUIPA", provId: 36 },
  { district: "BELLA UNION", provId: 36 },
  { district: "CAHUACHO", provId: 36 },
  { district: "CHALA", provId: 36 },
  { district: "CHAPARRA", provId: 36 },
  { district: "HUANUHUANU", provId: 36 },
  { district: "JAQUI", provId: 36 },
  { district: "LOMAS", provId: 36 },
  { district: "QUICACHA", provId: 36 },
  { district: "YAUCA", provId: 36 },
  { district: "APLAO", provId: 37 },
  { district: "ANDAGUA", provId: 37 },
  { district: "AYO", provId: 37 },
  { district: "CHACHAS", provId: 37 },
  { district: "CHILCAYMARCA", provId: 37 },
  { district: "CHOCO", provId: 37 },
  { district: "HUANCARQUI", provId: 37 },
  { district: "MACHAGUAY", provId: 37 },
  { district: "ORCOPAMPA", provId: 37 },
  { district: "PAMPACOLCA", provId: 37 },
  { district: "TIPAN", provId: 37 },
  { district: "UÑON", provId: 37 },
  { district: "URACA", provId: 37 },
  { district: "VIRACO", provId: 37 },
  { district: "CHIVAY", provId: 38 },
  { district: "ACHOMA", provId: 38 },
  { district: "CABANACONDE", provId: 38 },
  { district: "CALLALLI", provId: 38 },
  { district: "CAYLLOMA", provId: 38 },
  { district: "COPORAQUE", provId: 38 },
  { district: "HUAMBO", provId: 38 },
  { district: "HUANCA", provId: 38 },
  { district: "ICHUPAMPA", provId: 38 },
  { district: "LARI", provId: 38 },
  { district: "LLUTA", provId: 38 },
  { district: "MACA", provId: 38 },
  { district: "MADRIGAL", provId: 38 },
  { district: "SAN ANTONIO DE CHUCA", provId: 38 },
  { district: "SIBAYO", provId: 38 },
  { district: "TAPAY", provId: 38 },
  { district: "TISCO", provId: 38 },
  { district: "TUTI", provId: 38 },
  { district: "YANQUE", provId: 38 },
  { district: "MAJES", provId: 38 },
  { district: "CHUQUIBAMBA", provId: 39 },
  { district: "ANDARAY", provId: 39 },
  { district: "CAYARANI", provId: 39 },
  { district: "CHICHAS", provId: 39 },
  { district: "IRAY", provId: 39 },
  { district: "RIO GRANDE", provId: 39 },
  { district: "SALAMANCA", provId: 39 },
  { district: "YANAQUIHUA", provId: 39 },
  { district: "MOLLENDO", provId: 40 },
  { district: "COCACHACRA", provId: 40 },
  { district: "DEAN VALDIVIA", provId: 40 },
  { district: "ISLAY", provId: 40 },
  { district: "MEJIA", provId: 40 },
  { district: "PUNTA DE BOMBON", provId: 40 },
  { district: "COTAHUASI", provId: 41 },
  { district: "ALCA", provId: 41 },
  { district: "CHARCANA", provId: 41 },
  { district: "HUAYNACOTAS", provId: 41 },
  { district: "PAMPAMARCA", provId: 41 },
  { district: "PUYCA", provId: 41 },
  { district: "QUECHUALLA", provId: 41 },
  { district: "SAYLA", provId: 41 },
  { district: "TAURIA", provId: 41 },
  { district: "TOMEPAMPA", provId: 41 },
  { district: "TORO", provId: 41 },
  { district: "AYACUCHO", provId: 42 },
  { district: "ACOCRO", provId: 42 },
  { district: "ACOS VINCHOS", provId: 42 },
  { district: "CARMEN ALTO", provId: 42 },
  { district: "CHIARA", provId: 42 },
  { district: "OCROS", provId: 42 },
  { district: "PACAYCASA", provId: 42 },
  { district: "QUINUA", provId: 42 },
  { district: "SAN JOSE DE TICLLAS", provId: 42 },
  { district: "SAN JUAN BAUTISTA", provId: 42 },
  { district: "SANTIAGO DE PISCHA", provId: 42 },
  { district: "SOCOS", provId: 42 },
  { district: "TAMBILLO", provId: 42 },
  { district: "VINCHOS", provId: 42 },
  { district: "JESUS NAZARENO", provId: 42 },
  { district: "CANGALLO", provId: 43 },
  { district: "CHUSCHI", provId: 43 },
  { district: "LOS MOROCHUCOS", provId: 43 },
  { district: "MARIA PARADO DE BELLIDO", provId: 43 },
  { district: "PARAS", provId: 43 },
  { district: "TOTOS", provId: 43 },
  { district: "SANCOS", provId: 44 },
  { district: "CARAPO", provId: 44 },
  { district: "SACSAMARCA", provId: 44 },
  { district: "SANTIAGO DE LUCANAMARCA", provId: 44 },
  { district: "HUANTA", provId: 45 },
  { district: "AYAHUANCO", provId: 45 },
  { district: "HUAMANGUILLA", provId: 45 },
  { district: "IGUAIN", provId: 45 },
  { district: "LURICOCHA", provId: 45 },
  { district: "SANTILLANA", provId: 45 },
  { district: "SIVIA", provId: 45 },
  { district: "LLOCHEGUA", provId: 45 },
  { district: "SAN MIGUEL", provId: 46 },
  { district: "ANCO", provId: 46 },
  { district: "AYNA", provId: 46 },
  { district: "CHILCAS", provId: 46 },
  { district: "CHUNGUI", provId: 46 },
  { district: "LUIS CARRANZA", provId: 46 },
  { district: "SANTA ROSA", provId: 46 },
  { district: "TAMBO", provId: 46 },
  { district: "PUQUIO", provId: 47 },
  { district: "AUCARA", provId: 47 },
  { district: "CABANA", provId: 47 },
  { district: "CARMEN SALCEDO", provId: 47 },
  { district: "CHAVIÑA", provId: 47 },
  { district: "CHIPAO", provId: 47 },
  { district: "HUAC-HUAS", provId: 47 },
  { district: "LARAMATE", provId: 47 },
  { district: "LEONCIO PRADO", provId: 47 },
  { district: "LLAUTA", provId: 47 },
  { district: "LUCANAS", provId: 47 },
  { district: "OCAÑA", provId: 47 },
  { district: "OTOCA", provId: 47 },
  { district: "SAISA", provId: 47 },
  { district: "SAN CRISTOBAL", provId: 47 },
  { district: "SAN JUAN", provId: 47 },
  { district: "SAN PEDRO", provId: 47 },
  { district: "SAN PEDRO DE PALCO", provId: 47 },
  { district: "SANCOS", provId: 47 },
  { district: "SANTA ANA DE HUAYCAHUACHO", provId: 47 },
  { district: "SANTA LUCIA", provId: 47 },
  { district: "CORACORA", provId: 48 },
  { district: "CHUMPI", provId: 48 },
  { district: "CORONEL CASTAÑEDA", provId: 48 },
  { district: "PACAPAUSA", provId: 48 },
  { district: "PULLO", provId: 48 },
  { district: "PUYUSCA", provId: 48 },
  { district: "SAN FRANCISCO DE RAVACAYCO", provId: 48 },
  { district: "UPAHUACHO", provId: 48 },
  { district: "PAUSA", provId: 49 },
  { district: "COLTA", provId: 49 },
  { district: "CORCULLA", provId: 49 },
  { district: "LAMPA", provId: 49 },
  { district: "MARCABAMBA", provId: 49 },
  { district: "OYOLO", provId: 49 },
  { district: "PARARCA", provId: 49 },
  { district: "SAN JAVIER DE ALPABAMBA", provId: 49 },
  { district: "SAN JOSE DE USHUA", provId: 49 },
  { district: "SARA SARA", provId: 49 },
  { district: "QUEROBAMBA", provId: 50 },
  { district: "BELEN", provId: 50 },
  { district: "CHALCOS", provId: 50 },
  { district: "CHILCAYOC", provId: 50 },
  { district: "HUACAÑA", provId: 50 },
  { district: "MORCOLLA", provId: 50 },
  { district: "PAICO", provId: 50 },
  { district: "SAN PEDRO DE LARCAY", provId: 50 },
  { district: "SAN SALVADOR DE QUIJE", provId: 50 },
  { district: "SANTIAGO DE PAUCARAY", provId: 50 },
  { district: "SORAS", provId: 50 },
  { district: "HUANCAPI", provId: 51 },
  { district: "ALCAMENCA", provId: 51 },
  { district: "APONGO", provId: 51 },
  { district: "ASQUIPATA", provId: 51 },
  { district: "CANARIA", provId: 51 },
  { district: "CAYARA", provId: 51 },
  { district: "COLCA", provId: 51 },
  { district: "HUAMANQUIQUIA", provId: 51 },
  { district: "HUANCARAYLLA", provId: 51 },
  { district: "HUAYA", provId: 51 },
  { district: "SARHUA", provId: 51 },
  { district: "VILCANCHOS", provId: 51 },
  { district: "VILCAS HUAMAN", provId: 52 },
  { district: "ACCOMARCA", provId: 52 },
  { district: "CARHUANCA", provId: 52 },
  { district: "CONCEPCION", provId: 52 },
  { district: "HUAMBALPA", provId: 52 },
  { district: "INDEPENDENCIA", provId: 52 },
  { district: "SAURAMA", provId: 52 },
  { district: "VISCHONGO", provId: 52 },
  { district: "CAJAMARCA", provId: 53 },
  { district: "ASUNCION", provId: 53 },
  { district: "CHETILLA", provId: 53 },
  { district: "COSPAN", provId: 53 },
  { district: "ENCAÑADA", provId: 53 },
  { district: "JESUS", provId: 53 },
  { district: "LLACANORA", provId: 53 },
  { district: "LOS BAÑOS DEL INCA", provId: 53 },
  { district: "MAGDALENA", provId: 53 },
  { district: "MATARA", provId: 53 },
  { district: "NAMORA", provId: 53 },
  { district: "SAN JUAN", provId: 53 },
  { district: "CAJABAMBA", provId: 54 },
  { district: "CACHACHI", provId: 54 },
  { district: "CONDEBAMBA", provId: 54 },
  { district: "SITACOCHA", provId: 54 },
  { district: "CELENDIN", provId: 55 },
  { district: "CHUMUCH", provId: 55 },
  { district: "CORTEGANA", provId: 55 },
  { district: "HUASMIN", provId: 55 },
  { district: "JORGE CHAVEZ", provId: 55 },
  { district: "JOSE GALVEZ", provId: 55 },
  { district: "MIGUEL IGLESIAS", provId: 55 },
  { district: "OXAMARCA", provId: 55 },
  { district: "SOROCHUCO", provId: 55 },
  { district: "SUCRE", provId: 55 },
  { district: "UTCO", provId: 55 },
  { district: "LA LIBERTAD DE PALLAN", provId: 55 },
  { district: "CHOTA", provId: 56 },
  { district: "ANGUIA", provId: 56 },
  { district: "CHADIN", provId: 56 },
  { district: "CHIGUIRIP", provId: 56 },
  { district: "CHIMBAN", provId: 56 },
  { district: "CHOROPAMPA", provId: 56 },
  { district: "COCHABAMBA", provId: 56 },
  { district: "CONCHAN", provId: 56 },
  { district: "HUAMBOS", provId: 56 },
  { district: "LAJAS", provId: 56 },
  { district: "LLAMA", provId: 56 },
  { district: "MIRACOSTA", provId: 56 },
  { district: "PACCHA", provId: 56 },
  { district: "PION", provId: 56 },
  { district: "QUEROCOTO", provId: 56 },
  { district: "SAN JUAN DE LICUPIS", provId: 56 },
  { district: "TACABAMBA", provId: 56 },
  { district: "TOCMOCHE", provId: 56 },
  { district: "CHALAMARCA", provId: 56 },
  { district: "CONTUMAZA", provId: 57 },
  { district: "CHILETE", provId: 57 },
  { district: "CUPISNIQUE", provId: 57 },
  { district: "GUZMANGO", provId: 57 },
  { district: "SAN BENITO", provId: 57 },
  { district: "SANTA CRUZ DE TOLEDO", provId: 57 },
  { district: "TANTARICA", provId: 57 },
  { district: "YONAN", provId: 57 },
  { district: "CUTERVO", provId: 58 },
  { district: "CALLAYUC", provId: 58 },
  { district: "CHOROS", provId: 58 },
  { district: "CUJILLO", provId: 58 },
  { district: "LA RAMADA", provId: 58 },
  { district: "PIMPINGOS", provId: 58 },
  { district: "QUEROCOTILLO", provId: 58 },
  { district: "SAN ANDRES DE CUTERVO", provId: 58 },
  { district: "SAN JUAN DE CUTERVO", provId: 58 },
  { district: "SAN LUIS DE LUCMA", provId: 58 },
  { district: "SANTA CRUZ", provId: 58 },
  { district: "SANTO DOMINGO DE LA CAPILLA", provId: 58 },
  { district: "SANTO TOMAS", provId: 58 },
  { district: "SOCOTA", provId: 58 },
  { district: "TORIBIO CASANOVA", provId: 58 },
  { district: "BAMBAMARCA", provId: 59 },
  { district: "CHUGUR", provId: 59 },
  { district: "HUALGAYOC", provId: 59 },
  { district: "JAEN", provId: 60 },
  { district: "BELLAVISTA", provId: 60 },
  { district: "CHONTALI", provId: 60 },
  { district: "COLASAY", provId: 60 },
  { district: "HUABAL", provId: 60 },
  { district: "LAS PIRIAS", provId: 60 },
  { district: "POMAHUACA", provId: 60 },
  { district: "PUCARA", provId: 60 },
  { district: "SALLIQUE", provId: 60 },
  { district: "SAN FELIPE", provId: 60 },
  { district: "SAN JOSE DEL ALTO", provId: 60 },
  { district: "SANTA ROSA", provId: 60 },
  { district: "SAN IGNACIO", provId: 61 },
  { district: "CHIRINOS", provId: 61 },
  { district: "HUARANGO", provId: 61 },
  { district: "LA COIPA", provId: 61 },
  { district: "NAMBALLE", provId: 61 },
  { district: "SAN JOSE DE LOURDES", provId: 61 },
  { district: "TABACONAS", provId: 61 },
  { district: "PEDRO GALVEZ", provId: 62 },
  { district: "CHANCAY", provId: 62 },
  { district: "EDUARDO VILLANUEVA", provId: 62 },
  { district: "GREGORIO PITA", provId: 62 },
  { district: "ICHOCAN", provId: 62 },
  { district: "JOSE MANUEL QUIROZ", provId: 62 },
  { district: "JOSE SABOGAL", provId: 62 },
  { district: "SAN MIGUEL", provId: 63 },
  { district: "BOLIVAR", provId: 63 },
  { district: "CALQUIS", provId: 63 },
  { district: "CATILLUC", provId: 63 },
  { district: "EL PRADO", provId: 63 },
  { district: "LA FLORIDA", provId: 63 },
  { district: "LLAPA", provId: 63 },
  { district: "NANCHOC", provId: 63 },
  { district: "NIEPOS", provId: 63 },
  { district: "SAN GREGORIO", provId: 63 },
  { district: "SAN SILVESTRE DE COCHAN", provId: 63 },
  { district: "TONGOD", provId: 63 },
  { district: "UNION AGUA BLANCA", provId: 63 },
  { district: "SAN PABLO", provId: 64 },
  { district: "SAN BERNARDINO", provId: 64 },
  { district: "SAN LUIS", provId: 64 },
  { district: "TUMBADEN", provId: 64 },
  { district: "SANTA CRUZ", provId: 65 },
  { district: "ANDABAMBA", provId: 65 },
  { district: "CATACHE", provId: 65 },
  { district: "CHANCAYBAÑOS", provId: 65 },
  { district: "LA ESPERANZA", provId: 65 },
  { district: "NINABAMBA", provId: 65 },
  { district: "PULAN", provId: 65 },
  { district: "SAUCEPAMPA", provId: 65 },
  { district: "SEXI", provId: 65 },
  { district: "UTICYACU", provId: 65 },
  { district: "YAUYUCAN", provId: 65 },
  { district: "CALLAO", provId: 66 },
  { district: "BELLAVISTA", provId: 66 },
  { district: "CARMEN DE LA LEGUA REYNOSO", provId: 66 },
  { district: "LA PERLA", provId: 66 },
  { district: "LA PUNTA", provId: 66 },
  { district: "VENTANILLA", provId: 66 },
  { district: "MI PERU", provId: 66 },
  { district: "CUSCO", provId: 67 },
  { district: "CCORCA", provId: 67 },
  { district: "POROY", provId: 67 },
  { district: "SAN JERONIMO", provId: 67 },
  { district: "SAN SEBASTIAN", provId: 67 },
  { district: "SANTIAGO", provId: 67 },
  { district: "SAYLLA", provId: 67 },
  { district: "WANCHAQ", provId: 67 },
  { district: "ACOMAYO", provId: 68 },
  { district: "ACOPIA", provId: 68 },
  { district: "ACOS", provId: 68 },
  { district: "MOSOC LLACTA", provId: 68 },
  { district: "POMACANCHI", provId: 68 },
  { district: "RONDOCAN", provId: 68 },
  { district: "SANGARARA", provId: 68 },
  { district: "ANTA", provId: 69 },
  { district: "ANCAHUASI", provId: 69 },
  { district: "CACHIMAYO", provId: 69 },
  { district: "CHINCHAYPUJIO", provId: 69 },
  { district: "HUAROCONDO", provId: 69 },
  { district: "LIMATAMBO", provId: 69 },
  { district: "MOLLEPATA", provId: 69 },
  { district: "PUCYURA", provId: 69 },
  { district: "ZURITE", provId: 69 },
  { district: "CALCA", provId: 70 },
  { district: "COYA", provId: 70 },
  { district: "LAMAY", provId: 70 },
  { district: "LARES", provId: 70 },
  { district: "PISAC", provId: 70 },
  { district: "SAN SALVADOR", provId: 70 },
  { district: "TARAY", provId: 70 },
  { district: "YANATILE", provId: 70 },
  { district: "YANAOCA", provId: 71 },
  { district: "CHECCA", provId: 71 },
  { district: "KUNTURKANKI", provId: 71 },
  { district: "LANGUI", provId: 71 },
  { district: "LAYO", provId: 71 },
  { district: "PAMPAMARCA", provId: 71 },
  { district: "QUEHUE", provId: 71 },
  { district: "TUPAC AMARU", provId: 71 },
  { district: "SICUANI", provId: 72 },
  { district: "CHECACUPE", provId: 72 },
  { district: "COMBAPATA", provId: 72 },
  { district: "MARANGANI", provId: 72 },
  { district: "PITUMARCA", provId: 72 },
  { district: "SAN PABLO", provId: 72 },
  { district: "SAN PEDRO", provId: 72 },
  { district: "TINTA", provId: 72 },
  { district: "SANTO TOMAS", provId: 73 },
  { district: "CAPACMARCA", provId: 73 },
  { district: "CHAMACA", provId: 73 },
  { district: "COLQUEMARCA", provId: 73 },
  { district: "LIVITACA", provId: 73 },
  { district: "LLUSCO", provId: 73 },
  { district: "QUIÑOTA", provId: 73 },
  { district: "VELILLE", provId: 73 },
  { district: "ESPINAR", provId: 74 },
  { district: "CONDOROMA", provId: 74 },
  { district: "COPORAQUE", provId: 74 },
  { district: "OCORURO", provId: 74 },
  { district: "PALLPATA", provId: 74 },
  { district: "PICHIGUA", provId: 74 },
  { district: "SUYCKUTAMBO", provId: 74 },
  { district: "ALTO PICHIGUA", provId: 74 },
  { district: "SANTA ANA", provId: 75 },
  { district: "ECHARATE", provId: 75 },
  { district: "HUAYOPATA", provId: 75 },
  { district: "MARANURA", provId: 75 },
  { district: "OCOBAMBA", provId: 75 },
  { district: "QUELLOUNO", provId: 75 },
  { district: "KIMBIRI", provId: 75 },
  { district: "SANTA TERESA", provId: 75 },
  { district: "VILCABAMBA", provId: 75 },
  { district: "PICHARI", provId: 75 },
  { district: "PARURO", provId: 76 },
  { district: "ACCHA", provId: 76 },
  { district: "CCAPI", provId: 76 },
  { district: "COLCHA", provId: 76 },
  { district: "HUANOQUITE", provId: 76 },
  { district: "OMACHA", provId: 76 },
  { district: "PACCARITAMBO", provId: 76 },
  { district: "PILLPINTO", provId: 76 },
  { district: "YAURISQUE", provId: 76 },
  { district: "PAUCARTAMBO", provId: 77 },
  { district: "CAICAY", provId: 77 },
  { district: "CHALLABAMBA", provId: 77 },
  { district: "COLQUEPATA", provId: 77 },
  { district: "HUANCARANI", provId: 77 },
  { district: "KOSÑIPATA", provId: 77 },
  { district: "URCOS", provId: 78 },
  { district: "ANDAHUAYLILLAS", provId: 78 },
  { district: "CAMANTI", provId: 78 },
  { district: "CCARHUAYO", provId: 78 },
  { district: "CCATCA", provId: 78 },
  { district: "CUSIPATA", provId: 78 },
  { district: "HUARO", provId: 78 },
  { district: "LUCRE", provId: 78 },
  { district: "MARCAPATA", provId: 78 },
  { district: "OCONGATE", provId: 78 },
  { district: "OROPESA", provId: 78 },
  { district: "QUIQUIJANA", provId: 78 },
  { district: "URUBAMBA", provId: 79 },
  { district: "CHINCHERO", provId: 79 },
  { district: "HUAYLLABAMBA", provId: 79 },
  { district: "MACHUPICCHU", provId: 79 },
  { district: "MARAS", provId: 79 },
  { district: "OLLANTAYTAMBO", provId: 79 },
  { district: "YUCAY", provId: 79 },
  { district: "HUANCAVELICA", provId: 80 },
  { district: "ACOBAMBILLA", provId: 80 },
  { district: "ACORIA", provId: 80 },
  { district: "CONAYCA", provId: 80 },
  { district: "CUENCA", provId: 80 },
  { district: "HUACHOCOLPA", provId: 80 },
  { district: "HUAYLLAHUARA", provId: 80 },
  { district: "IZCUCHACA", provId: 80 },
  { district: "LARIA", provId: 80 },
  { district: "MANTA", provId: 80 },
  { district: "MARISCAL CACERES", provId: 80 },
  { district: "MOYA", provId: 80 },
  { district: "NUEVO OCCORO", provId: 80 },
  { district: "PALCA", provId: 80 },
  { district: "PILCHACA", provId: 80 },
  { district: "VILCA", provId: 80 },
  { district: "YAULI", provId: 80 },
  { district: "ASCENSION", provId: 80 },
  { district: "HUANDO", provId: 80 },
  { district: "ACOBAMBA", provId: 81 },
  { district: "ANDABAMBA", provId: 81 },
  { district: "ANTA", provId: 81 },
  { district: "CAJA", provId: 81 },
  { district: "MARCAS", provId: 81 },
  { district: "PAUCARA", provId: 81 },
  { district: "POMACOCHA", provId: 81 },
  { district: "ROSARIO", provId: 81 },
  { district: "LIRCAY", provId: 82 },
  { district: "ANCHONGA", provId: 82 },
  { district: "CALLANMARCA", provId: 82 },
  { district: "CCOCHACCASA", provId: 82 },
  { district: "CHINCHO", provId: 82 },
  { district: "CONGALLA", provId: 82 },
  { district: "HUANCA-HUANCA", provId: 82 },
  { district: "HUAYLLAY GRANDE", provId: 82 },
  { district: "JULCAMARCA", provId: 82 },
  { district: "SAN ANTONIO DE ANTAPARCO", provId: 82 },
  { district: "SANTO TOMAS DE PATA", provId: 82 },
  { district: "SECCLLA", provId: 82 },
  { district: "CASTROVIRREYNA", provId: 83 },
  { district: "ARMA", provId: 83 },
  { district: "AURAHUA", provId: 83 },
  { district: "CAPILLAS", provId: 83 },
  { district: "CHUPAMARCA", provId: 83 },
  { district: "COCAS", provId: 83 },
  { district: "HUACHOS", provId: 83 },
  { district: "HUAMATAMBO", provId: 83 },
  { district: "MOLLEPAMPA", provId: 83 },
  { district: "SAN JUAN", provId: 83 },
  { district: "SANTA ANA", provId: 83 },
  { district: "TANTARA", provId: 83 },
  { district: "TICRAPO", provId: 83 },
  { district: "CHURCAMPA", provId: 84 },
  { district: "ANCO", provId: 84 },
  { district: "CHINCHIHUASI", provId: 84 },
  { district: "EL CARMEN", provId: 84 },
  { district: "LA MERCED", provId: 84 },
  { district: "LOCROJA", provId: 84 },
  { district: "PAUCARBAMBA", provId: 84 },
  { district: "SAN MIGUEL DE MAYOCC", provId: 84 },
  { district: "SAN PEDRO DE CORIS", provId: 84 },
  { district: "PACHAMARCA", provId: 84 },
  { district: "HUAYTARA", provId: 85 },
  { district: "AYAVI", provId: 85 },
  { district: "CORDOVA", provId: 85 },
  { district: "HUAYACUNDO ARMA", provId: 85 },
  { district: "LARAMARCA", provId: 85 },
  { district: "OCOYO", provId: 85 },
  { district: "PILPICHACA", provId: 85 },
  { district: "QUERCO", provId: 85 },
  { district: "QUITO-ARMA", provId: 85 },
  { district: "SAN ANTONIO DE CUSICANCHA", provId: 85 },
  { district: "SAN FRANCISCO DE SANGAYAICO", provId: 85 },
  { district: "SAN ISIDRO", provId: 85 },
  { district: "SANTIAGO DE CHOCORVOS", provId: 85 },
  { district: "SANTIAGO DE QUIRAHUARA", provId: 85 },
  { district: "SANTO DOMINGO DE CAPILLAS", provId: 85 },
  { district: "TAMBO", provId: 85 },
  { district: "PAMPAS", provId: 86 },
  { district: "ACOSTAMBO", provId: 86 },
  { district: "ACRAQUIA", provId: 86 },
  { district: "AHUAYCHA", provId: 86 },
  { district: "COLCABAMBA", provId: 86 },
  { district: "DANIEL HERNANDEZ", provId: 86 },
  { district: "HUACHOCOLPA", provId: 86 },
  { district: "HUARIBAMBA", provId: 86 },
  { district: "ÑAHUIMPUQUIO", provId: 86 },
  { district: "PAZOS", provId: 86 },
  { district: "QUISHUAR", provId: 86 },
  { district: "SALCABAMBA", provId: 86 },
  { district: "SALCAHUASI", provId: 86 },
  { district: "SAN MARCOS DE ROCCHAC", provId: 86 },
  { district: "SURCUBAMBA", provId: 86 },
  { district: "TINTAY PUNCU", provId: 86 },
  { district: "HUANUCO", provId: 87 },
  { district: "AMARILIS", provId: 87 },
  { district: "CHINCHAO", provId: 87 },
  { district: "CHURUBAMBA", provId: 87 },
  { district: "MARGOS", provId: 87 },
  { district: "QUISQUI", provId: 87 },
  { district: "SAN FRANCISCO DE CAYRAN", provId: 87 },
  { district: "SAN PEDRO DE CHAULAN", provId: 87 },
  { district: "SANTA MARIA DEL VALLE", provId: 87 },
  { district: "YARUMAYO", provId: 87 },
  { district: "PILLCO MARCA", provId: 87 },
  { district: "AMBO", provId: 88 },
  { district: "CAYNA", provId: 88 },
  { district: "COLPAS", provId: 88 },
  { district: "CONCHAMARCA", provId: 88 },
  { district: "HUACAR", provId: 88 },
  { district: "SAN FRANCISCO", provId: 88 },
  { district: "SAN RAFAEL", provId: 88 },
  { district: "TOMAY KICHWA", provId: 88 },
  { district: "LA UNION", provId: 89 },
  { district: "CHUQUIS", provId: 89 },
  { district: "MARIAS", provId: 89 },
  { district: "PACHAS", provId: 89 },
  { district: "QUIVILLA", provId: 89 },
  { district: "RIPAN", provId: 89 },
  { district: "SHUNQUI", provId: 89 },
  { district: "SILLAPATA", provId: 89 },
  { district: "YANAS", provId: 89 },
  { district: "HUACAYBAMBA", provId: 90 },
  { district: "CANCHABAMBA", provId: 90 },
  { district: "COCHABAMBA", provId: 90 },
  { district: "PINRA", provId: 90 },
  { district: "LLATA", provId: 91 },
  { district: "ARANCAY", provId: 91 },
  { district: "CHAVIN DE PARIARCA", provId: 91 },
  { district: "JACAS GRANDE", provId: 91 },
  { district: "JIRCAN", provId: 91 },
  { district: "MIRAFLORES", provId: 91 },
  { district: "MONZON", provId: 91 },
  { district: "PUNCHAO", provId: 91 },
  { district: "PUÑOS", provId: 91 },
  { district: "SINGA", provId: 91 },
  { district: "TANTAMAYO", provId: 91 },
  { district: "RUPA-RUPA", provId: 92 },
  { district: "DANIEL ALOMIA ROBLES", provId: 92 },
  { district: "HERMILIO VALDIZAN", provId: 92 },
  { district: "JOSE CRESPO Y CASTILLO", provId: 92 },
  { district: "LUYANDO", provId: 92 },
  { district: "MARIANO DAMASO BERAUN", provId: 92 },
  { district: "HUACRACHUCO", provId: 93 },
  { district: "CHOLON", provId: 93 },
  { district: "SAN BUENAVENTURA", provId: 93 },
  { district: "PANAO", provId: 94 },
  { district: "CHAGLLA", provId: 94 },
  { district: "MOLINO", provId: 94 },
  { district: "UMARI", provId: 94 },
  { district: "PUERTO INCA", provId: 95 },
  { district: "CODO DEL POZUZO", provId: 95 },
  { district: "HONORIA", provId: 95 },
  { district: "TOURNAVISTA", provId: 95 },
  { district: "YUYAPICHIS", provId: 95 },
  { district: "JESUS", provId: 96 },
  { district: "BAÑOS", provId: 96 },
  { district: "JIVIA", provId: 96 },
  { district: "QUEROPALCA", provId: 96 },
  { district: "RONDOS", provId: 96 },
  { district: "SAN FRANCISCO DE ASIS", provId: 96 },
  { district: "SAN MIGUEL DE CAURI", provId: 96 },
  { district: "CHAVINILLO", provId: 97 },
  { district: "CAHUAC", provId: 97 },
  { district: "CHACABAMBA", provId: 97 },
  { district: "APARICIO POMARES", provId: 97 },
  { district: "JACAS CHICO", provId: 97 },
  { district: "OBAS", provId: 97 },
  { district: "PAMPAMARCA", provId: 97 },
  { district: "CHORAS", provId: 97 },
  { district: "ICA", provId: 98 },
  { district: "LA TINGUIÑA", provId: 98 },
  { district: "LOS AQUIJES", provId: 98 },
  { district: "OCUCAJE", provId: 98 },
  { district: "PACHACUTEC", provId: 98 },
  { district: "PARCONA", provId: 98 },
  { district: "PUEBLO NUEVO", provId: 98 },
  { district: "SALAS", provId: 98 },
  { district: "SAN JOSE DE LOS MOLINOS", provId: 98 },
  { district: "SAN JUAN BAUTISTA", provId: 98 },
  { district: "SANTIAGO", provId: 98 },
  { district: "SUBTANJALLA", provId: 98 },
  { district: "TATE", provId: 98 },
  { district: "YAUCA DEL ROSARIO", provId: 98 },
  { district: "CHINCHA ALTA", provId: 99 },
  { district: "ALTO LARAN", provId: 99 },
  { district: "CHAVIN", provId: 99 },
  { district: "CHINCHA BAJA", provId: 99 },
  { district: "EL CARMEN", provId: 99 },
  { district: "GROCIO PRADO", provId: 99 },
  { district: "PUEBLO NUEVO", provId: 99 },
  { district: "SAN JUAN DE YANAC", provId: 99 },
  { district: "SAN PEDRO DE HUACARPANA", provId: 99 },
  { district: "SUNAMPE", provId: 99 },
  { district: "TAMBO DE MORA", provId: 99 },
  { district: "NAZCA", provId: 100 },
  { district: "CHANGUILLO", provId: 100 },
  { district: "EL INGENIO", provId: 100 },
  { district: "MARCONA", provId: 100 },
  { district: "VISTA ALEGRE", provId: 100 },
  { district: "PALPA", provId: 101 },
  { district: "LLIPATA", provId: 101 },
  { district: "RIO GRANDE", provId: 101 },
  { district: "SANTA CRUZ", provId: 101 },
  { district: "TIBILLO", provId: 101 },
  { district: "PISCO", provId: 102 },
  { district: "HUANCANO", provId: 102 },
  { district: "HUMAY", provId: 102 },
  { district: "INDEPENDENCIA", provId: 102 },
  { district: "PARACAS", provId: 102 },
  { district: "SAN ANDRES", provId: 102 },
  { district: "SAN CLEMENTE", provId: 102 },
  { district: "TUPAC AMARU INCA", provId: 102 },
  { district: "HUANCAYO", provId: 103 },
  { district: "CARHUACALLANGA", provId: 103 },
  { district: "CHACAPAMPA", provId: 103 },
  { district: "CHICCHE", provId: 103 },
  { district: "CHILCA", provId: 103 },
  { district: "CHONGOS ALTO", provId: 103 },
  { district: "CHUPURO", provId: 103 },
  { district: "COLCA", provId: 103 },
  { district: "CULLHUAS", provId: 103 },
  { district: "EL TAMBO", provId: 103 },
  { district: "HUACRAPUQUIO", provId: 103 },
  { district: "HUALHUAS", provId: 103 },
  { district: "HUANCAN", provId: 103 },
  { district: "HUASICANCHA", provId: 103 },
  { district: "HUAYUCACHI", provId: 103 },
  { district: "INGENIO", provId: 103 },
  { district: "PARIAHUANCA", provId: 103 },
  { district: "PILCOMAYO", provId: 103 },
  { district: "PUCARA", provId: 103 },
  { district: "QUICHUAY", provId: 103 },
  { district: "QUILCAS", provId: 103 },
  { district: "SAN AGUSTIN", provId: 103 },
  { district: "SAN JERONIMO DE TUNAN", provId: 103 },
  { district: "SAÑO", provId: 103 },
  { district: "SAPALLANGA", provId: 103 },
  { district: "SICAYA", provId: 103 },
  { district: "SANTO DOMINGO DE ACOBAMBA", provId: 103 },
  { district: "VIQUES", provId: 103 },
  { district: "CONCEPCION", provId: 104 },
  { district: "ACO", provId: 104 },
  { district: "ANDAMARCA", provId: 104 },
  { district: "CHAMBARA", provId: 104 },
  { district: "COCHAS", provId: 104 },
  { district: "COMAS", provId: 104 },
  { district: "HEROINAS TOLEDO", provId: 104 },
  { district: "MANZANARES", provId: 104 },
  { district: "MARISCAL CASTILLA", provId: 104 },
  { district: "MATAHUASI", provId: 104 },
  { district: "MITO", provId: 104 },
  { district: "NUEVE DE JULIO", provId: 104 },
  { district: "ORCOTUNA", provId: 104 },
  { district: "SAN JOSE DE QUERO", provId: 104 },
  { district: "SANTA ROSA DE OCOPA", provId: 104 },
  { district: "CHANCHAMAYO", provId: 105 },
  { district: "PERENE", provId: 105 },
  { district: "PICHANAQUI", provId: 105 },
  { district: "SAN LUIS DE SHUARO", provId: 105 },
  { district: "SAN RAMON", provId: 105 },
  { district: "VITOC", provId: 105 },
  { district: "JAUJA", provId: 106 },
  { district: "ACOLLA", provId: 106 },
  { district: "APATA", provId: 106 },
  { district: "ATAURA", provId: 106 },
  { district: "CANCHAYLLO", provId: 106 },
  { district: "CURICACA", provId: 106 },
  { district: "EL MANTARO", provId: 106 },
  { district: "HUAMALI", provId: 106 },
  { district: "HUARIPAMPA", provId: 106 },
  { district: "HUERTAS", provId: 106 },
  { district: "JANJAILLO", provId: 106 },
  { district: "JULCAN", provId: 106 },
  { district: "LEONOR ORDOÑEZ", provId: 106 },
  { district: "LLOCLLAPAMPA", provId: 106 },
  { district: "MARCO", provId: 106 },
  { district: "MASMA", provId: 106 },
  { district: "MASMA CHICCHE", provId: 106 },
  { district: "MOLINOS", provId: 106 },
  { district: "MONOBAMBA", provId: 106 },
  { district: "MUQUI", provId: 106 },
  { district: "MUQUIYAUYO", provId: 106 },
  { district: "PACA", provId: 106 },
  { district: "PACCHA", provId: 106 },
  { district: "PANCAN", provId: 106 },
  { district: "PARCO", provId: 106 },
  { district: "POMACANCHA", provId: 106 },
  { district: "RICRAN", provId: 106 },
  { district: "SAN LORENZO", provId: 106 },
  { district: "SAN PEDRO DE CHUNAN", provId: 106 },
  { district: "SAUSA", provId: 106 },
  { district: "SINCOS", provId: 106 },
  { district: "TUNAN MARCA", provId: 106 },
  { district: "YAULI", provId: 106 },
  { district: "YAUYOS", provId: 106 },
  { district: "JUNIN", provId: 107 },
  { district: "CARHUAMAYO", provId: 107 },
  { district: "ONDORES", provId: 107 },
  { district: "ULCUMAYO", provId: 107 },
  { district: "SATIPO", provId: 108 },
  { district: "COVIRIALI", provId: 108 },
  { district: "LLAYLLA", provId: 108 },
  { district: "MAZAMARI", provId: 108 },
  { district: "PAMPA HERMOSA", provId: 108 },
  { district: "PANGOA", provId: 108 },
  { district: "RIO NEGRO", provId: 108 },
  { district: "RIO TAMBO", provId: 108 },
  { district: "TARMA", provId: 109 },
  { district: "ACOBAMBA", provId: 109 },
  { district: "HUARICOLCA", provId: 109 },
  { district: "HUASAHUASI", provId: 109 },
  { district: "LA UNION", provId: 109 },
  { district: "PALCA", provId: 109 },
  { district: "PALCAMAYO", provId: 109 },
  { district: "SAN PEDRO DE CAJAS", provId: 109 },
  { district: "TAPO", provId: 109 },
  { district: "LA OROYA", provId: 110 },
  { district: "CHACAPALPA", provId: 110 },
  { district: "HUAY-HUAY", provId: 110 },
  { district: "MARCAPOMACOCHA", provId: 110 },
  { district: "MOROCOCHA", provId: 110 },
  { district: "PACCHA", provId: 110 },
  { district: "SANTA BARBARA DE CARHUACAYAN", provId: 110 },
  { district: "SANTA ROSA DE SACCO", provId: 110 },
  { district: "SUITUCANCHA", provId: 110 },
  { district: "YAULI", provId: 110 },
  { district: "CHUPACA", provId: 111 },
  { district: "AHUAC", provId: 111 },
  { district: "CHONGOS BAJO", provId: 111 },
  { district: "HUACHAC", provId: 111 },
  { district: "HUAMANCACA CHICO", provId: 111 },
  { district: "SAN JUAN DE ISCOS", provId: 111 },
  { district: "SAN JUAN DE JARPA", provId: 111 },
  { district: "TRES DE DICIEMBRE", provId: 111 },
  { district: "YANACANCHA", provId: 111 },
  { district: "TRUJILLO", provId: 112 },
  { district: "EL PORVENIR", provId: 112 },
  { district: "FLORENCIA DE MORA", provId: 112 },
  { district: "HUANCHACO", provId: 112 },
  { district: "LA ESPERANZA", provId: 112 },
  { district: "LAREDO", provId: 112 },
  { district: "MOCHE", provId: 112 },
  { district: "POROTO", provId: 112 },
  { district: "SALAVERRY", provId: 112 },
  { district: "SIMBAL", provId: 112 },
  { district: "VICTOR LARCO HERRERA", provId: 112 },
  { district: "ASCOPE", provId: 113 },
  { district: "CHICAMA", provId: 113 },
  { district: "CHOCOPE", provId: 113 },
  { district: "MAGDALENA DE CAO", provId: 113 },
  { district: "PAIJAN", provId: 113 },
  { district: "RAZURI", provId: 113 },
  { district: "SANTIAGO DE CAO", provId: 113 },
  { district: "CASA GRANDE", provId: 113 },
  { district: "BOLIVAR", provId: 114 },
  { district: "BAMBAMARCA", provId: 114 },
  { district: "CONDORMARCA", provId: 114 },
  { district: "LONGOTEA", provId: 114 },
  { district: "UCHUMARCA", provId: 114 },
  { district: "UCUNCHA", provId: 114 },
  { district: "CHEPEN", provId: 115 },
  { district: "PACANGA", provId: 115 },
  { district: "PUEBLO NUEVO", provId: 115 },
  { district: "JULCAN", provId: 116 },
  { district: "CALAMARCA", provId: 116 },
  { district: "CARABAMBA", provId: 116 },
  { district: "HUASO", provId: 116 },
  { district: "OTUZCO", provId: 117 },
  { district: "AGALLPAMPA", provId: 117 },
  { district: "CHARAT", provId: 117 },
  { district: "HUARANCHAL", provId: 117 },
  { district: "LA CUESTA", provId: 117 },
  { district: "MACHE", provId: 117 },
  { district: "PARANDAY", provId: 117 },
  { district: "SALPO", provId: 117 },
  { district: "SINSICAP", provId: 117 },
  { district: "USQUIL", provId: 117 },
  { district: "SAN PEDRO DE LLOC", provId: 118 },
  { district: "GUADALUPE", provId: 118 },
  { district: "JEQUETEPEQUE", provId: 118 },
  { district: "PACASMAYO", provId: 118 },
  { district: "SAN JOSE", provId: 118 },
  { district: "TAYABAMBA", provId: 119 },
  { district: "BULDIBUYO", provId: 119 },
  { district: "CHILLIA", provId: 119 },
  { district: "HUANCASPATA", provId: 119 },
  { district: "HUAYLILLAS", provId: 119 },
  { district: "HUAYO", provId: 119 },
  { district: "ONGON", provId: 119 },
  { district: "PARCOY", provId: 119 },
  { district: "PATAZ", provId: 119 },
  { district: "PIAS", provId: 119 },
  { district: "SANTIAGO DE CHALLAS", provId: 119 },
  { district: "TAURIJA", provId: 119 },
  { district: "URPAY", provId: 119 },
  { district: "HUAMACHUCO", provId: 120 },
  { district: "CHUGAY", provId: 120 },
  { district: "COCHORCO", provId: 120 },
  { district: "CURGOS", provId: 120 },
  { district: "MARCABAL", provId: 120 },
  { district: "SANAGORAN", provId: 120 },
  { district: "SARIN", provId: 120 },
  { district: "SARTIMBAMBA", provId: 120 },
  { district: "SANTIAGO DE CHUCO", provId: 121 },
  { district: "ANGASMARCA", provId: 121 },
  { district: "CACHICADAN", provId: 121 },
  { district: "MOLLEBAMBA", provId: 121 },
  { district: "MOLLEPATA", provId: 121 },
  { district: "QUIRUVILCA", provId: 121 },
  { district: "SANTA CRUZ DE CHUCA", provId: 121 },
  { district: "SITABAMBA", provId: 121 },
  { district: "GRAN CHIMU", provId: 122 },
  { district: "CASCAS", provId: 122 },
  { district: "LUCMA", provId: 122 },
  { district: "MARMOT", provId: 122 },
  { district: "SAYAPULLO", provId: 122 },
  { district: "VIRU", provId: 123 },
  { district: "CHAO", provId: 123 },
  { district: "GUADALUPITO", provId: 123 },
  { district: "CHICLAYO", provId: 124 },
  { district: "CHONGOYAPE", provId: 124 },
  { district: "ETEN", provId: 124 },
  { district: "ETEN PUERTO", provId: 124 },
  { district: "JOSE LEONARDO ORTIZ", provId: 124 },
  { district: "LA VICTORIA", provId: 124 },
  { district: "LAGUNAS", provId: 124 },
  { district: "MONSEFU", provId: 124 },
  { district: "NUEVA ARICA", provId: 124 },
  { district: "OYOTUN", provId: 124 },
  { district: "PICSI", provId: 124 },
  { district: "PIMENTEL", provId: 124 },
  { district: "REQUE", provId: 124 },
  { district: "SANTA ROSA", provId: 124 },
  { district: "SAÑA", provId: 124 },
  { district: "CAYALTI", provId: 124 },
  { district: "PATAPO", provId: 124 },
  { district: "POMALCA", provId: 124 },
  { district: "PUCALA", provId: 124 },
  { district: "TUMAN", provId: 124 },
  { district: "FERREÑAFE", provId: 125 },
  { district: "CAÑARIS", provId: 125 },
  { district: "INCAHUASI", provId: 125 },
  { district: "MANUEL ANTONIO MESONES MURO", provId: 125 },
  { district: "PITIPO", provId: 125 },
  { district: "PUEBLO NUEVO", provId: 125 },
  { district: "LAMBAYEQUE", provId: 126 },
  { district: "CHOCHOPE", provId: 126 },
  { district: "ILLIMO", provId: 126 },
  { district: "JAYANCA", provId: 126 },
  { district: "MOCHUMI", provId: 126 },
  { district: "MORROPE", provId: 126 },
  { district: "MOTUPE", provId: 126 },
  { district: "OLMOS", provId: 126 },
  { district: "PACORA", provId: 126 },
  { district: "SALAS", provId: 126 },
  { district: "SAN JOSE", provId: 126 },
  { district: "TUCUME", provId: 126 },
  { district: "LIMA", provId: 127 },
  { district: "ANCON", provId: 127 },
  { district: "ATE", provId: 127 },
  { district: "BARRANCO", provId: 127 },
  { district: "BREÑA", provId: 127 },
  { district: "CARABAYLLO", provId: 127 },
  { district: "CHACLACAYO", provId: 127 },
  { district: "CHORRILLOS", provId: 127 },
  { district: "CIENEGUILLA", provId: 127 },
  { district: "COMAS", provId: 127 },
  { district: "EL AGUSTINO", provId: 127 },
  { district: "INDEPENDENCIA", provId: 127 },
  { district: "JESUS MARIA", provId: 127 },
  { district: "LA MOLINA", provId: 127 },
  { district: "LA VICTORIA", provId: 127 },
  { district: "LINCE", provId: 127 },
  { district: "LOS OLIVOS", provId: 127 },
  { district: "LURIGANCHO", provId: 127 },
  { district: "LURIN", provId: 127 },
  { district: "MAGDALENA DEL MAR", provId: 127 },
  { district: "PUEBLO LIBRE", provId: 127 },
  { district: "MIRAFLORES", provId: 127 },
  { district: "PACHACAMAC", provId: 127 },
  { district: "PUCUSANA", provId: 127 },
  { district: "PUENTE PIEDRA", provId: 127 },
  { district: "PUNTA HERMOSA", provId: 127 },
  { district: "PUNTA NEGRA", provId: 127 },
  { district: "RIMAC", provId: 127 },
  { district: "SAN BARTOLO", provId: 127 },
  { district: "SAN BORJA", provId: 127 },
  { district: "SAN ISIDRO", provId: 127 },
  { district: "SAN JUAN DE LURIGANCHO", provId: 127 },
  { district: "SAN JUAN DE MIRAFLORES", provId: 127 },
  { district: "SAN LUIS", provId: 127 },
  { district: "SAN MARTIN DE PORRES", provId: 127 },
  { district: "SAN MIGUEL", provId: 127 },
  { district: "SANTA ANITA", provId: 127 },
  { district: "SANTA MARIA DEL MAR", provId: 127 },
  { district: "SANTA ROSA", provId: 127 },
  { district: "SANTIAGO DE SURCO", provId: 127 },
  { district: "SURQUILLO", provId: 127 },
  { district: "VILLA EL SALVADOR", provId: 127 },
  { district: "VILLA MARIA DEL TRIUNFO", provId: 127 },
  { district: "BARRANCA", provId: 128 },
  { district: "PARAMONGA", provId: 128 },
  { district: "PATIVILCA", provId: 128 },
  { district: "SUPE", provId: 128 },
  { district: "SUPE PUERTO", provId: 128 },
  { district: "CAJATAMBO", provId: 129 },
  { district: "COPA", provId: 129 },
  { district: "GORGOR", provId: 129 },
  { district: "HUANCAPON", provId: 129 },
  { district: "MANAS", provId: 129 },
  { district: "CANTA", provId: 130 },
  { district: "ARAHUAY", provId: 130 },
  { district: "HUAMANTANGA", provId: 130 },
  { district: "HUAROS", provId: 130 },
  { district: "LACHAQUI", provId: 130 },
  { district: "SAN BUENAVENTURA", provId: 130 },
  { district: "SANTA ROSA DE QUIVES", provId: 130 },
  { district: "SAN VICENTE DE CAÑETE", provId: 131 },
  { district: "ASIA", provId: 131 },
  { district: "CALANGO", provId: 131 },
  { district: "CERRO AZUL", provId: 131 },
  { district: "CHILCA", provId: 131 },
  { district: "COAYLLO", provId: 131 },
  { district: "IMPERIAL", provId: 131 },
  { district: "LUNAHUANA", provId: 131 },
  { district: "MALA", provId: 131 },
  { district: "NUEVO IMPERIAL", provId: 131 },
  { district: "PACARAN", provId: 131 },
  { district: "QUILMANA", provId: 131 },
  { district: "SAN ANTONIO", provId: 131 },
  { district: "SAN LUIS", provId: 131 },
  { district: "SANTA CRUZ DE FLORES", provId: 131 },
  { district: "ZUÑIGA", provId: 131 },
  { district: "HUARAL", provId: 132 },
  { district: "ATAVILLOS ALTO", provId: 132 },
  { district: "ATAVILLOS BAJO", provId: 132 },
  { district: "AUCALLAMA", provId: 132 },
  { district: "CHANCAY", provId: 132 },
  { district: "IHUARI", provId: 132 },
  { district: "LAMPIAN", provId: 132 },
  { district: "PACARAOS", provId: 132 },
  { district: "SAN MIGUEL DE ACOS", provId: 132 },
  { district: "SANTA CRUZ DE ANDAMARCA", provId: 132 },
  { district: "SUMBILCA", provId: 132 },
  { district: "VEINTISIETE DE NOVIEMBRE", provId: 132 },
  { district: "MATUCANA", provId: 133 },
  { district: "ANTIOQUIA", provId: 133 },
  { district: "CALLAHUANCA", provId: 133 },
  { district: "CARAMPOMA", provId: 133 },
  { district: "CHICLA", provId: 133 },
  { district: "CUENCA", provId: 133 },
  { district: "HUACHUPAMPA", provId: 133 },
  { district: "HUANZA", provId: 133 },
  { district: "HUAROCHIRI", provId: 133 },
  { district: "LAHUAYTAMBO", provId: 133 },
  { district: "LANGA", provId: 133 },
  { district: "LARAOS", provId: 133 },
  { district: "MARIATANA", provId: 133 },
  { district: "RICARDO PALMA", provId: 133 },
  { district: "SAN ANDRES DE TUPICOCHA", provId: 133 },
  { district: "SAN ANTONIO", provId: 133 },
  { district: "SAN BARTOLOME", provId: 133 },
  { district: "SAN DAMIAN", provId: 133 },
  { district: "SAN JUAN DE IRIS", provId: 133 },
  { district: "SAN JUAN DE TANTARANCHE", provId: 133 },
  { district: "SAN LORENZO DE QUINTI", provId: 133 },
  { district: "SAN MATEO", provId: 133 },
  { district: "SAN MATEO DE OTAO", provId: 133 },
  { district: "SAN PEDRO DE CASTA", provId: 133 },
  { district: "SAN PEDRO DE HUANCAYRE", provId: 133 },
  { district: "SANGALLAYA", provId: 133 },
  { district: "SANTA CRUZ DE COCACHACRA", provId: 133 },
  { district: "SANTA EULALIA", provId: 133 },
  { district: "SANTIAGO DE ANCHUCAYA", provId: 133 },
  { district: "SANTIAGO DE TUNA", provId: 133 },
  { district: "SANTO DOMINGO DE LOS OLLEROS", provId: 133 },
  { district: "SURCO", provId: 133 },
  { district: "HUACHO", provId: 134 },
  { district: "AMBAR", provId: 134 },
  { district: "CALETA DE CARQUIN", provId: 134 },
  { district: "CHECRAS", provId: 134 },
  { district: "HUALMAY", provId: 134 },
  { district: "HUAURA", provId: 134 },
  { district: "LEONCIO PRADO", provId: 134 },
  { district: "PACCHO", provId: 134 },
  { district: "SANTA LEONOR", provId: 134 },
  { district: "SANTA MARIA", provId: 134 },
  { district: "SAYAN", provId: 134 },
  { district: "VEGUETA", provId: 134 },
  { district: "OYON", provId: 135 },
  { district: "ANDAJES", provId: 135 },
  { district: "CAUJUL", provId: 135 },
  { district: "COCHAMARCA", provId: 135 },
  { district: "NAVAN", provId: 135 },
  { district: "PACHANGARA", provId: 135 },
  { district: "YAUYOS", provId: 136 },
  { district: "ALIS", provId: 136 },
  { district: "AYAUCA", provId: 136 },
  { district: "AYAVIRI", provId: 136 },
  { district: "AZANGARO", provId: 136 },
  { district: "CACRA", provId: 136 },
  { district: "CARANIA", provId: 136 },
  { district: "CATAHUASI", provId: 136 },
  { district: "CHOCOS", provId: 136 },
  { district: "COCHAS", provId: 136 },
  { district: "COLONIA", provId: 136 },
  { district: "HONGOS", provId: 136 },
  { district: "HUAMPARA", provId: 136 },
  { district: "HUANCAYA", provId: 136 },
  { district: "HUANGASCAR", provId: 136 },
  { district: "HUANTAN", provId: 136 },
  { district: "HUAÑEC", provId: 136 },
  { district: "LARAOS", provId: 136 },
  { district: "LINCHA", provId: 136 },
  { district: "MADEAN", provId: 136 },
  { district: "MIRAFLORES", provId: 136 },
  { district: "OMAS", provId: 136 },
  { district: "PUTINZA", provId: 136 },
  { district: "QUINCHES", provId: 136 },
  { district: "QUINOCAY", provId: 136 },
  { district: "SAN JOAQUIN", provId: 136 },
  { district: "SAN PEDRO DE PILAS", provId: 136 },
  { district: "TANTA", provId: 136 },
  { district: "TAURIPAMPA", provId: 136 },
  { district: "TOMAS", provId: 136 },
  { district: "TUPE", provId: 136 },
  { district: "VIÑAC", provId: 136 },
  { district: "VITIS", provId: 136 },
  { district: "IQUITOS", provId: 137 },
  { district: "ALTO NANAY", provId: 137 },
  { district: "FERNANDO LORES", provId: 137 },
  { district: "INDIANA", provId: 137 },
  { district: "LAS AMAZONAS", provId: 137 },
  { district: "MAZAN", provId: 137 },
  { district: "NAPO", provId: 137 },
  { district: "PUNCHANA", provId: 137 },
  { district: "PUTUMAYO", provId: 137 },
  { district: "TORRES CAUSANA", provId: 137 },
  { district: "BELEN", provId: 137 },
  { district: "SAN JUAN BAUTISTA", provId: 137 },
  { district: "YURIMAGUAS", provId: 138 },
  { district: "BALSAPUERTO", provId: 138 },
  { district: "BARRANCA", provId: 138 },
  { district: "CAHUAPANAS", provId: 138 },
  { district: "JEBEROS", provId: 138 },
  { district: "LAGUNAS", provId: 138 },
  { district: "MANSERICHE", provId: 138 },
  { district: "MORONA", provId: 138 },
  { district: "PASTAZA", provId: 138 },
  { district: "SANTA CRUZ", provId: 138 },
  { district: "TENIENTE CESAR LOPEZ ROJAS", provId: 138 },
  { district: "NAUTA", provId: 139 },
  { district: "PARINARI", provId: 139 },
  { district: "TIGRE", provId: 139 },
  { district: "TROMPETEROS", provId: 139 },
  { district: "URARINAS", provId: 139 },
  { district: "RAMON CASTILLA", provId: 140 },
  { district: "PEBAS", provId: 140 },
  { district: "YAVARI", provId: 140 },
  { district: "SAN PABLO", provId: 140 },
  { district: "REQUENA", provId: 141 },
  { district: "ALTO TAPICHE", provId: 141 },
  { district: "CAPELO", provId: 141 },
  { district: "EMILIO SAN MARTIN", provId: 141 },
  { district: "MAQUIA", provId: 141 },
  { district: "PUINAHUA", provId: 141 },
  { district: "SAQUENA", provId: 141 },
  { district: "SOPLIN", provId: 141 },
  { district: "TAPICHE", provId: 141 },
  { district: "JENARO HERRERA", provId: 141 },
  { district: "YAQUERANA", provId: 141 },
  { district: "CONTAMANA", provId: 142 },
  { district: "INAHUAYA", provId: 142 },
  { district: "PADRE MARQUEZ", provId: 142 },
  { district: "PAMPA HERMOSA", provId: 142 },
  { district: "SARAYACU", provId: 142 },
  { district: "VARGAS GUERRA", provId: 142 },
  { district: "TAMBOPATA", provId: 143 },
  { district: "INAMBARI", provId: 143 },
  { district: "LAS PIEDRAS", provId: 143 },
  { district: "LABERINTO", provId: 143 },
  { district: "MANU", provId: 144 },
  { district: "FITZCARRALD", provId: 144 },
  { district: "MADRE DE DIOS", provId: 144 },
  { district: "HUEPETUHE", provId: 144 },
  { district: "IÑAPARI", provId: 145 },
  { district: "IBERIA", provId: 145 },
  { district: "TAHUAMANU", provId: 145 },
  { district: "MOQUEGUA", provId: 146 },
  { district: "CARUMAS", provId: 146 },
  { district: "CUCHUMBAYA", provId: 146 },
  { district: "SAMEGUA", provId: 146 },
  { district: "SAN CRISTOBAL", provId: 146 },
  { district: "TORATA", provId: 146 },
  { district: "OMATE", provId: 147 },
  { district: "CHOJATA", provId: 147 },
  { district: "COALAQUE", provId: 147 },
  { district: "ICHUÑA", provId: 147 },
  { district: "LA CAPILLA", provId: 147 },
  { district: "LLOQUE", provId: 147 },
  { district: "MATALAQUE", provId: 147 },
  { district: "PUQUINA", provId: 147 },
  { district: "QUINISTAQUILLAS", provId: 147 },
  { district: "UBINAS", provId: 147 },
  { district: "YUNGA", provId: 147 },
  { district: "ILO", provId: 148 },
  { district: "EL ALGARROBAL", provId: 148 },
  { district: "PACOCHA", provId: 148 },
  { district: "CHAUPIMARCA", provId: 149 },
  { district: "HUACHON", provId: 149 },
  { district: "HUARIACA", provId: 149 },
  { district: "HUAYLLAY", provId: 149 },
  { district: "NINACACA", provId: 149 },
  { district: "PALLANCHACRA", provId: 149 },
  { district: "PAUCARTAMBO", provId: 149 },
  { district: "SAN FCO DE ASIS DE YARUSYACAN", provId: 149 },
  { district: "SIMON BOLIVAR", provId: 149 },
  { district: "TICLACAYAN", provId: 149 },
  { district: "TINYAHUARCO", provId: 149 },
  { district: "VICCO", provId: 149 },
  { district: "YANACANCHA", provId: 149 },
  { district: "YANAHUANCA", provId: 150 },
  { district: "CHACAYAN", provId: 150 },
  { district: "GOYLLARISQUIZGA", provId: 150 },
  { district: "PAUCAR", provId: 150 },
  { district: "SAN PEDRO DE PILLAO", provId: 150 },
  { district: "SANTA ANA DE TUSI", provId: 150 },
  { district: "TAPUC", provId: 150 },
  { district: "VILCABAMBA", provId: 150 },
  { district: "OXAPAMPA", provId: 151 },
  { district: "CHONTABAMBA", provId: 151 },
  { district: "HUANCABAMBA", provId: 151 },
  { district: "PALCAZU", provId: 151 },
  { district: "POZUZO", provId: 151 },
  { district: "PUERTO BERMUDEZ", provId: 151 },
  { district: "VILLA RICA", provId: 151 },
  { district: "PIURA", provId: 152 },
  { district: "CASTILLA", provId: 152 },
  { district: "CATACAOS", provId: 152 },
  { district: "CURA MORI", provId: 152 },
  { district: "EL TALLAN", provId: 152 },
  { district: "LA ARENA", provId: 152 },
  { district: "LA UNION", provId: 152 },
  { district: "LAS LOMAS", provId: 152 },
  { district: "TAMBO GRANDE", provId: 152 },
  { district: "AYABACA", provId: 153 },
  { district: "FRIAS", provId: 153 },
  { district: "JILILI", provId: 153 },
  { district: "LAGUNAS", provId: 153 },
  { district: "MONTERO", provId: 153 },
  { district: "PACAIPAMPA", provId: 153 },
  { district: "PAIMAS", provId: 153 },
  { district: "SAPILLICA", provId: 153 },
  { district: "SICCHEZ", provId: 153 },
  { district: "SUYO", provId: 153 },
  { district: "HUANCABAMBA", provId: 154 },
  { district: "CANCHAQUE", provId: 154 },
  { district: "EL CARMEN DE LA FRONTERA", provId: 154 },
  { district: "HUARMACA", provId: 154 },
  { district: "LALAQUIZ", provId: 154 },
  { district: "SAN MIGUEL DE EL FAIQUE", provId: 154 },
  { district: "SONDOR", provId: 154 },
  { district: "SONDORILLO", provId: 154 },
  { district: "CHULUCANAS", provId: 155 },
  { district: "BUENOS AIRES", provId: 155 },
  { district: "CHALACO", provId: 155 },
  { district: "LA MATANZA", provId: 155 },
  { district: "MORROPON", provId: 155 },
  { district: "SALITRAL", provId: 155 },
  { district: "SAN JUAN DE BIGOTE", provId: 155 },
  { district: "SANTA CATALINA DE MOSSA", provId: 155 },
  { district: "SANTO DOMINGO", provId: 155 },
  { district: "YAMANGO", provId: 155 },
  { district: "PAITA", provId: 156 },
  { district: "AMOTAPE", provId: 156 },
  { district: "ARENAL", provId: 156 },
  { district: "COLAN", provId: 156 },
  { district: "LA HUACA", provId: 156 },
  { district: "TAMARINDO", provId: 156 },
  { district: "VICHAYAL", provId: 156 },
  { district: "SULLANA", provId: 157 },
  { district: "BELLAVISTA", provId: 157 },
  { district: "IGNACIO ESCUDERO", provId: 157 },
  { district: "LANCONES", provId: 157 },
  { district: "MARCAVELICA", provId: 157 },
  { district: "MIGUEL CHECA", provId: 157 },
  { district: "QUERECOTILLO", provId: 157 },
  { district: "SALITRAL", provId: 157 },
  { district: "PARIÑAS", provId: 158 },
  { district: "EL ALTO", provId: 158 },
  { district: "LA BREA", provId: 158 },
  { district: "LOBITOS", provId: 158 },
  { district: "LOS ORGANOS", provId: 158 },
  { district: "MANCORA", provId: 158 },
  { district: "SECHURA", provId: 159 },
  { district: "BELLAVISTA DE LA UNION", provId: 159 },
  { district: "BERNAL", provId: 159 },
  { district: "CRISTO NOS VALGA", provId: 159 },
  { district: "VICE", provId: 159 },
  { district: "RINCONADA LLICUAR", provId: 159 },
  { district: "PUNO", provId: 160 },
  { district: "ACORA", provId: 160 },
  { district: "AMANTANI", provId: 160 },
  { district: "ATUNCOLLA", provId: 160 },
  { district: "CAPACHICA", provId: 160 },
  { district: "CHUCUITO", provId: 160 },
  { district: "COATA", provId: 160 },
  { district: "HUATA", provId: 160 },
  { district: "MAÑAZO", provId: 160 },
  { district: "PAUCARCOLLA", provId: 160 },
  { district: "PICHACANI", provId: 160 },
  { district: "PLATERIA", provId: 160 },
  { district: "SAN ANTONIO", provId: 160 },
  { district: "TIQUILLACA", provId: 160 },
  { district: "VILQUE", provId: 160 },
  { district: "AZANGARO", provId: 161 },
  { district: "ACHAYA", provId: 161 },
  { district: "ARAPA", provId: 161 },
  { district: "ASILLO", provId: 161 },
  { district: "CAMINACA", provId: 161 },
  { district: "CHUPA", provId: 161 },
  { district: "JOSE DOMINGO CHOQUEHUANCA", provId: 161 },
  { district: "MUÑANI", provId: 161 },
  { district: "POTONI", provId: 161 },
  { district: "SAMAN", provId: 161 },
  { district: "SAN ANTON", provId: 161 },
  { district: "SAN JOSE", provId: 161 },
  { district: "SAN JUAN DE SALINAS", provId: 161 },
  { district: "SANTIAGO DE PUPUJA", provId: 161 },
  { district: "TIRAPATA", provId: 161 },
  { district: "MACUSANI", provId: 162 },
  { district: "AJOYANI", provId: 162 },
  { district: "AYAPATA", provId: 162 },
  { district: "COASA", provId: 162 },
  { district: "CORANI", provId: 162 },
  { district: "CRUCERO", provId: 162 },
  { district: "ITUATA", provId: 162 },
  { district: "OLLACHEA", provId: 162 },
  { district: "SAN GABAN", provId: 162 },
  { district: "USICAYOS", provId: 162 },
  { district: "JULI", provId: 163 },
  { district: "DESAGUADERO", provId: 163 },
  { district: "HUACULLANI", provId: 163 },
  { district: "KELLUYO", provId: 163 },
  { district: "PISACOMA", provId: 163 },
  { district: "POMATA", provId: 163 },
  { district: "ZEPITA", provId: 163 },
  { district: "ILAVE", provId: 164 },
  { district: "CAPAZO", provId: 164 },
  { district: "PILCUYO", provId: 164 },
  { district: "SANTA ROSA", provId: 164 },
  { district: "CONDURIRI", provId: 164 },
  { district: "HUANCANE", provId: 165 },
  { district: "COJATA", provId: 165 },
  { district: "HUATASANI", provId: 165 },
  { district: "INCHUPALLA", provId: 165 },
  { district: "PUSI", provId: 165 },
  { district: "ROSASPATA", provId: 165 },
  { district: "TARACO", provId: 165 },
  { district: "VILQUE CHICO", provId: 165 },
  { district: "LAMPA", provId: 166 },
  { district: "CABANILLA", provId: 166 },
  { district: "CALAPUJA", provId: 166 },
  { district: "NICASIO", provId: 166 },
  { district: "OCUVIRI", provId: 166 },
  { district: "PALCA", provId: 166 },
  { district: "PARATIA", provId: 166 },
  { district: "PUCARA", provId: 166 },
  { district: "SANTA LUCIA", provId: 166 },
  { district: "VILAVILA", provId: 166 },
  { district: "AYAVIRI", provId: 167 },
  { district: "ANTAUTA", provId: 167 },
  { district: "CUPI", provId: 167 },
  { district: "LLALLI", provId: 167 },
  { district: "MACARI", provId: 167 },
  { district: "NUÑOA", provId: 167 },
  { district: "ORURILLO", provId: 167 },
  { district: "SANTA ROSA", provId: 167 },
  { district: "UMACHIRI", provId: 167 },
  { district: "MOHO", provId: 168 },
  { district: "CONIMA", provId: 168 },
  { district: "HUAYRAPATA", provId: 168 },
  { district: "TILALI", provId: 168 },
  { district: "PUTINA", provId: 169 },
  { district: "ANANEA", provId: 169 },
  { district: "PEDRO VILCA APAZA", provId: 169 },
  { district: "QUILCAPUNCU", provId: 169 },
  { district: "SINA", provId: 169 },
  { district: "JULIACA", provId: 170 },
  { district: "CABANA", provId: 170 },
  { district: "CABANILLAS", provId: 170 },
  { district: "CARACOTO", provId: 170 },
  { district: "SANDIA", provId: 171 },
  { district: "CUYOCUYO", provId: 171 },
  { district: "LIMBANI", provId: 171 },
  { district: "PATAMBUCO", provId: 171 },
  { district: "PHARA", provId: 171 },
  { district: "QUIACA", provId: 171 },
  { district: "SAN JUAN DEL ORO", provId: 171 },
  { district: "YANAHUAYA", provId: 171 },
  { district: "ALTO INAMBARI", provId: 171 },
  { district: "YUNGUYO", provId: 172 },
  { district: "ANAPIA", provId: 172 },
  { district: "COPANI", provId: 172 },
  { district: "CUTURAPI", provId: 172 },
  { district: "OLLARAYA", provId: 172 },
  { district: "TINICACHI", provId: 172 },
  { district: "UNICACHI", provId: 172 },
  { district: "MOYOBAMBA", provId: 173 },
  { district: "CALZADA", provId: 173 },
  { district: "HABANA", provId: 173 },
  { district: "JEPELACIO", provId: 173 },
  { district: "SORITOR", provId: 173 },
  { district: "YANTALO", provId: 173 },
  { district: "BELLAVISTA", provId: 174 },
  { district: "ALTO BIAVO", provId: 174 },
  { district: "BAJO BIAVO", provId: 174 },
  { district: "HUALLAGA", provId: 174 },
  { district: "SAN PABLO", provId: 174 },
  { district: "SAN RAFAEL", provId: 174 },
  { district: "SAN JOSE DE SISA", provId: 175 },
  { district: "AGUA BLANCA", provId: 175 },
  { district: "SAN MARTIN", provId: 175 },
  { district: "SANTA ROSA", provId: 175 },
  { district: "SHATOJA", provId: 175 },
  { district: "SAPOSOA", provId: 176 },
  { district: "ALTO SAPOSOA", provId: 176 },
  { district: "EL ESLABON", provId: 176 },
  { district: "PISCOYACU", provId: 176 },
  { district: "SACANCHE", provId: 176 },
  { district: "TINGO DE SAPOSOA", provId: 176 },
  { district: "LAMAS", provId: 177 },
  { district: "ALONSO DE ALVARADO", provId: 177 },
  { district: "BARRANQUITA", provId: 177 },
  { district: "CAYNARACHI", provId: 177 },
  { district: "CUÑUMBUQUI", provId: 177 },
  { district: "PINTO RECODO", provId: 177 },
  { district: "RUMISAPA", provId: 177 },
  { district: "SAN ROQUE DE CUMBAZA", provId: 177 },
  { district: "SHANAO", provId: 177 },
  { district: "TABALOSOS", provId: 177 },
  { district: "ZAPATERO", provId: 177 },
  { district: "JUANJUI", provId: 178 },
  { district: "CAMPANILLA", provId: 178 },
  { district: "HUICUNGO", provId: 178 },
  { district: "PACHIZA", provId: 178 },
  { district: "PAJARILLO", provId: 178 },
  { district: "PICOTA", provId: 179 },
  { district: "BUENOS AIRES", provId: 179 },
  { district: "CASPISAPA", provId: 179 },
  { district: "PILLUANA", provId: 179 },
  { district: "PUCACACA", provId: 179 },
  { district: "SAN CRISTOBAL", provId: 179 },
  { district: "SAN HILARION", provId: 179 },
  { district: "SHAMBOYACU", provId: 179 },
  { district: "TINGO DE PONASA", provId: 179 },
  { district: "TRES UNIDOS", provId: 179 },
  { district: "RIOJA", provId: 180 },
  { district: "AWAJUN", provId: 180 },
  { district: "ELIAS SOPLIN VARGAS", provId: 180 },
  { district: "NUEVA CAJAMARCA", provId: 180 },
  { district: "PARDO MIGUEL", provId: 180 },
  { district: "POSIC", provId: 180 },
  { district: "SAN FERNANDO", provId: 180 },
  { district: "YORONGOS", provId: 180 },
  { district: "YURACYACU", provId: 180 },
  { district: "TARAPOTO", provId: 181 },
  { district: "ALBERTO LEVEAU", provId: 181 },
  { district: "CACATACHI", provId: 181 },
  { district: "CHAZUTA", provId: 181 },
  { district: "CHIPURANA", provId: 181 },
  { district: "EL PORVENIR", provId: 181 },
  { district: "HUIMBAYOC", provId: 181 },
  { district: "JUAN GUERRA", provId: 181 },
  { district: "LA BANDA DE SHILCAYO", provId: 181 },
  { district: "MORALES", provId: 181 },
  { district: "PAPAPLAYA", provId: 181 },
  { district: "SAN ANTONIO", provId: 181 },
  { district: "SAUCE", provId: 181 },
  { district: "SHAPAJA", provId: 181 },
  { district: "TOCACHE", provId: 182 },
  { district: "NUEVO PROGRESO", provId: 182 },
  { district: "POLVORA", provId: 182 },
  { district: "SHUNTE", provId: 182 },
  { district: "UCHIZA", provId: 182 },
  { district: "TACNA", provId: 183 },
  { district: "ALTO DE LA ALIANZA", provId: 183 },
  { district: "CALANA", provId: 183 },
  { district: "CIUDAD NUEVA", provId: 183 },
  { district: "INCLAN", provId: 183 },
  { district: "PACHIA", provId: 183 },
  { district: "PALCA", provId: 183 },
  { district: "POCOLLAY", provId: 183 },
  { district: "SAMA", provId: 183 },
  { district: "CORONEL GREGORIO ALBARRACIN LANCHIPA", provId: 183 },
  { district: "CANDARAVE", provId: 184 },
  { district: "CAIRANI", provId: 184 },
  { district: "CAMILACA", provId: 184 },
  { district: "CURIBAYA", provId: 184 },
  { district: "HUANUARA", provId: 184 },
  { district: "QUILAHUANI", provId: 184 },
  { district: "LOCUMBA", provId: 185 },
  { district: "ILABAYA", provId: 185 },
  { district: "ITE", provId: 185 },
  { district: "TARATA", provId: 186 },
  { district: "CHUCATAMANI", provId: 186 },
  { district: "ESTIQUE", provId: 186 },
  { district: "ESTIQUE-PAMPA", provId: 186 },
  { district: "SITAJARA", provId: 186 },
  { district: "SUSAPAYA", provId: 186 },
  { district: "TARUCACHI", provId: 186 },
  { district: "TICACO", provId: 186 },
  { district: "TUMBES", provId: 187 },
  { district: "CORRALES", provId: 187 },
  { district: "LA CRUZ", provId: 187 },
  { district: "PAMPAS DE HOSPITAL", provId: 187 },
  { district: "SAN JACINTO", provId: 187 },
  { district: "SAN JUAN DE LA VIRGEN", provId: 187 },
  { district: "ZORRITOS", provId: 188 },
  { district: "CASITAS", provId: 188 },
  { district: "CANOAS DE PUNTA SAL", provId: 188 },
  { district: "ZARUMILLA", provId: 189 },
  { district: "AGUAS VERDES", provId: 189 },
  { district: "MATAPALO", provId: 189 },
  { district: "PAPAYAL", provId: 189 },
  { district: "CALLERIA", provId: 190 },
  { district: "CAMPOVERDE", provId: 190 },
  { district: "IPARIA", provId: 190 },
  { district: "MASISEA", provId: 190 },
  { district: "YARINACOCHA", provId: 190 },
  { district: "NUEVA REQUENA", provId: 190 },
  { district: "MANANTAY", provId: 190 },
  { district: "RAYMONDI", provId: 191 },
  { district: "SEPAHUA", provId: 191 },
  { district: "TAHUANIA", provId: 191 },
  { district: "YURUA", provId: 191 },
  { district: "PADRE ABAD", provId: 192 },
  { district: "IRAZOLA", provId: 192 },
  { district: "CURIMANA", provId: 192 },
  { district: "PURUS", provId: 193 },
];