import React from 'react'
import MainPayment from './../components/payment/MainPayment'

export default function Payment() {
  return (
    <>
      <MainPayment />
    </>
  )
}
