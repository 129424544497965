import styled from 'styled-components';

export const MainTrackingContainer = styled.div`
  padding: 0 0 50px 0;
  
  .trackingTitle {
    font-size: 1.05em;
    line-height: 1.15em;
    font-weight: ${props => props.theme.weight.bold};
    margin: 20px 0;

    span {
      font-weight: ${props => props.theme.weight.medium};
    }
  }

  @media(min-width:1200px) {
    .trackingTitle {
      font-size: 1.5em;
      margin: 30px 0;
    }
  }
`

export const DesktopGrid = styled.div`
  @media(min-width:1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
`
