import styled from 'styled-components';

export const MainModalContainer = styled.div`
  color: ${props => props.theme.backgroundColor};
  
  .closeSvgModal {
    position: absolute;
    top: 15px;
    right: 25px;
    fill: ${props => props.fillColor || props.theme.backgroundColor};
    cursor: pointer;
  }
`;
