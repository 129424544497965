import styled, { css } from 'styled-components'

export const Title1 = styled.h1`
  font-weight: ${props => props.theme.weight.bold};
  font-size: 1.875em;
  cursor: default;
  line-height: 1.35em;

  ${props => props.classicBlue && css`
    color: ${props => props.theme.brandClassicBlueColor};
  `}

  @media(min-width:768px) {
    h2 {
      font-size: 2em;
    }
  }

  @media(min-width:1200px) {
    font-size: 3.125em;
  }
`

export const Title2 = styled.h2`
  font-size: 1.563em;
  line-height: 1.35em;
  cursor: default;
  font-weight: ${props => props.theme.weight.bold};

  ${props => props.classicBlue && css`
    color: ${props => props.theme.brandClassicBlueColor};

    em, mark {
      color: ${props => props.theme.brandClassicBlueColor};
    }
  `}

  ${props => props.morePages && css`
    margin: 30px 0 20px 0;

    @media(min-width:1200px) {
      margin: 40px 0 20px 0;
    }
  `}

  ${props => props.small && css`
    @media(min-width:1200px) {
      margin: 0 10%;
    }
  `}

  @media(min-width:1200px) {
    font-size: 2.750em;
  }
`

export const Title3 = styled.h3`
  font-size: 1.125em;
  font-weight: ${props => props.theme.weight.bold};
  line-height: 1.25em;
  cursor: default;

  ${props => props.morePages && css`
    margin: 25px 0 15px 0;
    color: ${props => props.theme.brandClassicBlueColor};

    @media(min-width:1200px) {
      margin: 30px 0 20px 0;
    }
  `}

  /* ${props => props.red

  } */

  @media(min-width:1200px) {
    font-size: 1.875em;
  }
`

export const Title4 = styled.h2`
  font-size: 1em;
  font-weight: ${props => props.theme.weight.semiBold};
  line-height: 1.25em;
  cursor: default;
`

export const Title5 = styled.h5`
  font-size: 1em;
  font-weight: ${props => props.theme.weight.medium};
  line-height: 1.25em;
  cursor: default;
`

export const Text = styled.p`
  font-size: 0.938em;
  line-height: 1.5em;
  font-weight: ${props => props.theme.weight.regular};
  cursor: default;

  ${props => props.paragraphLanding && css`
    margin: 15px 0 0 0;
  `}

  ${props => props.danger && css`
    color: ${props => props.theme.red500Color};
    font-weight: ${props => props.theme.weight.semiBold};
    margin: 10px 0 0 0;
  `}

  ${props => props.unlist && css`
    margin: 10px 0 0 0;

    @media(min-width:1200px) {
      margin: 15px 0 0 0;
    }
  `}

  ${props => props.center && css`
    text-align: center;
  `}
`
export const ErrorText = styled.p`
  font-size: 0.85em;
  line-height: 1.5em;
  font-weight: ${props => props.theme.weight.medium};
  color: ${props => props.theme.red500Color};
  cursor: default;

  ${props => props.sizeH3 && css`
    font-size: 1.125em;
    line-height: 1.25em;
    `
  }

  ${props => props.paragraphLanding && css`
    margin: 15px 0 0 0;
  `}
`

export const TextUnlist = styled.ul`
  list-style: disc;
  margin: 15px 0 0 0;

  li {
    margin: 0 0 0 25px;
  }

  .linkText {
    color: ${props => props.theme.brandClassicBlueColor};
    font-weight: ${props => props.theme.weight.medium};
    margin: 5px 0 0 0;
    display: block;
    font-size: 0.938em;

    &:hover {
      text-decoration: underline;
    }
  }

  @media(min-width:1200px) {
    margin: 20px 0 0 0;
  }
`
