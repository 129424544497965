import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../../styles/generalStyles'
import LogoServicioPuntual from './../../../images/logos/image-2.png'
import { ReactComponent as MenuSVG } from './../../../icons/menu.svg'
import { HeaderLandingContainer } from './styles/sHeaderLanding'
import { Button } from './../../../styles/buttonGeneral'
import { Link } from 'react-router-dom'
import MainModal from './../../modal/MainModal';

export default function HeaderLanding({ buttonLogin, displayDesktop }) {
  const [t] = useTranslation("main");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  return (
    <header>
      <Wrapper>
        <HeaderLandingContainer buttonLogin={buttonLogin} displayDesktop={displayDesktop} >
          <Link to="/">
            <img src={LogoServicioPuntual} alt="Logo principal de Serivico Puntual" />
          </Link>
          <div className='headerLandingMenuContent'>
            <Link to="/login">
              <Button secondary small>{t("signInAndUp.enterButton")}</Button>
            </Link>
            {/* <MenuSVG onClick={openModal} /> */}
          </div>
        </HeaderLandingContainer>
      </Wrapper>
      <MainModal isOpen={modalIsOpen} closeModal={closeModal} isClose={closeModal} background="#F8FAFD" fillColor="#262A2E">
        <div></div>
      </MainModal>
    </header>
  )
}
