import React from 'react';
import HeaderLanding from './../components/general/header/HeaderLanding';
import MainRegister from './../components/loginAndRegister/MainRegister';

export default function Register() {
  return (
    <>
      <HeaderLanding buttonLogin="none" displayDesktop="none" />
      <MainRegister />
    </>
  )
}
