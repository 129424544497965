import React from 'react'
import NavbarHome from './NavbarHome'
import { MainMenuContainer } from './styles/sMainMenu'

export default function MainMenu() {
  return (
    <MainMenuContainer>
      <NavbarHome closeModal={() => { return; }} />
    </MainMenuContainer>
  )
}
