import React from 'react';
import MainHome from './../components/home/MainHome';

export default function Home() {
  return (
    <>
      <MainHome />
    </>
  )
}
