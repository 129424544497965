import React, { useEffect } from "react";
import { useReducedState } from "../../hooks/useReducedState";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { ReactComponent as StorageSVG } from "./../../icons/storage.svg";
// import { ReactComponent as HandTruckSVG } from "./../../icons/hand-truck.svg";
import { ReactComponent as DeliveryCardSVG } from "./../../icons/delivery-car.svg";
import { ReactComponent as PackageSVG } from "./../../icons/package-2.svg";
import { TrackingLineCardContainer } from "./styles/sTrackingLineCard";

export default function TrackingLineCard({ state }) {
  const [statusColor, setStatusColor] = useReducedState({
    storageLine: false,
    routeLine: false,
    deliveredLine: false,
  });
  useEffect(() => {
    if (state.status === "PARA DESPACHO") {
      setStatusColor({
        storageLine: true,
        routeLine: false,
        deliveredLine: false,
      });
    } else if (
      state.status === "EN GESTION" ||
      state.status === "MOTIVO" ||
      state.status === "NO DISTRIBUIDO"
    ) {
      setStatusColor({
        storageLine: true,
        routeLine: true,
        deliveredLine: false,
      });
    } else if (state.status === "ENTREGADO") {
      setStatusColor({
        storageLine: true,
        routeLine: true,
        deliveredLine: true,
      });
    }
  }, []);
  return (
    <BoxGeneral shadow>
      <TrackingLineCardContainer statusColor={statusColor}>
        <div className="line">
          <div className="lineTracking storageTextTracking">
            <div className="circleTracking storageLine">
              <StorageSVG className="storageSVGColor"/>
            </div>
            <p>Asignado para recojo</p>
          </div>
          {/* <div className='lineTracking'>
            <div className='circleTracking officeLine'>
              <HandTruckSVG />
            </div>
            <p>Despacho</p>
          </div> */}
          <div className="lineTracking routeTextTracking">
            <div className="circleTracking routeLine">
              <DeliveryCardSVG className="routeSVGColor"/>
            </div>
            <p>En tránsito</p>
          </div>
          <div className="lineTracking deliveredTextTracking">
            <div className="circleTracking deliveredLine">
              <PackageSVG className="deliveredSVGColor"/>
            </div>
            <p>Entregado</p>
          </div>
        </div>
        <div className="progressBar barStorageOffice"></div>
        <div className="progressBar barOfficeRoute"></div>
        {/* <div className='progressBar barRouteDelivered'></div> */}
      </TrackingLineCardContainer>
    </BoxGeneral>
  );
}
