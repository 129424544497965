import React from 'react'
import MainTracking from './../components/tracking/MainTracking'
import HeaderTwo from './../components/general/header/HeaderTwo'
import FooterTwo from './../components/general/footer/FooterTwo'

export default function Tracking() {
  return (
    <>
      <HeaderTwo button="Otro seguimiento" path={"/tracking"} service="Seguimiento de envíos" />
      <MainTracking />
      <FooterTwo />
    </>
  )
}
