import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseSVG } from './../../icons/close.svg'
import { MainModalContainer } from './styles/sMainModal'

export default function MainModal({ isOpen, closeModal, isClose, children, background, fillColor, width, height, borderRadius }) {
  Modal.setAppElement('#app')

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      inset: 'initial',
      borderRadius: `${borderRadius}`,
      border: 'none',
      width: `${width}`,
      height: `${height}`,
      marginRight: '0',
      padding: '0',
      background: `${background}`,
    },
  }
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={isClose}
      style={customStyles}
      contentLabel="Modal"
    >
      <MainModalContainer fillColor={fillColor}>
        <CloseSVG className='closeSvgModal' onClick={closeModal} />
        {children}
      </MainModalContainer>
    </Modal>
  )
}
