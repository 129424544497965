import { provinces } from "../../../data/provinces";
import { districts } from "../../../data/districts";

export const getDataFromUbigeo = (dataOfUser) => {
    try {
        if(dataOfUser && !dataOfUser.ubigeo) {
            return {departmentDataUser: 14, provinceDataUser: 127, districtDataUser: 'LIMA'}
        }
        const provinceFromData = dataOfUser && dataOfUser.ubigeo?.desc_prov_inei && provinces.filter(item => 
            item.province === dataOfUser.ubigeo.desc_prov_inei)[0];
        const districtFromData = dataOfUser && dataOfUser.ubigeo?.desc_ubigeo_inei && districts.filter(item => 
            item.district === dataOfUser.ubigeo.desc_ubigeo_inei)[0];
        return {
            departmentDataUser: provinceFromData?.deptId ?? 14, 
            provinceDataUser: provinceFromData?.provinceIndex ?? 127, 
            districtDataUser: districtFromData?.district ?? 'LIMA'
        };
    } catch(err) {
        console.log(err.message);
        return {departmentDataUser: 14, provinceDataUser: 127, districtDataUser: 'LIMA'}
    }
  }