import styled from 'styled-components';

export const AddsCardContainer = styled.div`
  svg {
    margin: 20px auto;
    display: block;
  }

  @media(min-width:1200px) {
    position: relative;

    svg {
      position: absolute;
      top: -10px;
      right: 0;
      margin: 0;
    }

    button {
      width: 250px;
      margin: 15px 0 0 0;
    }

    .addsCardText {
      width: 56%;
    }
  }
`;
