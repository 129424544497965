export const ubigeo = [
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010101",
    desc_ubigeo_inei: "CHACHAPOYAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010102",
    desc_ubigeo_inei: "ASUNCION",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010103",
    desc_ubigeo_inei: "BALSAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010104",
    desc_ubigeo_inei: "CHETO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010105",
    desc_ubigeo_inei: "CHILIQUIN",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010106",
    desc_ubigeo_inei: "CHUQUIBAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010107",
    desc_ubigeo_inei: "GRANADA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010108",
    desc_ubigeo_inei: "HUANCAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010109",
    desc_ubigeo_inei: "LA JALCA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010110",
    desc_ubigeo_inei: "LEIMEBAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010111",
    desc_ubigeo_inei: "LEVANTO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010112",
    desc_ubigeo_inei: "MAGDALENA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010113",
    desc_ubigeo_inei: "MARISCAL CASTILLA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010114",
    desc_ubigeo_inei: "MOLINOPAMPA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010115",
    desc_ubigeo_inei: "MONTEVIDEO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010116",
    desc_ubigeo_inei: "OLLEROS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010117",
    desc_ubigeo_inei: "QUINJALCA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010118",
    desc_ubigeo_inei: "SAN FRANCISCO DE DAGUAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010119",
    desc_ubigeo_inei: "SAN ISIDRO DE MAINO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010120",
    desc_ubigeo_inei: "SOLOCO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0101",
    desc_prov_inei: "CHACHAPOYAS",
    cod_ubigeo_inei: "010121",
    desc_ubigeo_inei: "SONCHE",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010201",
    desc_ubigeo_inei: "BAGUA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010202",
    desc_ubigeo_inei: "ARAMANGO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010203",
    desc_ubigeo_inei: "COPALLIN",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010204",
    desc_ubigeo_inei: "EL PARCO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010205",
    desc_ubigeo_inei: "IMAZA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0102",
    desc_prov_inei: "BAGUA",
    cod_ubigeo_inei: "010206",
    desc_ubigeo_inei: "LA PECA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010301",
    desc_ubigeo_inei: "JUMBILLA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010302",
    desc_ubigeo_inei: "CHISQUILLA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010303",
    desc_ubigeo_inei: "CHURUJA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010304",
    desc_ubigeo_inei: "COROSHA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010305",
    desc_ubigeo_inei: "CUISPES",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010306",
    desc_ubigeo_inei: "FLORIDA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010307",
    desc_ubigeo_inei: "JAZAN",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010308",
    desc_ubigeo_inei: "RECTA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010309",
    desc_ubigeo_inei: "SAN CARLOS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010310",
    desc_ubigeo_inei: "SHIPASBAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010311",
    desc_ubigeo_inei: "VALERA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0103",
    desc_prov_inei: "BONGARA",
    cod_ubigeo_inei: "010312",
    desc_ubigeo_inei: "YAMBRASBAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0104",
    desc_prov_inei: "CONDORCANQUI",
    cod_ubigeo_inei: "010401",
    desc_ubigeo_inei: "NIEVA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0104",
    desc_prov_inei: "CONDORCANQUI",
    cod_ubigeo_inei: "010402",
    desc_ubigeo_inei: "EL CENEPA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0104",
    desc_prov_inei: "CONDORCANQUI",
    cod_ubigeo_inei: "010403",
    desc_ubigeo_inei: "RIO SANTIAGO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010501",
    desc_ubigeo_inei: "LAMUD",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010502",
    desc_ubigeo_inei: "CAMPORREDONDO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010503",
    desc_ubigeo_inei: "COCABAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010504",
    desc_ubigeo_inei: "COLCAMAR",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010505",
    desc_ubigeo_inei: "CONILA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010506",
    desc_ubigeo_inei: "INGUILPATA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010507",
    desc_ubigeo_inei: "LONGUITA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010508",
    desc_ubigeo_inei: "LONYA CHICO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010509",
    desc_ubigeo_inei: "LUYA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010510",
    desc_ubigeo_inei: "LUYA VIEJO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010511",
    desc_ubigeo_inei: "MARIA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010512",
    desc_ubigeo_inei: "OCALLI",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010513",
    desc_ubigeo_inei: "OCUMAL",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010514",
    desc_ubigeo_inei: "PISUQUIA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010515",
    desc_ubigeo_inei: "PROVIDENCIA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010516",
    desc_ubigeo_inei: "SAN CRISTOBAL",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010517",
    desc_ubigeo_inei: "SAN FRANCISCO DE YESO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010518",
    desc_ubigeo_inei: "SAN JERONIMO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010519",
    desc_ubigeo_inei: "SAN JUAN DE LOPECANCHA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010520",
    desc_ubigeo_inei: "SANTA CATALINA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010521",
    desc_ubigeo_inei: "SANTO TOMAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010522",
    desc_ubigeo_inei: "TINGO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0105",
    desc_prov_inei: "LUYA",
    cod_ubigeo_inei: "010523",
    desc_ubigeo_inei: "TRITA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010601",
    desc_ubigeo_inei: "SAN NICOLAS",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010602",
    desc_ubigeo_inei: "CHIRIMOTO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010603",
    desc_ubigeo_inei: "COCHAMAL",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010604",
    desc_ubigeo_inei: "HUAMBO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010605",
    desc_ubigeo_inei: "LIMABAMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010606",
    desc_ubigeo_inei: "LONGAR",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010607",
    desc_ubigeo_inei: "MARISCAL BENAVIDES",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010608",
    desc_ubigeo_inei: "MILPUC",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010609",
    desc_ubigeo_inei: "OMIA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010610",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010611",
    desc_ubigeo_inei: "TOTORA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0106",
    desc_prov_inei: "RODRIGUEZ DE MENDOZA",
    cod_ubigeo_inei: "010612",
    desc_ubigeo_inei: "VISTA ALEGRE",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010701",
    desc_ubigeo_inei: "BAGUA GRANDE",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010702",
    desc_ubigeo_inei: "CAJARURO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010703",
    desc_ubigeo_inei: "CUMBA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010704",
    desc_ubigeo_inei: "EL MILAGRO",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010705",
    desc_ubigeo_inei: "JAMALCA",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010706",
    desc_ubigeo_inei: "LONYA GRANDE",
  },
  {
    cod_dep_inei: "01",
    desc_dep_inei: "AMAZONAS",
    cod_prov_inei: "0107",
    desc_prov_inei: "UTCUBAMBA",
    cod_ubigeo_inei: "010707",
    desc_ubigeo_inei: "YAMON",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020101",
    desc_ubigeo_inei: "HUARAZ",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020102",
    desc_ubigeo_inei: "COCHABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020103",
    desc_ubigeo_inei: "COLCABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020104",
    desc_ubigeo_inei: "HUANCHAY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020105",
    desc_ubigeo_inei: "INDEPENDENCIA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020106",
    desc_ubigeo_inei: "JANGAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020107",
    desc_ubigeo_inei: "LA LIBERTAD",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020108",
    desc_ubigeo_inei: "OLLEROS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020109",
    desc_ubigeo_inei: "PAMPAS GRANDE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020110",
    desc_ubigeo_inei: "PARIACOTO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020111",
    desc_ubigeo_inei: "PIRA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0201",
    desc_prov_inei: "HUARAZ",
    cod_ubigeo_inei: "020112",
    desc_ubigeo_inei: "TARICA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0202",
    desc_prov_inei: "AIJA",
    cod_ubigeo_inei: "020201",
    desc_ubigeo_inei: "AIJA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0202",
    desc_prov_inei: "AIJA",
    cod_ubigeo_inei: "020202",
    desc_ubigeo_inei: "CORIS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0202",
    desc_prov_inei: "AIJA",
    cod_ubigeo_inei: "020203",
    desc_ubigeo_inei: "HUACLLAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0202",
    desc_prov_inei: "AIJA",
    cod_ubigeo_inei: "020204",
    desc_ubigeo_inei: "LA MERCED",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0202",
    desc_prov_inei: "AIJA",
    cod_ubigeo_inei: "020205",
    desc_ubigeo_inei: "SUCCHA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020301",
    desc_ubigeo_inei: "LLAMELLIN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020302",
    desc_ubigeo_inei: "ACZO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020303",
    desc_ubigeo_inei: "CHACCHO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020304",
    desc_ubigeo_inei: "CHINGAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020305",
    desc_ubigeo_inei: "MIRGAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0203",
    desc_prov_inei: "ANTONIO RAYMONDI",
    cod_ubigeo_inei: "020306",
    desc_ubigeo_inei: "SAN JUAN DE RONTOY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0204",
    desc_prov_inei: "ASUNCION",
    cod_ubigeo_inei: "020401",
    desc_ubigeo_inei: "CHACAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0204",
    desc_prov_inei: "ASUNCION",
    cod_ubigeo_inei: "020402",
    desc_ubigeo_inei: "ACOCHACA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020501",
    desc_ubigeo_inei: "CHIQUIAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020502",
    desc_ubigeo_inei: "ABELARDO PARDO LEZAMETA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020503",
    desc_ubigeo_inei: "ANTONIO RAYMONDI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020504",
    desc_ubigeo_inei: "AQUIA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020505",
    desc_ubigeo_inei: "CAJACAY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020506",
    desc_ubigeo_inei: "CANIS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020507",
    desc_ubigeo_inei: "COLQUIOC",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020508",
    desc_ubigeo_inei: "HUALLANCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020509",
    desc_ubigeo_inei: "HUASTA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020510",
    desc_ubigeo_inei: "HUAYLLACAYAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020511",
    desc_ubigeo_inei: "LA PRIMAVERA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020512",
    desc_ubigeo_inei: "MANGAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020513",
    desc_ubigeo_inei: "PACLLON",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020514",
    desc_ubigeo_inei: "SAN MIGUEL DE CORPANQUI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0205",
    desc_prov_inei: "BOLOGNESI",
    cod_ubigeo_inei: "020515",
    desc_ubigeo_inei: "TICLLOS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020601",
    desc_ubigeo_inei: "CARHUAZ",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020602",
    desc_ubigeo_inei: "ACOPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020603",
    desc_ubigeo_inei: "AMASHCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020604",
    desc_ubigeo_inei: "ANTA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020605",
    desc_ubigeo_inei: "ATAQUERO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020606",
    desc_ubigeo_inei: "MARCARA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020607",
    desc_ubigeo_inei: "PARIAHUANCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020608",
    desc_ubigeo_inei: "SAN MIGUEL DE ACO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020609",
    desc_ubigeo_inei: "SHILLA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020610",
    desc_ubigeo_inei: "TINCO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0206",
    desc_prov_inei: "CARHUAZ",
    cod_ubigeo_inei: "020611",
    desc_ubigeo_inei: "YUNGAR",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0207",
    desc_prov_inei: "CARLOS FERMIN FITZCARRALD",
    cod_ubigeo_inei: "020701",
    desc_ubigeo_inei: "SAN LUIS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0207",
    desc_prov_inei: "CARLOS FERMIN FITZCARRALD",
    cod_ubigeo_inei: "020702",
    desc_ubigeo_inei: "SAN NICOLAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0207",
    desc_prov_inei: "CARLOS FERMIN FITZCARRALD",
    cod_ubigeo_inei: "020703",
    desc_ubigeo_inei: "YAUYA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0208",
    desc_prov_inei: "CASMA",
    cod_ubigeo_inei: "020801",
    desc_ubigeo_inei: "CASMA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0208",
    desc_prov_inei: "CASMA",
    cod_ubigeo_inei: "020802",
    desc_ubigeo_inei: "BUENA VISTA ALTA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0208",
    desc_prov_inei: "CASMA",
    cod_ubigeo_inei: "020803",
    desc_ubigeo_inei: "COMANDANTE NOEL",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0208",
    desc_prov_inei: "CASMA",
    cod_ubigeo_inei: "020804",
    desc_ubigeo_inei: "YAUTAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020901",
    desc_ubigeo_inei: "CORONGO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020902",
    desc_ubigeo_inei: "ACO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020903",
    desc_ubigeo_inei: "BAMBAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020904",
    desc_ubigeo_inei: "CUSCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020905",
    desc_ubigeo_inei: "LA PAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020906",
    desc_ubigeo_inei: "YANAC",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0209",
    desc_prov_inei: "CORONGO",
    cod_ubigeo_inei: "020907",
    desc_ubigeo_inei: "YUPAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021001",
    desc_ubigeo_inei: "HUARI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021002",
    desc_ubigeo_inei: "ANRA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021003",
    desc_ubigeo_inei: "CAJAY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021004",
    desc_ubigeo_inei: "CHAVIN DE HUANTAR",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021005",
    desc_ubigeo_inei: "HUACACHI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021006",
    desc_ubigeo_inei: "HUACCHIS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021007",
    desc_ubigeo_inei: "HUACHIS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021008",
    desc_ubigeo_inei: "HUANTAR",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021009",
    desc_ubigeo_inei: "MASIN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021010",
    desc_ubigeo_inei: "PAUCAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021011",
    desc_ubigeo_inei: "PONTO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021012",
    desc_ubigeo_inei: "RAHUAPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021013",
    desc_ubigeo_inei: "RAPAYAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021014",
    desc_ubigeo_inei: "SAN MARCOS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021015",
    desc_ubigeo_inei: "SAN PEDRO DE CHANA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0210",
    desc_prov_inei: "HUARI",
    cod_ubigeo_inei: "021016",
    desc_ubigeo_inei: "UCO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0211",
    desc_prov_inei: "HUARMEY",
    cod_ubigeo_inei: "021101",
    desc_ubigeo_inei: "HUARMEY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0211",
    desc_prov_inei: "HUARMEY",
    cod_ubigeo_inei: "021102",
    desc_ubigeo_inei: "COCHAPETI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0211",
    desc_prov_inei: "HUARMEY",
    cod_ubigeo_inei: "021103",
    desc_ubigeo_inei: "CULEBRAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0211",
    desc_prov_inei: "HUARMEY",
    cod_ubigeo_inei: "021104",
    desc_ubigeo_inei: "HUAYAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0211",
    desc_prov_inei: "HUARMEY",
    cod_ubigeo_inei: "021105",
    desc_ubigeo_inei: "MALVAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021201",
    desc_ubigeo_inei: "CARAZ",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021202",
    desc_ubigeo_inei: "HUALLANCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021203",
    desc_ubigeo_inei: "HUATA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021204",
    desc_ubigeo_inei: "HUAYLAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021205",
    desc_ubigeo_inei: "MATO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021206",
    desc_ubigeo_inei: "PAMPAROMAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021207",
    desc_ubigeo_inei: "PUEBLO LIBRE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021208",
    desc_ubigeo_inei: "SANTA CRUZ",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021209",
    desc_ubigeo_inei: "SANTO TORIBIO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0212",
    desc_prov_inei: "HUAYLAS",
    cod_ubigeo_inei: "021210",
    desc_ubigeo_inei: "YURACMARCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021301",
    desc_ubigeo_inei: "PISCOBAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021302",
    desc_ubigeo_inei: "CASCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021303",
    desc_ubigeo_inei: "ELEAZAR GUZMAN BARRON",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021304",
    desc_ubigeo_inei: "FIDEL OLIVAS ESCUDERO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021305",
    desc_ubigeo_inei: "LLAMA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021306",
    desc_ubigeo_inei: "LLUMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021307",
    desc_ubigeo_inei: "LUCMA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0213",
    desc_prov_inei: "MARISCAL LUZURIAGA",
    cod_ubigeo_inei: "021308",
    desc_ubigeo_inei: "MUSGA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021401",
    desc_ubigeo_inei: "OCROS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021402",
    desc_ubigeo_inei: "ACAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021403",
    desc_ubigeo_inei: "CAJAMARQUILLA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021404",
    desc_ubigeo_inei: "CARHUAPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021405",
    desc_ubigeo_inei: "COCHAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021406",
    desc_ubigeo_inei: "CONGAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021407",
    desc_ubigeo_inei: "LLIPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021408",
    desc_ubigeo_inei: "SAN CRISTOBAL DE RAJAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021409",
    desc_ubigeo_inei: "SAN PEDRO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0214",
    desc_prov_inei: "OCROS",
    cod_ubigeo_inei: "021410",
    desc_ubigeo_inei: "SANTIAGO DE CHILCAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021501",
    desc_ubigeo_inei: "CABANA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021502",
    desc_ubigeo_inei: "BOLOGNESI",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021503",
    desc_ubigeo_inei: "CONCHUCOS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021504",
    desc_ubigeo_inei: "HUACASCHUQUE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021505",
    desc_ubigeo_inei: "HUANDOVAL",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021506",
    desc_ubigeo_inei: "LACABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021507",
    desc_ubigeo_inei: "LLAPO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021508",
    desc_ubigeo_inei: "PALLASCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021509",
    desc_ubigeo_inei: "PAMPAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021510",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0215",
    desc_prov_inei: "PALLASCA",
    cod_ubigeo_inei: "021511",
    desc_ubigeo_inei: "TAUCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0216",
    desc_prov_inei: "POMABAMBA",
    cod_ubigeo_inei: "021601",
    desc_ubigeo_inei: "POMABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0216",
    desc_prov_inei: "POMABAMBA",
    cod_ubigeo_inei: "021602",
    desc_ubigeo_inei: "HUAYLLAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0216",
    desc_prov_inei: "POMABAMBA",
    cod_ubigeo_inei: "021603",
    desc_ubigeo_inei: "PAROBAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0216",
    desc_prov_inei: "POMABAMBA",
    cod_ubigeo_inei: "021604",
    desc_ubigeo_inei: "QUINUABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021701",
    desc_ubigeo_inei: "RECUAY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021702",
    desc_ubigeo_inei: "CATAC",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021703",
    desc_ubigeo_inei: "COTAPARACO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021704",
    desc_ubigeo_inei: "HUAYLLAPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021705",
    desc_ubigeo_inei: "LLACLLIN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021706",
    desc_ubigeo_inei: "MARCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021707",
    desc_ubigeo_inei: "PAMPAS CHICO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021708",
    desc_ubigeo_inei: "PARARIN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021709",
    desc_ubigeo_inei: "TAPACOCHA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0217",
    desc_prov_inei: "RECUAY",
    cod_ubigeo_inei: "021710",
    desc_ubigeo_inei: "TICAPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021801",
    desc_ubigeo_inei: "CHIMBOTE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021802",
    desc_ubigeo_inei: "CACERES DEL PERU",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021803",
    desc_ubigeo_inei: "COISHCO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021804",
    desc_ubigeo_inei: "MACATE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021805",
    desc_ubigeo_inei: "MORO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021806",
    desc_ubigeo_inei: "NEPEÑA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021807",
    desc_ubigeo_inei: "SAMANCO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021808",
    desc_ubigeo_inei: "SANTA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0218",
    desc_prov_inei: "SANTA",
    cod_ubigeo_inei: "021809",
    desc_ubigeo_inei: "NUEVO CHIMBOTE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021901",
    desc_ubigeo_inei: "SIHUAS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021902",
    desc_ubigeo_inei: "ACOBAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021903",
    desc_ubigeo_inei: "ALFONSO UGARTE",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021904",
    desc_ubigeo_inei: "CASHAPAMPA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021905",
    desc_ubigeo_inei: "CHINGALPO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021906",
    desc_ubigeo_inei: "HUAYLLABAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021907",
    desc_ubigeo_inei: "QUICHES",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021908",
    desc_ubigeo_inei: "RAGASH",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021909",
    desc_ubigeo_inei: "SAN JUAN",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0219",
    desc_prov_inei: "SIHUAS",
    cod_ubigeo_inei: "021910",
    desc_ubigeo_inei: "SICSIBAMBA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022001",
    desc_ubigeo_inei: "YUNGAY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022002",
    desc_ubigeo_inei: "CASCAPARA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022003",
    desc_ubigeo_inei: "MANCOS",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022004",
    desc_ubigeo_inei: "MATACOTO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022005",
    desc_ubigeo_inei: "QUILLO",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022006",
    desc_ubigeo_inei: "RANRAHIRCA",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022007",
    desc_ubigeo_inei: "SHUPLUY",
  },
  {
    cod_dep_inei: "02",
    desc_dep_inei: "ANCASH",
    cod_prov_inei: "0220",
    desc_prov_inei: "YUNGAY",
    cod_ubigeo_inei: "022008",
    desc_ubigeo_inei: "YANAMA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030101",
    desc_ubigeo_inei: "ABANCAY",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030102",
    desc_ubigeo_inei: "CHACOCHE",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030103",
    desc_ubigeo_inei: "CIRCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030104",
    desc_ubigeo_inei: "CURAHUASI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030105",
    desc_ubigeo_inei: "HUANIPACA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030106",
    desc_ubigeo_inei: "LAMBRAMA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030107",
    desc_ubigeo_inei: "PICHIRHUA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030108",
    desc_ubigeo_inei: "SAN PEDRO DE CACHORA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0301",
    desc_prov_inei: "ABANCAY",
    cod_ubigeo_inei: "030109",
    desc_ubigeo_inei: "TAMBURCO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030201",
    desc_ubigeo_inei: "ANDAHUAYLAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030202",
    desc_ubigeo_inei: "ANDARAPA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030203",
    desc_ubigeo_inei: "CHIARA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030204",
    desc_ubigeo_inei: "HUANCARAMA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030205",
    desc_ubigeo_inei: "HUANCARAY",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030206",
    desc_ubigeo_inei: "HUAYANA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030207",
    desc_ubigeo_inei: "KISHUARA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030208",
    desc_ubigeo_inei: "PACOBAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030209",
    desc_ubigeo_inei: "PACUCHA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030210",
    desc_ubigeo_inei: "PAMPACHIRI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030211",
    desc_ubigeo_inei: "POMACOCHA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030212",
    desc_ubigeo_inei: "SAN ANTONIO DE CACHI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030213",
    desc_ubigeo_inei: "SAN JERONIMO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030214",
    desc_ubigeo_inei: "SAN MIGUEL DE CHACCRAMPA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030215",
    desc_ubigeo_inei: "SANTA MARIA DE CHICMO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030216",
    desc_ubigeo_inei: "TALAVERA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030217",
    desc_ubigeo_inei: "TUMAY HUARACA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030218",
    desc_ubigeo_inei: "TURPO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030219",
    desc_ubigeo_inei: "KAQUIABAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0302",
    desc_prov_inei: "ANDAHUAYLAS",
    cod_ubigeo_inei: "030220",
    desc_ubigeo_inei: "JOSE MARIA ARGUEDAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030301",
    desc_ubigeo_inei: "ANTABAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030302",
    desc_ubigeo_inei: "EL ORO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030303",
    desc_ubigeo_inei: "HUAQUIRCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030304",
    desc_ubigeo_inei: "JUAN ESPINOZA MEDRANO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030305",
    desc_ubigeo_inei: "OROPESA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030306",
    desc_ubigeo_inei: "PACHACONAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0303",
    desc_prov_inei: "ANTABAMBA",
    cod_ubigeo_inei: "030307",
    desc_ubigeo_inei: "SABAINO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030401",
    desc_ubigeo_inei: "CHALHUANCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030402",
    desc_ubigeo_inei: "CAPAYA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030403",
    desc_ubigeo_inei: "CARAYBAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030404",
    desc_ubigeo_inei: "CHAPIMARCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030405",
    desc_ubigeo_inei: "COLCABAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030406",
    desc_ubigeo_inei: "COTARUSE",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030407",
    desc_ubigeo_inei: "IHUAYLLO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030408",
    desc_ubigeo_inei: "JUSTO APU SAHUARAURA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030409",
    desc_ubigeo_inei: "LUCRE",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030410",
    desc_ubigeo_inei: "POCOHUANCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030411",
    desc_ubigeo_inei: "SAN JUAN DE CHACÑA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030412",
    desc_ubigeo_inei: "SAÑAYCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030413",
    desc_ubigeo_inei: "SORAYA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030414",
    desc_ubigeo_inei: "TAPAIRIHUA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030415",
    desc_ubigeo_inei: "TINTAY",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030416",
    desc_ubigeo_inei: "TORAYA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0304",
    desc_prov_inei: "AYMARAES",
    cod_ubigeo_inei: "030417",
    desc_ubigeo_inei: "YANACA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030501",
    desc_ubigeo_inei: "TAMBOBAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030502",
    desc_ubigeo_inei: "COTABAMBAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030503",
    desc_ubigeo_inei: "COYLLURQUI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030504",
    desc_ubigeo_inei: "HAQUIRA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030505",
    desc_ubigeo_inei: "MARA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0305",
    desc_prov_inei: "COTABAMBAS",
    cod_ubigeo_inei: "030506",
    desc_ubigeo_inei: "CHALLHUAHUACHO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030601",
    desc_ubigeo_inei: "CHINCHEROS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030602",
    desc_ubigeo_inei: "ANCO_HUALLO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030603",
    desc_ubigeo_inei: "COCHARCAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030604",
    desc_ubigeo_inei: "HUACCANA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030605",
    desc_ubigeo_inei: "OCOBAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030606",
    desc_ubigeo_inei: "ONGOY",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030607",
    desc_ubigeo_inei: "URANMARCA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030608",
    desc_ubigeo_inei: "RANRACANCHA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030609",
    desc_ubigeo_inei: "ROCCHACC",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030610",
    desc_ubigeo_inei: "EL PORVENIR",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0306",
    desc_prov_inei: "CHINCHEROS",
    cod_ubigeo_inei: "030611",
    desc_ubigeo_inei: "LOS CHANKAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030701",
    desc_ubigeo_inei: "CHUQUIBAMBILLA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030702",
    desc_ubigeo_inei: "CURPAHUASI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030703",
    desc_ubigeo_inei: "GAMARRA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030704",
    desc_ubigeo_inei: "HUAYLLATI",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030705",
    desc_ubigeo_inei: "MAMARA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030706",
    desc_ubigeo_inei: "MICAELA BASTIDAS",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030707",
    desc_ubigeo_inei: "PATAYPAMPA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030708",
    desc_ubigeo_inei: "PROGRESO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030709",
    desc_ubigeo_inei: "SAN ANTONIO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030710",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030711",
    desc_ubigeo_inei: "TURPAY",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030712",
    desc_ubigeo_inei: "VILCABAMBA",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030713",
    desc_ubigeo_inei: "VIRUNDO",
  },
  {
    cod_dep_inei: "03",
    desc_dep_inei: "APURIMAC",
    cod_prov_inei: "0307",
    desc_prov_inei: "GRAU",
    cod_ubigeo_inei: "030714",
    desc_ubigeo_inei: "CURASCO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040101",
    desc_ubigeo_inei: "AREQUIPA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040102",
    desc_ubigeo_inei: "ALTO SELVA ALEGRE",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040103",
    desc_ubigeo_inei: "CAYMA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040104",
    desc_ubigeo_inei: "CERRO COLORADO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040105",
    desc_ubigeo_inei: "CHARACATO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040106",
    desc_ubigeo_inei: "CHIGUATA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040107",
    desc_ubigeo_inei: "JACOBO HUNTER",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040108",
    desc_ubigeo_inei: "LA JOYA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040109",
    desc_ubigeo_inei: "MARIANO MELGAR",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040110",
    desc_ubigeo_inei: "MIRAFLORES",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040111",
    desc_ubigeo_inei: "MOLLEBAYA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040112",
    desc_ubigeo_inei: "PAUCARPATA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040113",
    desc_ubigeo_inei: "POCSI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040114",
    desc_ubigeo_inei: "POLOBAYA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040115",
    desc_ubigeo_inei: "QUEQUEÑA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040116",
    desc_ubigeo_inei: "SABANDIA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040117",
    desc_ubigeo_inei: "SACHACA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040118",
    desc_ubigeo_inei: "SAN JUAN DE SIGUAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040119",
    desc_ubigeo_inei: "SAN JUAN DE TARUCANI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040120",
    desc_ubigeo_inei: "SANTA ISABEL DE SIGUAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040121",
    desc_ubigeo_inei: "SANTA RITA DE SIGUAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040122",
    desc_ubigeo_inei: "SOCABAYA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040123",
    desc_ubigeo_inei: "TIABAYA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040124",
    desc_ubigeo_inei: "UCHUMAYO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040125",
    desc_ubigeo_inei: "VITOR",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040126",
    desc_ubigeo_inei: "YANAHUARA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040127",
    desc_ubigeo_inei: "YARABAMBA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040128",
    desc_ubigeo_inei: "YURA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0401",
    desc_prov_inei: "AREQUIPA",
    cod_ubigeo_inei: "040129",
    desc_ubigeo_inei: "JOSE LUIS BUSTAMANTE Y RIVERO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040201",
    desc_ubigeo_inei: "CAMANA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040202",
    desc_ubigeo_inei: "JOSE MARIA QUIMPER",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040203",
    desc_ubigeo_inei: "MARIANO NICOLAS VALCARCEL",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040204",
    desc_ubigeo_inei: "MARISCAL CACERES",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040205",
    desc_ubigeo_inei: "NICOLAS DE PIEROLA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040206",
    desc_ubigeo_inei: "OCOÑA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040207",
    desc_ubigeo_inei: "QUILCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0402",
    desc_prov_inei: "CAMANA",
    cod_ubigeo_inei: "040208",
    desc_ubigeo_inei: "SAMUEL PASTOR",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040301",
    desc_ubigeo_inei: "CARAVELI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040302",
    desc_ubigeo_inei: "ACARI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040303",
    desc_ubigeo_inei: "ATICO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040304",
    desc_ubigeo_inei: "ATIQUIPA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040305",
    desc_ubigeo_inei: "BELLA UNION",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040306",
    desc_ubigeo_inei: "CAHUACHO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040307",
    desc_ubigeo_inei: "CHALA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040308",
    desc_ubigeo_inei: "CHAPARRA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040309",
    desc_ubigeo_inei: "HUANUHUANU",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040310",
    desc_ubigeo_inei: "JAQUI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040311",
    desc_ubigeo_inei: "LOMAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040312",
    desc_ubigeo_inei: "QUICACHA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0403",
    desc_prov_inei: "CARAVELI",
    cod_ubigeo_inei: "040313",
    desc_ubigeo_inei: "YAUCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040401",
    desc_ubigeo_inei: "APLAO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040402",
    desc_ubigeo_inei: "ANDAGUA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040403",
    desc_ubigeo_inei: "AYO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040404",
    desc_ubigeo_inei: "CHACHAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040405",
    desc_ubigeo_inei: "CHILCAYMARCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040406",
    desc_ubigeo_inei: "CHOCO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040407",
    desc_ubigeo_inei: "HUANCARQUI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040408",
    desc_ubigeo_inei: "MACHAGUAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040409",
    desc_ubigeo_inei: "ORCOPAMPA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040410",
    desc_ubigeo_inei: "PAMPACOLCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040411",
    desc_ubigeo_inei: "TIPAN",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040412",
    desc_ubigeo_inei: "UÑON",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040413",
    desc_ubigeo_inei: "URACA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0404",
    desc_prov_inei: "CASTILLA",
    cod_ubigeo_inei: "040414",
    desc_ubigeo_inei: "VIRACO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040501",
    desc_ubigeo_inei: "CHIVAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040502",
    desc_ubigeo_inei: "ACHOMA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040503",
    desc_ubigeo_inei: "CABANACONDE",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040504",
    desc_ubigeo_inei: "CALLALLI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040505",
    desc_ubigeo_inei: "CAYLLOMA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040506",
    desc_ubigeo_inei: "COPORAQUE",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040507",
    desc_ubigeo_inei: "HUAMBO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040508",
    desc_ubigeo_inei: "HUANCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040509",
    desc_ubigeo_inei: "ICHUPAMPA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040510",
    desc_ubigeo_inei: "LARI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040511",
    desc_ubigeo_inei: "LLUTA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040512",
    desc_ubigeo_inei: "MACA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040513",
    desc_ubigeo_inei: "MADRIGAL",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040514",
    desc_ubigeo_inei: "SAN ANTONIO DE CHUCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040515",
    desc_ubigeo_inei: "SIBAYO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040516",
    desc_ubigeo_inei: "TAPAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040517",
    desc_ubigeo_inei: "TISCO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040518",
    desc_ubigeo_inei: "TUTI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040519",
    desc_ubigeo_inei: "YANQUE",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0405",
    desc_prov_inei: "CAYLLOMA",
    cod_ubigeo_inei: "040520",
    desc_ubigeo_inei: "MAJES",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040601",
    desc_ubigeo_inei: "CHUQUIBAMBA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040602",
    desc_ubigeo_inei: "ANDARAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040603",
    desc_ubigeo_inei: "CAYARANI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040604",
    desc_ubigeo_inei: "CHICHAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040605",
    desc_ubigeo_inei: "IRAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040606",
    desc_ubigeo_inei: "RIO GRANDE",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040607",
    desc_ubigeo_inei: "SALAMANCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0406",
    desc_prov_inei: "CONDESUYOS",
    cod_ubigeo_inei: "040608",
    desc_ubigeo_inei: "YANAQUIHUA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040701",
    desc_ubigeo_inei: "MOLLENDO",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040702",
    desc_ubigeo_inei: "COCACHACRA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040703",
    desc_ubigeo_inei: "DEAN VALDIVIA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040704",
    desc_ubigeo_inei: "ISLAY",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040705",
    desc_ubigeo_inei: "MEJIA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0407",
    desc_prov_inei: "ISLAY",
    cod_ubigeo_inei: "040706",
    desc_ubigeo_inei: "PUNTA DE BOMBON",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040801",
    desc_ubigeo_inei: "COTAHUASI",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040802",
    desc_ubigeo_inei: "ALCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040803",
    desc_ubigeo_inei: "CHARCANA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040804",
    desc_ubigeo_inei: "HUAYNACOTAS",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040805",
    desc_ubigeo_inei: "PAMPAMARCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040806",
    desc_ubigeo_inei: "PUYCA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040807",
    desc_ubigeo_inei: "QUECHUALLA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040808",
    desc_ubigeo_inei: "SAYLA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040809",
    desc_ubigeo_inei: "TAURIA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040810",
    desc_ubigeo_inei: "TOMEPAMPA",
  },
  {
    cod_dep_inei: "04",
    desc_dep_inei: "AREQUIPA",
    cod_prov_inei: "0408",
    desc_prov_inei: "LA UNIÑN",
    cod_ubigeo_inei: "040811",
    desc_ubigeo_inei: "TORO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050101",
    desc_ubigeo_inei: "AYACUCHO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050102",
    desc_ubigeo_inei: "ACOCRO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050103",
    desc_ubigeo_inei: "ACOS VINCHOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050104",
    desc_ubigeo_inei: "CARMEN ALTO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050105",
    desc_ubigeo_inei: "CHIARA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050106",
    desc_ubigeo_inei: "OCROS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050107",
    desc_ubigeo_inei: "PACAYCASA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050108",
    desc_ubigeo_inei: "QUINUA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050109",
    desc_ubigeo_inei: "SAN JOSE DE TICLLAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050110",
    desc_ubigeo_inei: "SAN JUAN BAUTISTA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050111",
    desc_ubigeo_inei: "SANTIAGO DE PISCHA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050112",
    desc_ubigeo_inei: "SOCOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050113",
    desc_ubigeo_inei: "TAMBILLO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050114",
    desc_ubigeo_inei: "VINCHOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050115",
    desc_ubigeo_inei: "JESUS NAZARENO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0501",
    desc_prov_inei: "HUAMANGA",
    cod_ubigeo_inei: "050116",
    desc_ubigeo_inei: "ANDRES AVELINO CACERES DORREGARAY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050201",
    desc_ubigeo_inei: "CANGALLO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050202",
    desc_ubigeo_inei: "CHUSCHI",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050203",
    desc_ubigeo_inei: "LOS MOROCHUCOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050204",
    desc_ubigeo_inei: "MARIA PARADO DE BELLIDO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050205",
    desc_ubigeo_inei: "PARAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0502",
    desc_prov_inei: "CANGALLO",
    cod_ubigeo_inei: "050206",
    desc_ubigeo_inei: "TOTOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0503",
    desc_prov_inei: "HUANCA SANCOS",
    cod_ubigeo_inei: "050301",
    desc_ubigeo_inei: "SANCOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0503",
    desc_prov_inei: "HUANCA SANCOS",
    cod_ubigeo_inei: "050302",
    desc_ubigeo_inei: "CARAPO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0503",
    desc_prov_inei: "HUANCA SANCOS",
    cod_ubigeo_inei: "050303",
    desc_ubigeo_inei: "SACSAMARCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0503",
    desc_prov_inei: "HUANCA SANCOS",
    cod_ubigeo_inei: "050304",
    desc_ubigeo_inei: "SANTIAGO DE LUCANAMARCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050401",
    desc_ubigeo_inei: "HUANTA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050402",
    desc_ubigeo_inei: "AYAHUANCO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050403",
    desc_ubigeo_inei: "HUAMANGUILLA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050404",
    desc_ubigeo_inei: "IGUAIN",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050405",
    desc_ubigeo_inei: "LURICOCHA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050406",
    desc_ubigeo_inei: "SANTILLANA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050407",
    desc_ubigeo_inei: "SIVIA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050408",
    desc_ubigeo_inei: "LLOCHEGUA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050409",
    desc_ubigeo_inei: "CANAYRE",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050410",
    desc_ubigeo_inei: "UCHURACCAY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050411",
    desc_ubigeo_inei: "PUCACOLPA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0504",
    desc_prov_inei: "HUANTA",
    cod_ubigeo_inei: "050412",
    desc_ubigeo_inei: "CHACA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050501",
    desc_ubigeo_inei: "SAN MIGUEL",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050502",
    desc_ubigeo_inei: "ANCO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050503",
    desc_ubigeo_inei: "AYNA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050504",
    desc_ubigeo_inei: "CHILCAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050505",
    desc_ubigeo_inei: "CHUNGUI",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050506",
    desc_ubigeo_inei: "LUIS CARRANZA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050507",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050508",
    desc_ubigeo_inei: "TAMBO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050509",
    desc_ubigeo_inei: "SAMUGARI",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050510",
    desc_ubigeo_inei: "ANCHIHUAY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0505",
    desc_prov_inei: "LA MAR",
    cod_ubigeo_inei: "050511",
    desc_ubigeo_inei: "ORONCCOY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050601",
    desc_ubigeo_inei: "PUQUIO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050602",
    desc_ubigeo_inei: "AUCARA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050603",
    desc_ubigeo_inei: "CABANA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050604",
    desc_ubigeo_inei: "CARMEN SALCEDO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050605",
    desc_ubigeo_inei: "CHAVIÑA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050606",
    desc_ubigeo_inei: "CHIPAO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050607",
    desc_ubigeo_inei: "HUAC-HUAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050608",
    desc_ubigeo_inei: "LARAMATE",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050609",
    desc_ubigeo_inei: "LEONCIO PRADO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050610",
    desc_ubigeo_inei: "LLAUTA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050611",
    desc_ubigeo_inei: "LUCANAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050612",
    desc_ubigeo_inei: "OCAÑA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050613",
    desc_ubigeo_inei: "OTOCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050614",
    desc_ubigeo_inei: "SAISA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050615",
    desc_ubigeo_inei: "SAN CRISTOBAL",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050616",
    desc_ubigeo_inei: "SAN JUAN",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050617",
    desc_ubigeo_inei: "SAN PEDRO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050618",
    desc_ubigeo_inei: "SAN PEDRO DE PALCO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050619",
    desc_ubigeo_inei: "SANCOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050620",
    desc_ubigeo_inei: "SANTA ANA DE HUAYCAHUACHO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0506",
    desc_prov_inei: "LUCANAS",
    cod_ubigeo_inei: "050621",
    desc_ubigeo_inei: "SANTA LUCIA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050701",
    desc_ubigeo_inei: "CORACORA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050702",
    desc_ubigeo_inei: "CHUMPI",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050703",
    desc_ubigeo_inei: "CORONEL CASTAÑEDA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050704",
    desc_ubigeo_inei: "PACAPAUSA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050705",
    desc_ubigeo_inei: "PULLO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050706",
    desc_ubigeo_inei: "PUYUSCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050707",
    desc_ubigeo_inei: "SAN FRANCISCO DE RAVACAYCO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0507",
    desc_prov_inei: "PARINACOCHAS",
    cod_ubigeo_inei: "050708",
    desc_ubigeo_inei: "UPAHUACHO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050801",
    desc_ubigeo_inei: "PAUSA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050802",
    desc_ubigeo_inei: "COLTA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050803",
    desc_ubigeo_inei: "CORCULLA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050804",
    desc_ubigeo_inei: "LAMPA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050805",
    desc_ubigeo_inei: "MARCABAMBA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050806",
    desc_ubigeo_inei: "OYOLO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050807",
    desc_ubigeo_inei: "PARARCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050808",
    desc_ubigeo_inei: "SAN JAVIER DE ALPABAMBA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050809",
    desc_ubigeo_inei: "SAN JOSE DE USHUA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0508",
    desc_prov_inei: "PÑUCAR DEL SARA SARA",
    cod_ubigeo_inei: "050810",
    desc_ubigeo_inei: "SARA SARA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050901",
    desc_ubigeo_inei: "QUEROBAMBA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050902",
    desc_ubigeo_inei: "BELEN",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050903",
    desc_ubigeo_inei: "CHALCOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050904",
    desc_ubigeo_inei: "CHILCAYOC",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050905",
    desc_ubigeo_inei: "HUACAÑA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050906",
    desc_ubigeo_inei: "MORCOLLA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050907",
    desc_ubigeo_inei: "PAICO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050908",
    desc_ubigeo_inei: "SAN PEDRO DE LARCAY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050909",
    desc_ubigeo_inei: "SAN SALVADOR DE QUIJE",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050910",
    desc_ubigeo_inei: "SANTIAGO DE PAUCARAY",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0509",
    desc_prov_inei: "SUCRE",
    cod_ubigeo_inei: "050911",
    desc_ubigeo_inei: "SORAS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051001",
    desc_ubigeo_inei: "HUANCAPI",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051002",
    desc_ubigeo_inei: "ALCAMENCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051003",
    desc_ubigeo_inei: "APONGO",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051004",
    desc_ubigeo_inei: "ASQUIPATA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051005",
    desc_ubigeo_inei: "CANARIA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051006",
    desc_ubigeo_inei: "CAYARA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051007",
    desc_ubigeo_inei: "COLCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051008",
    desc_ubigeo_inei: "HUAMANQUIQUIA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051009",
    desc_ubigeo_inei: "HUANCARAYLLA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051010",
    desc_ubigeo_inei: "HUAYA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051011",
    desc_ubigeo_inei: "SARHUA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0510",
    desc_prov_inei: "VICTOR FAJARDO",
    cod_ubigeo_inei: "051012",
    desc_ubigeo_inei: "VILCANCHOS",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051101",
    desc_ubigeo_inei: "VILCAS HUAMAN",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051102",
    desc_ubigeo_inei: "ACCOMARCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051103",
    desc_ubigeo_inei: "CARHUANCA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051104",
    desc_ubigeo_inei: "CONCEPCION",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051105",
    desc_ubigeo_inei: "HUAMBALPA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051106",
    desc_ubigeo_inei: "INDEPENDENCIA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051107",
    desc_ubigeo_inei: "SAURAMA",
  },
  {
    cod_dep_inei: "05",
    desc_dep_inei: "AYACUCHO",
    cod_prov_inei: "0511",
    desc_prov_inei: "VILCAS HUAMAN",
    cod_ubigeo_inei: "051108",
    desc_ubigeo_inei: "VISCHONGO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060101",
    desc_ubigeo_inei: "CAJAMARCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060102",
    desc_ubigeo_inei: "ASUNCION",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060103",
    desc_ubigeo_inei: "CHETILLA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060104",
    desc_ubigeo_inei: "COSPAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060105",
    desc_ubigeo_inei: "ENCAÑADA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060106",
    desc_ubigeo_inei: "JESUS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060107",
    desc_ubigeo_inei: "LLACANORA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060108",
    desc_ubigeo_inei: "LOS BAÑOS DEL INCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060109",
    desc_ubigeo_inei: "MAGDALENA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060110",
    desc_ubigeo_inei: "MATARA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060111",
    desc_ubigeo_inei: "NAMORA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0601",
    desc_prov_inei: "CAJAMARCA",
    cod_ubigeo_inei: "060112",
    desc_ubigeo_inei: "SAN JUAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0602",
    desc_prov_inei: "CAJABAMBA",
    cod_ubigeo_inei: "060201",
    desc_ubigeo_inei: "CAJABAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0602",
    desc_prov_inei: "CAJABAMBA",
    cod_ubigeo_inei: "060202",
    desc_ubigeo_inei: "CACHACHI",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0602",
    desc_prov_inei: "CAJABAMBA",
    cod_ubigeo_inei: "060203",
    desc_ubigeo_inei: "CONDEBAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0602",
    desc_prov_inei: "CAJABAMBA",
    cod_ubigeo_inei: "060204",
    desc_ubigeo_inei: "SITACOCHA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060301",
    desc_ubigeo_inei: "CELENDIN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060302",
    desc_ubigeo_inei: "CHUMUCH",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060303",
    desc_ubigeo_inei: "CORTEGANA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060304",
    desc_ubigeo_inei: "HUASMIN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060305",
    desc_ubigeo_inei: "JORGE CHAVEZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060306",
    desc_ubigeo_inei: "JOSE GALVEZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060307",
    desc_ubigeo_inei: "MIGUEL IGLESIAS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060308",
    desc_ubigeo_inei: "OXAMARCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060309",
    desc_ubigeo_inei: "SOROCHUCO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060310",
    desc_ubigeo_inei: "SUCRE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060311",
    desc_ubigeo_inei: "UTCO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0603",
    desc_prov_inei: "CELENDIN",
    cod_ubigeo_inei: "060312",
    desc_ubigeo_inei: "LA LIBERTAD DE PALLAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060401",
    desc_ubigeo_inei: "CHOTA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060402",
    desc_ubigeo_inei: "ANGUIA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060403",
    desc_ubigeo_inei: "CHADIN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060404",
    desc_ubigeo_inei: "CHIGUIRIP",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060405",
    desc_ubigeo_inei: "CHIMBAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060406",
    desc_ubigeo_inei: "CHOROPAMPA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060407",
    desc_ubigeo_inei: "COCHABAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060408",
    desc_ubigeo_inei: "CONCHAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060409",
    desc_ubigeo_inei: "HUAMBOS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060410",
    desc_ubigeo_inei: "LAJAS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060411",
    desc_ubigeo_inei: "LLAMA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060412",
    desc_ubigeo_inei: "MIRACOSTA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060413",
    desc_ubigeo_inei: "PACCHA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060414",
    desc_ubigeo_inei: "PION",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060415",
    desc_ubigeo_inei: "QUEROCOTO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060416",
    desc_ubigeo_inei: "SAN JUAN DE LICUPIS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060417",
    desc_ubigeo_inei: "TACABAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060418",
    desc_ubigeo_inei: "TOCMOCHE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0604",
    desc_prov_inei: "CHOTA",
    cod_ubigeo_inei: "060419",
    desc_ubigeo_inei: "CHALAMARCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060501",
    desc_ubigeo_inei: "CONTUMAZA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060502",
    desc_ubigeo_inei: "CHILETE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060503",
    desc_ubigeo_inei: "CUPISNIQUE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060504",
    desc_ubigeo_inei: "GUZMANGO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060505",
    desc_ubigeo_inei: "SAN BENITO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060506",
    desc_ubigeo_inei: "SANTA CRUZ DE TOLEDO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060507",
    desc_ubigeo_inei: "TANTARICA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0605",
    desc_prov_inei: "CONTUMAZA",
    cod_ubigeo_inei: "060508",
    desc_ubigeo_inei: "YONAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060601",
    desc_ubigeo_inei: "CUTERVO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060602",
    desc_ubigeo_inei: "CALLAYUC",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060603",
    desc_ubigeo_inei: "CHOROS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060604",
    desc_ubigeo_inei: "CUJILLO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060605",
    desc_ubigeo_inei: "LA RAMADA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060606",
    desc_ubigeo_inei: "PIMPINGOS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060607",
    desc_ubigeo_inei: "QUEROCOTILLO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060608",
    desc_ubigeo_inei: "SAN ANDRES DE CUTERVO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060609",
    desc_ubigeo_inei: "SAN JUAN DE CUTERVO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060610",
    desc_ubigeo_inei: "SAN LUIS DE LUCMA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060611",
    desc_ubigeo_inei: "SANTA CRUZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060612",
    desc_ubigeo_inei: "SANTO DOMINGO DE LA CAPILLA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060613",
    desc_ubigeo_inei: "SANTO TOMAS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060614",
    desc_ubigeo_inei: "SOCOTA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0606",
    desc_prov_inei: "CUTERVO",
    cod_ubigeo_inei: "060615",
    desc_ubigeo_inei: "TORIBIO CASANOVA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0607",
    desc_prov_inei: "HUALGAYOC",
    cod_ubigeo_inei: "060701",
    desc_ubigeo_inei: "BAMBAMARCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0607",
    desc_prov_inei: "HUALGAYOC",
    cod_ubigeo_inei: "060702",
    desc_ubigeo_inei: "CHUGUR",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0607",
    desc_prov_inei: "HUALGAYOC",
    cod_ubigeo_inei: "060703",
    desc_ubigeo_inei: "HUALGAYOC",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060801",
    desc_ubigeo_inei: "JAEN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060802",
    desc_ubigeo_inei: "BELLAVISTA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060803",
    desc_ubigeo_inei: "CHONTALI",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060804",
    desc_ubigeo_inei: "COLASAY",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060805",
    desc_ubigeo_inei: "HUABAL",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060806",
    desc_ubigeo_inei: "LAS PIRIAS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060807",
    desc_ubigeo_inei: "POMAHUACA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060808",
    desc_ubigeo_inei: "PUCARA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060809",
    desc_ubigeo_inei: "SALLIQUE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060810",
    desc_ubigeo_inei: "SAN FELIPE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060811",
    desc_ubigeo_inei: "SAN JOSE DEL ALTO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0608",
    desc_prov_inei: "JAEN",
    cod_ubigeo_inei: "060812",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060901",
    desc_ubigeo_inei: "SAN IGNACIO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060902",
    desc_ubigeo_inei: "CHIRINOS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060903",
    desc_ubigeo_inei: "HUARANGO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060904",
    desc_ubigeo_inei: "LA COIPA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060905",
    desc_ubigeo_inei: "NAMBALLE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060906",
    desc_ubigeo_inei: "SAN JOSE DE LOURDES",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0609",
    desc_prov_inei: "SAN IGNACIO",
    cod_ubigeo_inei: "060907",
    desc_ubigeo_inei: "TABACONAS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061001",
    desc_ubigeo_inei: "PEDRO GALVEZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061002",
    desc_ubigeo_inei: "CHANCAY",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061003",
    desc_ubigeo_inei: "EDUARDO VILLANUEVA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061004",
    desc_ubigeo_inei: "GREGORIO PITA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061005",
    desc_ubigeo_inei: "ICHOCAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061006",
    desc_ubigeo_inei: "JOSE MANUEL QUIROZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0610",
    desc_prov_inei: "SAN MARCOS",
    cod_ubigeo_inei: "061007",
    desc_ubigeo_inei: "JOSE SABOGAL",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061101",
    desc_ubigeo_inei: "SAN MIGUEL",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061102",
    desc_ubigeo_inei: "BOLIVAR",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061103",
    desc_ubigeo_inei: "CALQUIS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061104",
    desc_ubigeo_inei: "CATILLUC",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061105",
    desc_ubigeo_inei: "EL PRADO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061106",
    desc_ubigeo_inei: "LA FLORIDA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061107",
    desc_ubigeo_inei: "LLAPA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061108",
    desc_ubigeo_inei: "NANCHOC",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061109",
    desc_ubigeo_inei: "NIEPOS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061110",
    desc_ubigeo_inei: "SAN GREGORIO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061111",
    desc_ubigeo_inei: "SAN SILVESTRE DE COCHAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061112",
    desc_ubigeo_inei: "TONGOD",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0611",
    desc_prov_inei: "SAN MIGUEL",
    cod_ubigeo_inei: "061113",
    desc_ubigeo_inei: "UNION AGUA BLANCA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0612",
    desc_prov_inei: "SAN PABLO",
    cod_ubigeo_inei: "061201",
    desc_ubigeo_inei: "SAN PABLO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0612",
    desc_prov_inei: "SAN PABLO",
    cod_ubigeo_inei: "061202",
    desc_ubigeo_inei: "SAN BERNARDINO",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0612",
    desc_prov_inei: "SAN PABLO",
    cod_ubigeo_inei: "061203",
    desc_ubigeo_inei: "SAN LUIS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0612",
    desc_prov_inei: "SAN PABLO",
    cod_ubigeo_inei: "061204",
    desc_ubigeo_inei: "TUMBADEN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061301",
    desc_ubigeo_inei: "SANTA CRUZ",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061302",
    desc_ubigeo_inei: "ANDABAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061303",
    desc_ubigeo_inei: "CATACHE",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061304",
    desc_ubigeo_inei: "CHANCAYBAÑOS",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061305",
    desc_ubigeo_inei: "LA ESPERANZA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061306",
    desc_ubigeo_inei: "NINABAMBA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061307",
    desc_ubigeo_inei: "PULAN",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061308",
    desc_ubigeo_inei: "SAUCEPAMPA",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061309",
    desc_ubigeo_inei: "SEXI",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061310",
    desc_ubigeo_inei: "UTICYACU",
  },
  {
    cod_dep_inei: "06",
    desc_dep_inei: "CAJAMARCA",
    cod_prov_inei: "0613",
    desc_prov_inei: "SANTA CRUZ",
    cod_ubigeo_inei: "061311",
    desc_ubigeo_inei: "YAUYUCAN",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070101",
    desc_ubigeo_inei: "CALLAO",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070102",
    desc_ubigeo_inei: "BELLAVISTA",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070103",
    desc_ubigeo_inei: "CARMEN DE LA LEGUA REYNOSO",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070104",
    desc_ubigeo_inei: "LA PERLA",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070105",
    desc_ubigeo_inei: "LA PUNTA",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070106",
    desc_ubigeo_inei: "VENTANILLA",
  },
  {
    cod_dep_inei: "07",
    desc_dep_inei: "CALLAO",
    cod_prov_inei: "0701",
    desc_prov_inei: "CALLAO",
    cod_ubigeo_inei: "070107",
    desc_ubigeo_inei: "MI PERU",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080101",
    desc_ubigeo_inei: "CUSCO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080102",
    desc_ubigeo_inei: "CCORCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080103",
    desc_ubigeo_inei: "POROY",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080104",
    desc_ubigeo_inei: "SAN JERONIMO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080105",
    desc_ubigeo_inei: "SAN SEBASTIAN",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080106",
    desc_ubigeo_inei: "SANTIAGO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080107",
    desc_ubigeo_inei: "SAYLLA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0801",
    desc_prov_inei: "CUSCO",
    cod_ubigeo_inei: "080108",
    desc_ubigeo_inei: "WANCHAQ",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080201",
    desc_ubigeo_inei: "ACOMAYO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080202",
    desc_ubigeo_inei: "ACOPIA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080203",
    desc_ubigeo_inei: "ACOS",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080204",
    desc_ubigeo_inei: "MOSOC LLACTA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080205",
    desc_ubigeo_inei: "POMACANCHI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080206",
    desc_ubigeo_inei: "RONDOCAN",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0802",
    desc_prov_inei: "ACOMAYO",
    cod_ubigeo_inei: "080207",
    desc_ubigeo_inei: "SANGARARA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080301",
    desc_ubigeo_inei: "ANTA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080302",
    desc_ubigeo_inei: "ANCAHUASI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080303",
    desc_ubigeo_inei: "CACHIMAYO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080304",
    desc_ubigeo_inei: "CHINCHAYPUJIO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080305",
    desc_ubigeo_inei: "HUAROCONDO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080306",
    desc_ubigeo_inei: "LIMATAMBO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080307",
    desc_ubigeo_inei: "MOLLEPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080308",
    desc_ubigeo_inei: "PUCYURA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0803",
    desc_prov_inei: "ANTA",
    cod_ubigeo_inei: "080309",
    desc_ubigeo_inei: "ZURITE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080401",
    desc_ubigeo_inei: "CALCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080402",
    desc_ubigeo_inei: "COYA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080403",
    desc_ubigeo_inei: "LAMAY",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080404",
    desc_ubigeo_inei: "LARES",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080405",
    desc_ubigeo_inei: "PISAC",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080406",
    desc_ubigeo_inei: "SAN SALVADOR",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080407",
    desc_ubigeo_inei: "TARAY",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0804",
    desc_prov_inei: "CALCA",
    cod_ubigeo_inei: "080408",
    desc_ubigeo_inei: "YANATILE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080501",
    desc_ubigeo_inei: "YANAOCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080502",
    desc_ubigeo_inei: "CHECCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080503",
    desc_ubigeo_inei: "KUNTURKANKI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080504",
    desc_ubigeo_inei: "LANGUI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080505",
    desc_ubigeo_inei: "LAYO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080506",
    desc_ubigeo_inei: "PAMPAMARCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080507",
    desc_ubigeo_inei: "QUEHUE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0805",
    desc_prov_inei: "CANAS",
    cod_ubigeo_inei: "080508",
    desc_ubigeo_inei: "TUPAC AMARU",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080601",
    desc_ubigeo_inei: "SICUANI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080602",
    desc_ubigeo_inei: "CHECACUPE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080603",
    desc_ubigeo_inei: "COMBAPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080604",
    desc_ubigeo_inei: "MARANGANI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080605",
    desc_ubigeo_inei: "PITUMARCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080606",
    desc_ubigeo_inei: "SAN PABLO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080607",
    desc_ubigeo_inei: "SAN PEDRO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0806",
    desc_prov_inei: "CANCHIS",
    cod_ubigeo_inei: "080608",
    desc_ubigeo_inei: "TINTA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080701",
    desc_ubigeo_inei: "SANTO TOMAS",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080702",
    desc_ubigeo_inei: "CAPACMARCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080703",
    desc_ubigeo_inei: "CHAMACA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080704",
    desc_ubigeo_inei: "COLQUEMARCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080705",
    desc_ubigeo_inei: "LIVITACA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080706",
    desc_ubigeo_inei: "LLUSCO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080707",
    desc_ubigeo_inei: "QUIÑOTA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0807",
    desc_prov_inei: "CHUMBIVILCAS",
    cod_ubigeo_inei: "080708",
    desc_ubigeo_inei: "VELILLE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080801",
    desc_ubigeo_inei: "ESPINAR",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080802",
    desc_ubigeo_inei: "CONDOROMA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080803",
    desc_ubigeo_inei: "COPORAQUE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080804",
    desc_ubigeo_inei: "OCORURO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080805",
    desc_ubigeo_inei: "PALLPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080806",
    desc_ubigeo_inei: "PICHIGUA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080807",
    desc_ubigeo_inei: "SUYCKUTAMBO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0808",
    desc_prov_inei: "ESPINAR",
    cod_ubigeo_inei: "080808",
    desc_ubigeo_inei: "ALTO PICHIGUA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080901",
    desc_ubigeo_inei: "SANTA ANA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080902",
    desc_ubigeo_inei: "ECHARATE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080903",
    desc_ubigeo_inei: "HUAYOPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080904",
    desc_ubigeo_inei: "MARANURA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080905",
    desc_ubigeo_inei: "OCOBAMBA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080906",
    desc_ubigeo_inei: "QUELLOUNO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080907",
    desc_ubigeo_inei: "KIMBIRI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080908",
    desc_ubigeo_inei: "SANTA TERESA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080909",
    desc_ubigeo_inei: "VILCABAMBA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080910",
    desc_ubigeo_inei: "PICHARI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080911",
    desc_ubigeo_inei: "INKAWASI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080912",
    desc_ubigeo_inei: "VILLA VIRGEN",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080913",
    desc_ubigeo_inei: "VILLA KINTIARINA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0809",
    desc_prov_inei: "LA CONVENCION",
    cod_ubigeo_inei: "080914",
    desc_ubigeo_inei: "MEGANTONI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081001",
    desc_ubigeo_inei: "PARURO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081002",
    desc_ubigeo_inei: "ACCHA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081003",
    desc_ubigeo_inei: "CCAPI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081004",
    desc_ubigeo_inei: "COLCHA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081005",
    desc_ubigeo_inei: "HUANOQUITE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081006",
    desc_ubigeo_inei: "OMACHA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081007",
    desc_ubigeo_inei: "PACCARITAMBO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081008",
    desc_ubigeo_inei: "PILLPINTO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0810",
    desc_prov_inei: "PARURO",
    cod_ubigeo_inei: "081009",
    desc_ubigeo_inei: "YAURISQUE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081101",
    desc_ubigeo_inei: "PAUCARTAMBO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081102",
    desc_ubigeo_inei: "CAICAY",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081103",
    desc_ubigeo_inei: "CHALLABAMBA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081104",
    desc_ubigeo_inei: "COLQUEPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081105",
    desc_ubigeo_inei: "HUANCARANI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0811",
    desc_prov_inei: "PAUCARTAMBO",
    cod_ubigeo_inei: "081106",
    desc_ubigeo_inei: "KOSÑIPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081201",
    desc_ubigeo_inei: "URCOS",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081202",
    desc_ubigeo_inei: "ANDAHUAYLILLAS",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081203",
    desc_ubigeo_inei: "CAMANTI",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081204",
    desc_ubigeo_inei: "CCARHUAYO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081205",
    desc_ubigeo_inei: "CCATCA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081206",
    desc_ubigeo_inei: "CUSIPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081207",
    desc_ubigeo_inei: "HUARO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081208",
    desc_ubigeo_inei: "LUCRE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081209",
    desc_ubigeo_inei: "MARCAPATA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081210",
    desc_ubigeo_inei: "OCONGATE",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081211",
    desc_ubigeo_inei: "OROPESA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0812",
    desc_prov_inei: "QUISPICANCHI",
    cod_ubigeo_inei: "081212",
    desc_ubigeo_inei: "QUIQUIJANA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081301",
    desc_ubigeo_inei: "URUBAMBA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081302",
    desc_ubigeo_inei: "CHINCHERO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081303",
    desc_ubigeo_inei: "HUAYLLABAMBA",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081304",
    desc_ubigeo_inei: "MACHUPICCHU",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081305",
    desc_ubigeo_inei: "MARAS",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081306",
    desc_ubigeo_inei: "OLLANTAYTAMBO",
  },
  {
    cod_dep_inei: "08",
    desc_dep_inei: "CUSCO",
    cod_prov_inei: "0813",
    desc_prov_inei: "URUBAMBA",
    cod_ubigeo_inei: "081307",
    desc_ubigeo_inei: "YUCAY",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090101",
    desc_ubigeo_inei: "HUANCAVELICA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090102",
    desc_ubigeo_inei: "ACOBAMBILLA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090103",
    desc_ubigeo_inei: "ACORIA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090104",
    desc_ubigeo_inei: "CONAYCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090105",
    desc_ubigeo_inei: "CUENCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090106",
    desc_ubigeo_inei: "HUACHOCOLPA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090107",
    desc_ubigeo_inei: "HUAYLLAHUARA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090108",
    desc_ubigeo_inei: "IZCUCHACA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090109",
    desc_ubigeo_inei: "LARIA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090110",
    desc_ubigeo_inei: "MANTA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090111",
    desc_ubigeo_inei: "MARISCAL CACERES",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090112",
    desc_ubigeo_inei: "MOYA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090113",
    desc_ubigeo_inei: "NUEVO OCCORO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090114",
    desc_ubigeo_inei: "PALCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090115",
    desc_ubigeo_inei: "PILCHACA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090116",
    desc_ubigeo_inei: "VILCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090117",
    desc_ubigeo_inei: "YAULI",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090118",
    desc_ubigeo_inei: "ASCENSION",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0901",
    desc_prov_inei: "HUANCAVELICA",
    cod_ubigeo_inei: "090119",
    desc_ubigeo_inei: "HUANDO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090201",
    desc_ubigeo_inei: "ACOBAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090202",
    desc_ubigeo_inei: "ANDABAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090203",
    desc_ubigeo_inei: "ANTA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090204",
    desc_ubigeo_inei: "CAJA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090205",
    desc_ubigeo_inei: "MARCAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090206",
    desc_ubigeo_inei: "PAUCARA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090207",
    desc_ubigeo_inei: "POMACOCHA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0902",
    desc_prov_inei: "ACOBAMBA",
    cod_ubigeo_inei: "090208",
    desc_ubigeo_inei: "ROSARIO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090301",
    desc_ubigeo_inei: "LIRCAY",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090302",
    desc_ubigeo_inei: "ANCHONGA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090303",
    desc_ubigeo_inei: "CALLANMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090304",
    desc_ubigeo_inei: "CCOCHACCASA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090305",
    desc_ubigeo_inei: "CHINCHO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090306",
    desc_ubigeo_inei: "CONGALLA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090307",
    desc_ubigeo_inei: "HUANCA-HUANCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090308",
    desc_ubigeo_inei: "HUAYLLAY GRANDE",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090309",
    desc_ubigeo_inei: "JULCAMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090310",
    desc_ubigeo_inei: "SAN ANTONIO DE ANTAPARCO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090311",
    desc_ubigeo_inei: "SANTO TOMAS DE PATA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0903",
    desc_prov_inei: "ANGARAES",
    cod_ubigeo_inei: "090312",
    desc_ubigeo_inei: "SECCLLA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090401",
    desc_ubigeo_inei: "CASTROVIRREYNA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090402",
    desc_ubigeo_inei: "ARMA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090403",
    desc_ubigeo_inei: "AURAHUA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090404",
    desc_ubigeo_inei: "CAPILLAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090405",
    desc_ubigeo_inei: "CHUPAMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090406",
    desc_ubigeo_inei: "COCAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090407",
    desc_ubigeo_inei: "HUACHOS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090408",
    desc_ubigeo_inei: "HUAMATAMBO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090409",
    desc_ubigeo_inei: "MOLLEPAMPA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090410",
    desc_ubigeo_inei: "SAN JUAN",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090411",
    desc_ubigeo_inei: "SANTA ANA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090412",
    desc_ubigeo_inei: "TANTARA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0904",
    desc_prov_inei: "CASTROVIRREYNA",
    cod_ubigeo_inei: "090413",
    desc_ubigeo_inei: "TICRAPO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090501",
    desc_ubigeo_inei: "CHURCAMPA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090502",
    desc_ubigeo_inei: "ANCO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090503",
    desc_ubigeo_inei: "CHINCHIHUASI",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090504",
    desc_ubigeo_inei: "EL CARMEN",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090505",
    desc_ubigeo_inei: "LA MERCED",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090506",
    desc_ubigeo_inei: "LOCROJA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090507",
    desc_ubigeo_inei: "PAUCARBAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090508",
    desc_ubigeo_inei: "SAN MIGUEL DE MAYOCC",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090509",
    desc_ubigeo_inei: "SAN PEDRO DE CORIS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090510",
    desc_ubigeo_inei: "PACHAMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0905",
    desc_prov_inei: "CHURCAMPA",
    cod_ubigeo_inei: "090511",
    desc_ubigeo_inei: "COSME",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090601",
    desc_ubigeo_inei: "HUAYTARA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090602",
    desc_ubigeo_inei: "AYAVI",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090603",
    desc_ubigeo_inei: "CORDOVA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090604",
    desc_ubigeo_inei: "HUAYACUNDO ARMA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090605",
    desc_ubigeo_inei: "LARAMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090606",
    desc_ubigeo_inei: "OCOYO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090607",
    desc_ubigeo_inei: "PILPICHACA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090608",
    desc_ubigeo_inei: "QUERCO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090609",
    desc_ubigeo_inei: "QUITO-ARMA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090610",
    desc_ubigeo_inei: "SAN ANTONIO DE CUSICANCHA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090611",
    desc_ubigeo_inei: "SAN FRANCISCO DE SANGAYAICO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090612",
    desc_ubigeo_inei: "SAN ISIDRO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090613",
    desc_ubigeo_inei: "SANTIAGO DE CHOCORVOS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090614",
    desc_ubigeo_inei: "SANTIAGO DE QUIRAHUARA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090615",
    desc_ubigeo_inei: "SANTO DOMINGO DE CAPILLAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0906",
    desc_prov_inei: "HUAYTARA",
    cod_ubigeo_inei: "090616",
    desc_ubigeo_inei: "TAMBO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090701",
    desc_ubigeo_inei: "PAMPAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090702",
    desc_ubigeo_inei: "ACOSTAMBO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090703",
    desc_ubigeo_inei: "ACRAQUIA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090704",
    desc_ubigeo_inei: "AHUAYCHA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090705",
    desc_ubigeo_inei: "COLCABAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090706",
    desc_ubigeo_inei: "DANIEL HERNANDEZ",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090707",
    desc_ubigeo_inei: "HUACHOCOLPA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090708",
    desc_ubigeo_inei: "HUARIBAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090709",
    desc_ubigeo_inei: "ÑAHUIMPUQUIO",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090710",
    desc_ubigeo_inei: "PAZOS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090711",
    desc_ubigeo_inei: "QUISHUAR",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090712",
    desc_ubigeo_inei: "SALCABAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090713",
    desc_ubigeo_inei: "SALCAHUASI",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090714",
    desc_ubigeo_inei: "SAN MARCOS DE ROCCHAC",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090715",
    desc_ubigeo_inei: "SURCUBAMBA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090716",
    desc_ubigeo_inei: "TINTAY PUNCU",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090717",
    desc_ubigeo_inei: "QUICHUAS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090718",
    desc_ubigeo_inei: "ANDAYMARCA",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090719",
    desc_ubigeo_inei: "ROBLE",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090720",
    desc_ubigeo_inei: "PICHOS",
  },
  {
    cod_dep_inei: "09",
    desc_dep_inei: "HUANCAVELICA",
    cod_prov_inei: "0907",
    desc_prov_inei: "TAYACAJA",
    cod_ubigeo_inei: "090721",
    desc_ubigeo_inei: "SANTIAGO DE TUCUMA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100101",
    desc_ubigeo_inei: "HUANUCO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100102",
    desc_ubigeo_inei: "AMARILIS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100103",
    desc_ubigeo_inei: "CHINCHAO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100104",
    desc_ubigeo_inei: "CHURUBAMBA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100105",
    desc_ubigeo_inei: "MARGOS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100106",
    desc_ubigeo_inei: "QUISQUI (KICHKI)",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100107",
    desc_ubigeo_inei: "SAN FRANCISCO DE CAYRAN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100108",
    desc_ubigeo_inei: "SAN PEDRO DE CHAULAN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100109",
    desc_ubigeo_inei: "SANTA MARIA DEL VALLE",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100110",
    desc_ubigeo_inei: "YARUMAYO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100111",
    desc_ubigeo_inei: "PILLCO MARCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100112",
    desc_ubigeo_inei: "YACUS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1001",
    desc_prov_inei: "HUANUCO",
    cod_ubigeo_inei: "100113",
    desc_ubigeo_inei: "SAN PABLO DE PILLAO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100201",
    desc_ubigeo_inei: "AMBO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100202",
    desc_ubigeo_inei: "CAYNA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100203",
    desc_ubigeo_inei: "COLPAS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100204",
    desc_ubigeo_inei: "CONCHAMARCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100205",
    desc_ubigeo_inei: "HUACAR",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100206",
    desc_ubigeo_inei: "SAN FRANCISCO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100207",
    desc_ubigeo_inei: "SAN RAFAEL",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1002",
    desc_prov_inei: "AMBO",
    cod_ubigeo_inei: "100208",
    desc_ubigeo_inei: "TOMAY KICHWA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100301",
    desc_ubigeo_inei: "LA UNION",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100307",
    desc_ubigeo_inei: "CHUQUIS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100311",
    desc_ubigeo_inei: "MARIAS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100313",
    desc_ubigeo_inei: "PACHAS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100316",
    desc_ubigeo_inei: "QUIVILLA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100317",
    desc_ubigeo_inei: "RIPAN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100321",
    desc_ubigeo_inei: "SHUNQUI",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100322",
    desc_ubigeo_inei: "SILLAPATA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1003",
    desc_prov_inei: "DOS DE MAYO",
    cod_ubigeo_inei: "100323",
    desc_ubigeo_inei: "YANAS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1004",
    desc_prov_inei: "HUACAYBAMBA",
    cod_ubigeo_inei: "100401",
    desc_ubigeo_inei: "HUACAYBAMBA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1004",
    desc_prov_inei: "HUACAYBAMBA",
    cod_ubigeo_inei: "100402",
    desc_ubigeo_inei: "CANCHABAMBA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1004",
    desc_prov_inei: "HUACAYBAMBA",
    cod_ubigeo_inei: "100403",
    desc_ubigeo_inei: "COCHABAMBA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1004",
    desc_prov_inei: "HUACAYBAMBA",
    cod_ubigeo_inei: "100404",
    desc_ubigeo_inei: "PINRA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100501",
    desc_ubigeo_inei: "LLATA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100502",
    desc_ubigeo_inei: "ARANCAY",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100503",
    desc_ubigeo_inei: "CHAVIN DE PARIARCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100504",
    desc_ubigeo_inei: "JACAS GRANDE",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100505",
    desc_ubigeo_inei: "JIRCAN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100506",
    desc_ubigeo_inei: "MIRAFLORES",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100507",
    desc_ubigeo_inei: "MONZON",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100508",
    desc_ubigeo_inei: "PUNCHAO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100509",
    desc_ubigeo_inei: "PUÑOS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100510",
    desc_ubigeo_inei: "SINGA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1005",
    desc_prov_inei: "HUAMALIES",
    cod_ubigeo_inei: "100511",
    desc_ubigeo_inei: "TANTAMAYO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100601",
    desc_ubigeo_inei: "RUPA-RUPA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100602",
    desc_ubigeo_inei: "DANIEL ALOMIA ROBLES",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100603",
    desc_ubigeo_inei: "HERMILIO VALDIZAN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100604",
    desc_ubigeo_inei: "JOSE CRESPO Y CASTILLO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100605",
    desc_ubigeo_inei: "LUYANDO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100606",
    desc_ubigeo_inei: "MARIANO DAMASO BERAUN",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100607",
    desc_ubigeo_inei: "PUCAYACU",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100608",
    desc_ubigeo_inei: "CASTILLO GRANDE",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100609",
    desc_ubigeo_inei: "PUEBLO NUEVO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1006",
    desc_prov_inei: "LEONCIO PRADO",
    cod_ubigeo_inei: "100610",
    desc_ubigeo_inei: "SANTO DOMINGO DE ANDA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1007",
    desc_prov_inei: "MARAÑON",
    cod_ubigeo_inei: "100701",
    desc_ubigeo_inei: "HUACRACHUCO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1007",
    desc_prov_inei: "MARAÑON",
    cod_ubigeo_inei: "100702",
    desc_ubigeo_inei: "CHOLON",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1007",
    desc_prov_inei: "MARAÑON",
    cod_ubigeo_inei: "100703",
    desc_ubigeo_inei: "SAN BUENAVENTURA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1007",
    desc_prov_inei: "MARAÑON",
    cod_ubigeo_inei: "100704",
    desc_ubigeo_inei: "LA MORADA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1007",
    desc_prov_inei: "MARAÑON",
    cod_ubigeo_inei: "100705",
    desc_ubigeo_inei: "SANTA ROSA DE ALTO YANAJANCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1008",
    desc_prov_inei: "PACHITEA",
    cod_ubigeo_inei: "100801",
    desc_ubigeo_inei: "PANAO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1008",
    desc_prov_inei: "PACHITEA",
    cod_ubigeo_inei: "100802",
    desc_ubigeo_inei: "CHAGLLA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1008",
    desc_prov_inei: "PACHITEA",
    cod_ubigeo_inei: "100803",
    desc_ubigeo_inei: "MOLINO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1008",
    desc_prov_inei: "PACHITEA",
    cod_ubigeo_inei: "100804",
    desc_ubigeo_inei: "UMARI",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1009",
    desc_prov_inei: "PUERTO INCA",
    cod_ubigeo_inei: "100901",
    desc_ubigeo_inei: "PUERTO INCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1009",
    desc_prov_inei: "PUERTO INCA",
    cod_ubigeo_inei: "100902",
    desc_ubigeo_inei: "CODO DEL POZUZO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1009",
    desc_prov_inei: "PUERTO INCA",
    cod_ubigeo_inei: "100903",
    desc_ubigeo_inei: "HONORIA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1009",
    desc_prov_inei: "PUERTO INCA",
    cod_ubigeo_inei: "100904",
    desc_ubigeo_inei: "TOURNAVISTA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1009",
    desc_prov_inei: "PUERTO INCA",
    cod_ubigeo_inei: "100905",
    desc_ubigeo_inei: "YUYAPICHIS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101001",
    desc_ubigeo_inei: "JESUS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101002",
    desc_ubigeo_inei: "BAÑOS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101003",
    desc_ubigeo_inei: "JIVIA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101004",
    desc_ubigeo_inei: "QUEROPALCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101005",
    desc_ubigeo_inei: "RONDOS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101006",
    desc_ubigeo_inei: "SAN FRANCISCO DE ASIS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1010",
    desc_prov_inei: "LAURICOCHA",
    cod_ubigeo_inei: "101007",
    desc_ubigeo_inei: "SAN MIGUEL DE CAURI",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101101",
    desc_ubigeo_inei: "CHAVINILLO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101102",
    desc_ubigeo_inei: "CAHUAC",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101103",
    desc_ubigeo_inei: "CHACABAMBA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101104",
    desc_ubigeo_inei: "APARICIO POMARES",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101105",
    desc_ubigeo_inei: "JACAS CHICO",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101106",
    desc_ubigeo_inei: "OBAS",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101107",
    desc_ubigeo_inei: "PAMPAMARCA",
  },
  {
    cod_dep_inei: "10",
    desc_dep_inei: "HUANUCO",
    cod_prov_inei: "1011",
    desc_prov_inei: "YAROWILCA",
    cod_ubigeo_inei: "101108",
    desc_ubigeo_inei: "CHORAS",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110101",
    desc_ubigeo_inei: "ICA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110102",
    desc_ubigeo_inei: "LA TINGUIÑA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110103",
    desc_ubigeo_inei: "LOS AQUIJES",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110104",
    desc_ubigeo_inei: "OCUCAJE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110105",
    desc_ubigeo_inei: "PACHACUTEC",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110106",
    desc_ubigeo_inei: "PARCONA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110107",
    desc_ubigeo_inei: "PUEBLO NUEVO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110108",
    desc_ubigeo_inei: "SALAS",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110109",
    desc_ubigeo_inei: "SAN JOSE DE LOS MOLINOS",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110110",
    desc_ubigeo_inei: "SAN JUAN BAUTISTA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110111",
    desc_ubigeo_inei: "SANTIAGO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110112",
    desc_ubigeo_inei: "SUBTANJALLA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110113",
    desc_ubigeo_inei: "TATE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1101",
    desc_prov_inei: "ICA",
    cod_ubigeo_inei: "110114",
    desc_ubigeo_inei: "YAUCA DEL ROSARIO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110201",
    desc_ubigeo_inei: "CHINCHA ALTA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110202",
    desc_ubigeo_inei: "ALTO LARAN",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110203",
    desc_ubigeo_inei: "CHAVIN",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110204",
    desc_ubigeo_inei: "CHINCHA BAJA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110205",
    desc_ubigeo_inei: "EL CARMEN",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110206",
    desc_ubigeo_inei: "GROCIO PRADO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110207",
    desc_ubigeo_inei: "PUEBLO NUEVO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110208",
    desc_ubigeo_inei: "SAN JUAN DE YANAC",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110209",
    desc_ubigeo_inei: "SAN PEDRO DE HUACARPANA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110210",
    desc_ubigeo_inei: "SUNAMPE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1102",
    desc_prov_inei: "CHINCHA",
    cod_ubigeo_inei: "110211",
    desc_ubigeo_inei: "TAMBO DE MORA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1103",
    desc_prov_inei: "NASCA",
    cod_ubigeo_inei: "110301",
    desc_ubigeo_inei: "NASCA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1103",
    desc_prov_inei: "NASCA",
    cod_ubigeo_inei: "110302",
    desc_ubigeo_inei: "CHANGUILLO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1103",
    desc_prov_inei: "NASCA",
    cod_ubigeo_inei: "110303",
    desc_ubigeo_inei: "EL INGENIO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1103",
    desc_prov_inei: "NASCA",
    cod_ubigeo_inei: "110304",
    desc_ubigeo_inei: "MARCONA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1103",
    desc_prov_inei: "NASCA",
    cod_ubigeo_inei: "110305",
    desc_ubigeo_inei: "VISTA ALEGRE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1104",
    desc_prov_inei: "PALPA",
    cod_ubigeo_inei: "110401",
    desc_ubigeo_inei: "PALPA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1104",
    desc_prov_inei: "PALPA",
    cod_ubigeo_inei: "110402",
    desc_ubigeo_inei: "LLIPATA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1104",
    desc_prov_inei: "PALPA",
    cod_ubigeo_inei: "110403",
    desc_ubigeo_inei: "RIO GRANDE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1104",
    desc_prov_inei: "PALPA",
    cod_ubigeo_inei: "110404",
    desc_ubigeo_inei: "SANTA CRUZ",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1104",
    desc_prov_inei: "PALPA",
    cod_ubigeo_inei: "110405",
    desc_ubigeo_inei: "TIBILLO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110501",
    desc_ubigeo_inei: "PISCO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110502",
    desc_ubigeo_inei: "HUANCANO",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110503",
    desc_ubigeo_inei: "HUMAY",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110504",
    desc_ubigeo_inei: "INDEPENDENCIA",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110505",
    desc_ubigeo_inei: "PARACAS",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110506",
    desc_ubigeo_inei: "SAN ANDRES",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110507",
    desc_ubigeo_inei: "SAN CLEMENTE",
  },
  {
    cod_dep_inei: "11",
    desc_dep_inei: "ICA",
    cod_prov_inei: "1105",
    desc_prov_inei: "PISCO",
    cod_ubigeo_inei: "110508",
    desc_ubigeo_inei: "TUPAC AMARU INCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120101",
    desc_ubigeo_inei: "HUANCAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120104",
    desc_ubigeo_inei: "CARHUACALLANGA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120105",
    desc_ubigeo_inei: "CHACAPAMPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120106",
    desc_ubigeo_inei: "CHICCHE",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120107",
    desc_ubigeo_inei: "CHILCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120108",
    desc_ubigeo_inei: "CHONGOS ALTO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120111",
    desc_ubigeo_inei: "CHUPURO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120112",
    desc_ubigeo_inei: "COLCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120113",
    desc_ubigeo_inei: "CULLHUAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120114",
    desc_ubigeo_inei: "EL TAMBO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120116",
    desc_ubigeo_inei: "HUACRAPUQUIO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120117",
    desc_ubigeo_inei: "HUALHUAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120119",
    desc_ubigeo_inei: "HUANCAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120120",
    desc_ubigeo_inei: "HUASICANCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120121",
    desc_ubigeo_inei: "HUAYUCACHI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120122",
    desc_ubigeo_inei: "INGENIO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120124",
    desc_ubigeo_inei: "PARIAHUANCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120125",
    desc_ubigeo_inei: "PILCOMAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120126",
    desc_ubigeo_inei: "PUCARA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120127",
    desc_ubigeo_inei: "QUICHUAY",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120128",
    desc_ubigeo_inei: "QUILCAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120129",
    desc_ubigeo_inei: "SAN AGUSTIN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120130",
    desc_ubigeo_inei: "SAN JERONIMO DE TUNAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120132",
    desc_ubigeo_inei: "SAÑO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120133",
    desc_ubigeo_inei: "SAPALLANGA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120134",
    desc_ubigeo_inei: "SICAYA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120135",
    desc_ubigeo_inei: "SANTO DOMINGO DE ACOBAMBA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1201",
    desc_prov_inei: "HUANCAYO",
    cod_ubigeo_inei: "120136",
    desc_ubigeo_inei: "VIQUES",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120201",
    desc_ubigeo_inei: "CONCEPCION",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120202",
    desc_ubigeo_inei: "ACO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120203",
    desc_ubigeo_inei: "ANDAMARCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120204",
    desc_ubigeo_inei: "CHAMBARA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120205",
    desc_ubigeo_inei: "COCHAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120206",
    desc_ubigeo_inei: "COMAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120207",
    desc_ubigeo_inei: "HEROINAS TOLEDO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120208",
    desc_ubigeo_inei: "MANZANARES",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120209",
    desc_ubigeo_inei: "MARISCAL CASTILLA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120210",
    desc_ubigeo_inei: "MATAHUASI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120211",
    desc_ubigeo_inei: "MITO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120212",
    desc_ubigeo_inei: "NUEVE DE JULIO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120213",
    desc_ubigeo_inei: "ORCOTUNA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120214",
    desc_ubigeo_inei: "SAN JOSE DE QUERO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1202",
    desc_prov_inei: "CONCEPCION",
    cod_ubigeo_inei: "120215",
    desc_ubigeo_inei: "SANTA ROSA DE OCOPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120301",
    desc_ubigeo_inei: "CHANCHAMAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120302",
    desc_ubigeo_inei: "PERENE",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120303",
    desc_ubigeo_inei: "PICHANAQUI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120304",
    desc_ubigeo_inei: "SAN LUIS DE SHUARO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120305",
    desc_ubigeo_inei: "SAN RAMON",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1203",
    desc_prov_inei: "CHANCHAMAYO",
    cod_ubigeo_inei: "120306",
    desc_ubigeo_inei: "VITOC",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120401",
    desc_ubigeo_inei: "JAUJA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120402",
    desc_ubigeo_inei: "ACOLLA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120403",
    desc_ubigeo_inei: "APATA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120404",
    desc_ubigeo_inei: "ATAURA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120405",
    desc_ubigeo_inei: "CANCHAYLLO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120406",
    desc_ubigeo_inei: "CURICACA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120407",
    desc_ubigeo_inei: "EL MANTARO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120408",
    desc_ubigeo_inei: "HUAMALI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120409",
    desc_ubigeo_inei: "HUARIPAMPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120410",
    desc_ubigeo_inei: "HUERTAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120411",
    desc_ubigeo_inei: "JANJAILLO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120412",
    desc_ubigeo_inei: "JULCAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120413",
    desc_ubigeo_inei: "LEONOR ORDOÑEZ",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120414",
    desc_ubigeo_inei: "LLOCLLAPAMPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120415",
    desc_ubigeo_inei: "MARCO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120416",
    desc_ubigeo_inei: "MASMA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120417",
    desc_ubigeo_inei: "MASMA CHICCHE",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120418",
    desc_ubigeo_inei: "MOLINOS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120419",
    desc_ubigeo_inei: "MONOBAMBA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120420",
    desc_ubigeo_inei: "MUQUI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120421",
    desc_ubigeo_inei: "MUQUIYAUYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120422",
    desc_ubigeo_inei: "PACA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120423",
    desc_ubigeo_inei: "PACCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120424",
    desc_ubigeo_inei: "PANCAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120425",
    desc_ubigeo_inei: "PARCO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120426",
    desc_ubigeo_inei: "POMACANCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120427",
    desc_ubigeo_inei: "RICRAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120428",
    desc_ubigeo_inei: "SAN LORENZO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120429",
    desc_ubigeo_inei: "SAN PEDRO DE CHUNAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120430",
    desc_ubigeo_inei: "SAUSA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120431",
    desc_ubigeo_inei: "SINCOS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120432",
    desc_ubigeo_inei: "TUNAN MARCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120433",
    desc_ubigeo_inei: "YAULI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1204",
    desc_prov_inei: "JAUJA",
    cod_ubigeo_inei: "120434",
    desc_ubigeo_inei: "YAUYOS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1205",
    desc_prov_inei: "JUNIN",
    cod_ubigeo_inei: "120501",
    desc_ubigeo_inei: "JUNIN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1205",
    desc_prov_inei: "JUNIN",
    cod_ubigeo_inei: "120502",
    desc_ubigeo_inei: "CARHUAMAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1205",
    desc_prov_inei: "JUNIN",
    cod_ubigeo_inei: "120503",
    desc_ubigeo_inei: "ONDORES",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1205",
    desc_prov_inei: "JUNIN",
    cod_ubigeo_inei: "120504",
    desc_ubigeo_inei: "ULCUMAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120601",
    desc_ubigeo_inei: "SATIPO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120602",
    desc_ubigeo_inei: "COVIRIALI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120603",
    desc_ubigeo_inei: "LLAYLLA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120604",
    desc_ubigeo_inei: "MAZAMARI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120605",
    desc_ubigeo_inei: "PAMPA HERMOSA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120606",
    desc_ubigeo_inei: "PANGOA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120607",
    desc_ubigeo_inei: "RIO NEGRO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120608",
    desc_ubigeo_inei: "RIO TAMBO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1206",
    desc_prov_inei: "SATIPO",
    cod_ubigeo_inei: "120609",
    desc_ubigeo_inei: "VIZCATAN DEL ENE",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120701",
    desc_ubigeo_inei: "TARMA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120702",
    desc_ubigeo_inei: "ACOBAMBA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120703",
    desc_ubigeo_inei: "HUARICOLCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120704",
    desc_ubigeo_inei: "HUASAHUASI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120705",
    desc_ubigeo_inei: "LA UNION",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120706",
    desc_ubigeo_inei: "PALCA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120707",
    desc_ubigeo_inei: "PALCAMAYO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120708",
    desc_ubigeo_inei: "SAN PEDRO DE CAJAS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1207",
    desc_prov_inei: "TARMA",
    cod_ubigeo_inei: "120709",
    desc_ubigeo_inei: "TAPO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120801",
    desc_ubigeo_inei: "LA OROYA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120802",
    desc_ubigeo_inei: "CHACAPALPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120803",
    desc_ubigeo_inei: "HUAY-HUAY",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120804",
    desc_ubigeo_inei: "MARCAPOMACOCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120805",
    desc_ubigeo_inei: "MOROCOCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120806",
    desc_ubigeo_inei: "PACCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120807",
    desc_ubigeo_inei: "SANTA BARBARA DE CARHUACAYAN",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120808",
    desc_ubigeo_inei: "SANTA ROSA DE SACCO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120809",
    desc_ubigeo_inei: "SUITUCANCHA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1208",
    desc_prov_inei: "YAULI",
    cod_ubigeo_inei: "120810",
    desc_ubigeo_inei: "YAULI",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120901",
    desc_ubigeo_inei: "CHUPACA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120902",
    desc_ubigeo_inei: "AHUAC",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120903",
    desc_ubigeo_inei: "CHONGOS BAJO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120904",
    desc_ubigeo_inei: "HUACHAC",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120905",
    desc_ubigeo_inei: "HUAMANCACA CHICO",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120906",
    desc_ubigeo_inei: "SAN JUAN DE ISCOS",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120907",
    desc_ubigeo_inei: "SAN JUAN DE JARPA",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120908",
    desc_ubigeo_inei: "TRES DE DICIEMBRE",
  },
  {
    cod_dep_inei: "12",
    desc_dep_inei: "JUNIN",
    cod_prov_inei: "1209",
    desc_prov_inei: "CHUPACA",
    cod_ubigeo_inei: "120909",
    desc_ubigeo_inei: "YANACANCHA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130101",
    desc_ubigeo_inei: "TRUJILLO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130102",
    desc_ubigeo_inei: "EL PORVENIR",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130103",
    desc_ubigeo_inei: "FLORENCIA DE MORA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130104",
    desc_ubigeo_inei: "HUANCHACO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130105",
    desc_ubigeo_inei: "LA ESPERANZA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130106",
    desc_ubigeo_inei: "LAREDO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130107",
    desc_ubigeo_inei: "MOCHE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130108",
    desc_ubigeo_inei: "POROTO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130109",
    desc_ubigeo_inei: "SALAVERRY",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130110",
    desc_ubigeo_inei: "SIMBAL",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1301",
    desc_prov_inei: "TRUJILLO",
    cod_ubigeo_inei: "130111",
    desc_ubigeo_inei: "VICTOR LARCO HERRERA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130201",
    desc_ubigeo_inei: "ASCOPE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130202",
    desc_ubigeo_inei: "CHICAMA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130203",
    desc_ubigeo_inei: "CHOCOPE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130204",
    desc_ubigeo_inei: "MAGDALENA DE CAO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130205",
    desc_ubigeo_inei: "PAIJAN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130206",
    desc_ubigeo_inei: "RAZURI",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130207",
    desc_ubigeo_inei: "SANTIAGO DE CAO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1302",
    desc_prov_inei: "ASCOPE",
    cod_ubigeo_inei: "130208",
    desc_ubigeo_inei: "CASA GRANDE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130301",
    desc_ubigeo_inei: "BOLIVAR",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130302",
    desc_ubigeo_inei: "BAMBAMARCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130303",
    desc_ubigeo_inei: "CONDORMARCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130304",
    desc_ubigeo_inei: "LONGOTEA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130305",
    desc_ubigeo_inei: "UCHUMARCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1303",
    desc_prov_inei: "BOLIVAR",
    cod_ubigeo_inei: "130306",
    desc_ubigeo_inei: "UCUNCHA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1304",
    desc_prov_inei: "CHEPEN",
    cod_ubigeo_inei: "130401",
    desc_ubigeo_inei: "CHEPEN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1304",
    desc_prov_inei: "CHEPEN",
    cod_ubigeo_inei: "130402",
    desc_ubigeo_inei: "PACANGA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1304",
    desc_prov_inei: "CHEPEN",
    cod_ubigeo_inei: "130403",
    desc_ubigeo_inei: "PUEBLO NUEVO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1305",
    desc_prov_inei: "JULCAN",
    cod_ubigeo_inei: "130501",
    desc_ubigeo_inei: "JULCAN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1305",
    desc_prov_inei: "JULCAN",
    cod_ubigeo_inei: "130502",
    desc_ubigeo_inei: "CALAMARCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1305",
    desc_prov_inei: "JULCAN",
    cod_ubigeo_inei: "130503",
    desc_ubigeo_inei: "CARABAMBA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1305",
    desc_prov_inei: "JULCAN",
    cod_ubigeo_inei: "130504",
    desc_ubigeo_inei: "HUASO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130601",
    desc_ubigeo_inei: "OTUZCO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130602",
    desc_ubigeo_inei: "AGALLPAMPA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130604",
    desc_ubigeo_inei: "CHARAT",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130605",
    desc_ubigeo_inei: "HUARANCHAL",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130606",
    desc_ubigeo_inei: "LA CUESTA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130608",
    desc_ubigeo_inei: "MACHE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130610",
    desc_ubigeo_inei: "PARANDAY",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130611",
    desc_ubigeo_inei: "SALPO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130613",
    desc_ubigeo_inei: "SINSICAP",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1306",
    desc_prov_inei: "OTUZCO",
    cod_ubigeo_inei: "130614",
    desc_ubigeo_inei: "USQUIL",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1307",
    desc_prov_inei: "PACASMAYO",
    cod_ubigeo_inei: "130701",
    desc_ubigeo_inei: "SAN PEDRO DE LLOC",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1307",
    desc_prov_inei: "PACASMAYO",
    cod_ubigeo_inei: "130702",
    desc_ubigeo_inei: "GUADALUPE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1307",
    desc_prov_inei: "PACASMAYO",
    cod_ubigeo_inei: "130703",
    desc_ubigeo_inei: "JEQUETEPEQUE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1307",
    desc_prov_inei: "PACASMAYO",
    cod_ubigeo_inei: "130704",
    desc_ubigeo_inei: "PACASMAYO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1307",
    desc_prov_inei: "PACASMAYO",
    cod_ubigeo_inei: "130705",
    desc_ubigeo_inei: "SAN JOSE",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130801",
    desc_ubigeo_inei: "TAYABAMBA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130802",
    desc_ubigeo_inei: "BULDIBUYO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130803",
    desc_ubigeo_inei: "CHILLIA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130804",
    desc_ubigeo_inei: "HUANCASPATA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130805",
    desc_ubigeo_inei: "HUAYLILLAS",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130806",
    desc_ubigeo_inei: "HUAYO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130807",
    desc_ubigeo_inei: "ONGON",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130808",
    desc_ubigeo_inei: "PARCOY",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130809",
    desc_ubigeo_inei: "PATAZ",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130810",
    desc_ubigeo_inei: "PIAS",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130811",
    desc_ubigeo_inei: "SANTIAGO DE CHALLAS",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130812",
    desc_ubigeo_inei: "TAURIJA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1308",
    desc_prov_inei: "PATAZ",
    cod_ubigeo_inei: "130813",
    desc_ubigeo_inei: "URPAY",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130901",
    desc_ubigeo_inei: "HUAMACHUCO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130902",
    desc_ubigeo_inei: "CHUGAY",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130903",
    desc_ubigeo_inei: "COCHORCO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130904",
    desc_ubigeo_inei: "CURGOS",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130905",
    desc_ubigeo_inei: "MARCABAL",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130906",
    desc_ubigeo_inei: "SANAGORAN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130907",
    desc_ubigeo_inei: "SARIN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1309",
    desc_prov_inei: "SANCHEZ CARRION",
    cod_ubigeo_inei: "130908",
    desc_ubigeo_inei: "SARTIMBAMBA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131001",
    desc_ubigeo_inei: "SANTIAGO DE CHUCO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131002",
    desc_ubigeo_inei: "ANGASMARCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131003",
    desc_ubigeo_inei: "CACHICADAN",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131004",
    desc_ubigeo_inei: "MOLLEBAMBA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131005",
    desc_ubigeo_inei: "MOLLEPATA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131006",
    desc_ubigeo_inei: "QUIRUVILCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131007",
    desc_ubigeo_inei: "SANTA CRUZ DE CHUCA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1310",
    desc_prov_inei: "SANTIAGO DE CHUCO",
    cod_ubigeo_inei: "131008",
    desc_ubigeo_inei: "SITABAMBA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1311",
    desc_prov_inei: "GRAN CHIMU",
    cod_ubigeo_inei: "131101",
    desc_ubigeo_inei: "CASCAS",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1311",
    desc_prov_inei: "GRAN CHIMU",
    cod_ubigeo_inei: "131102",
    desc_ubigeo_inei: "LUCMA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1311",
    desc_prov_inei: "GRAN CHIMU",
    cod_ubigeo_inei: "131103",
    desc_ubigeo_inei: "MARMOT",
  },
  {
    cod_dep_inei: "NA",
    desc_dep_inei: "NA",
    cod_prov_inei: "  NA",
    desc_prov_inei: "NA",
    cod_ubigeo_inei: "    NA",
    desc_ubigeo_inei: "NA",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1311",
    desc_prov_inei: "GRAN CHIMU",
    cod_ubigeo_inei: "131104",
    desc_ubigeo_inei: "SAYAPULLO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1312",
    desc_prov_inei: "VIRU",
    cod_ubigeo_inei: "131201",
    desc_ubigeo_inei: "VIRU",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1312",
    desc_prov_inei: "VIRU",
    cod_ubigeo_inei: "131202",
    desc_ubigeo_inei: "CHAO",
  },
  {
    cod_dep_inei: "13",
    desc_dep_inei: "LA LIBERTAD",
    cod_prov_inei: "1312",
    desc_prov_inei: "VIRU",
    cod_ubigeo_inei: "131203",
    desc_ubigeo_inei: "GUADALUPITO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140101",
    desc_ubigeo_inei: "CHICLAYO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140102",
    desc_ubigeo_inei: "CHONGOYAPE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140103",
    desc_ubigeo_inei: "ETEN",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140104",
    desc_ubigeo_inei: "ETEN PUERTO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140105",
    desc_ubigeo_inei: "JOSE LEONARDO ORTIZ",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140106",
    desc_ubigeo_inei: "LA VICTORIA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140107",
    desc_ubigeo_inei: "LAGUNAS",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140108",
    desc_ubigeo_inei: "MONSEFU",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140109",
    desc_ubigeo_inei: "NUEVA ARICA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140110",
    desc_ubigeo_inei: "OYOTUN",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140111",
    desc_ubigeo_inei: "PICSI",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140112",
    desc_ubigeo_inei: "PIMENTEL",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140113",
    desc_ubigeo_inei: "REQUE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140114",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140115",
    desc_ubigeo_inei: "SAÑA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140116",
    desc_ubigeo_inei: "CAYALTI",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140117",
    desc_ubigeo_inei: "PATAPO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140118",
    desc_ubigeo_inei: "POMALCA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140119",
    desc_ubigeo_inei: "PUCALA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1401",
    desc_prov_inei: "CHICLAYO",
    cod_ubigeo_inei: "140120",
    desc_ubigeo_inei: "TUMAN",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140201",
    desc_ubigeo_inei: "FERREÑAFE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140202",
    desc_ubigeo_inei: "CAÑARIS",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140203",
    desc_ubigeo_inei: "INCAHUASI",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140204",
    desc_ubigeo_inei: "MANUEL ANTONIO MESONES MURO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140205",
    desc_ubigeo_inei: "PITIPO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1402",
    desc_prov_inei: "FERREÑAFE",
    cod_ubigeo_inei: "140206",
    desc_ubigeo_inei: "PUEBLO NUEVO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140301",
    desc_ubigeo_inei: "LAMBAYEQUE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140302",
    desc_ubigeo_inei: "CHOCHOPE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140303",
    desc_ubigeo_inei: "ILLIMO",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140304",
    desc_ubigeo_inei: "JAYANCA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140305",
    desc_ubigeo_inei: "MOCHUMI",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140306",
    desc_ubigeo_inei: "MORROPE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140307",
    desc_ubigeo_inei: "MOTUPE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140308",
    desc_ubigeo_inei: "OLMOS",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140309",
    desc_ubigeo_inei: "PACORA",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140310",
    desc_ubigeo_inei: "SALAS",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140311",
    desc_ubigeo_inei: "SAN JOSE",
  },
  {
    cod_dep_inei: "14",
    desc_dep_inei: "LAMBAYEQUE",
    cod_prov_inei: "1403",
    desc_prov_inei: "LAMBAYEQUE",
    cod_ubigeo_inei: "140312",
    desc_ubigeo_inei: "TUCUME",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150101",
    desc_ubigeo_inei: "LIMA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150102",
    desc_ubigeo_inei: "ANCON",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150103",
    desc_ubigeo_inei: "ATE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150104",
    desc_ubigeo_inei: "BARRANCO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150105",
    desc_ubigeo_inei: "BREÑA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150106",
    desc_ubigeo_inei: "CARABAYLLO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150107",
    desc_ubigeo_inei: "CHACLACAYO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150108",
    desc_ubigeo_inei: "CHORRILLOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150109",
    desc_ubigeo_inei: "CIENEGUILLA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150110",
    desc_ubigeo_inei: "COMAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150111",
    desc_ubigeo_inei: "EL AGUSTINO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150112",
    desc_ubigeo_inei: "INDEPENDENCIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150113",
    desc_ubigeo_inei: "JESUS MARIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150114",
    desc_ubigeo_inei: "LA MOLINA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150115",
    desc_ubigeo_inei: "LA VICTORIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150116",
    desc_ubigeo_inei: "LINCE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150117",
    desc_ubigeo_inei: "LOS OLIVOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150118",
    desc_ubigeo_inei: "LURIGANCHO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150119",
    desc_ubigeo_inei: "LURIN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150120",
    desc_ubigeo_inei: "MAGDALENA DEL MAR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150121",
    desc_ubigeo_inei: "PUEBLO LIBRE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150122",
    desc_ubigeo_inei: "MIRAFLORES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150123",
    desc_ubigeo_inei: "PACHACAMAC",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150124",
    desc_ubigeo_inei: "PUCUSANA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150125",
    desc_ubigeo_inei: "PUENTE PIEDRA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150126",
    desc_ubigeo_inei: "PUNTA HERMOSA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150127",
    desc_ubigeo_inei: "PUNTA NEGRA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150128",
    desc_ubigeo_inei: "RIMAC",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150129",
    desc_ubigeo_inei: "SAN BARTOLO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150130",
    desc_ubigeo_inei: "SAN BORJA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150131",
    desc_ubigeo_inei: "SAN ISIDRO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150132",
    desc_ubigeo_inei: "SAN JUAN DE LURIGANCHO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150133",
    desc_ubigeo_inei: "SAN JUAN DE MIRAFLORES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150134",
    desc_ubigeo_inei: "SAN LUIS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150135",
    desc_ubigeo_inei: "SAN MARTIN DE PORRES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150136",
    desc_ubigeo_inei: "SAN MIGUEL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150137",
    desc_ubigeo_inei: "SANTA ANITA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150138",
    desc_ubigeo_inei: "SANTA MARIA DEL MAR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150139",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150140",
    desc_ubigeo_inei: "SANTIAGO DE SURCO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150141",
    desc_ubigeo_inei: "SURQUILLO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150142",
    desc_ubigeo_inei: "VILLA EL SALVADOR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1501",
    desc_prov_inei: "LIMA",
    cod_ubigeo_inei: "150143",
    desc_ubigeo_inei: "VILLA MARIA DEL TRIUNFO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1502",
    desc_prov_inei: "BARRANCA",
    cod_ubigeo_inei: "150201",
    desc_ubigeo_inei: "BARRANCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1502",
    desc_prov_inei: "BARRANCA",
    cod_ubigeo_inei: "150202",
    desc_ubigeo_inei: "PARAMONGA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1502",
    desc_prov_inei: "BARRANCA",
    cod_ubigeo_inei: "150203",
    desc_ubigeo_inei: "PATIVILCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1502",
    desc_prov_inei: "BARRANCA",
    cod_ubigeo_inei: "150204",
    desc_ubigeo_inei: "SUPE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1502",
    desc_prov_inei: "BARRANCA",
    cod_ubigeo_inei: "150205",
    desc_ubigeo_inei: "SUPE PUERTO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1503",
    desc_prov_inei: "CAJATAMBO",
    cod_ubigeo_inei: "150301",
    desc_ubigeo_inei: "CAJATAMBO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1503",
    desc_prov_inei: "CAJATAMBO",
    cod_ubigeo_inei: "150302",
    desc_ubigeo_inei: "COPA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1503",
    desc_prov_inei: "CAJATAMBO",
    cod_ubigeo_inei: "150303",
    desc_ubigeo_inei: "GORGOR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1503",
    desc_prov_inei: "CAJATAMBO",
    cod_ubigeo_inei: "150304",
    desc_ubigeo_inei: "HUANCAPON",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1503",
    desc_prov_inei: "CAJATAMBO",
    cod_ubigeo_inei: "150305",
    desc_ubigeo_inei: "MANAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150401",
    desc_ubigeo_inei: "CANTA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150402",
    desc_ubigeo_inei: "ARAHUAY",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150403",
    desc_ubigeo_inei: "HUAMANTANGA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150404",
    desc_ubigeo_inei: "HUAROS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150405",
    desc_ubigeo_inei: "LACHAQUI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150406",
    desc_ubigeo_inei: "SAN BUENAVENTURA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1504",
    desc_prov_inei: "CANTA",
    cod_ubigeo_inei: "150407",
    desc_ubigeo_inei: "SANTA ROSA DE QUIVES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150501",
    desc_ubigeo_inei: "SAN VICENTE DE CAÑETE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150502",
    desc_ubigeo_inei: "ASIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150503",
    desc_ubigeo_inei: "CALANGO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150504",
    desc_ubigeo_inei: "CERRO AZUL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150505",
    desc_ubigeo_inei: "CHILCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150506",
    desc_ubigeo_inei: "COAYLLO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150507",
    desc_ubigeo_inei: "IMPERIAL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150508",
    desc_ubigeo_inei: "LUNAHUANA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150509",
    desc_ubigeo_inei: "MALA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150510",
    desc_ubigeo_inei: "NUEVO IMPERIAL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150511",
    desc_ubigeo_inei: "PACARAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150512",
    desc_ubigeo_inei: "QUILMANA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150513",
    desc_ubigeo_inei: "SAN ANTONIO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150514",
    desc_ubigeo_inei: "SAN LUIS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150515",
    desc_ubigeo_inei: "SANTA CRUZ DE FLORES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1505",
    desc_prov_inei: "CAÑETE",
    cod_ubigeo_inei: "150516",
    desc_ubigeo_inei: "ZUÑIGA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150601",
    desc_ubigeo_inei: "HUARAL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150602",
    desc_ubigeo_inei: "ATAVILLOS ALTO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150603",
    desc_ubigeo_inei: "ATAVILLOS BAJO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150604",
    desc_ubigeo_inei: "AUCALLAMA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150605",
    desc_ubigeo_inei: "CHANCAY",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150606",
    desc_ubigeo_inei: "IHUARI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150607",
    desc_ubigeo_inei: "LAMPIAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150608",
    desc_ubigeo_inei: "PACARAOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150609",
    desc_ubigeo_inei: "SAN MIGUEL DE ACOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150610",
    desc_ubigeo_inei: "SANTA CRUZ DE ANDAMARCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150611",
    desc_ubigeo_inei: "SUMBILCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1506",
    desc_prov_inei: "HUARAL",
    cod_ubigeo_inei: "150612",
    desc_ubigeo_inei: "VEINTISIETE DE NOVIEMBRE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150701",
    desc_ubigeo_inei: "MATUCANA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150702",
    desc_ubigeo_inei: "ANTIOQUIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150703",
    desc_ubigeo_inei: "CALLAHUANCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150704",
    desc_ubigeo_inei: "CARAMPOMA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150705",
    desc_ubigeo_inei: "CHICLA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150706",
    desc_ubigeo_inei: "CUENCA",
  },
  {
    cod_dep_inei: "NA",
    desc_dep_inei: "NA",
    cod_prov_inei: "  NA",
    desc_prov_inei: "NA",
    cod_ubigeo_inei: "    NA",
    desc_ubigeo_inei: "NA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150707",
    desc_ubigeo_inei: "HUACHUPAMPA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150708",
    desc_ubigeo_inei: "HUANZA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150709",
    desc_ubigeo_inei: "HUAROCHIRI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150710",
    desc_ubigeo_inei: "LAHUAYTAMBO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150711",
    desc_ubigeo_inei: "LANGA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150712",
    desc_ubigeo_inei: "LARAOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150713",
    desc_ubigeo_inei: "MARIATANA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150714",
    desc_ubigeo_inei: "RICARDO PALMA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150715",
    desc_ubigeo_inei: "SAN ANDRES DE TUPICOCHA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150716",
    desc_ubigeo_inei: "SAN ANTONIO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150717",
    desc_ubigeo_inei: "SAN BARTOLOME",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150718",
    desc_ubigeo_inei: "SAN DAMIAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150719",
    desc_ubigeo_inei: "SAN JUAN DE IRIS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150720",
    desc_ubigeo_inei: "SAN JUAN DE TANTARANCHE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150721",
    desc_ubigeo_inei: "SAN LORENZO DE QUINTI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150722",
    desc_ubigeo_inei: "SAN MATEO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150723",
    desc_ubigeo_inei: "SAN MATEO DE OTAO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150724",
    desc_ubigeo_inei: "SAN PEDRO DE CASTA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150725",
    desc_ubigeo_inei: "SAN PEDRO DE HUANCAYRE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150726",
    desc_ubigeo_inei: "SANGALLAYA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150727",
    desc_ubigeo_inei: "SANTA CRUZ DE COCACHACRA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150728",
    desc_ubigeo_inei: "SANTA EULALIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150729",
    desc_ubigeo_inei: "SANTIAGO DE ANCHUCAYA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150730",
    desc_ubigeo_inei: "SANTIAGO DE TUNA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150731",
    desc_ubigeo_inei: "SANTO DOMINGO DE LOS OLLEROS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1507",
    desc_prov_inei: "HUAROCHIRI",
    cod_ubigeo_inei: "150732",
    desc_ubigeo_inei: "SURCO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150801",
    desc_ubigeo_inei: "HUACHO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150802",
    desc_ubigeo_inei: "AMBAR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150803",
    desc_ubigeo_inei: "CALETA DE CARQUIN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150804",
    desc_ubigeo_inei: "CHECRAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150805",
    desc_ubigeo_inei: "HUALMAY",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150806",
    desc_ubigeo_inei: "HUAURA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150807",
    desc_ubigeo_inei: "LEONCIO PRADO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150808",
    desc_ubigeo_inei: "PACCHO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150809",
    desc_ubigeo_inei: "SANTA LEONOR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150810",
    desc_ubigeo_inei: "SANTA MARIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150811",
    desc_ubigeo_inei: "SAYAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1508",
    desc_prov_inei: "HUAURA",
    cod_ubigeo_inei: "150812",
    desc_ubigeo_inei: "VEGUETA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150901",
    desc_ubigeo_inei: "OYON",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150902",
    desc_ubigeo_inei: "ANDAJES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150903",
    desc_ubigeo_inei: "CAUJUL",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150904",
    desc_ubigeo_inei: "COCHAMARCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150905",
    desc_ubigeo_inei: "NAVAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1509",
    desc_prov_inei: "OYON",
    cod_ubigeo_inei: "150906",
    desc_ubigeo_inei: "PACHANGARA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151001",
    desc_ubigeo_inei: "YAUYOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151002",
    desc_ubigeo_inei: "ALIS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151003",
    desc_ubigeo_inei: "ALLAUCA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151004",
    desc_ubigeo_inei: "AYAVIRI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151005",
    desc_ubigeo_inei: "AZANGARO",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151006",
    desc_ubigeo_inei: "CACRA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151007",
    desc_ubigeo_inei: "CARANIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151008",
    desc_ubigeo_inei: "CATAHUASI",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151009",
    desc_ubigeo_inei: "CHOCOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151010",
    desc_ubigeo_inei: "COCHAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151011",
    desc_ubigeo_inei: "COLONIA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151012",
    desc_ubigeo_inei: "HONGOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151013",
    desc_ubigeo_inei: "HUAMPARA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151014",
    desc_ubigeo_inei: "HUANCAYA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151015",
    desc_ubigeo_inei: "HUANGASCAR",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151016",
    desc_ubigeo_inei: "HUANTAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151017",
    desc_ubigeo_inei: "HUAÑEC",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151018",
    desc_ubigeo_inei: "LARAOS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151019",
    desc_ubigeo_inei: "LINCHA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151020",
    desc_ubigeo_inei: "MADEAN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151021",
    desc_ubigeo_inei: "MIRAFLORES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151022",
    desc_ubigeo_inei: "OMAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151023",
    desc_ubigeo_inei: "PUTINZA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151024",
    desc_ubigeo_inei: "QUINCHES",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151025",
    desc_ubigeo_inei: "QUINOCAY",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151026",
    desc_ubigeo_inei: "SAN JOAQUIN",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151027",
    desc_ubigeo_inei: "SAN PEDRO DE PILAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151028",
    desc_ubigeo_inei: "TANTA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151029",
    desc_ubigeo_inei: "TAURIPAMPA",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151030",
    desc_ubigeo_inei: "TOMAS",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151031",
    desc_ubigeo_inei: "TUPE",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151032",
    desc_ubigeo_inei: "VIÑAC",
  },
  {
    cod_dep_inei: "15",
    desc_dep_inei: "LIMA",
    cod_prov_inei: "1510",
    desc_prov_inei: "YAUYOS",
    cod_ubigeo_inei: "151033",
    desc_ubigeo_inei: "VITIS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160101",
    desc_ubigeo_inei: "IQUITOS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160102",
    desc_ubigeo_inei: "ALTO NANAY",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160103",
    desc_ubigeo_inei: "FERNANDO LORES",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160104",
    desc_ubigeo_inei: "INDIANA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160105",
    desc_ubigeo_inei: "LAS AMAZONAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160106",
    desc_ubigeo_inei: "MAZAN",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160107",
    desc_ubigeo_inei: "NAPO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160108",
    desc_ubigeo_inei: "PUNCHANA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160110",
    desc_ubigeo_inei: "TORRES CAUSANA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160112",
    desc_ubigeo_inei: "BELEN",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1601",
    desc_prov_inei: "MAYNAS",
    cod_ubigeo_inei: "160113",
    desc_ubigeo_inei: "SAN JUAN BAUTISTA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160201",
    desc_ubigeo_inei: "YURIMAGUAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160202",
    desc_ubigeo_inei: "BALSAPUERTO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160205",
    desc_ubigeo_inei: "JEBEROS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160206",
    desc_ubigeo_inei: "LAGUNAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160210",
    desc_ubigeo_inei: "SANTA CRUZ",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1602",
    desc_prov_inei: "ALTO AMAZONAS",
    cod_ubigeo_inei: "160211",
    desc_ubigeo_inei: "TENIENTE CESAR LOPEZ ROJAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1603",
    desc_prov_inei: "LORETO",
    cod_ubigeo_inei: "160301",
    desc_ubigeo_inei: "NAUTA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1603",
    desc_prov_inei: "LORETO",
    cod_ubigeo_inei: "160302",
    desc_ubigeo_inei: "PARINARI",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1603",
    desc_prov_inei: "LORETO",
    cod_ubigeo_inei: "160303",
    desc_ubigeo_inei: "TIGRE",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1603",
    desc_prov_inei: "LORETO",
    cod_ubigeo_inei: "160304",
    desc_ubigeo_inei: "TROMPETEROS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1603",
    desc_prov_inei: "LORETO",
    cod_ubigeo_inei: "160305",
    desc_ubigeo_inei: "URARINAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1604",
    desc_prov_inei: "MARISCAL RAMON CASTILLA",
    cod_ubigeo_inei: "160401",
    desc_ubigeo_inei: "RAMON CASTILLA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1604",
    desc_prov_inei: "MARISCAL RAMON CASTILLA",
    cod_ubigeo_inei: "160402",
    desc_ubigeo_inei: "PEBAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1604",
    desc_prov_inei: "MARISCAL RAMON CASTILLA",
    cod_ubigeo_inei: "160403",
    desc_ubigeo_inei: "YAVARI",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1604",
    desc_prov_inei: "MARISCAL RAMON CASTILLA",
    cod_ubigeo_inei: "160404",
    desc_ubigeo_inei: "SAN PABLO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160501",
    desc_ubigeo_inei: "REQUENA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160502",
    desc_ubigeo_inei: "ALTO TAPICHE",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160503",
    desc_ubigeo_inei: "CAPELO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160504",
    desc_ubigeo_inei: "EMILIO SAN MARTIN",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160505",
    desc_ubigeo_inei: "MAQUIA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160506",
    desc_ubigeo_inei: "PUINAHUA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160507",
    desc_ubigeo_inei: "SAQUENA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160508",
    desc_ubigeo_inei: "SOPLIN",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160509",
    desc_ubigeo_inei: "TAPICHE",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160510",
    desc_ubigeo_inei: "JENARO HERRERA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1605",
    desc_prov_inei: "REQUENA",
    cod_ubigeo_inei: "160511",
    desc_ubigeo_inei: "YAQUERANA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160601",
    desc_ubigeo_inei: "CONTAMANA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160602",
    desc_ubigeo_inei: "INAHUAYA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160603",
    desc_ubigeo_inei: "PADRE MARQUEZ",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160604",
    desc_ubigeo_inei: "PAMPA HERMOSA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160605",
    desc_ubigeo_inei: "SARAYACU",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1606",
    desc_prov_inei: "UCAYALI",
    cod_ubigeo_inei: "160606",
    desc_ubigeo_inei: "VARGAS GUERRA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160701",
    desc_ubigeo_inei: "BARRANCA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160702",
    desc_ubigeo_inei: "CAHUAPANAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160703",
    desc_ubigeo_inei: "MANSERICHE",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160704",
    desc_ubigeo_inei: "MORONA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160705",
    desc_ubigeo_inei: "PASTAZA",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1607",
    desc_prov_inei: "DATEM DEL MARAÑON",
    cod_ubigeo_inei: "160706",
    desc_ubigeo_inei: "ANDOAS",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1608",
    desc_prov_inei: "PUTUMAYO",
    cod_ubigeo_inei: "160801",
    desc_ubigeo_inei: "PUTUMAYO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1608",
    desc_prov_inei: "PUTUMAYO",
    cod_ubigeo_inei: "160802",
    desc_ubigeo_inei: "ROSA PANDURO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1608",
    desc_prov_inei: "PUTUMAYO",
    cod_ubigeo_inei: "160803",
    desc_ubigeo_inei: "TENIENTE MANUEL CLAVERO",
  },
  {
    cod_dep_inei: "16",
    desc_dep_inei: "LORETO",
    cod_prov_inei: "1608",
    desc_prov_inei: "PUTUMAYO",
    cod_ubigeo_inei: "160804",
    desc_ubigeo_inei: "YAGUAS",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1701",
    desc_prov_inei: "TAMBOPATA",
    cod_ubigeo_inei: "170101",
    desc_ubigeo_inei: "TAMBOPATA",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1701",
    desc_prov_inei: "TAMBOPATA",
    cod_ubigeo_inei: "170102",
    desc_ubigeo_inei: "INAMBARI",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1701",
    desc_prov_inei: "TAMBOPATA",
    cod_ubigeo_inei: "170103",
    desc_ubigeo_inei: "LAS PIEDRAS",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1701",
    desc_prov_inei: "TAMBOPATA",
    cod_ubigeo_inei: "170104",
    desc_ubigeo_inei: "LABERINTO",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1702",
    desc_prov_inei: "MANU",
    cod_ubigeo_inei: "170201",
    desc_ubigeo_inei: "MANU",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1702",
    desc_prov_inei: "MANU",
    cod_ubigeo_inei: "170202",
    desc_ubigeo_inei: "FITZCARRALD",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1702",
    desc_prov_inei: "MANU",
    cod_ubigeo_inei: "170203",
    desc_ubigeo_inei: "MADRE DE DIOS",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1702",
    desc_prov_inei: "MANU",
    cod_ubigeo_inei: "170204",
    desc_ubigeo_inei: "HUEPETUHE",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1703",
    desc_prov_inei: "TAHUAMANU",
    cod_ubigeo_inei: "170301",
    desc_ubigeo_inei: "IÑAPARI",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1703",
    desc_prov_inei: "TAHUAMANU",
    cod_ubigeo_inei: "170302",
    desc_ubigeo_inei: "IBERIA",
  },
  {
    cod_dep_inei: "17",
    desc_dep_inei: "MADRE DE DIOS",
    cod_prov_inei: "1703",
    desc_prov_inei: "TAHUAMANU",
    cod_ubigeo_inei: "170303",
    desc_ubigeo_inei: "TAHUAMANU",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180101",
    desc_ubigeo_inei: "MOQUEGUA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180102",
    desc_ubigeo_inei: "CARUMAS",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180103",
    desc_ubigeo_inei: "CUCHUMBAYA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180104",
    desc_ubigeo_inei: "SAMEGUA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180105",
    desc_ubigeo_inei: "SAN CRISTOBAL",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1801",
    desc_prov_inei: "MARISCAL NIETO",
    cod_ubigeo_inei: "180106",
    desc_ubigeo_inei: "TORATA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180201",
    desc_ubigeo_inei: "OMATE",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180202",
    desc_ubigeo_inei: "CHOJATA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180203",
    desc_ubigeo_inei: "COALAQUE",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180204",
    desc_ubigeo_inei: "ICHUÑA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180205",
    desc_ubigeo_inei: "LA CAPILLA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180206",
    desc_ubigeo_inei: "LLOQUE",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180207",
    desc_ubigeo_inei: "MATALAQUE",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180208",
    desc_ubigeo_inei: "PUQUINA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180209",
    desc_ubigeo_inei: "QUINISTAQUILLAS",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180210",
    desc_ubigeo_inei: "UBINAS",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1802",
    desc_prov_inei: "GENERAL SANCHEZ CERRO",
    cod_ubigeo_inei: "180211",
    desc_ubigeo_inei: "YUNGA",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1803",
    desc_prov_inei: "ILO",
    cod_ubigeo_inei: "180301",
    desc_ubigeo_inei: "ILO",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1803",
    desc_prov_inei: "ILO",
    cod_ubigeo_inei: "180302",
    desc_ubigeo_inei: "EL ALGARROBAL",
  },
  {
    cod_dep_inei: "18",
    desc_dep_inei: "MOQUEGUA",
    cod_prov_inei: "1803",
    desc_prov_inei: "ILO",
    cod_ubigeo_inei: "180303",
    desc_ubigeo_inei: "PACOCHA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190101",
    desc_ubigeo_inei: "CHAUPIMARCA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190102",
    desc_ubigeo_inei: "HUACHON",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190103",
    desc_ubigeo_inei: "HUARIACA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190104",
    desc_ubigeo_inei: "HUAYLLAY",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190105",
    desc_ubigeo_inei: "NINACACA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190106",
    desc_ubigeo_inei: "PALLANCHACRA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190107",
    desc_ubigeo_inei: "PAUCARTAMBO",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190108",
    desc_ubigeo_inei: "SAN FRANCISCO DE ASIS DE YARUSYACAN",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190109",
    desc_ubigeo_inei: "SIMON BOLIVAR",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190110",
    desc_ubigeo_inei: "TICLACAYAN",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190111",
    desc_ubigeo_inei: "TINYAHUARCO",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190112",
    desc_ubigeo_inei: "VICCO",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1901",
    desc_prov_inei: "PASCO",
    cod_ubigeo_inei: "190113",
    desc_ubigeo_inei: "YANACANCHA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190201",
    desc_ubigeo_inei: "YANAHUANCA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190202",
    desc_ubigeo_inei: "CHACAYAN",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190203",
    desc_ubigeo_inei: "GOYLLARISQUIZGA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190204",
    desc_ubigeo_inei: "PAUCAR",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190205",
    desc_ubigeo_inei: "SAN PEDRO DE PILLAO",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190206",
    desc_ubigeo_inei: "SANTA ANA DE TUSI",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190207",
    desc_ubigeo_inei: "TAPUC",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1902",
    desc_prov_inei: "DANIEL ALCIDES CARRION",
    cod_ubigeo_inei: "190208",
    desc_ubigeo_inei: "VILCABAMBA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190301",
    desc_ubigeo_inei: "OXAPAMPA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190302",
    desc_ubigeo_inei: "CHONTABAMBA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190303",
    desc_ubigeo_inei: "HUANCABAMBA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190304",
    desc_ubigeo_inei: "PALCAZU",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190305",
    desc_ubigeo_inei: "POZUZO",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190306",
    desc_ubigeo_inei: "PUERTO BERMUDEZ",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190307",
    desc_ubigeo_inei: "VILLA RICA",
  },
  {
    cod_dep_inei: "19",
    desc_dep_inei: "PASCO",
    cod_prov_inei: "1903",
    desc_prov_inei: "OXAPAMPA",
    cod_ubigeo_inei: "190308",
    desc_ubigeo_inei: "CONSTITUCION",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200101",
    desc_ubigeo_inei: "PIURA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200104",
    desc_ubigeo_inei: "CASTILLA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200105",
    desc_ubigeo_inei: "CATACAOS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200107",
    desc_ubigeo_inei: "CURA MORI",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200108",
    desc_ubigeo_inei: "EL TALLAN",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200109",
    desc_ubigeo_inei: "LA ARENA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200110",
    desc_ubigeo_inei: "LA UNION",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200111",
    desc_ubigeo_inei: "LAS LOMAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200114",
    desc_ubigeo_inei: "TAMBO GRANDE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2001",
    desc_prov_inei: "PIURA",
    cod_ubigeo_inei: "200115",
    desc_ubigeo_inei: "VEINTISEIS DE OCTUBRE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200201",
    desc_ubigeo_inei: "AYABACA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200202",
    desc_ubigeo_inei: "FRIAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200203",
    desc_ubigeo_inei: "JILILI",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200204",
    desc_ubigeo_inei: "LAGUNAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200205",
    desc_ubigeo_inei: "MONTERO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200206",
    desc_ubigeo_inei: "PACAIPAMPA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200207",
    desc_ubigeo_inei: "PAIMAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200208",
    desc_ubigeo_inei: "SAPILLICA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200209",
    desc_ubigeo_inei: "SICCHEZ",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2002",
    desc_prov_inei: "AYABACA",
    cod_ubigeo_inei: "200210",
    desc_ubigeo_inei: "SUYO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200301",
    desc_ubigeo_inei: "HUANCABAMBA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200302",
    desc_ubigeo_inei: "CANCHAQUE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200303",
    desc_ubigeo_inei: "EL CARMEN DE LA FRONTERA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200304",
    desc_ubigeo_inei: "HUARMACA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200305",
    desc_ubigeo_inei: "LALAQUIZ",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200306",
    desc_ubigeo_inei: "SAN MIGUEL DE EL FAIQUE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200307",
    desc_ubigeo_inei: "SONDOR",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2003",
    desc_prov_inei: "HUANCABAMBA",
    cod_ubigeo_inei: "200308",
    desc_ubigeo_inei: "SONDORILLO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200401",
    desc_ubigeo_inei: "CHULUCANAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200402",
    desc_ubigeo_inei: "BUENOS AIRES",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200403",
    desc_ubigeo_inei: "CHALACO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200404",
    desc_ubigeo_inei: "LA MATANZA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200405",
    desc_ubigeo_inei: "MORROPON",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200406",
    desc_ubigeo_inei: "SALITRAL",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200407",
    desc_ubigeo_inei: "SAN JUAN DE BIGOTE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200408",
    desc_ubigeo_inei: "SANTA CATALINA DE MOSSA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200409",
    desc_ubigeo_inei: "SANTO DOMINGO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2004",
    desc_prov_inei: "MORROPON",
    cod_ubigeo_inei: "200410",
    desc_ubigeo_inei: "YAMANGO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200501",
    desc_ubigeo_inei: "PAITA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200502",
    desc_ubigeo_inei: "AMOTAPE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200503",
    desc_ubigeo_inei: "ARENAL",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200504",
    desc_ubigeo_inei: "COLAN",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200505",
    desc_ubigeo_inei: "LA HUACA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200506",
    desc_ubigeo_inei: "TAMARINDO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2005",
    desc_prov_inei: "PAITA",
    cod_ubigeo_inei: "200507",
    desc_ubigeo_inei: "VICHAYAL",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200601",
    desc_ubigeo_inei: "SULLANA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200602",
    desc_ubigeo_inei: "BELLAVISTA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200603",
    desc_ubigeo_inei: "IGNACIO ESCUDERO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200604",
    desc_ubigeo_inei: "LANCONES",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200605",
    desc_ubigeo_inei: "MARCAVELICA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200606",
    desc_ubigeo_inei: "MIGUEL CHECA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200607",
    desc_ubigeo_inei: "QUERECOTILLO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2006",
    desc_prov_inei: "SULLANA",
    cod_ubigeo_inei: "200608",
    desc_ubigeo_inei: "SALITRAL",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200701",
    desc_ubigeo_inei: "PARIÑAS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200702",
    desc_ubigeo_inei: "EL ALTO",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200703",
    desc_ubigeo_inei: "LA BREA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200704",
    desc_ubigeo_inei: "LOBITOS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200705",
    desc_ubigeo_inei: "LOS ORGANOS",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2007",
    desc_prov_inei: "TALARA",
    cod_ubigeo_inei: "200706",
    desc_ubigeo_inei: "MANCORA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200801",
    desc_ubigeo_inei: "SECHURA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200802",
    desc_ubigeo_inei: "BELLAVISTA DE LA UNION",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200803",
    desc_ubigeo_inei: "BERNAL",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200804",
    desc_ubigeo_inei: "CRISTO NOS VALGA",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200805",
    desc_ubigeo_inei: "VICE",
  },
  {
    cod_dep_inei: "20",
    desc_dep_inei: "PIURA",
    cod_prov_inei: "2008",
    desc_prov_inei: "SECHURA",
    cod_ubigeo_inei: "200806",
    desc_ubigeo_inei: "RINCONADA LLICUAR",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210101",
    desc_ubigeo_inei: "PUNO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210102",
    desc_ubigeo_inei: "ACORA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210103",
    desc_ubigeo_inei: "AMANTANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210104",
    desc_ubigeo_inei: "ATUNCOLLA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210105",
    desc_ubigeo_inei: "CAPACHICA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210106",
    desc_ubigeo_inei: "CHUCUITO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210107",
    desc_ubigeo_inei: "COATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210108",
    desc_ubigeo_inei: "HUATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210109",
    desc_ubigeo_inei: "MAÑAZO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210110",
    desc_ubigeo_inei: "PAUCARCOLLA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210111",
    desc_ubigeo_inei: "PICHACANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210112",
    desc_ubigeo_inei: "PLATERIA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210113",
    desc_ubigeo_inei: "SAN ANTONIO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210114",
    desc_ubigeo_inei: "TIQUILLACA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2101",
    desc_prov_inei: "PUNO",
    cod_ubigeo_inei: "210115",
    desc_ubigeo_inei: "VILQUE",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210201",
    desc_ubigeo_inei: "AZANGARO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210202",
    desc_ubigeo_inei: "ACHAYA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210203",
    desc_ubigeo_inei: "ARAPA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210204",
    desc_ubigeo_inei: "ASILLO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210205",
    desc_ubigeo_inei: "CAMINACA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210206",
    desc_ubigeo_inei: "CHUPA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210207",
    desc_ubigeo_inei: "JOSE DOMINGO CHOQUEHUANCA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210208",
    desc_ubigeo_inei: "MUÑANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210209",
    desc_ubigeo_inei: "POTONI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210210",
    desc_ubigeo_inei: "SAMAN",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210211",
    desc_ubigeo_inei: "SAN ANTON",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210212",
    desc_ubigeo_inei: "SAN JOSE",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210213",
    desc_ubigeo_inei: "SAN JUAN DE SALINAS",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210214",
    desc_ubigeo_inei: "SANTIAGO DE PUPUJA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2102",
    desc_prov_inei: "AZANGARO",
    cod_ubigeo_inei: "210215",
    desc_ubigeo_inei: "TIRAPATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210301",
    desc_ubigeo_inei: "MACUSANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210302",
    desc_ubigeo_inei: "AJOYANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210303",
    desc_ubigeo_inei: "AYAPATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210304",
    desc_ubigeo_inei: "COASA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210305",
    desc_ubigeo_inei: "CORANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210306",
    desc_ubigeo_inei: "CRUCERO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210307",
    desc_ubigeo_inei: "ITUATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210308",
    desc_ubigeo_inei: "OLLACHEA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210309",
    desc_ubigeo_inei: "SAN GABAN",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2103",
    desc_prov_inei: "CARABAYA",
    cod_ubigeo_inei: "210310",
    desc_ubigeo_inei: "USICAYOS",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210401",
    desc_ubigeo_inei: "JULI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210402",
    desc_ubigeo_inei: "DESAGUADERO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210403",
    desc_ubigeo_inei: "HUACULLANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210404",
    desc_ubigeo_inei: "KELLUYO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210405",
    desc_ubigeo_inei: "PISACOMA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210406",
    desc_ubigeo_inei: "POMATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2104",
    desc_prov_inei: "CHUCUITO",
    cod_ubigeo_inei: "210407",
    desc_ubigeo_inei: "ZEPITA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2105",
    desc_prov_inei: "EL COLLAO",
    cod_ubigeo_inei: "210501",
    desc_ubigeo_inei: "ILAVE",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2105",
    desc_prov_inei: "EL COLLAO",
    cod_ubigeo_inei: "210502",
    desc_ubigeo_inei: "CAPAZO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2105",
    desc_prov_inei: "EL COLLAO",
    cod_ubigeo_inei: "210503",
    desc_ubigeo_inei: "PILCUYO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2105",
    desc_prov_inei: "EL COLLAO",
    cod_ubigeo_inei: "210504",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2105",
    desc_prov_inei: "EL COLLAO",
    cod_ubigeo_inei: "210505",
    desc_ubigeo_inei: "CONDURIRI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210601",
    desc_ubigeo_inei: "HUANCANE",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210602",
    desc_ubigeo_inei: "COJATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210603",
    desc_ubigeo_inei: "HUATASANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210604",
    desc_ubigeo_inei: "INCHUPALLA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210605",
    desc_ubigeo_inei: "PUSI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210606",
    desc_ubigeo_inei: "ROSASPATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210607",
    desc_ubigeo_inei: "TARACO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2106",
    desc_prov_inei: "HUANCANE",
    cod_ubigeo_inei: "210608",
    desc_ubigeo_inei: "VILQUE CHICO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210701",
    desc_ubigeo_inei: "LAMPA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210702",
    desc_ubigeo_inei: "CABANILLA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210703",
    desc_ubigeo_inei: "CALAPUJA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210704",
    desc_ubigeo_inei: "NICASIO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210705",
    desc_ubigeo_inei: "OCUVIRI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210706",
    desc_ubigeo_inei: "PALCA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210707",
    desc_ubigeo_inei: "PARATIA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210708",
    desc_ubigeo_inei: "PUCARA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210709",
    desc_ubigeo_inei: "SANTA LUCIA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2107",
    desc_prov_inei: "LAMPA",
    cod_ubigeo_inei: "210710",
    desc_ubigeo_inei: "VILAVILA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210801",
    desc_ubigeo_inei: "AYAVIRI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210802",
    desc_ubigeo_inei: "ANTAUTA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210803",
    desc_ubigeo_inei: "CUPI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210804",
    desc_ubigeo_inei: "LLALLI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210805",
    desc_ubigeo_inei: "MACARI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210806",
    desc_ubigeo_inei: "NUÑOA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210807",
    desc_ubigeo_inei: "ORURILLO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210808",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2108",
    desc_prov_inei: "MELGAR",
    cod_ubigeo_inei: "210809",
    desc_ubigeo_inei: "UMACHIRI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2109",
    desc_prov_inei: "MOHO",
    cod_ubigeo_inei: "210901",
    desc_ubigeo_inei: "MOHO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2109",
    desc_prov_inei: "MOHO",
    cod_ubigeo_inei: "210902",
    desc_ubigeo_inei: "CONIMA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2109",
    desc_prov_inei: "MOHO",
    cod_ubigeo_inei: "210903",
    desc_ubigeo_inei: "HUAYRAPATA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2109",
    desc_prov_inei: "MOHO",
    cod_ubigeo_inei: "210904",
    desc_ubigeo_inei: "TILALI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2110",
    desc_prov_inei: "SAN ANTONIO DE PUTINA",
    cod_ubigeo_inei: "211001",
    desc_ubigeo_inei: "PUTINA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2110",
    desc_prov_inei: "SAN ANTONIO DE PUTINA",
    cod_ubigeo_inei: "211002",
    desc_ubigeo_inei: "ANANEA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2110",
    desc_prov_inei: "SAN ANTONIO DE PUTINA",
    cod_ubigeo_inei: "211003",
    desc_ubigeo_inei: "PEDRO VILCA APAZA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2110",
    desc_prov_inei: "SAN ANTONIO DE PUTINA",
    cod_ubigeo_inei: "211004",
    desc_ubigeo_inei: "QUILCAPUNCU",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2110",
    desc_prov_inei: "SAN ANTONIO DE PUTINA",
    cod_ubigeo_inei: "211005",
    desc_ubigeo_inei: "SINA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2111",
    desc_prov_inei: "SAN ROMAN",
    cod_ubigeo_inei: "211101",
    desc_ubigeo_inei: "JULIACA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2111",
    desc_prov_inei: "SAN ROMAN",
    cod_ubigeo_inei: "211102",
    desc_ubigeo_inei: "CABANA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2111",
    desc_prov_inei: "SAN ROMAN",
    cod_ubigeo_inei: "211103",
    desc_ubigeo_inei: "CABANILLAS",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2111",
    desc_prov_inei: "SAN ROMAN",
    cod_ubigeo_inei: "211104",
    desc_ubigeo_inei: "CARACOTO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2111",
    desc_prov_inei: "SAN ROMAN",
    cod_ubigeo_inei: "211105",
    desc_ubigeo_inei: "SAN MIGUEL",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211201",
    desc_ubigeo_inei: "SANDIA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211202",
    desc_ubigeo_inei: "CUYOCUYO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211203",
    desc_ubigeo_inei: "LIMBANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211204",
    desc_ubigeo_inei: "PATAMBUCO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211205",
    desc_ubigeo_inei: "PHARA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211206",
    desc_ubigeo_inei: "QUIACA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211207",
    desc_ubigeo_inei: "SAN JUAN DEL ORO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211208",
    desc_ubigeo_inei: "YANAHUAYA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211209",
    desc_ubigeo_inei: "ALTO INAMBARI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2112",
    desc_prov_inei: "SANDIA",
    cod_ubigeo_inei: "211210",
    desc_ubigeo_inei: "SAN PEDRO DE PUTINA PUNCO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211301",
    desc_ubigeo_inei: "YUNGUYO",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211302",
    desc_ubigeo_inei: "ANAPIA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211303",
    desc_ubigeo_inei: "COPANI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211304",
    desc_ubigeo_inei: "CUTURAPI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211305",
    desc_ubigeo_inei: "OLLARAYA",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211306",
    desc_ubigeo_inei: "TINICACHI",
  },
  {
    cod_dep_inei: "21",
    desc_dep_inei: "PUNO",
    cod_prov_inei: "2113",
    desc_prov_inei: "YUNGUYO",
    cod_ubigeo_inei: "211307",
    desc_ubigeo_inei: "UNICACHI",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220101",
    desc_ubigeo_inei: "MOYOBAMBA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220102",
    desc_ubigeo_inei: "CALZADA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220103",
    desc_ubigeo_inei: "HABANA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220104",
    desc_ubigeo_inei: "JEPELACIO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220105",
    desc_ubigeo_inei: "SORITOR",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2201",
    desc_prov_inei: "MOYOBAMBA",
    cod_ubigeo_inei: "220106",
    desc_ubigeo_inei: "YANTALO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220201",
    desc_ubigeo_inei: "BELLAVISTA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220202",
    desc_ubigeo_inei: "ALTO BIAVO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220203",
    desc_ubigeo_inei: "BAJO BIAVO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220204",
    desc_ubigeo_inei: "HUALLAGA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220205",
    desc_ubigeo_inei: "SAN PABLO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2202",
    desc_prov_inei: "BELLAVISTA",
    cod_ubigeo_inei: "220206",
    desc_ubigeo_inei: "SAN RAFAEL",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2203",
    desc_prov_inei: "EL DORADO",
    cod_ubigeo_inei: "220301",
    desc_ubigeo_inei: "SAN JOSE DE SISA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2203",
    desc_prov_inei: "EL DORADO",
    cod_ubigeo_inei: "220302",
    desc_ubigeo_inei: "AGUA BLANCA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2203",
    desc_prov_inei: "EL DORADO",
    cod_ubigeo_inei: "220303",
    desc_ubigeo_inei: "SAN MARTIN",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2203",
    desc_prov_inei: "EL DORADO",
    cod_ubigeo_inei: "220304",
    desc_ubigeo_inei: "SANTA ROSA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2203",
    desc_prov_inei: "EL DORADO",
    cod_ubigeo_inei: "220305",
    desc_ubigeo_inei: "SHATOJA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220401",
    desc_ubigeo_inei: "SAPOSOA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220402",
    desc_ubigeo_inei: "ALTO SAPOSOA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220403",
    desc_ubigeo_inei: "EL ESLABON",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220404",
    desc_ubigeo_inei: "PISCOYACU",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220405",
    desc_ubigeo_inei: "SACANCHE",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2204",
    desc_prov_inei: "HUALLAGA",
    cod_ubigeo_inei: "220406",
    desc_ubigeo_inei: "TINGO DE SAPOSOA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220501",
    desc_ubigeo_inei: "LAMAS",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220502",
    desc_ubigeo_inei: "ALONSO DE ALVARADO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220503",
    desc_ubigeo_inei: "BARRANQUITA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220504",
    desc_ubigeo_inei: "CAYNARACHI",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220505",
    desc_ubigeo_inei: "CUÑUMBUQUI",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220506",
    desc_ubigeo_inei: "PINTO RECODO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220507",
    desc_ubigeo_inei: "RUMISAPA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220508",
    desc_ubigeo_inei: "SAN ROQUE DE CUMBAZA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220509",
    desc_ubigeo_inei: "SHANAO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220510",
    desc_ubigeo_inei: "TABALOSOS",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2205",
    desc_prov_inei: "LAMAS",
    cod_ubigeo_inei: "220511",
    desc_ubigeo_inei: "ZAPATERO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2206",
    desc_prov_inei: "MARISCAL CACERES",
    cod_ubigeo_inei: "220601",
    desc_ubigeo_inei: "JUANJUI",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2206",
    desc_prov_inei: "MARISCAL CACERES",
    cod_ubigeo_inei: "220602",
    desc_ubigeo_inei: "CAMPANILLA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2206",
    desc_prov_inei: "MARISCAL CACERES",
    cod_ubigeo_inei: "220603",
    desc_ubigeo_inei: "HUICUNGO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2206",
    desc_prov_inei: "MARISCAL CACERES",
    cod_ubigeo_inei: "220604",
    desc_ubigeo_inei: "PACHIZA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2206",
    desc_prov_inei: "MARISCAL CACERES",
    cod_ubigeo_inei: "220605",
    desc_ubigeo_inei: "PAJARILLO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220701",
    desc_ubigeo_inei: "PICOTA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220702",
    desc_ubigeo_inei: "BUENOS AIRES",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220703",
    desc_ubigeo_inei: "CASPISAPA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220704",
    desc_ubigeo_inei: "PILLUANA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220705",
    desc_ubigeo_inei: "PUCACACA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220706",
    desc_ubigeo_inei: "SAN CRISTOBAL",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220707",
    desc_ubigeo_inei: "SAN HILARION",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220708",
    desc_ubigeo_inei: "SHAMBOYACU",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220709",
    desc_ubigeo_inei: "TINGO DE PONASA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2207",
    desc_prov_inei: "PICOTA",
    cod_ubigeo_inei: "220710",
    desc_ubigeo_inei: "TRES UNIDOS",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220801",
    desc_ubigeo_inei: "RIOJA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220802",
    desc_ubigeo_inei: "AWAJUN",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220803",
    desc_ubigeo_inei: "ELIAS SOPLIN VARGAS",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220804",
    desc_ubigeo_inei: "NUEVA CAJAMARCA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220805",
    desc_ubigeo_inei: "PARDO MIGUEL",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220806",
    desc_ubigeo_inei: "POSIC",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220807",
    desc_ubigeo_inei: "SAN FERNANDO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220808",
    desc_ubigeo_inei: "YORONGOS",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2208",
    desc_prov_inei: "RIOJA",
    cod_ubigeo_inei: "220809",
    desc_ubigeo_inei: "YURACYACU",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220901",
    desc_ubigeo_inei: "TARAPOTO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220902",
    desc_ubigeo_inei: "ALBERTO LEVEAU",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220903",
    desc_ubigeo_inei: "CACATACHI",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220904",
    desc_ubigeo_inei: "CHAZUTA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220905",
    desc_ubigeo_inei: "CHIPURANA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220906",
    desc_ubigeo_inei: "EL PORVENIR",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220907",
    desc_ubigeo_inei: "HUIMBAYOC",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220908",
    desc_ubigeo_inei: "JUAN GUERRA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220909",
    desc_ubigeo_inei: "LA BANDA DE SHILCAYO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220910",
    desc_ubigeo_inei: "MORALES",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220911",
    desc_ubigeo_inei: "PAPAPLAYA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220912",
    desc_ubigeo_inei: "SAN ANTONIO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220913",
    desc_ubigeo_inei: "SAUCE",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2209",
    desc_prov_inei: "SAN MARTIN",
    cod_ubigeo_inei: "220914",
    desc_ubigeo_inei: "SHAPAJA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2210",
    desc_prov_inei: "TOCACHE",
    cod_ubigeo_inei: "221001",
    desc_ubigeo_inei: "TOCACHE",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2210",
    desc_prov_inei: "TOCACHE",
    cod_ubigeo_inei: "221002",
    desc_ubigeo_inei: "NUEVO PROGRESO",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2210",
    desc_prov_inei: "TOCACHE",
    cod_ubigeo_inei: "221003",
    desc_ubigeo_inei: "POLVORA",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2210",
    desc_prov_inei: "TOCACHE",
    cod_ubigeo_inei: "221004",
    desc_ubigeo_inei: "SHUNTE",
  },
  {
    cod_dep_inei: "22",
    desc_dep_inei: "SAN MARTIN",
    cod_prov_inei: "2210",
    desc_prov_inei: "TOCACHE",
    cod_ubigeo_inei: "221005",
    desc_ubigeo_inei: "UCHIZA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230101",
    desc_ubigeo_inei: "TACNA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230102",
    desc_ubigeo_inei: "ALTO DE LA ALIANZA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230103",
    desc_ubigeo_inei: "CALANA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230104",
    desc_ubigeo_inei: "CIUDAD NUEVA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230105",
    desc_ubigeo_inei: "INCLAN",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230106",
    desc_ubigeo_inei: "PACHIA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230107",
    desc_ubigeo_inei: "PALCA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230108",
    desc_ubigeo_inei: "POCOLLAY",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230109",
    desc_ubigeo_inei: "SAMA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230110",
    desc_ubigeo_inei: "CORONEL GREGORIO ALBARRACIN LANCHIPA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2301",
    desc_prov_inei: "TACNA",
    cod_ubigeo_inei: "230111",
    desc_ubigeo_inei: "LA YARADA LOS PALOS",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230201",
    desc_ubigeo_inei: "CANDARAVE",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230202",
    desc_ubigeo_inei: "CAIRANI",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230203",
    desc_ubigeo_inei: "CAMILACA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230204",
    desc_ubigeo_inei: "CURIBAYA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230205",
    desc_ubigeo_inei: "HUANUARA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2302",
    desc_prov_inei: "CANDARAVE",
    cod_ubigeo_inei: "230206",
    desc_ubigeo_inei: "QUILAHUANI",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2303",
    desc_prov_inei: "JORGE BASADRE",
    cod_ubigeo_inei: "230301",
    desc_ubigeo_inei: "LOCUMBA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2303",
    desc_prov_inei: "JORGE BASADRE",
    cod_ubigeo_inei: "230302",
    desc_ubigeo_inei: "ILABAYA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2303",
    desc_prov_inei: "JORGE BASADRE",
    cod_ubigeo_inei: "230303",
    desc_ubigeo_inei: "ITE",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230401",
    desc_ubigeo_inei: "TARATA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230402",
    desc_ubigeo_inei: "HEROES ALBARRACIN",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230403",
    desc_ubigeo_inei: "ESTIQUE",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230404",
    desc_ubigeo_inei: "ESTIQUE-PAMPA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230405",
    desc_ubigeo_inei: "SITAJARA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230406",
    desc_ubigeo_inei: "SUSAPAYA",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230407",
    desc_ubigeo_inei: "TARUCACHI",
  },
  {
    cod_dep_inei: "23",
    desc_dep_inei: "TACNA",
    cod_prov_inei: "2304",
    desc_prov_inei: "TARATA",
    cod_ubigeo_inei: "230408",
    desc_ubigeo_inei: "TICACO",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240101",
    desc_ubigeo_inei: "TUMBES",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240102",
    desc_ubigeo_inei: "CORRALES",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240103",
    desc_ubigeo_inei: "LA CRUZ",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240104",
    desc_ubigeo_inei: "PAMPAS DE HOSPITAL",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240105",
    desc_ubigeo_inei: "SAN JACINTO",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2401",
    desc_prov_inei: "TUMBES",
    cod_ubigeo_inei: "240106",
    desc_ubigeo_inei: "SAN JUAN DE LA VIRGEN",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2402",
    desc_prov_inei: "CONTRALMIRANTE VILLAR",
    cod_ubigeo_inei: "240201",
    desc_ubigeo_inei: "ZORRITOS",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2402",
    desc_prov_inei: "CONTRALMIRANTE VILLAR",
    cod_ubigeo_inei: "240202",
    desc_ubigeo_inei: "CASITAS",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2402",
    desc_prov_inei: "CONTRALMIRANTE VILLAR",
    cod_ubigeo_inei: "240203",
    desc_ubigeo_inei: "CANOAS DE PUNTA SAL",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2403",
    desc_prov_inei: "ZARUMILLA",
    cod_ubigeo_inei: "240301",
    desc_ubigeo_inei: "ZARUMILLA",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2403",
    desc_prov_inei: "ZARUMILLA",
    cod_ubigeo_inei: "240302",
    desc_ubigeo_inei: "AGUAS VERDES",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2403",
    desc_prov_inei: "ZARUMILLA",
    cod_ubigeo_inei: "240303",
    desc_ubigeo_inei: "MATAPALO",
  },
  {
    cod_dep_inei: "24",
    desc_dep_inei: "TUMBES",
    cod_prov_inei: "2403",
    desc_prov_inei: "ZARUMILLA",
    cod_ubigeo_inei: "240304",
    desc_ubigeo_inei: "PAPAYAL",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250101",
    desc_ubigeo_inei: "CALLERIA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250102",
    desc_ubigeo_inei: "CAMPOVERDE",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250103",
    desc_ubigeo_inei: "IPARIA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250104",
    desc_ubigeo_inei: "MASISEA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250105",
    desc_ubigeo_inei: "YARINACOCHA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250106",
    desc_ubigeo_inei: "NUEVA REQUENA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2501",
    desc_prov_inei: "CORONEL PORTILLO",
    cod_ubigeo_inei: "250107",
    desc_ubigeo_inei: "MANANTAY",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2502",
    desc_prov_inei: "ATALAYA",
    cod_ubigeo_inei: "250201",
    desc_ubigeo_inei: "RAYMONDI",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2502",
    desc_prov_inei: "ATALAYA",
    cod_ubigeo_inei: "250202",
    desc_ubigeo_inei: "SEPAHUA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2502",
    desc_prov_inei: "ATALAYA",
    cod_ubigeo_inei: "250203",
    desc_ubigeo_inei: "TAHUANIA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2502",
    desc_prov_inei: "ATALAYA",
    cod_ubigeo_inei: "250204",
    desc_ubigeo_inei: "YURUA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2503",
    desc_prov_inei: "PADRE ABAD",
    cod_ubigeo_inei: "250301",
    desc_ubigeo_inei: "PADRE ABAD",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2503",
    desc_prov_inei: "PADRE ABAD",
    cod_ubigeo_inei: "250302",
    desc_ubigeo_inei: "IRAZOLA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2503",
    desc_prov_inei: "PADRE ABAD",
    cod_ubigeo_inei: "250303",
    desc_ubigeo_inei: "CURIMANA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2503",
    desc_prov_inei: "PADRE ABAD",
    cod_ubigeo_inei: "250304",
    desc_ubigeo_inei: "NESHUYA",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2503",
    desc_prov_inei: "PADRE ABAD",
    cod_ubigeo_inei: "250305",
    desc_ubigeo_inei: "ALEXANDER VON HUMBOLDT",
  },
  {
    cod_dep_inei: "25",
    desc_dep_inei: "UCAYALI",
    cod_prov_inei: "2504",
    desc_prov_inei: "PURUS",
    cod_ubigeo_inei: "250401",
    desc_ubigeo_inei: "PURUS",
  },
];