import { useContext } from "react";
import { AppContext } from "../App";
import { useLocation, Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";

export function ProtectedRoute({ children }) {
  const { currentUser } = getAuth();
  const location = useLocation();
  const { dataOfUser } = useContext(AppContext);
  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/email-verify" ||
    location.pathname === "/recover-password" ||
    location.pathname === "/recover-password-2" ||
    location.pathname === "/change-password"
  ) {
    return currentUser && dataOfUser ? (
      <Navigate to={location.state?.from ?? "/home"} replace />
    ) : (
      children
    );
  }

  return currentUser && dataOfUser ? (
    children
  ) : (
    <Navigate to={"/login"} state={{ from: location.pathname }} replace />
  );
}
