import styled from 'styled-components';

export const MetodPlinYapeContainer = styled.div`
  p {
    span {
      font-weight: ${props => props.theme.weight.medium};
    }
  }

  img {
    display: block;
    margin: 20px auto;
  }

  input {
    text-align: center;
  }

  .paragraphTwoMetod {
    margin: 0 0 15px 0;
  }

  .standByValidation {
    color: ${props => props.theme.gray500Color};
    text-align: center;
    margin: 10px 0 0 0;
  }

  @media(min-width:768px) {
    p {
      width: 80%;
      display: block;
      margin: 0 auto;
    }

    .paragraphTwoMetod {
      margin: 0 auto 15px auto;
    }
  }
`
