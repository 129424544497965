import styled from 'styled-components';

export const ShippingGroupCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875em;
  flex-direction: column;

  span {
    font-weight: ${props => props.theme.weight.medium};
  }

  a {
    color: ${props => props.theme.brandClassicBlueColor};
    font-weight: ${props => props.theme.weight.semiBold};
    margin: 10px 0 0 0;

    &:hover {
      text-decoration: underline;
    }
  }

  .descriptionContainer {
    display: flex;
    margin: 7px 0 0 0;

    p:first-child {
      margin: 0 30px 0 0;
    }
  }

  @media(min-width:1200px) {
    flex-direction: row;

    a {
      margin: 0;
    }
  }
`
