import styled from "styled-components";

export const TrackingTimelineCardContainer = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;

  .timelineGraphic {
    position: relative;
  }

  .timelineContent {
    height: 170px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    place-items: center;
    margin: 0 10px 0 0;
  }

  .circleTimeline {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    position: relative;
    z-index: 10;
  }

  .timelineBar {
    width: 4px;
    height: 115px;
    background: ${(props) => props.theme.baseColor};
    position: absolute;
    top: 27px;
    left: 18px;
  }

  .timelineDescription {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    font-size: 0.875em;
    font-weight: ${(props) => props.theme.weight.semiBold};
  }

  .dateDeliveredTimeline {
    position: absolute;
    font-size: 0.75em;
    color: ${(props) => props.theme.gray500Color};
    margin: 4px 0 0 0;
    font-weight: ${(props) => props.theme.weight.medium};
  }

  /*Cuando se valida*/

  .storageColor {
    background: ${(props) =>
      props.statusColor.storage
        ? props.theme.brandClassicBlueColor
        : props.theme.gray500Color};
  }

  .routeColor {
    background: ${(props) =>
      props.statusColor.route
        ? props.theme.brandClassicBlueColor
        : props.theme.gray500Color};
  }

  .deliveredColor {
    background: ${(props) =>
      props.statusColor.delivered
        ? props.theme.brandClassicBlueColor
        : props.theme.gray500Color};
  }

  .storageTimelineDescription {
    color: ${(props) =>
      props.statusColor.storage
        ? props.theme.blackColor
        : props.theme.gray500Color};
  }

  .routeTimelineDescription {
    color: ${(props) =>
      props.statusColor.route
        ? props.theme.blackColor
        : props.theme.gray500Color};
  }

  .deliveredTimelineDescription {
    position: relative;
    color: ${(props) =>
      props.statusColor.nodelivered
        ? props.theme.red500Color
        : props.statusColor.delivered
        ? props.theme.brandClassicBlueColor
        : props.theme.gray500Color};
  }

  /*Cuando no se envía*/

  /* .timelineDescription {
    color: ${(props) => props.theme.red500Color};
  } */

  @media (min-width: 768px) {
    .timelineContent {
      height: 200px;
    }

    .timelineDescription {
      font-size: 0.938em;
    }

    .timelineContent {
      margin: 0 20px 0 0;
    }

    .timelineBar {
      height: 144px;
      left: 13px;
    }
  }
`;
