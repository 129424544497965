import React from 'react';
import { MainSpinnerContainer } from './styles/sMainSpinner'
import LogoServicioPuntual from './../../images/logos/image-2.png'

export default function MainSpinner() {
  return (
    <MainSpinnerContainer>
      <div className='load'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <img src={LogoServicioPuntual} alt="Logo de Servicio Puntual" />
    </MainSpinnerContainer>
  )
}
