import styled from 'styled-components';

export const PackagingCardContainer = styled.article`
  margin: 0 0 30px 0;

  p {
    text-align: center;
  }

  .barPackagingCard {
    height: 5px;
    width: 50%;
    background: ${props => props.theme.brandClassicBlueColor};
    margin: 15px auto 40px auto;
  }

  @media(min-width:768px) {
    margin: 0 15% 30px 15%;
  }
`;
