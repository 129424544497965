import styled from 'styled-components';

export const ImagePackagingCardContainer = styled.article`
  position: relative;
  margin: 0 0 30px 0;

  img {
    width: 100%;
    height: 55vh;
    border-radius: 8px;
  }

  h3, p {
    text-align: start;
    color: ${props => props.theme.backgroundColor};
    font-size: 0.875em;
  }

  h3 {
    margin: 0 0 8px 0 !important;
    font-weight: ${props => props.theme.weight.semiBold};
  }
  
  .imagePackagingTransparent {
    width: 100%;
    height: 70%;
    position: absolute;
    z-index: 1000;
    background: linear-gradient(180deg, rgba(38, 42, 46, 0) 0%, #262A2E 100%);
    border-radius: 0px 0px 8px 8px;
    bottom: 0;
  }
  
  .imagePackagingDescription {
    padding: 15px;
    position: absolute;
    z-index: 1500;
    bottom: 0;
    left: 0;
  }

  @media(min-width:768px) {
    .imagePackagingDescription {
      padding: 20px;
      position: absolute;
      z-index: 1500;
      bottom: 0;
      left: 0;
    }
  }

  @media(min-width:1200px) {
    img {
      height: 75vh;
    }

    h3, p {
      font-size: 0.938em;
    }
  }
`;
