import styled from 'styled-components';

export const FooterTwoContainer = styled.div`
  margin: 70px 0 0 0;
  background: ${props => props.theme.blackColor};

  a, p {
    color: ${props => props.theme.gray200Color};
  }

  .gridContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 50px 0;
    justify-items: center;
    gap: 20px;
  }

  .sectionLinks {
    p {
      font-size: 0.938em;
      font-weight: ${props => props.theme.weight.bold};
      margin: 0 0 15px 0;
      color: ${props => props.theme.backgroundColor};
    }

    a {
      margin: 0 0 10px 0;
      display: block;

      &:last-child {
        margin: 0;
      }

      &:hover {
        color: ${props => props.theme.backgroundColor};
      }
    }
  }

  @media(min-width:1200px) {
    margin: 120px 0 0 0;

    .gridContent {
      margin: 0 auto;
      width: 400px;
    }
  }
`
