import React from 'react'
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../../styles/generalStyles';
import { FooterTwoContainer } from './styles/sFooterTwo'
import { Link } from 'react-router-dom'
import { FooterLandingCopyrightContent } from './styles/sFooterLanding'

export default function FooterTwo() {
  const [t] = useTranslation("main");

  return (
    <footer>
      <FooterTwoContainer>
        <Wrapper>
          <div className='gridContent'>
            <div className='sectionLinks'>
              <p>Servicio Puntual</p>
              <Link to="/">Aplicación web</Link>
              <Link to="/terms">Terminos de uso</Link>
              <Link to="/terms">Privacidad</Link>
            </div>
            <div className='sectionLinks'>
              <p>Ayuda</p>
              <Link to="/help-center">Centro de asistencia</Link>
              <Link to="/assistance">Contáctanos</Link>
            </div>
          </div>
          <FooterLandingCopyrightContent>
            <p>{t("landing.footer.copyright")}</p>
          </FooterLandingCopyrightContent>
        </Wrapper>
      </FooterTwoContainer>
    </footer>
  )
}
