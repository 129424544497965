import React from 'react';
import { useTranslation } from "react-i18next"
import { Text } from './../../styles/textGeneral'
import { Button } from './../../styles/buttonGeneral'
import { ReactComponent as Motocycle } from './../../images/motocycle-1.svg'
import { AddsCardContainer } from './styles/sAddsCard'
import { Link } from 'react-router-dom'

export default function AddsCard() {
  const [t] = useTranslation("main");

  return (
    <AddsCardContainer>
      <Text className='addsCardText'>{t("start.newShipmentDescription")}</Text>
      <Motocycle />
      <Link to={`/new-package/${localStorage.getItem("serviceType") || "next-day"}`}>
        <Button primary small>{t("start.performShipment")}</Button>
      </Link>
    </AddsCardContainer>
  )
}
