import { writeBatch, doc } from "firebase/firestore";

export const changeTypeOfUser = async (
  db,
  userToChangeType,
  setUserToChangeType,
) => {
  try {
    const batch = writeBatch(db);
    let localUser = userToChangeType;
    let localTypeOfUser =
      userToChangeType?.typeOfUser === "B2C" ? "B2B" : "B2C";
    if (userToChangeType) {
      localUser.typeOfUser = localTypeOfUser;
    }

    batch.set(
      doc(db, "users", userToChangeType?.uid),
      {
        typeOfUser: localTypeOfUser,
      },
      { merge: true }
    );

    await batch
      .commit()
      .then(() => {
        console.log("perfect change");
        setUserToChangeType(localUser)
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err.message);
  }
};
