import styled from 'styled-components';

export const HelpCenterCover = styled.div`
  margin: 70px 0 50px 0;
  background: linear-gradient(90deg, #088AC0 0%, #174F82 100%);
  color: ${props => props.theme.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 320px;

  h1, span {
    font-weight: ${props => props.theme.weight.bold};
  }

  span {
    font-size: 1.5em;
    margin: 0 0 5px 0;
    display: block;
  }

  h1 {
    font-size: 2.0em;
    line-height: 1.25em;
  }

  @media(min-width:1200px) {
    margin: 70px 0;

    span {
      font-size: 2.125em;
      margin: 0 auto;
      width: 630px;
    }

    h1 {
      font-size: 2.75em;
      width: fit-content;
      margin: 0 auto;
    }
  }
`

export const ServiceCardsUnlist = styled.ul`
  li {
    margin: 0 0 35px 0;

    &:last-child {
      margin: 0;
    }
  }

  @media(min-width:1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px;

    li {
      margin: 0;
    }
  }
`
