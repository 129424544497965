import styled from 'styled-components';

export const LoginAndRegisterDesktop = styled.div`
  .coverPageLoginAndRegisterContent {
    display: none;
  }

  @media(min-width:1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .coverPageLoginAndRegisterContent {
      font-weight: ${props => props.theme.weight.semiBold};
      height: 100vh;
      position: fixed;
      display: flex;
      width: 50%;
      
      & > div {
        position: absolute;
        background: rgba(23, 79, 130, 0.85);
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          color ${props => props.theme.backgroundColor};
          font-size: 1.875em;
          margin: 0 0 30px 0;
          text-align: center;
        }
  
        img {
          height: 70px;
        }
      }
    }

    .loginAndRegisterBackgroundImage {
      width: 100%;
      height: 100vh;
    }
  }
`

export const LoginAndRegisterContainer = styled.div`
  padding: 40px 0;
  position: relative;
  top: 70px;
  
  h1 {
    text-align: center;
    font-size: 1.125em;
    font-weight: ${props => props.theme.weight.semiBold};
    margin: 0 0 20px 0;
    line-height: 1.25em;
  }

  h4 {
    font-weight: ${props => props.theme.weight.semiBold};
    margin: 0 0 15px 0;
  }

  a {
    color ${props => props.theme.brandClassicBlueColor};
    font-weight: ${props => props.theme.weight.semiBold};

    &:hover {
      text-decoration: underline;
    }
  }

  .textCopyright {
    text-align: center;
    margin: 30px auto 0 auto;
    font-size: 0.875em;

    a {
      text-decoration: underline;
    }
  }

  .forgetPassword {
    margin: 12px 0 0 0;
    display: block;
    text-align: right;
    font-size: 0.875em;
  }

  .emailVerificaction {
    display: block;
    margin: 25px 0 -15px 0;
    text-align: center;
    font-size: 1em;
    font-weight: ${props => props.theme.weight.semiBold};
  }

  .loginAndRegisterQuestionContent {
    margin: 40px auto 0 auto;
    
    a {
      margin: 10px 0 0 0;
      display: block;
      text-align: center;
    }
  }

  @media(min-width:1200px) {
    .textCopyright, button {
      width: 370.52px;
    }

    .textCopyright {
      margin: 50px auto 0 auto;
    }

    button {
      margin: 20px auto 0 auto;
      display: block;
    }
  }
`;

export const RegisterAnswerContent = styled.div`
  border-top: 1px solid ${props => props.theme.gray400Color};
  margin: 50px 0 0 0;
  padding: 50px 0 0 0;

  p {
    font-weight: ${props => props.theme.weight.medium};
    margin: 0 0 20px 0;
    text-align: center;
  }
`
