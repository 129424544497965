export const totalTimestampCalculator = (timestamp, setTimestamp) => {
  timestamp.dateFrom &&
    timestamp.timeFrom &&
    setTimestamp({ from: timestamp.dateFrom + timestamp.timeFrom });

  timestamp.dateFrom &&
    !timestamp.timeFrom &&
    setTimestamp({ from: timestamp.dateFrom });

  timestamp.dateTo &&
    timestamp.timeTo &&
    setTimestamp({ to: timestamp.dateTo + timestamp.timeTo });

  timestamp.dateTo &&
    !timestamp.timeTo &&
    setTimestamp({ to: timestamp.dateTo });
};
