import styled from 'styled-components';

export const DesktopGrid = styled.div`
  margin: 0 0 70px 0;

  @media(min-width:1200px) {
    margin: 0 0 120px 0;
    display: grid;
    grid-template-columns: 70% 1fr;
  }
`
