import React from 'react'
import HeaderLanding from './../components/general/header/HeaderLanding';
import MainError404 from './../components/errorComps/MainError404'

export default function Error404() {
  return (
    <>
      <HeaderLanding buttonLogin="none" />
      <MainError404 />
    </>
  )
}
