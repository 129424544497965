import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./../../styles/generalStyles";
import { BoxGeneral, Table } from "./../../styles/boxesGeneral";
import { Text, Title4 } from "./../../styles/textGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { ReactComponent as ArrowSVG } from "./../../icons/arrow-left.svg";
import { useFirestore } from "reactfire";
import MainModal from "../modal/MainModal";
import UploadDataStatus from "../general/cards/UploadDataStatus";
import ConfirmUploadData from "../general/cards/ConfirmUploadData"
import Spinner from "../spinner/MainSpinner";

//Import algorithms
import { capitalize } from "../../algorithms/general/capitalize";
import SupportNotice from "../general/cards/SupportNotice"


import { verifyPackageLimitAll } from './algorithms/verifyPackageLimit'

export default function TabResumeReceptors({
  setDataPackaging,
  setComplete,
  needSupport,
  setNeedSupport,
}) {
  const [t] = useTranslation("main");
  const db = useFirestore();
  const navigate = useNavigate()
  const [packageLimit, setPackageLimit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendedStatus, setSendedStatus] = useState(null); //True=> Envio correcto / False=> Envio fallido/ null=> No se ha ejecutado el envio
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [modalSupportIsOpen, setModalSupportIsOpen] = useState(false);
  const [confirmStateSupp, setConfirmStateSupp] = useState(null);

  const { setDataOfService, dataOfService, currentUser, dataOfUser, setPackageInfo, packageInfo } = useContext(AppContext);
  const closeModal = () => {
    if (sendedStatus !== null || (confirmState | confirmStateSupp)) {
      setConfirmState(false);
      setConfirmStateSupp(null);
      if (sendedStatus) {
        setDataPackaging({
          amount: true,
          sender: false,
          receptor: false,
          content: false,
          upload: false,
        });
        setDataOfService({
          serviceType: null,
          amount: null,
          senderData: null,
          packetAndReceptorData: null,
        });
        setModalIsOpen(false);
        setComplete(false);
        navigate(`/new-package/${dataOfService.serviceType}?amount=${packageInfo.amount}`)
      } else {
        setModalIsOpen(false);
      }
    }
  };

  function closeModalConfirm(e) {
    setModalConfirmIsOpen(false);
  }

  function closeModalSupport(e) {
    if (confirmStateSupp !== null) {
      if (confirmStateSupp) {
        setDataPackaging({
          amount: true,
          sender: false,
          receptor: false,
          content: false,
          upload: false,
        });
        setDataOfService({
          serviceType: null,
          amount: null,
          senderData: null,
          packetAndReceptorData: null,
        });
        setModalSupportIsOpen(false);
        setConfirmStateSupp(false);
        navigate(`/new-package/${dataOfService.serviceType}?amount=${packageInfo.amount}`)
      }
    } else {
      setModalSupportIsOpen(false)
    }
  }

  useEffect(() => {
    const valueLimit = verifyPackageLimitAll(dataOfService.packetAndReceptorData, dataOfUser, dataOfService, setNeedSupport)
    setPackageLimit(valueLimit)
  }, [packageLimit])

  if (loading) return <Spinner />;
  return (
    <main className="base">
      <div>
        <BoxGeneral table>
          <div
            className="titleWithArrow"
            onClick={() => {
              setDataPackaging({ content: true });
              setComplete(false);
            }}
          >
            <ArrowSVG />
            <Title4>{t("resumenReceptors.tabResumenReseptorsTitle")}</Title4>
          </div>
          <div className="tableContent">
            <Table>
              <thead>
                <tr className="tableHead">
                  <th>{t("table.number")}</th>
                  <th>{t("table.ecommerceName")}</th>
                  <th>{t("table.name")}</th>
                  <th>{t("table.telephone")}</th>
                  <th>{t("table.email")}</th>
                  <th>{t("table.documentId")}</th>
                  <th>{t("table.address")}</th>
                  <th>{t("table.reference")}</th>
                  <th>{t("table.district")}</th>
                  <th>{t("table.province")}</th>
                  <th>{t("table.department")}</th>
                  <th>{t("table.description")}</th>
                  <th>{t("table.fragile")}</th>
                  <th>{t("table.value")}</th>
                  <th>{t("table.height")}</th>
                  <th>{t("table.width")}</th>
                  <th>{t("table.large")}</th>
                  <th>{t("table.weight")}</th>
                </tr>
                {dataOfService && dataOfService?.packetAndReceptorData &&
                  dataOfService.packetAndReceptorData.map((customer, index) => {
                    // console.log(customer)
                    return (
                      <tr key={customer?.name.concat(index + 1)}>
                        <th className="tableHead">{index + 1}</th>
                        <th>{customer.ecommerceName}</th>
                        <th>{customer.name}</th>
                        <th>{customer.phone}</th>
                        <th>{customer.email}</th>
                        <th>{customer.documentId}</th>
                        <th>{customer.address}</th>
                        <th>{customer.reference}</th>
                        <th>{customer.ubigeo?.desc_ubigeo_inei ? capitalize(customer.ubigeo.desc_ubigeo_inei) : "-"}</th>
                        <th>{customer.ubigeo?.desc_prov_inei ? capitalize(customer.ubigeo.desc_prov_inei) : "-"}</th>
                        <th>{customer.ubigeo?.desc_dep_inei ? capitalize(customer.ubigeo.desc_dep_inei) : "-"}</th>
                        <th>{customer.packetDescription}</th>
                        <th>{customer.fragile ? "Sí" : "No"}</th>
                        <th>{customer.value}</th>
                        <th>{customer.height}</th>
                        <th>{customer.width}</th>
                        <th>{customer.large}</th>
                        <th>{customer.weight}</th>
                      </tr>
                    );
                  })}
              </thead>
            </Table>
          </div>
        </BoxGeneral>
        <BoxGeneral onlyButtons buttonsTable>
          <Button
            terciary
            large
            onClick={() => {
              setDataPackaging({ content: true });
              setComplete(false);
            }}
          >
            {t("packet.modButton")}
          </Button>
          <Button
            primary
            large
            onClick={(e) => {
              !(dataOfUser.typeOfUser === "B2C") ?
                setModalConfirmIsOpen(true) :
                (
                  (dataOfUser.typeOfUser === "B2C") &&
                    (
                      dataOfService.serviceType === "same-day" ||
                      dataOfService.serviceType === "next-day"
                    ) && dataOfService.serviceType !== "province" &&
                    needSupport && packageLimit ?
                    setModalSupportIsOpen(true) :
                    (
                      dataOfUser.typeOfUser === "B2C") &&
                      dataOfService.serviceType === "province" ?
                      setPackageInfo({ existData: true }) :
                      setPackageInfo({ existData: true })
                )
            }}
          >
            {t("packet.nextButton")}
          </Button>
        </BoxGeneral>
      </div>

      <MainModal
        isOpen={modalSupportIsOpen}
        closeModal={closeModalSupport}
        isClose={closeModalSupport}
        background="#F8FAFD"
        width="auto"
        hegiht="auto"
        borderRadius="8px"
        fillColor="#262A2E"
      >
        <SupportNotice
          paddingModal="30px 60px"
          closeModalSupport={closeModalSupport}
          setConfirmStateSupp={setConfirmStateSupp}
          dataOfService={dataOfService}
          db={db}
          currentUser={currentUser}
          setLoading={setLoading}
          setSendedStatus={setSendedStatus}
          setDataOfService={setDataOfService}
          setComplete={setComplete}
          setDataPackaging={setDataPackaging}
          dataOfUser={dataOfUser}
          setPackageInfo={setPackageInfo}
          packageLimit={packageLimit}
          setNeedSupport={setNeedSupport}
          needSupport={needSupport}
          setModalSupportIsOpen={setModalSupportIsOpen}
        />
      </MainModal>

      <MainModal
        isOpen={modalConfirmIsOpen}
        closeModal={closeModalConfirm}
        isClose={closeModalConfirm}
        background="#F8FAFD"
        width="auto"
        hegiht="auto"
        borderRadius="8px"
        fillColor="#262A2E"
      >
        <ConfirmUploadData
          paddingModal="30px 60px"
          closeModalConfirm={closeModalConfirm}
          setConfirmState={setConfirmState}
          dataOfService={dataOfService}
          db={db}
          currentUser={currentUser}
          setLoading={setLoading}
          setSendedStatus={setSendedStatus}
          setDataOfService={setDataOfService}
          setComplete={setComplete}
          setDataPackaging={setDataPackaging}
          dataOfUser={dataOfUser}
          setPackageInfo={setPackageInfo}
          packageLimit={packageLimit}
          setModalConfirmIsOpen={setModalConfirmIsOpen}
        />
      </MainModal>

      {(sendedStatus !== null) && (confirmState || confirmStateSupp) && (
        <MainModal
          isOpen={true}
          closeModal={closeModal}
          isClose={closeModal}
          background="#F8FAFD"
          width="auto"
          height="auto"
          borderRadius="8px"
          fillColor="#262A2E"
        >
          <UploadDataStatus
            paddingModal="30px 60px"
            sendedStatus={sendedStatus}
            closeModal={closeModal}
            dataOfService={dataOfService}
            setPackageInfo={setPackageInfo}
            db={db}
            currentUser={currentUser}
            setLoading={setLoading}
            setSendedStatus={setSendedStatus}
            setDataOfService={setDataOfService}
            setComplete={setComplete}
            setDataPackaging={setDataPackaging}
            dataOfUser={dataOfUser}
          />
        </MainModal>
      )}
    </main>
  );
}
