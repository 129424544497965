import styled, { keyframes } from 'styled-components';

const Load = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

export const MainSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #F8FAFD;
  width: 100%;
  height: 100vh;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 30px;
  }

  .load {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    margin: 20px;
  }

  .load div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 4px;
    border: 4px solid #174F82;
    border-radius: 50%;
    animation: ${Load} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #174F82 transparent transparent transparent;
  }

  .load div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .load div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .load div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
