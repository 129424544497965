import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalWrapper } from './../../../styles/generalStyles';
import { Text } from './../../../styles/textGeneral';
import qr from './../../../images/qr.png';
import {
  InputContainer,
  FormContainer
} from "./../../../styles/inputGeneral";
import { Button } from "./../../../styles/buttonGeneral";
import { MetodPlinYapeContainer } from './styles/sMetodPlinYape';
import { sendDataPayToDB } from '../../general/cards/algorithms/sendDataPayToDB'
import Spinner from '../../spinner/MainSpinner'

export default function MetodPlinYape({ 
  paddingModal, 
  totalPrice, 
  metod, 
  dataOfUser, 
  currentUser, 
  dataOfService, 
  db,
  closeModal,
  mounted,
  packageInfo,
  setPackageInfo
}) {
  const navigate = useNavigate()
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  if(loading) {
    <Spinner/>
  }

  return (
    <ModalWrapper paddingModal={paddingModal}>
      <h2>Pago con {metod} de S/ {totalPrice}</h2>
      <MetodPlinYapeContainer>
        <Text center>Escanea el código QR con tu celular</Text>
        <img src={qr} alt="Código QR" />
        <Text center className="paragraphTwoMetod">O agrega a <span>Servicio Puntual (990123654)</span> como contacto y realiza el pago.</Text>
        <Text center>Por último, ingresa el código de operación del pago para la verificación.</Text>
        <FormContainer>
          <InputContainer>
            <label htmlFor="CodigoDeVeficacion"></label>
            <input placeholder='Código de verificación' minlength={4} onChange={e => setCode(e.target.value)}/>
            <span className='standByValidation'>La validación puede tardar unos minutos</span>
          </InputContainer>
          <Button secondary large disabled={!(code.trim().length > 4)} onClick={(e) => {
            if(code.trim().length > 4) {
              sendDataPayToDB(
                e, 
                dataOfService, 
                db, 
                currentUser, 
                setLoading, 
                dataOfUser,
                code,
                totalPrice,
                metod,
                mounted,
                closeModal,
                navigate,
                setPackageInfo
              )
            }
          }}>Terminar</Button>
        </FormContainer>
      </MetodPlinYapeContainer>
    </ModalWrapper>
    /*  Luego <PaymentVerificationEmail /> */
  )
}
