import React from 'react';
import MainNewPackage from './../components/newPackage/MainNewPackage';

export default function NewPackage() {
  return (
    <>
      <MainNewPackage />
    </>
  )
}
