import styled from 'styled-components';

export const FooterLandingWaveContent = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
  overflow: hidden;

  .wave {
    width: 700px;
    position: absolute;
    left: 0;
  }

  @media(min-width:768px) {
    height: 120px;

    .wave {
      width: 1100px;
      top: -55px;
    }
  }

  @media(min-width:1200px) {
    height: 170px;

    .wave {
      width: 1700px;
      position: absolute;
      right: 0;
      left: 0;
      top: -80px;
    }
  }

  @media(min-width:1200px) {
    height: 200px;

    .wave {
      width: 2080px;
    }
  }

  @media(min-width:1500px) {
    height: 250px;

    .wave {
      width: 2280px;
    }
  }
`

export const FooterLandingContainer = styled.div`
  background : ${props => props.theme.brandClassicBlueColor};
  color: ${props => props.theme.backgroundColor};
  margin: -1px 0 0 0;

  h2, p {
    text-align: center;
  }

  h2 {
    padding: 70px 0 0 0;
    margin: 0 0 30px 0;
  }

  p {
    margin: 50px 0 20px 0;
    font-weight: ${props => props.theme.weight.medium};
  }

  svg {
    fill: ${props => props.theme.backgroundColor};
    margin: 0 15px 0 0;
    flex: 0 0 auto;
  }

  span {
    display: block;
    margin: 0 0 20px 0;

    &:last-child {
      margin: 0;
    }
  }

  .footerLandingDetailsContent {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
  }

  .footerLandingSocialIconsContent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    margin: 0 10% 100px 10%;

    svg {
      margin: 0;
      width: 40px;
    }
  }

  @media(min-width:1200px) {
    p {
      margin: 28px 0 30px 0;
      text-align: left;
    }
    
    .footerLandingFlexDesktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
    }

    .footerLandingSocialIconsContent {
      margin: 0 40% 100px 40%;
    }

    .footerLandingSocialIconsTitle {
      text-align: center;
    }
  }
`;

export const FooterLandingMapContent = styled.div`
  width: 100%;
  height: 212px;
  background: ${props => props.theme.blue000Color};
  border-radius: 8px;
  margin: 30px 0 0 0;

  iframe {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border: none;
    border-radius: 8px;
  }
`;

export const FooterLandingCopyrightContent = styled.div`
  background: ${props => props.theme.blackColor};
  font-size: 0.50em;

  p {
    padding: 10px 0;
    margin: 0;
    text-align: center;
  }

  @media(min-width:1200px) {
    font-size: 0.750em;

    p {
      padding: 12px 0;
    }
  }
`;
