import styled, { css } from 'styled-components'

export const Button = styled.button`
  text-transform: uppercase;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  outline: none;
  font-weight: ${props => props.theme.weight.semiBold};
  font-family: ${props => props.theme.secondaryFont};
  font-size: 0.938em;
  transition: .2s;
  will-change: transform;
  width: 100%;

  &:disabled {
    background: ${props => props.theme.gray400Color};
    color: ${props => props.theme.gray100Color};

    &:hover {
      background: ${props => props.theme.gray500Color};
      color: ${props => props.theme.gray200Color};
    }
  }

  ${props => props.primary && css`
    background: ${props => props.theme.brandClassicBlueColor};
    color: ${props => props.theme.backgroundColor};

    &:hover {
      background: #083257;
    }
  `}

  ${props => props.secondary && css`
    background: transparent;
    color: ${props => props.theme.brandClassicBlueColor};
    border: 1px solid ${props => props.theme.brandClassicBlueColor};

    &:hover {
      background: #EEF3F9;
      color: #083257;
      border: 1px solid #083257;
    }
  `}

  ${props => props.terciary && css`
    background: ${props => props.theme.blue100Color};
    color: ${props => props.theme.brandClassicBlueColor};

    &:hover {
      background:  ${props => props.theme.blue200Color};
    }
  `}

  ${props => props.white && css`
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.blackColor};

    &:hover {
      background: #EBEBEB;
    }
  `}

  ${props => props.large && css`
    height: 40px
  `}

  ${props => props.medium && css`
    height: 38px
  `}

  ${props => props.small && css`
    height: 30px;
    font-size: 0.878em;
    padding: 0 10px;
  `}
`