import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { capitalize } from "./capitalize";
import { returnShipmentState } from "./returnShipmentState";

export const createXlsxFromData = (data, petition, userType) => {
  if (!Array.isArray(data) || data.length === 0) return false;
  let wb = XLSX.utils.book_new();
  var wsData = [];

  if (userType === "sender") {
    //Esta condición es para que el usuario recupere sus envíos
    wb.Props = {
      Title: "Resumen de envíos",
      Subject: "Organización de resumen de envíos",
      Author: "Servicio Puntual de Mensajería SAC",
      CreatedDate: new Date(),
      Company: "Servicio Puntual de Mensajería SAC",
      Manager: "Fernando Beizaga (thezeivier)",
    };
    //Cabeceras de columnas
    wsData[0] = [
      "Nombre de E-commerce",
      "Destinatario",
      "Celular",
      "Email",
      "Dirección",
      "Referencia",
      "Distrito",
      "Provincia",
      "Departamento",
      "Descripción",
      "Número de remito",
      "Fecha de generación",
      "Tracking ID",
      "Estado",
    ];
    data.map((elem) => {
      wsData.push([
        capitalize(elem.ecommerceName),
        capitalize(elem.name),
        capitalize(elem.phone),
        capitalize(elem.email),
        capitalize(elem.address),
        capitalize(elem.reference),
        capitalize(elem.desc_ubigeo_inei),
        capitalize(elem.desc_prov_inei),
        capitalize(elem.desc_dep_inei),
        capitalize(elem.packetDescription),
        elem.senderCode,
        elem.sendNumberGenenerateDate.toDate().toLocaleString("es-ES"),
        elem.receptorCode,
        capitalize(
          returnShipmentState(elem.status, elem.statusDeterminant, userType)
        ),
      ]);
    });
  } else if (userType === "admin" && petition === "allUsers") {
    wb.Props = {
      Title: "Log de Usuarios",
      Subject: "Datos recopilados por usuario",
      Author: "Servicio Puntual de Mensajería SAC",
      CreatedDate: new Date(),
      Company: "Servicio Puntual de Mensajería SAC",
      Manager: "Fernando Beizaga (thezeivier)",
    };
    //Cabeceras de columnas
    wsData[0] = [
      "uid",
      "Nombre registrado",
      "DNI",
      "Nombre (Obtenido)",
      "Tel. personal",
      "Correo personal",
      "Nombre de la empresa o marca",
      "Tel. de la empresa",
      "Correo corporativo",
      "Dirección",
      "Referencia",
      "Distrito",
      "Provincia",
      "Ubigeo (INEI)",
      "Departamento",
      "Fecha y hora de registro",
      "Lista de logins",
      "Cuenta Obeservada",
      "Tipo de usuario",
      "RUC",
      "Nombre RUC (obtenido)",
      "Condición RUC (obtenido)",
      "Departamento RUC (obtenido)",
      "Provincia RUC (obtenido)",
      "Distrito RUC (obtenido)",
      "Dirección RUC (obtenido)",
      "Estado RUC (obtenido)",
    ];
    data.map((elem) => {
      wsData.push([
        elem.uid,
        elem.name?.prevName.toUpperCase(),
        elem.DNI,
        elem.name.firstName &&
          elem.name.lastName &&
          `${elem.name.firstName} ${elem.name.lastName}`.toUpperCase(),
        elem.personalPhone,
        elem.email,
        elem.bussinesName?.toUpperCase(),
        elem.bussinesPhone,
        elem.bussinesEmail,
        elem.address?.toUpperCase(),
        elem.reference?.toUpperCase(),
        elem.ubigeo?.desc_ubigeo_inei.toUpperCase(),
        elem.ubigeo?.desc_prov_inei.toUpperCase(),
        elem.ubigeo?.desc_dep_inei.toUpperCase(),
        elem.ubigeo?.cod_ubigeo_inei,
        elem.metadata &&
          new Date(elem.metadata.createdAt).toLocaleString("es-ES"),
        elem.metadata?.listOfLogins.toString(),
        (elem.observed === true || elem.observed === false) && elem.observed,
        elem.typeOfUser === "B2B" || elem.typeOfUser === "B2C"
          ? elem.typeOfUser
          : "ADMIN",
        elem.RUC && elem.RUC,
        elem.rucRecoveredData?.nombre,
        elem.rucRecoveredData?.condicion,
        elem.rucRecoveredData?.departamento,
        elem.rucRecoveredData?.provincia,
        elem.rucRecoveredData?.distrito,
        elem.rucRecoveredData?.direccion,
        elem.rucRecoveredData?.estado,
      ]);
    });
  } else if (userType === "admin" && petition === "segmentOfPackets") {
    wb.Props = {
      Title: "Registro de pedidos",
      Subject: "Segmento de pedidos",
      Author: "Servicio Puntual de Mensajería SAC",
      CreatedDate: new Date(),
      Company: "Servicio Puntual de Mensajería SAC",
      Manager: "Fernando Beizaga (thezeivier)",
    };
    //Cabeceras de columnas
    wsData[0] = [
      "N° de pedido (unitario)",
      "N° de remito (bloque)",
      "Nombre de E-commerce",
      "Nombre (receptor)",
      "Celular (receptor)",
      "Email (receptor)",
      "DNI (receptor)",
      "Dirección (receptor)",
      "Referencia (receptor)",
      "Departamento (receptor)",
      "Provincia (receptor)",
      "Distrito (receptor)",
      "Ubigeo (receptor)",
      "Uid (Cliente)",
      "Nombre (Cliente)",
      "Tel. (Cliente)",
      "Dirección (Cliente)",
      "Referencia (Cliente)",
      "Departamento (Cliente)",
      "Provincia (Cliente)",
      "Distrito (Cliente)",
      "Ubigeo (Cliente)",
      "Tipo de cliente",
      "Contenido del paquete",
      "Alto (cm)",
      "Ancho (cm)",
      "Largo (cm)",
      "Peso (kg)",
      "Frágil",
      "N° de paquetes",
      "Valor del contenido (S/.)",
      "Tipo de servicio",
      "Hora de Generación",
      "Hora de Confirmación",
      "Estado de Gestión",
      "Determinante de Estado",
      "Estado de pago",
      "Código de barras",
      "URL de cargo 1",
      "URL de cargo 2",
      "URL de cargo 3",
    ];
    data.map((elem) => {
      wsData.push([
        elem.receptorCode && elem.receptorCode,
        elem.senderCode && elem.senderCode,
        elem.ecommerceName && elem.ecommerceName,
        elem.name && elem.name,
        elem.phone && elem.phone,
        elem.email && elem.email,
        elem.documentId && elem.documentId,
        elem.address && elem.address,
        elem.reference && elem.reference,
        elem.desc_dep_inei,
        elem.desc_prov_inei,
        elem.desc_ubigeo_inei,
        elem.cod_ubigeo_inei,
        elem.senderUid,
        elem.nameOfSender && elem.nameOfSender,
        elem.phoneOfSender && elem.phoneOfSender,
        elem.addressOfSender && elem.addressOfSender,
        elem.referenceOfSender && elem.referenceOfSender,
        elem.desc_dep_ineiOfSender && elem.desc_dep_ineiOfSender,
        elem.desc_prov_ineiOfSender && elem.desc_prov_ineiOfSender,
        elem.desc_ubigeo_ineiOfSender && elem.desc_ubigeo_ineiOfSender,
        elem.cod_ubigeo_ineiOfSender && elem.cod_ubigeo_ineiOfSender,
        elem.typeOfUser && elem.typeOfUser,
        elem.packetDescription,
        elem.height,
        elem.width,
        elem.large,
        elem.weight,
        elem.fragile,
        (elem.numberOfPackets && elem.numberOfPackets) || 1,
        elem.valueOfpacket && elem.valueOfpacket,
        elem.serviceType && elem.serviceType,
        elem.sendNumberGenenerateDate?.toDate(),
        elem.orderConfirmationDate?.toDate(),
        elem.status,
        elem.statusDeterminant,
        elem.payState,
        elem.envionumero && elem.envionumero,
        elem.url_cargo1 && elem.url_cargo1,
        elem.url_cargo2 && elem.url_cargo2,
        elem.url_cargo3 && elem.url_cargo3,
      ]);
    });
  } else if (userType === "admin" && petition.startsWith("REM-")) {
    wb.Props = {
      Title: "Registro de pedidos por número de remito",
      Subject: petition,
      Author: "Servicio Puntual de Mensajería SAC",
      CreatedDate: new Date(),
      Company: "Servicio Puntual de Mensajería SAC",
      Manager: "Fernando Beizaga (thezeivier)",
    };
    //Cabeceras de columnas
    wsData[0] = [
      "N° de pedido (unitario)",
      "N° de remito (bloque)",
      "Nombre de E-commerce",
      "Nombre (receptor)",
      "Celular (receptor)",
      "Email (receptor)",
      "DNI (receptor)",
      "Dirección (receptor)",
      "Referencia (receptor)",
      "Departamento (receptor)",
      "Provincia (receptor)",
      "Distrito (receptor)",
      "Ubigeo (receptor)",
      "Uid (Cliente)",
      "Nombre (Cliente)",
      "Tel. (Cliente)",
      "Dirección (Cliente)",
      "Referencia (Cliente)",
      "Departamento (Cliente)",
      "Provincia (Cliente)",
      "Distrito (Cliente)",
      "Ubigeo (Cliente)",
      "Tipo de cliente",
      "Contenido del paquete",
      "Alto (cm)",
      "Ancho (cm)",
      "Largo (cm)",
      "Peso (kg)",
      "Frágil",
      "N° de paquetes",
      "Valor del contenido (S/.)",
      "Tipo de servicio",
      "Hora de Generación",
      "Hora de Confirmación",
      "Uid del confirmador",
      "Estado de Gestión",
      "Determinante de Estado",
      "Estado de pago",
      "Código de barras",
    ];
    data.map((elem) => {
      wsData.push([
        elem.data.receptorCode && elem.data.receptorCode,
        elem.data.senderCode && elem.data.senderCode,
        elem.data.ecommerceName && elem.data.ecommerceName,
        elem.data.name && elem.data.name,
        elem.data.phone && elem.data.phone,
        elem.data.email && elem.data.email,
        elem.data.documentId && elem.data.documentId,
        elem.data.address && elem.data.address,
        elem.data.reference && elem.data.reference,
        elem.data?.desc_dep_inei,
        elem.data?.desc_prov_inei,
        elem.data?.desc_ubigeo_inei,
        elem.data?.cod_ubigeo_inei,
        elem.data.senderUid,
        elem.data.nameOfSender && elem.data.nameOfSender,
        elem.data.phoneOfSender && elem.data.phoneOfSender,
        elem.data.addressOfSender && elem.data.addressOfSender,
        elem.data.referenceOfSender && elem.data.referenceOfSender,
        elem.data.desc_dep_ineiOfSender && elem.data.desc_dep_ineiOfSender,
        elem.data.desc_prov_ineiOfSender && elem.data.desc_prov_ineiOfSender,
        elem.data.desc_ubigeo_ineiOfSender &&
          elem.data.desc_ubigeo_ineiOfSender,
        elem.data.cod_ubigeo_ineiOfSender && elem.data.cod_ubigeo_ineiOfSender,
        elem.data.typeOfUser && elem.data.typeOfUser,
        elem.data?.packetDescription,
        elem.data?.height,
        elem.data?.width,
        elem.data?.large,
        elem.data?.weight,
        elem.data?.fragile,
        (elem.data.numberOfPackets && elem.data.numberOfPackets) || 1,
        elem.data.valueOfpacket && elem.data.valueOfpacket,
        elem.data.serviceType && elem.data.serviceType,
        elem.data.sendNumberGenenerateDate?.toDate(),
        elem.data.orderConfirmationDate?.toDate(),
        elem.data?.confirmerUid,
        elem.data?.status,
        elem.data?.statusDeterminant,
        elem.data?.payState,
        elem.data?.envionumero,
      ]);
    });
  }

  wb.SheetNames.push("Sheet 1");
  let ws = XLSX.utils.aoa_to_sheet(wsData);
  wb.Sheets["Sheet 1"] = ws;
  let wbOut = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
  let blob = new Blob([sheetToArrayBufer(wbOut)], {
    type: "application/octet-stream",
  });

  saveAs(
    blob,
    `${petition}_${new Date().getDate()}_${
      new Date().getMonth() + 1
    }_${new Date().getFullYear()}_${new Date().getHours() + 1}_${
      new Date().getMinutes() + 1
    }.xlsx`
  );
};

const sheetToArrayBufer = (s) => {
  var buf = new ArrayBuffer(s.length); //Convierte s a arrayBuffer
  var view = new Uint8Array(buf); //crea uint8array como visor
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //Convierte a octal
  return buf;
};
