import React from "react";
import { useTranslation } from "react-i18next";
import { BoxBorder } from "./../../styles/boxesGeneral";
import {
  BoxStatusContent,
  RecentShipmentDetails,
  RecentShipmentContainer,
} from "./styles/sRecentShipment";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";

export default function RecentShipment({
  name,
  address,
  serviceType,
  receptorCode,
  status,
  statusDeterminant,
  visitDate,
  desc_ubigeo_inei,
  desc_prov_inei,
  desc_dep_inei,
}) {
  const [t] = useTranslation("main");
  return (
    <li>
      <BoxBorder>
        <RecentShipmentContainer>
          <RecentShipmentDetails>
            <h4>
              {t("start.packetTo", {
                name: name ? capitalize(name) : "Cargando",
                address: address ? capitalize(address) : "Cargando",
                district: desc_ubigeo_inei
                  ? capitalize(desc_ubigeo_inei)
                  : "Cargando",
                province: desc_prov_inei
                  ? capitalize(desc_prov_inei)
                  : "Cargando",
                department: desc_dep_inei
                  ? capitalize(desc_dep_inei)
                  : "Cargando",
              })}
            </h4>
            <div className="recentShipmentDetailsContent">
              <div className="recentShipmentDetail">
                <p>Prioridad: </p>
                {serviceType === "same-day" || serviceType === "next-day" ? (
                  <span>Alta</span>
                ) : (
                  <span>Media</span>
                )}
              </div>
              <div className="recentShipmentDetail">
                <p>Tracking ID: </p>
                <span>{receptorCode ? receptorCode : "Procesando..."}</span>
              </div>
              <div className="recentShipmentDetail">
                <p>Fecha de entrega: </p>
                <span>{visitDate ? visitDate : "Aún no entregado"}</span>
              </div>
            </div>
          </RecentShipmentDetails>
          {capitalize(status) === "No Asignado" ? (
            <BoxStatusContent red>
              <p>
                {statusDeterminant
                  ? capitalize(statusDeterminant)
                  : "Falta Pagar"}
              </p>
            </BoxStatusContent>
          ) : capitalize(status) === "Para Despacho" ||
            capitalize(status) === "En Gestion" ? (
            <BoxStatusContent yellow>
              <p>Pendiente</p>
            </BoxStatusContent>
          ) : capitalize(status) === "Entregado" ? (
            <BoxStatusContent green>
              <p>{capitalize(status)}</p>
            </BoxStatusContent>
          ) : capitalize(status) === "Motivo" ? (
            <BoxStatusContent blue>
              <p>{capitalize(statusDeterminant)}</p>
            </BoxStatusContent>
          ) : (
            <BoxStatusContent red>
              <p>{capitalize(statusDeterminant)}</p>
            </BoxStatusContent>
          )}
        </RecentShipmentContainer>
      </BoxBorder>
    </li>
  );
}
