import React, { useState } from "react";
import { Text } from "./../../../styles/textGeneral";
import { ModalWrapper } from "./../../../styles/generalStyles";
import { Button } from "./../../../styles/buttonGeneral";
import MainSpinner from "../../spinner/MainSpinner";

//Import firebase utils
import { useFirestore } from "reactfire";

//Import ALGORITHMS
import { changeTypeOfUser } from "./algorithms/changeTypeOfUser";

export default function ModifyTypeUser({
  paddingModal,
  userToChangeType,
  closeModal,
  setUserToChangeType,
}) {
  const db = useFirestore();

  return (
    <ModalWrapper paddingModal={paddingModal}>
      <h2>Modificar tipo de usuario</h2>
      <Text center>Actualiza el tipo de usuario</Text>
      <Button
        secondary
        medium
        onClick={() => {
          changeTypeOfUser(db, userToChangeType, setUserToChangeType);
          closeModal();
        }}
      >
        {userToChangeType?.typeOfUser === "B2B" ? "B2C" : "B2B"}
      </Button>
    </ModalWrapper>
  );
}
