import {
  writeBatch,
  doc,
  collection,
  serverTimestamp,
} from "firebase/firestore";

export const sendDataToDB = async (
  e,
  dataOfService,
  db,
  currentUser,
  setLoading,
  setSendedStatus,
  dataOfUser
) => {
  setLoading(true);
  try {
    e.preventDefault();
    const batch = writeBatch(db);
    const listOfReferences = [];
    dataOfService.packetAndReceptorData.map((receptor) => {
      let localRef = doc(collection(db, "receptors"));
      listOfReferences.push(localRef);
      return batch.set(
        localRef,
        {
          ecommerceName: receptor.ecommerceName,
          name: receptor.name,
          phone: receptor.phone,
          email: receptor.email,
          documentId: receptor.documentId,
          address: receptor.address,
          reference: receptor.reference,
          packetDescription: receptor.packetDescription,
          height: receptor.height,
          large: receptor.large,
          width: receptor.width,
          weight: receptor.weight,
          fragile: receptor.fragile,
          numberOfPackets: receptor.numberOfPackets || 1,
          valueOfpacket: receptor.value,
          optionalComment: receptor.optionalComment || null,
          cod_dep_inei: receptor.ubigeo.cod_dep_inei,
          cod_prov_inei: receptor.ubigeo.cod_prov_inei,
          cod_ubigeo_inei: receptor.ubigeo.cod_ubigeo_inei,
          desc_dep_inei: receptor.ubigeo.desc_dep_inei,
          desc_prov_inei: receptor.ubigeo.desc_prov_inei,
          desc_ubigeo_inei: receptor.ubigeo.desc_ubigeo_inei,
          serviceType: dataOfService.serviceType,
          vistDate: null,
          sendNumberGenenerateDate: serverTimestamp(),
          orderConfirmationDate: null,
          receptorCode: null,
          //Sender data
          senderUid: currentUser.uid,
          senderCode: null,
          addressOfSender: dataOfService.senderData.address,
          nameOfSender: dataOfService.senderData.name,
          phoneOfSender: dataOfService.senderData.phone,
          referenceOfSender: dataOfService.senderData.reference,
          cod_ubigeo_ineiOfSender: dataOfService.senderData.ubigeo.cod_ubigeo_inei,
          desc_dep_ineiOfSender: dataOfService.senderData.ubigeo.desc_dep_inei,
          desc_prov_ineiOfSender: dataOfService.senderData.ubigeo.desc_prov_inei,
          desc_ubigeo_ineiOfSender: dataOfService.senderData.ubigeo.desc_ubigeo_inei,
          typeOfUser: dataOfUser.typeOfUser,
        },
        { merge: true }
      );
    });

    batch.set(
      doc(collection(db, "senders")),
      {
        address: dataOfService.senderData.address,
        documentId: dataOfService.senderData.documentId,
        documentType: dataOfService.senderData.documentType,
        name: dataOfService.senderData.name,
        phone: dataOfService.senderData.phone,
        reference: dataOfService.senderData.reference,
        cod_dep_inei: dataOfService.senderData.ubigeo.cod_dep_inei,
        cod_prov_inei: dataOfService.senderData.ubigeo.cod_prov_inei,
        cod_ubigeo_inei: dataOfService.senderData.ubigeo.cod_ubigeo_inei,
        desc_dep_inei: dataOfService.senderData.ubigeo.desc_dep_inei,
        desc_prov_inei: dataOfService.senderData.ubigeo.desc_prov_inei,
        desc_ubigeo_inei: dataOfService.senderData.ubigeo.desc_ubigeo_inei,
        serviceType: dataOfService.serviceType,
        senderUid: currentUser.uid,
        sendNumberGenenerateDate: serverTimestamp(),
        orderConfirmationDate: null,
        transactionCode: null,
        listOfReferences,
        senderCode: null,
        typeOfUser: dataOfUser.typeOfUser
      },
      { merge: true }
    );

    await batch
      .commit()
      .then(() => {
        console.log("perfect upload");
        setLoading(false);
        setSendedStatus(true);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setSendedStatus(false);
      });
  } catch (err) {
    console.error(err.message);
    setLoading(false);
    setSendedStatus(false);
  }
};
