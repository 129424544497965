import React, { useContext } from "react";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { SolutionCardContainer } from "./styles/sSolutionCard";
import { Text } from "./../../styles/textGeneral";

export default function SolutionCard({
  title,
  description,
  image,
  route,
  setDataPackaging,
  mounted
}) {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const { setDataOfService, dataOfService } = useContext(AppContext);

  const changeService = () => {
    if (route !== dataOfService.serviceType) {
      localStorage.setItem("serviceType", route);
      navigate(`/new-package/${route}`, { replace: true });
      setDataOfService({ serviceType: route });
      if (dataOfService.packetAndReceptorData) {
        mounted.current && setDataPackaging({
          solutionCard: false,
          amount: false,
          sender: true,
          receptor: false,
          content: false,
          upload: false,
        });
      }
    }else{
      mounted.current && setDataPackaging({
        solutionCard: false,
        amount: true,
        sender: false,
        receptor: false,
        content: false,
        upload: false,
      })
    }
  };

  return (
    <li>
      <SolutionCardContainer
        onClick={changeService}
        className={
          localStorage.getItem("serviceType") === route
            ? "active"
            : localStorage.getItem("serviceType") === null &&
              route === "next-day" &&
              "active"
        }
      >
        <div className="solutionCardContent">
          <div className="imageSolutionContent">{image}</div>
          <h3>{title}</h3>
          <Text>{description}</Text>
          <div className="buttonSolutionContent">
            {t("solutions.generalButtonSolution")}
          </div>
        </div>
      </SolutionCardContainer>
    </li>
  );
}
