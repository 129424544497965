import styled from 'styled-components';

export const StatisticCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1.375em;
    font-weight: ${props => props.theme.weight.semiBold};
    margin: 0 0 
    20px 0;
  }

  p {
    font-size: 0.875em;
    color: ${props => props.theme.brandGrayColor};
  }
  
  .numberStatStatisticCard {
    display: flex;
    align-items: center;
  }

  .cercleStatisticCardContent {
    background: ${props => props.theme.brandClassicBlueColor};
    border-radius: 50px;
    margin: 0 15px 0 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: ${props => props.theme.brandWhiteColor};
    }
  }

  .statStaticCardSvg {
    fill: ${props => props.theme.brandGrayColor};
  }
`;
