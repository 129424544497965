import { collection, query, getDocs } from "firebase/firestore";
import { createXlsxFromData } from "../../../algorithms/general/createXlsxFromData";

export const downloadAllUsers = async (db) => {
  const querySnapshot = await getDocs(query(collection(db, "users")));
  let listOfUsers = [];
  querySnapshot.docs.map((u) => {
    listOfUsers.push(u.data());
  });
  createXlsxFromData(listOfUsers, "allUsers", "admin");
};
