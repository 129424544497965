export const provinces = [
  { province: "CHACHAPOYAS", deptId: 0, provinceIndex: 0 },
  { province: "BAGUA", deptId: 0, provinceIndex: 1 },
  { province: "BONGARA", deptId: 0, provinceIndex: 2 },
  { province: "CONDORCANQUI", deptId: 0, provinceIndex: 3 },
  { province: "LUYA", deptId: 0, provinceIndex: 4 },
  { province: "RODRIGUEZ DE MENDOZA", deptId: 0, provinceIndex: 5 },
  { province: "UTCUBAMBA", deptId: 0, provinceIndex: 6 },
  { province: "HUARAZ", deptId: 1, provinceIndex: 7 },
  { province: "AIJA", deptId: 1, provinceIndex: 8 },
  { province: "ANTONIO RAYMONDI", deptId: 1, provinceIndex: 9 },
  { province: "ASUNCION", deptId: 1, provinceIndex: 10 },
  { province: "BOLOGNESI", deptId: 1, provinceIndex: 11 },
  { province: "CARHUAZ", deptId: 1, provinceIndex: 12 },
  { province: "CARLOS FERMIN FITZCARRALD", deptId: 1, provinceIndex: 13 },
  { province: "CASMA", deptId: 1, provinceIndex: 14 },
  { province: "CORONGO", deptId: 1, provinceIndex: 15 },
  { province: "HUARI", deptId: 1, provinceIndex: 16 },
  { province: "HUARMEY", deptId: 1, provinceIndex: 17 },
  { province: "HUAYLAS", deptId: 1, provinceIndex: 18 },
  { province: "MARISCAL LUZURIAGA", deptId: 1, provinceIndex: 19 },
  { province: "OCROS", deptId: 1, provinceIndex: 20 },
  { province: "PALLASCA", deptId: 1, provinceIndex: 21 },
  { province: "POMABAMBA", deptId: 1, provinceIndex: 22 },
  { province: "RECUAY", deptId: 1, provinceIndex: 23 },
  { province: "SANTA", deptId: 1, provinceIndex: 24 },
  { province: "SIHUAS", deptId: 1, provinceIndex: 25 },
  { province: "YUNGAY", deptId: 1, provinceIndex: 26 },
  { province: "ABANCAY", deptId: 2, provinceIndex: 27 },
  { province: "ANDAHUAYLAS", deptId: 2, provinceIndex: 28 },
  { province: "ANTABAMBA", deptId: 2, provinceIndex: 29 },
  { province: "AYMARAES", deptId: 2, provinceIndex: 30 },
  { province: "COTABAMBAS", deptId: 2, provinceIndex: 31 },
  { province: "CHINCHEROS", deptId: 2, provinceIndex: 32 },
  { province: "GRAU", deptId: 2, provinceIndex: 33 },
  { province: "AREQUIPA", deptId: 3, provinceIndex: 34 },
  { province: "CAMANA", deptId: 3, provinceIndex: 35 },
  { province: "CARAVELI", deptId: 3, provinceIndex: 36 },
  { province: "CASTILLA", deptId: 3, provinceIndex: 37 },
  { province: "CAYLLOMA", deptId: 3, provinceIndex: 38 },
  { province: "CONDESUYOS", deptId: 3, provinceIndex: 39 },
  { province: "ISLAY", deptId: 3, provinceIndex: 40 },
  { province: "LA UNION", deptId: 3, provinceIndex: 41 },
  { province: "HUAMANGA", deptId: 4, provinceIndex: 42 },
  { province: "CANGALLO", deptId: 4, provinceIndex: 43 },
  { province: "HUANCA SANCOS", deptId: 4, provinceIndex: 44 },
  { province: "HUANTA", deptId: 4, provinceIndex: 45 },
  { province: "LA MAR", deptId: 4, provinceIndex: 46 },
  { province: "LUCANAS", deptId: 4, provinceIndex: 47 },
  { province: "PARINACOCHAS", deptId: 4, provinceIndex: 48 },
  { province: "PAUCAR DEL SARA SARA", deptId: 4, provinceIndex: 49 },
  { province: "SUCRE", deptId: 4, provinceIndex: 50 },
  { province: "VICTOR FAJARDO", deptId: 4, provinceIndex: 51 },
  { province: "VILCAS HUAMAN", deptId: 4, provinceIndex: 52 },
  { province: "CAJAMARCA", deptId: 5, provinceIndex: 53 },
  { province: "CAJABAMBA", deptId: 5, provinceIndex: 54 },
  { province: "CELENDIN", deptId: 5, provinceIndex: 55 },
  { province: "CHOTA ", deptId: 5, provinceIndex: 56 },
  { province: "CONTUMAZA", deptId: 5, provinceIndex: 57 },
  { province: "CUTERVO", deptId: 5, provinceIndex: 58 },
  { province: "HUALGAYOC", deptId: 5, provinceIndex: 59 },
  { province: "JAEN", deptId: 5, provinceIndex: 60 },
  { province: "SAN IGNACIO", deptId: 5, provinceIndex: 61 },
  { province: "SAN MARCOS", deptId: 5, provinceIndex: 62 },
  { province: "SAN MIGUEL", deptId: 5, provinceIndex: 63 },
  { province: "SAN PABLO", deptId: 5, provinceIndex: 64 },
  { province: "SANTA CRUZ", deptId: 5, provinceIndex: 65 },
  { province: "CALLAO", deptId: 6, provinceIndex: 66 },
  { province: "CUSCO", deptId: 7, provinceIndex: 67 },
  { province: "ACOMAYO", deptId: 7, provinceIndex: 68 },
  { province: "ANTA", deptId: 7, provinceIndex: 69 },
  { province: "CALCA", deptId: 7, provinceIndex: 70 },
  { province: "CANAS", deptId: 7, provinceIndex: 71 },
  { province: "CANCHIS", deptId: 7, provinceIndex: 72 },
  { province: "CHUMBIVILCAS", deptId: 7, provinceIndex: 73 },
  { province: "ESPINAR", deptId: 7, provinceIndex: 74 },
  { province: "LA CONVENCION", deptId: 7, provinceIndex: 75 },
  { province: "PARURO", deptId: 7, provinceIndex: 76 },
  { province: "PAUCARTAMBO", deptId: 7, provinceIndex: 77 },
  { province: "QUISPICANCHI", deptId: 7, provinceIndex: 78 },
  { province: "URUBAMBA", deptId: 7, provinceIndex: 79 },
  { province: "HUANCAVELICA", deptId: 8, provinceIndex: 80 },
  { province: "ACOBAMBA", deptId: 8, provinceIndex: 81 },
  { province: "ANGARAES", deptId: 8, provinceIndex: 82 },
  { province: "CASTROVIRREYNA", deptId: 8, provinceIndex: 83 },
  { province: "CHURCAMPA", deptId: 8, provinceIndex: 84 },
  { province: "HUAYTARA", deptId: 8, provinceIndex: 85 },
  { province: "TAYACAJA", deptId: 8, provinceIndex: 86 },
  { province: "HUANUCO", deptId: 9, provinceIndex: 87 },
  { province: "AMBO", deptId: 9, provinceIndex: 88 },
  { province: "DOS DE MAYO", deptId: 9, provinceIndex: 89 },
  { province: "HUACAYBAMBA", deptId: 9, provinceIndex: 90 },
  { province: "HUAMALIES", deptId: 9, provinceIndex: 91 },
  { province: "LEONCIO PRADO", deptId: 9, provinceIndex: 92 },
  { province: "MARAÑON", deptId: 9, provinceIndex: 93 },
  { province: "PACHITEA", deptId: 9, provinceIndex: 94 },
  { province: "PUERTO INCA", deptId: 9, provinceIndex: 95 },
  { province: "LAURICOCHA", deptId: 9, provinceIndex: 96 },
  { province: "YAROWILCA", deptId: 9, provinceIndex: 97 },
  { province: "ICA", deptId: 10, provinceIndex: 98 },
  { province: "CHINCHA", deptId: 10, provinceIndex: 99 },
  { province: "NAZCA", deptId: 10, provinceIndex: 100 },
  { province: "PALPA", deptId: 10, provinceIndex: 101 },
  { province: "PISCO", deptId: 10, provinceIndex: 102 },
  { province: "HUANCAYO", deptId: 11, provinceIndex: 103 },
  { province: "CONCEPCION", deptId: 11, provinceIndex: 104 },
  { province: "CHANCHAMAYO", deptId: 11, provinceIndex: 105 },
  { province: "JAUJA", deptId: 11, provinceIndex: 106 },
  { province: "JUNIN", deptId: 11, provinceIndex: 107 },
  { province: "SATIPO", deptId: 11, provinceIndex: 108 },
  { province: "TARMA", deptId: 11, provinceIndex: 109 },
  { province: "YAULI", deptId: 11, provinceIndex: 110 },
  { province: "CHUPACA", deptId: 11, provinceIndex: 111 },
  { province: "TRUJILLO", deptId: 12, provinceIndex: 112 },
  { province: "ASCOPE", deptId: 12, provinceIndex: 113 },
  { province: "BOLIVAR", deptId: 12, provinceIndex: 114 },
  { province: "CHEPEN", deptId: 12, provinceIndex: 115 },
  { province: "JULCAN", deptId: 12, provinceIndex: 116 },
  { province: "OTUZCO", deptId: 12, provinceIndex: 117 },
  { province: "PACASMAYO", deptId: 12, provinceIndex: 118 },
  { province: "PATAZ", deptId: 12, provinceIndex: 119 },
  { province: "SANCHEZ CARRION", deptId: 12, provinceIndex: 120 },
  { province: "SANTIAGO DE CHUCO", deptId: 12, provinceIndex: 121 },
  { province: "GRAN CHIMU", deptId: 12, provinceIndex: 122 },
  { province: "VIRU", deptId: 12, provinceIndex: 123 },
  { province: "CHICLAYO", deptId: 13, provinceIndex: 124 },
  { province: "FERREÑAFE", deptId: 13, provinceIndex: 125 },
  { province: "LAMBAYEQUE", deptId: 13, provinceIndex: 126 },
  { province: "LIMA", deptId: 14, provinceIndex: 127 },
  { province: "BARRANCA", deptId: 14, provinceIndex: 128 },
  { province: "CAJATAMBO", deptId: 14, provinceIndex: 129 },
  { province: "CANTA", deptId: 14, provinceIndex: 130 },
  { province: "CAÑETE", deptId: 14, provinceIndex: 131 },
  { province: "HUARAL", deptId: 14, provinceIndex: 132 },
  { province: "HUAROCHIRI", deptId: 14, provinceIndex: 133 },
  { province: "HUAURA", deptId: 14, provinceIndex: 134 },
  { province: "OYON", deptId: 14, provinceIndex: 135 },
  { province: "YAUYOS", deptId: 14, provinceIndex: 136 },
  { province: "MAYNAS", deptId: 15, provinceIndex: 137 },
  { province: "ALTO AMAZONAS", deptId: 15, provinceIndex: 138 },
  { province: "LORETO", deptId: 15, provinceIndex: 139 },
  { province: "MARISCAL RAMON CASTILLA", deptId: 15, provinceIndex: 140 },
  { province: "REQUENA", deptId: 15, provinceIndex: 141 },
  { province: "UCAYALI", deptId: 15, provinceIndex: 142 },
  { province: "TAMBOPATA", deptId: 16, provinceIndex: 143 },
  { province: "MANU", deptId: 16, provinceIndex: 144 },
  { province: "TAHUAMANU", deptId: 16, provinceIndex: 145 },
  { province: "MARISCAL NIETO", deptId: 17, provinceIndex: 146 },
  { province: "GENERAL SANCHEZ CERRO", deptId: 17, provinceIndex: 147 },
  { province: "ILO", deptId: 17, provinceIndex: 148 },
  { province: "PASCO", deptId: 18, provinceIndex: 149 },
  { province: "DANIEL ALCIDES CARRION", deptId: 18, provinceIndex: 150 },
  { province: "OXAPAMPA", deptId: 18, provinceIndex: 151 },
  { province: "PIURA", deptId: 19, provinceIndex: 152 },
  { province: "AYABACA", deptId: 19, provinceIndex: 153 },
  { province: "HUANCABAMBA", deptId: 19, provinceIndex: 154 },
  { province: "MORROPON", deptId: 19, provinceIndex: 155 },
  { province: "PAITA", deptId: 19, provinceIndex: 156 },
  { province: "SULLANA", deptId: 19, provinceIndex: 157 },
  { province: "TALARA", deptId: 19, provinceIndex: 158 },
  { province: "SECHURA", deptId: 19, provinceIndex: 159 },
  { province: "PUNO", deptId: 20, provinceIndex: 160 },
  { province: "AZANGARO", deptId: 20, provinceIndex: 161 },
  { province: "CARABAYA", deptId: 20, provinceIndex: 162 },
  { province: "CHUCUITO", deptId: 20, provinceIndex: 163 },
  { province: "EL COLLAO", deptId: 20, provinceIndex: 164 },
  { province: "HUANCANE", deptId: 20, provinceIndex: 165 },
  { province: "LAMPA", deptId: 20, provinceIndex: 166 },
  { province: "MELGAR", deptId: 20, provinceIndex: 167 },
  { province: "MOHO", deptId: 20, provinceIndex: 168 },
  { province: "SAN ANTONIO DE PUTINA", deptId: 20, provinceIndex: 169 },
  { province: "SAN ROMAN", deptId: 20, provinceIndex: 170 },
  { province: "SANDIA", deptId: 20, provinceIndex: 171 },
  { province: "YUNGUYO", deptId: 20, provinceIndex: 172 },
  { province: "MOYOBAMBA", deptId: 21, provinceIndex: 173 },
  { province: "BELLAVISTA", deptId: 21, provinceIndex: 174 },
  { province: "EL DORADO", deptId: 21, provinceIndex: 175 },
  { province: "HUALLAGA", deptId: 21, provinceIndex: 176 },
  { province: "LAMAS", deptId: 21, provinceIndex: 177 },
  { province: "MARISCAL CACERES", deptId: 21, provinceIndex: 178 },
  { province: "PICOTA", deptId: 21, provinceIndex: 179 },
  { province: "RIOJA", deptId: 21, provinceIndex: 180 },
  { province: "SAN MARTIN", deptId: 21, provinceIndex: 181 },
  { province: "TOCACHE", deptId: 21, provinceIndex: 182 },
  { province: "TACNA", deptId: 22, provinceIndex: 183 },
  { province: "CANDARAVE", deptId: 22, provinceIndex: 184 },
  { province: "JORGE BASADRE", deptId: 22, provinceIndex: 185 },
  { province: "TARATA", deptId: 22, provinceIndex: 186 },
  { province: "TUMBES", deptId: 23, provinceIndex: 187 },
  { province: "CONTRALMIRANTE VILLAR", deptId: 23, provinceIndex: 188 },
  { province: "ZARUMILLA", deptId: 23, provinceIndex: 189 },
  { province: "CORONEL PORTILLO", deptId: 24, provinceIndex: 190 },
  { province: "ATALAYA", deptId: 24, provinceIndex: 191 },
  { province: "PADRE ABAD", deptId: 24, provinceIndex: 192 },
  { province: "PURUS", deptId: 24, provinceIndex: 193 },
];
