import { ratePrices } from '../../../data/ratePrices'

export const getRatePrice = (ubigeoData, dataOfService) => {
  try {
    if(dataOfService.serviceType === "province" || !ubigeoData) {
      return null
    }
  
    const dataRate = ratePrices.filter(item => 
      item.department.toUpperCase() === ubigeoData?.desc_dep_inei.toUpperCase() &&
      item.province.toUpperCase() === ubigeoData?.desc_prov_inei.toUpperCase() &&
      item.district.toUpperCase() === ubigeoData?.desc_ubigeo_inei.toUpperCase()
    )
  
    const ratePrice = dataRate.map(item => {
      if(dataOfService.serviceType === "next-day") {
        return item.nextDayRate
      } else if (dataOfService.serviceType === "same-day") {
        return item.sameDayRate 
      } else {
        return null
      }
    })
  
    return ratePrice[0]
  } catch(err) {
    console.error(err.message)
  }
} 

export const getAllRatePrice = (packetAndReceptorData, dataOfService) => {
  try{
    if(dataOfService.serviceType === "province" || (packetAndReceptorData.length === 0)) {
      return null
    }
    const allDataRate = packetAndReceptorData.map(item => {
      const ratePrice = getRatePrice(item.ubigeo, dataOfService)
      return {
        ...item,
        ratePrice
      }
    })
    
    return allDataRate
  } catch (err) {
    console.error(err.message)
  }
}