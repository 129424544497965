import styled from 'styled-components';

export const OptionsColutionContainer = styled.ul`
  li {
    margin: 0 0 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  button {
    display: flex;
    border: 1px solid ${props => props.theme.gray200Color};
    padding: 12px 18px;
    align-items: center;
    wdith: 100%;
    transition: all .2s;

    &:hover {
      background: #f6fbff;
      border: 1px solid ${props => props.theme.brandClassicBlueColor};

      p {
        color: ${props => props.theme.brandClassicBlueColor};
      }

      svg {
        fill: ${props => props.theme.brandClassicBlueColor};
      }
    }
  }
  
  p, button {
    cursor: pointer;
  }

  svg {
    margin: 0 10px 0 0;
    fill: ${props => props.theme.blackColor};
  }
`
