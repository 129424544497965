import styled from 'styled-components';

export const MainMenuContainer = styled.div`
  display: none;

  @media(min-width:1200px) {
    display: block;
    background: ${props => props.theme.brandClassicBlueColor};
    position: fixed;
    width: 270px;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 2000;
  }
`
