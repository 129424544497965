import React from 'react'
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../styles/generalStyles';
import { BoxGeneral } from './../../styles/boxesGeneral'
import { Title4 } from './../../styles/textGeneral'
import TabShippingHistory from './TabShippingHistory';
import ShippingGroupCard from './ShippingGroupCard';

export default function MainShippingHistory() {
  const [t] = useTranslation("main");

  return (
    <main className='base'>
      <Wrapper home>
        <TabShippingHistory />
        <BoxGeneral>
          <Title4>{t("shippingHistory.allShipments")}</Title4>
          <ul>
            <ShippingGroupCard />
          </ul>
        </BoxGeneral>
      </Wrapper>
    </main>
  )
}
