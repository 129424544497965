import styled from "styled-components";

export const TrackingLineCardContainer = styled.div`
  display: block;
  font-size: 0.688em;
  font-weight: ${(props) => props.theme.weight.semiBold};
  margin: 20px 0;
  position: relative;

  .line {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 12px;
  }

  .lineTracking {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circleTracking {
    width: 44px;
    height: 44px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
    position: relative;
    z-index: 10;
  }

  .storageSVGColor {
    fill: ${(props) =>
      props.statusColor.storageLine
        ? props.theme.backgroundColor
        : props.theme.gray500Color};
  }

  .routeSVGColor {
    fill: ${(props) =>
      props.statusColor.routeLine
        ? props.theme.backgroundColor
        : props.theme.gray500Color};
  }

  .deliveredSVGColor {
    fill: ${(props) =>
      props.statusColor.deliveredLine
        ? props.theme.backgroundColor
        : props.theme.gray500Color};
  }

  .storageTextTracking {
    color: ${(props) =>
      props.statusColor.storageLine
        ? props.theme.blackColor
        : props.theme.gray400Color};
  }

  .routeTextTracking {
    color: ${(props) =>
      props.statusColor.routeLine
        ? props.theme.blackColor
        : props.theme.gray400Color};
  }

  .deliveredTextTracking {
    color: ${(props) =>
      props.statusColor.deliveredLine
        ? props.theme.blackColor
        : props.theme.gray400Color};
  }

  .storageLine {
    background: ${(props) =>
      props.statusColor.storageLine ? `#E55C3E` : props.theme.gray100Color};
  }

  /* .officeLine{
    background: ${(props) => props.theme.gray100Color};
  } */

  .routeLine {
    background: ${(props) =>
      props.statusColor.routeLine ? `#E5B63E` : props.theme.gray100Color};
  }

  .deliveredLine {
    background: ${(props) =>
      props.statusColor.deliveredLine ? `#55E53E` : props.theme.gray100Color};
  }

  .barStorageOffice,
  .barOfficeRoute,
  .barRouteDelivered {
    background: ${(props) => props.theme.gray100Color};
  }

  .barRouteDelivered {
    left: 64%;
  }

  .barStorageOffice {
    left: 14%;
    background: ${(props) =>
      props.statusColor.routeLine
        ? `linear-gradient(90deg, #E55C3E -2.25%, #E5B63E 100%)`
        : props.theme.gray100Color};
  }

  .barOfficeRoute {
    left: 50%;
    background: ${(props) =>
      props.statusColor.deliveredLine
        ? `linear-gradient(90deg, #E5B63E -2.25%, #55E53E 100%)`
        : props.theme.gray100Color};
  }
  
  /*Cuando se valida*/ 
  
  /* .officeLine {
    background: #3E77E5;
  } */

  /* .barRouteDelivered {
    left: 64%;
    background: linear-gradient(90deg, #55E53E -2.25%, #3E77E5 100%); 
  }*/

  .progressBar {
    width: 35%;
    height: 4px;
    position: absolute;
    top: calc(50% - 20px);
  }

  @media (min-width: 768px) {
    font-size: 0.75em;

    .progressBar {
      width: 35%;
      top: calc(50% - 14px);
    }
  }

  @media (min-width: 1200px) {
    font-size: 0.875em;

    svg {
      width: 30px;
      height: 30px;
    }

    .circleTracking {
      width: 70px;
      height: 70px;
      margin: 0 0 15px 0;
    }
  }
`;
