import React from 'react'
import { Wrapper } from "./../../styles/generalStyles";
import { Text, Title2, Title3, TextUnlist } from "./../../styles/textGeneral";
import { Link } from 'react-router-dom'
import { DesktopGrid } from './styles/sMainTerms'

export default function MainTerms() {
  return (
    <main>
      <Wrapper morePages>
        <DesktopGrid>
          <div>
            <Title2 morePages>Términos de uso</Title2>
            <TextUnlist>
              <li>
                <Text>Las tarifas son válidas para paquetes con medidas máximas 30x20x12. En caso de tener paquetes de mayores dimensiones, consultar la tarifa correspondiente.</Text>
              </li>
              <li>
                <Text unlist>La programación de envió tanto para Next Day, Same Day y Provincia se realiza por envió de una base de direcciones Excel subida al sistema desde la cuenta del cliente.</Text>
              </li>
              <li>
                <Text unlist>La programación del recojo varia según el tipo de servicio elegido.</Text>
              </li>
            </TextUnlist>
            <Title3 morePages>Next Day</Title3>
            <Text>Programación hasta las 11:59 pm del día anterior. Recojo entre las 8:00 am y 10:30 am. Entregas desde las 11:00 am hasta las 6:00 pm.</Text>
            <Title3 morePages>Same Day</Title3>
            <Text>Programación hasta las 11:59 pm del día anterior. Recojo entre las 8:00 am y 10:30 am. Entregas desde las 11:00 am hasta las 6:00 pm.</Text>
            <Title3 morePages>Envíos a Provincia</Title3>
            <Text>Programación en cualquier horario del día.</Text>
            <Text paragraphLanding>Para recojo y despacho el mismo día se tiene que programar antes de las 7:00 am, el recojo será hasta las 10:30 pm.</Text>
            <Text paragraphLanding>Si se programa después de las 7:00 am el recojo se hace en el transcurso de la tarde.</Text>
            <Text paragraphLanding>Si se programa después de las 3:00 pm recojo se hace al día siguiente entre las 8 am y 10:30 am.</Text>
            <Title3 morePages>Otros aspectos</Title3>
            <TextUnlist>
              <li>
                <Text>Los datos de referencia son de importancia para la programación de envíos estos deberán consignarse en la data de direcciones.</Text>
              </li>
              <li>
                <Text unlist>El paquete debe estar correctamente rotulado, caso contrario no procederá el recojo.</Text>
                <Link to="/terms" className='linkText'>Ver guía de rotulado</Link>
              </li>
              <li>
                <Text unlist>Para productos frágiles utilizar embalaje especial (Tecnopor, Corrospum y/o proteger los interiores con burbupack). SERVICIO PUNTUAL no se responsabiliza por productos frágiles mal embalados o no protegidos.</Text>
              </li>
              <li>
                <Text unlist>Se considera un tiempo de espera en la etapa de pick up de productos de 10 minutos, en caso el cliente sobre pase el tiempo, SERVICIO PUNTUAL reprogramara el recojo al siguiente día hábil.</Text>
              </li>
              <li>
                <Text unlist>No transportamos dinero, perecibles, armas, municiones, restos humanos, sustancias controladas, joyas que superen el valor de S/1,000 (mil y 00/100), productos inflamables, seres vivos productos considerados ilegales bajo normativa peruana.</Text>
              </li>
              <li>
                <Text unlist>La gestión de envíos contra entrega no tiene costo adicional, por lo cual se le pide que los clientes paguen con montos exactos, ya que los motorizados no llevan vuelto. En caso sea por transferencias, yape, plin se deben hacer a cuentas de los proveedores (quien contrata nuestro servicio).</Text>
              </li>
              <li>
                <Text unlist>Para envíos contra entrega, el monto máximo en efectivo a cobrar será de S/.200 por envío. Por transferencia, no tiene límite máximo.</Text>
              </li>
              <li>
                <Text unlist>Los datos de referencia de dirección son indispensables para programar un envío. De lo contrario, no se podrá programar de manera satisfactoria.</Text>
              </li>
              <li>
                <Text unlist>En caso de pérdida o robo, SERVICIO PUNTUAL asumirá EL COSTO del producto, teniendo como tope máximo 10 veces la tarifa de envío del día. El cual, deberá estar sustentado con boletas.</Text>
              </li>
              <li>
                <Text unlist>En caso tenga envíos fuera de nuestra cobertura, consultar, antes de programar,por disponibilidad y tarifa para el mismo.</Text>
              </li>
              <li>
                <Text unlist>Para anulación de recojo máximo notificarnos hasta las 6:30am via WhatsApp al número POR DEFINIR indicando el numero de orden y las direcciones a retener.</Text>
              </li>
              <li>
                <Text unlist>En caso el cliente indique devolver los productos retenidos a sus oficinas este tendrá un costo de acuerdo al tarifario.</Text>
              </li>
              <li>
                <Text unlist>En caso el cliente requiera modificar los datos de las entregas.</Text>
              </li>
              <li>
                <Text unlist>Para cambios o modificaciones en los datos de las entregas (cambio de dirección, cambio de monto a cobrar, teléfonos de contacto) se deberán avisar como máximo hasta las 7:30am.</Text>
              </li>
              <li>
                <Text unlist>En caso de pérdida o robo, SERVICIO PUNTUAL asumirá EL COSTO del producto, teniendo como tope máximo 10 veces la tarifa de envío del día. El cual, deberá estar sustentado con boletas.</Text>
              </li>
              <li>
                <Text unlist>En caso el agente llegue a la dirección de entrega y por motivos atribuibles al cliente final (quien recibirá el producto) como, por ejemplo: no contesta llamadas, no contesta timbre, la dirección es errada, no manda ubicación exacta por WhatsApp, no tiene efectivo exacto para pagar o demora más de 10min para recibir el producto se cobrará el servicio y el producto quedará en custodia por 1 día. El cliente podrá reprogramar la entrega con la dirección correo o datos correctos sin embargo se facturará un nuevo flete.</Text>
              </li>
              <li>
                <Text unlist>Servicio Puntual reserva los derechos de cambiar o de modificar estos términos sin previo aviso.</Text>
              </li>
            </TextUnlist>
          </div>
          <div></div>
        </DesktopGrid>
      </Wrapper>
    </main>
  )
}
