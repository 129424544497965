import styled from 'styled-components';

export const DetailsTrackingCardContainer = styled.div`
  p {
    margin: 0 0 7px 0;
    font-weight: ${props => props.theme.weight.regular};
    line-height: 1.25em;

    &:last-child {
      margin: 0;
    }
  }

  span {
    font-weight: ${props => props.theme.weight.medium};
  }
`
