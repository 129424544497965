import React from 'react'
import { HelpServiceCardContainer } from './styles/sHelpServiceCard'
import { Text } from "./../../styles/textGeneral";
import { Link } from 'react-router-dom'

export default function HelpServiceCard({ icon, title, description, background, colorHover, link }) {
  return (
    <li>
      <Link to={link}>
        <HelpServiceCardContainer background={background} colorHover={colorHover}>
          {icon}
          <div>
          </div>
            <h2>{title}</h2>
            <Text center>{description}</Text>
        </HelpServiceCardContainer>
      </Link>
    </li>
  )
}
