import React from 'react';
import { ImagePackagingCardContainer } from './styles/sImagePackagingCard'
import { Text } from './../../styles/textGeneral'

export default function ImagePackagingCard({ image, alt, title, text }) {
  return (
    <li>
      <ImagePackagingCardContainer>
        <div className='imagePackagingTransparent' />
        <img src={image} alt={alt} />
        <div className='imagePackagingDescription'>
          <h3>{title}</h3>
          <Text>{text}</Text>
        </div>
      </ImagePackagingCardContainer>
    </li>
  );
}
