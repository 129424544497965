import styled, { keyframes, css } from 'styled-components';

const animateMixin = css`
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
`

const moveImage = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-200px); }
`

const moveImageD = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-550px); }
`

const moveWave = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-200px); }
`

export const CoverPageContainer = styled.section`
  position: relative;
  overflow: hidden;
  height: 100vh;
  top: 70px;

  h1, p {
    text-align: center;
  }

  h1 {
    margin: 2vh 0 3vh 0;
  }

  p {
    font-size: 0.958em;
    line-height: initial;
  }

  button {
    margin: 7vh 0;
    height: 44px;
  }

  img {
    width: 100vw;
    height: 30vh;
    animation-name: ${moveImage};
    animation-duration: 8s;
    animation-delay: 2s;
    ${animateMixin}
  }

  @media(min-width:768px) {
    height: 100vh;

    h1 {
      margin: 10vh 0 3vh 0;
    }

    button {
      margin: 8vh 0 7vh 0;
    }
  }

  @media(min-width:1024px) {
    height: 112vh;
  }

  @media(min-width:1200px) {
    height: 100vh;
    display: flex;
    align-items: center;
    top: 0;

    h1, p {
      text-align: left;
    }

    h1 {
      line-height: 1.25em;
      margin: 5vh 0 4vh 0;
    }

    p {
      font-size: 1.15em;
    }

    button {
      margin: 10vh 0 0 0;
      width: 265px;
    }

    img {
      width: 620px;
      height: 380px;
      animation-name: ${moveImageD};
      animation-duration: 20s;
      animation-delay: 1s;
    }
  }
`;

export const CoverPageImages = styled.ul`
  display: flex;
  overflow-x: hidden;
  
  @media(min-width:1200px) {
    position: absolute;
    width: 620px;
    right: 0;
    top: -10px;
  }
`;

export const WavesCoverPageContainer = styled.div`
  .wave {
    width: 700px;
    position: absolute;
    bottom: 50px;
    left: 0;
  }

  .wave1, .wave2, .wave3 {
    animation-name: ${moveWave};
    animation-duration: 16s;
    ${animateMixin}
  }

  .wave2 {
    left: -30px;
    animation-duration: 188s;
  }

  .wave3 {
    left: -50px;
    animation-duration: 20s;
  }

  @media(min-width:768px) {
    .wave {
      width: 1100px;
    }
  }

  @media(min-width:1024px) {
    .wave {
      width: 1250px;
      bottom: 30px;
    }

    .wave3 {
      left: 0px;
    }
  }

  @media(min-width:1200px) {
    .wave {
      width: 1700px;
      bottom: -87px;
    }
  }

  @media(min-width:1400px) {
    .wave {
      width: 2080px;
      bottom: -87px;
    }
  }

  @media(min-width:1500px) {
    .wave {
      width: 2280px;
    }
  }
`

export const FlexDesktop = styled.div`
  @media(min-width:1200px) {
    display: grid;
    align-content: center;
    grid-template-columns: 1.5fr 1fr;
    position: relative;

    .flexDesktopOne {
      z-index: 1000;
      position: relative;
      top: -10px;
    }

    .descriptionCoverPageContent {
      position: relative;
      z-index: 1000;
    }

    .transparent {
      background: linear-gradient(89.85deg, ${props => props.theme.backgroundColor} 53.65%, rgba(248, 250, 253, 0) 99.89%);
      height: 380px;
      width: 464px;
      position: absolute;
      top: 0;
      left: 240px;
    }
  }
`
