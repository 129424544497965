import React, { useContext } from "react";
import { AppContext } from "../../App";
import { BoxBorder } from "./../../styles/boxesGeneral";
import { Link } from "react-router-dom";
import { ShippingGroupCardContainer } from "./styles/sShippingGroupCard";

//Import ALGORITHMS
import { recoverAllShippings } from "./algorithms/recoverAllShippings";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function ShippingGroupCard() {
  const db = useFirestore();
  const { currentUser } = useContext(AppContext);
  return (
    <li>
      <BoxBorder>
        <ShippingGroupCardContainer>
          <div>
            <p>
              Nombre del archivo:{" "}
              <span>all_shipments_(día)_(mes)_(año)_(hora)_(min).xlsx</span>
            </p>
            <div className="descriptionContainer">
              <p>
                Fecha de inicio: <span>Origen de los tiempos</span>
              </p>
              {/* <p>Estado: <span>Parcialmente enviado</span></p> */}
            </div>
          </div>
          <Link to="#" onClick={() => recoverAllShippings(db, currentUser)}>
            Descargar en xlsx
          </Link>
        </ShippingGroupCardContainer>
      </BoxBorder>
    </li>
  );
}
