import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'

//Import firebase utils
import { FirebaseAppProvider, SuspenseWithPerf } from 'reactfire';
import { firebaseConfig } from './firebase/Config';

//Import languages and I18nextProvider
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import main_es from "./lang/main/main-es.json"

i18next.init({
  interpolation: {escapeValue: false},
  lng: "es",
  resources: {
    es: {
      main: main_es,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <SuspenseWithPerf traceId={'firebase-user-wait'} fallback={"Charging page..." }>
            <App />
          </SuspenseWithPerf>
        </BrowserRouter>
      </I18nextProvider>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
