import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage"
import { doc, setDoc} from "firebase/firestore"

export const uploadProfileImage = (file, setProgress, setError, currentUser, db, dataOfUser) => {
    try {
        let initialType = file.type.substr(0, 5)
        
        if(initialType !== "image") {
            setError("Archivo inválido*")
            return
        }

        // Rename Image File
        const newFile = new File([file], `profile_${currentUser.uid}.jpeg`, {type: 'image/jpeg'})
        
        // Reference to Storage
        const storage = getStorage()
        const routePath = `profiles/${currentUser.uid}/${newFile.name}`
        const profileImageRef = ref(storage, routePath)

        // Reference to Firestore user
        const userRef = doc(db, "users", currentUser.uid)

        const metaData = {
            contentType: file.type
        }

        // Upload Image File to Storage
        const uploadTask = uploadBytesResumable(profileImageRef, newFile, metaData)

        // Listen task change
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setProgress(progress)
                switch(snapshot.state) {
                    case 'paused':
                        console.log('Upload is pause');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                switch(error.code){
                    case 'storage/unauthorized':
                        console.info('El usuario no tiene permiso para acceder a este objeto');
                        setError('El usuario no cuenta con los permisos suficientes. El tamaño de la imagen debe ser menor a 5MB*')
                        break;
                    case 'storage/unknown':
                        console.info('Error inesperado');
                        break;
                }
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                    console.log("Url disponible", downloadURL);
                    setDoc(userRef, {
                        photoUrl: downloadURL,
                        bucketAndRoute: {
                            routePhoto: profileImageRef.fullPath,
                            bucket: profileImageRef.bucket
                        }
                    }, {merge: true})
                    .then(() => {
                        window.location.reload()
                    })
                    .catch(err => {
                        console.error(err.message)
                    })
                })
            }
        )
    } catch(err) {
        console.error(err.message)
    }
}