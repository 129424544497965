import React from 'react';
import MainLanding from './../components/landing/MainLanding';
import HeaderLanding from './../components/general/header/HeaderLanding';
import FooterLanding from './../components/general/footer/FooterLanding';

const Landing = () => {
  return (
    <>
      <HeaderLanding />
      <MainLanding />
      <FooterLanding />
    </>
  )
};

export default Landing;
