import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { Title4 } from "./../../styles/textGeneral";
import { Button } from "./../../styles/buttonGeneral";
import {
  InputContainer,
  FormContainer,
  InputSelect,
} from "./../../styles/inputGeneral";
import { EditProfileContainer } from "./styles/sEditProfile";
import { useForm } from "react-hook-form";
import { ErrorText } from "./../../styles/textGeneral";
import { capitalize } from "../../algorithms/general/capitalize";

//Import data of location
import { departments } from "../../data/departments";
import { provinces } from "../../data/provinces";
import { districts } from "../../data/districts";
import { ubigeo } from "../../data/ubigeo";

// Import function from algorithms this path
import { getDataFromUbigeo } from "./algorithms/getDataFromUbigeo";
import { updateProfileUser } from "./algorithms/updateProfileUser";
import { updateBusinessProfile } from "./algorithms/updateBusinessProfile";
import { uploadProfileImage } from "./algorithms/uploadProfileImage";

//Import firebase utils
import { useFirestore } from "reactfire";

import {
  nameFValidator,
  emailFValidator,
  phoneFValidator,
  DNIFValidator,
  bussinesNameFValidator,
  RUCFValidator,
  addressFValidator,
} from "../../components/loginAndRegister/objects/formValidators";

export default function EditProfile({ dataOfUser, currentUser }) {
  const [t] = useTranslation("main");
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState(null)
  const refFile = useRef(null)
  const db = useFirestore();
  const { departmentDataUser, provinceDataUser, districtDataUser } =
    getDataFromUbigeo(dataOfUser);
  const [department, setDepartment] = useState(departmentDataUser); //Lima si no es cuenta enterprise
  const [province, setProvince] = useState(provinceDataUser); //Lima si no es cuenta enterprise
  const [district, setDistrict] = useState(districtDataUser); //Lima si no es cuenta enterprise

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: dataOfUser.name?.firstName
        ? capitalize(dataOfUser.name.firstName)
        : dataOfUser.name.prevName
        ? capitalize(dataOfUser.name.prevName)
        : "",
      firstLastName: dataOfUser.name?.lastFirstName ?? "",
      secondLastName: dataOfUser.name?.lastSecondName ?? "",
      dni: dataOfUser?.DNI ?? "",
      personalPhone: dataOfUser?.personalPhone ?? "",
      email: dataOfUser?.email ?? "",
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm({
    defaultValues: {
      companyName: dataOfUser?.bussinesName
        ? capitalize(dataOfUser.bussinesName)
        : "",
      ruc: dataOfUser?.RUC ?? "",
      corporativeEmail: dataOfUser?.bussinesEmail ?? "",
      bussinesPhone: dataOfUser?.bussinesPhone ?? "",
      address: dataOfUser?.address ?? "",
    },
  });

  const onSubmitInfo = async (data) => {
    await updateProfileUser(db, data, dataOfUser.uid);
  };

  const onSubmitInfoBusiness = async (data) => {
    const ubigeoData = ubigeo.find(
      (u) =>
        u.desc_prov_inei === provinces[province].province &&
        u.desc_ubigeo_inei === district
    );
    // Recuperacion de ubigeo inei
    data.ubigeo = ubigeoData;
    await updateBusinessProfile(db, data, dataOfUser.uid)
  };

  const handleUploadImage = async () => {
    setError(null)
    setProgress(0)
    refFile.current.click()
    refFile.current.addEventListener('change', async (e) => {
      const file = e.target.files[0]
      if(file) {
        await uploadProfileImage(file, setProgress, setError, currentUser, db, dataOfUser)
        refFile.current.value = null
      }
    })
  }
  
  const { required, ...restEmailValidator } = emailFValidator;

  return (
    <EditProfileContainer>
      <BoxGeneral>
        <Title4>{t("profile.personal.yourInformation")}</Title4>
        
        <Button secondary small className="buttonEditPhotoProfile" onClick={handleUploadImage}>
          {t("profile.personal.changeProfilePhotoButton")}
        </Button>
        <input type="file" accept="image/jpeg" ref={refFile} style={{display: "none"}}/>
        { progress > 0 && <progress value={progress} max="100"/> }
        { error && <ErrorText>{error}</ErrorText>}

        <FormContainer onSubmit={handleSubmit(onSubmitInfo)}>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label>{t("signInAndUp.name")}</label>
              <input type={"text"} {...register("name", nameFValidator)} />
              {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label>{t("profile.personal.firstLastName")}</label>
              <input
                type={"text"}
                {...register("firstLastName", nameFValidator)}
              />
              {errors.firstLastName && (
                <ErrorText>{errors.firstLastName.message}</ErrorText>
              )}
            </InputContainer>
            <InputContainer>
              <label>{t("profile.personal.secondLastName")}</label>
              <input
                type={"text"}
                {...register("secondLastName", nameFValidator)}
              />
              {errors.secondLastName && (
                <ErrorText>{errors.secondLastName.message}</ErrorText>
              )}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label>{t("signInAndUp.documentId")}</label>
              <input type={"text"} {...register("dni", DNIFValidator)} />
              {errors.dni && <ErrorText>{errors.dni.message}</ErrorText>}
            </InputContainer>
            <InputContainer>
              <label>{t("signInAndUp.phone")}</label>
              <input
                type={"text"}
                {...register("personalPhone", phoneFValidator)}
              />
              {errors.personalPhone && (
                <ErrorText>{errors.personalPhone.message}</ErrorText>
              )}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <InputContainer>
              <label>{t("signInAndUp.email")}</label>
              <input type={"text"} {...register("email", emailFValidator)} />
              {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
            </InputContainer>
          </div>
          <div className="inputContainerDuplo">
            <Button primary large type={"submit"}>
              {t("profile.saveInformationButton")}
            </Button>
          </div>
        </FormContainer>
      </BoxGeneral>
      <BoxGeneral>
        <Title4>{t("profile.business.yourBusinessInformation")}</Title4>
        <FormContainer onSubmit={handleSubmit2(onSubmitInfoBusiness)}>
          <div>
            <h5 className="formSubtitle">
              {t("profile.business.generalSectionTitle")}
            </h5>
            <div className="inputContainerDuplo">
              <InputContainer>
                <label>{t("signInAndUp.companyName")}</label>
                <input
                  type={"text"}
                  {...register2("companyName", bussinesNameFValidator)}
                />
                {errors2.companyName && (
                  <ErrorText>{errors2.companyName.message}</ErrorText>
                )}
              </InputContainer>
              <InputContainer>
                <label>{t("signInAndUp.companyId")}</label>
                <input type={"text"} {...register2("ruc", RUCFValidator)} />
                {errors2.ruc && <ErrorText>{errors2.ruc.message}</ErrorText>}
              </InputContainer>
            </div>
            <div className="inputContainerDuplo">
              <InputContainer>
                <label>{t("signInAndUp.phone")}</label>
                <input
                  type={"text"}
                  {...register2("bussinesPhone", phoneFValidator)}
                />
                {errors2.bussinesPhone && (
                  <ErrorText>{errors2.bussinesPhone.message}</ErrorText>
                )}
              </InputContainer>
              <InputContainer>
                <label>{t("signInAndUp.corporativeEmail")}</label>
                <input
                  type={"text"}
                  {...register2("corporativeEmail", restEmailValidator)}
                />
                {errors2.corporativeEmail && (
                  <ErrorText>{errors2.corporativeEmail.message}</ErrorText>
                )}
              </InputContainer>
            </div>
          </div>
          <div>
            <h5 className="formSubtitle">
              {t("profile.business.contactSectionTitle")}
            </h5>
            <div className="inputContainerDuplo">
              <InputContainer>
                <label>{t("signInAndUp.address")}</label>
                <input
                  type={"text"}
                  {...register2("address", addressFValidator)}
                />
                {errors2.address && (
                  <ErrorText>{errors2.address.message}</ErrorText>
                )}
              </InputContainer>
              <InputSelect>
                <label>{t("signInAndUp.department")}</label>
                <select
                  value={department}
                  onChange={(e) => {
                    let filterPD = provinces.filter(
                      (prov) => prov.deptId === e.target.selectedIndex
                    )[0].provinceIndex; //Filtro de la 1era provincia
                    setDepartment(e.target.selectedIndex);
                    setProvince(filterPD);
                    setDistrict(
                      districts.filter((dist) => dist.provId === filterPD)[0]
                        .district
                    ); //Filtro del 1er distrito de la provincia
                  }}
                >
                  {departments.map((dep, index) => {
                    //Iterador de departamentos
                    return (
                      <option value={index} key={dep} id={index}>
                        {capitalize(dep)}
                      </option>
                    );
                  })}
                </select>
              </InputSelect>
            </div>
            <div className="inputContainerDuplo">
              <InputSelect>
                <label>{t("signInAndUp.province")}</label>
                <select
                  value={province}
                  onChange={(e) => {
                    setProvince(
                      parseInt(e.target[e.target.selectedIndex].accessKey)
                    );
                    setDistrict(
                      districts.filter(
                        (dist) =>
                          dist.provId ===
                          parseInt(e.target[e.target.selectedIndex].accessKey)
                      )[0].district
                    ); // Filtro del 1er distrito de una provincia
                  }}
                >
                  {provinces
                    .filter((prov) => prov.deptId === department)
                    .map((prov) => (
                      <option
                        value={prov.provinceIndex}
                        key={`prov_${prov.province}`}
                        accessKey={prov.provinceIndex}
                      >
                        {capitalize(prov.province)}
                      </option>
                    ))}
                </select>
              </InputSelect>
              <InputSelect>
                <label>{t("signInAndUp.district")}</label>
                <select
                  value={district}
                  onChange={(e) =>
                    setDistrict(e.target[e.target.selectedIndex].accessKey)
                  }
                >
                  {districts
                    .filter((dist) => dist.provId === province)
                    .map((dist) => (
                      <option
                        value={dist.district}
                        key={`dist_${dist.district}`}
                        accessKey={dist.district}
                      >
                        {capitalize(dist.district)}
                      </option>
                    ))}
                </select>
              </InputSelect>
            </div>
          </div>
          <div className="inputContainerDuplo">
            <Button primary large type={"submit"}>
              {t("profile.saveInformationButton")}
            </Button>
          </div>
        </FormContainer>
      </BoxGeneral>
    </EditProfileContainer>
  );
}
