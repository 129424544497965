import React, { useContext } from "react";
import { AppContext } from "../../../App";
import LogoServicioPuntual from "./../../../images/logos/image-24.png";
import { Link } from "react-router-dom";
import NavbarHomeItem from "./NavbarHomeItem";
import { ReactComponent as HomeSVG } from "./../../../icons/home.svg";
import { ReactComponent as ProfileSVG } from "./../../../icons/user.svg";
import { ReactComponent as PackageSVG } from "./../../../icons/package.svg";
import { ReactComponent as HistoryCheckSVG } from "./../../../icons/history-check.svg";
import { ReactComponent as CoinSVG } from "./../../../icons/coin.svg";
import { ReactComponent as SignOutSVG } from "./../../../icons/sign-out.svg";
import { ReactComponent as PhoneSVG } from "./../../../icons/phone-2.svg";
import { ReactComponent as TrackingSVG } from "./../../../icons/tracking.svg";
import { ReactComponent as AdministratorSVG } from "./../../../icons/administrator.svg";
import { NavbarHomeContainer } from "./styles/sNavbarHome";

export default function NavbarHome({ closeModal }) {
  const { dataOfUser } = useContext(AppContext);
  const itemsList = [
    {
      icon: <HomeSVG />,
      text: "Inicio",
      link: "/",
    },
    {
      icon: <AdministratorSVG />,
      text: "Administrador",
      link: "/admin",
    },
    {
      icon: <ProfileSVG />,
      text: "Mi perfil",
      link: "/profile",
    },
    {
      icon: <PackageSVG />,
      text: "Nuevo envío",
      link: `/new-package/${localStorage.getItem("serviceType") || "same-day"}`,
    },
    {
      icon: <HistoryCheckSVG />,
      text: "Historial de envíos",
      link: "/history",
    },
    // {
    //   icon: <CoinSVG />,
    //   text: 'Cotizar envíos',
    //   link: '/quote'
    // },
    {
      icon: <TrackingSVG />,
      text: "Rastreo de envíos",
      link: "/tracking-home",
    },
    {
      icon: <PhoneSVG />,
      text: "Centro de asistencia",
      link: "/help-center",
    },
    {
      icon: <SignOutSVG />,
      text: "Salir de la cuenta",
      link: "/",
    },
  ];

  if (dataOfUser?.typeOfUser === process.env.REACT_APP_ADMIN_ID) {
    //Controla la existencia del botón Administrador en NavbarHome
    itemsList.splice(3, 2);
  } else {
    //Controla la existencia del nuevo envío y historial en modo Administrador en NavbarHome
    itemsList.splice(1, 1);
  }

  return (
    <nav>
      <NavbarHomeContainer>
        <Link to="/">
          <img
            className="menuLogoContent"
            src={LogoServicioPuntual}
            alt="Logo principal de Serivico Puntual"
          />
        </Link>
        <ul>
          {itemsList.map((item) => (
            <NavbarHomeItem key={item.text} {...item} closeModal={closeModal} />
          ))}
        </ul>
      </NavbarHomeContainer>
    </nav>
  );
}
