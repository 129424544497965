import React from 'react';
import MainEmailVerify from './../components/loginAndRegister/MainEmailVerify';
import HeaderLanding from './../components/general/header/HeaderLanding';

export default function EmailVerify() {
  return (
    <>
      <HeaderLanding />
      <MainEmailVerify />
    </>
  )
}