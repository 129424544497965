import styled from 'styled-components';

export const NavbarHomeContainer = styled.div`
  height: calc(100vh - 30px);
  
  .menuLogoContent {
    height: 30px;
    margin: 30px auto;
    display: block;
  }
`;
