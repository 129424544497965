import React from 'react';
import HeaderLanding from './../components/general/header/HeaderLanding';
import MainLogin from './../components/loginAndRegister/MainLogin';

export default function Login() {
  return (
    <>
      <HeaderLanding buttonLogin="none" displayDesktop="none" />
      <MainLogin />
    </>
  )
}
