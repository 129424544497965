import React from "react";
import { ModalWrapper } from "../../../styles/generalStyles";
import { Button } from "../../../styles/buttonGeneral";
import { sendDataToDB } from "../../newPackage/algorithms/sendDataToDB";
import { Text, ErrorText } from "../../../styles/textGeneral";

export default function SupportNotice({
  paddingModal,
  dataOfService,
  db,
  currentUser,
  setLoading,
  setSendedStatus,
  setDataOfService,
  setDataPackaging,
  dataOfUser,
  setPackageInfo,
  packageLimit,
  needSupport,
  closeModalSupport,
  setModalSupportIsOpen,
  setConfirmStateSupp
}) {
  const uploadData = (e) => {
    setConfirmStateSupp(true); 
    setModalSupportIsOpen(false);
    if((dataOfUser?.typeOfUser === "B2C") && 
    (dataOfService.serviceType === "same-day" || 
    dataOfService.serviceType === "next-day") && 
    needSupport && packageLimit
    ) {
      sendDataToDB(
        e,
        dataOfService,
        db,
        currentUser,
        setLoading,
        setSendedStatus,
        dataOfUser
      );
      setPackageInfo({amount: dataOfService.amount})
    }
  }
  return (
    <ModalWrapper error paddingModal={paddingModal}>
      <ErrorText sizeH3>Advertencia: Superaste las medidas establecidas de envío</ErrorText >
      <br/>
      <Text>Límites de medida por paquete: <span style={{fontWeight: 'bold'}}>30x20x12</span></Text>
      <Text>Peso por paquete: <span style={{fontWeight: 'bold'}}>3kg</span></Text>
      <Text>Si deseas continuar, tendrás que ponerte en contacto con soporte.</Text>
      <Text>Asistente por Whatsapp: <span style={{fontWeight: 'bold'}}>(+51) 969 711 789</span></Text>
      <div>
          <Button primary large onClick={uploadData}>
            Sí
          </Button>
          <br />
          <Button secondary large onClick={closeModalSupport}>
            No
          </Button>
      </div>
    </ModalWrapper>
  );
}
