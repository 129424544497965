import styled from 'styled-components';

export const ManagementCardContainer = styled.article`
  background: ${props => props.background};
  padding: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-weight: ${props => props.theme.weight.semiBold};
    font-size: 1.00em;
    margin: 0 0 15px 0;
    color: ${props => props.color};
  }

  svg {
    width: 30px;
    fill: ${props => props.theme.backgroundColor};
  }
  
  .managementCardCercleContainer {
    background: ${props => props.color};
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 30px 0 !important;
  }

  @media(min-width:1200px) {
    padding: 30px 45px;

    h3, p {
      font-size: 1.000em;
    }
  }
`;
