import styled from 'styled-components';

export const NoDeliveredCardContainer = styled.div`
  h3 {
    margin: 0 0 10px 0;
    color: ${props => props.theme.red500Color};
  }

  a {
    color: ${props => props.theme.brandClassicBlueColor};

    &:hover {
      text-decoration: underline;
    }
  }

  .noDeliveredDescription {
    margin: 10px 0;
  }

  .contactSupport {
    font-weight: ${props => props.theme.weight.medium};
    font-size: 0.875em;
    text-decoration: underline;

    &:hover {
      font-weight: ${props => props.theme.weight.semiBold};
    }
  }
  `
