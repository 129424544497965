import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";

export const recoverLatest50Shippings = async (
  db,
  currentUser,
  setRecoveredLatest50Shippings
) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, "receptors"),
      where("senderUid", "==", currentUser.uid),
      orderBy("sendNumberGenenerateDate", "desc"),
      limit(50)
    )
  );

  let listOfShipments = []
  querySnapshot.docs.map(s=>{
      return listOfShipments.push(s.data())
  })
  setRecoveredLatest50Shippings(listOfShipments)
};
