import React, { useState } from "react";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4 } from "./../../styles/textGeneral";
import { DetailsTrackingCardContainer } from "./styles/sDetailsTrackingCard";
import { DeliveredDetailsCardContainer } from "./styles/sDeliveredDetailsCard";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";

import MainModal from "../modal/MainModal";

export default function DeliveredDetailsCard({ state }) {
  const [modalIsOpen, setModalIsOpen] = useState();
  const [selectImage, setSelectImage] = useState();

  const openModal = (e) => {
    setSelectImage(e.target.src);
    setModalIsOpen(true);
  };
  const closeModal = () => setModalIsOpen(false);
  return (
    <BoxGeneral shadow>
      <Title4>Detalles de la entrega</Title4>
      <DeliveredDetailsCardContainer>
        <DetailsTrackingCardContainer>
          <p>
            Destinatario: <span>{capitalize(state?.name)}</span>
          </p>
          <p>Evidencia fotográfica:</p>
        </DetailsTrackingCardContainer>
        {state?.url_cargo1 && state?.url_cargo1 !== "" && (
          <img
            alt="imagen de envidencia de entrega"
            src={state?.url_cargo1}
            onClick={openModal}
          ></img>
        )}
        {state?.url_cargo2 && state?.url_cargo2 !== "" && (
          <img
            alt="imagen de envidencia de entrega"
            src={state?.url_cargo2}
            onClick={openModal}
          ></img>
        )}
        {state?.url_cargo3 && state?.url_cargo3 !== "" && (
          <img
            alt="imagen de envidencia de entrega"
            src={state?.url_cargo3}
            onClick={openModal}
          ></img>
        )}
        <MainModal
          isOpen={modalIsOpen}
          closeModal={closeModal}
          isClose={closeModal}
          background="#232324"
          width="60%"
          height="auto"
          borderRadius="8px"
          fillColor="#262A2E"
        >
          {/* <div style={{height: "100%", width: "auto",}}> */}
          <img
            alt="imagen de envidencia de entrega"
            src={selectImage}
            width="100%"
            height="100%"
          ></img>
          {/* </div> */}
        </MainModal>
      </DeliveredDetailsCardContainer>
    </BoxGeneral>
  );
}
