import styled from 'styled-components';

export const LayoutGridContainer = styled.div`
  background: ${props => props.theme.baseColor};
  height: 100%;

  @media(min-width:1200px) {
    display: grid;
    grid-template-columns: 270px 1fr;
    padding: 0 0 20px 0;

    main > div {
      padding: 0 20px;
    }
  }

  @media(min-width:1250px) {
    main > div {
      width: 970px;
      padding: 0;
    }
  }
`
