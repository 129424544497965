import React from 'react';
import { Text } from './../../styles/textGeneral';
import { PackagingCardContainer } from './styles/sPackagingCard'

export default function PackagingCard({ text, color }) {
  return (
    <li>
      <PackagingCardContainer>
        <Text>Logística diseñada para el envió de comunicaciones físicas de empresas, negocio y/o entidades estatales.</Text>
        <Text paragraphLanding>Se caracteriza por la necesidad de repartir grandes cantidades de documentos con un protocolo de entrega de acuerdo al requerimiento del cliente.</Text>
        <Text paragraphLanding>Contamos con cobertura en todo el Perú.</Text>
        <div className='barPackagingCard'></div>
      </PackagingCardContainer>
    </li>
  )
}
