import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App";
import { StatisticCardContainer } from "./styles/sStatisticCard";
import { ReactComponent as DeliverySVG } from "./../../icons/delivery-car.svg";
import { ReactComponent as StatsSVG } from "./../../icons/stats.svg";
import { BoxBorder } from "./../../styles/boxesGeneral";

//Import firebase utils
import { useFirestore } from "reactfire";

//Import ALGORITHMS
import { numberOfSends } from "./algorithms/numberOfSends";
import useMounted from "../../hooks/useMounted";

export default function StatisticCard() {
  const db = useFirestore();
  const mounted = useMounted()
  const { currentUser } = useContext(AppContext);
  const [recoveredNumberOfSends, setRecoveredNumberOfSends] = useState(0);
  useEffect(() => {
    currentUser && numberOfSends(db, currentUser, setRecoveredNumberOfSends, mounted);
  }, []);
  return (
    <li>
      <BoxBorder>
        <StatisticCardContainer>
          <div className="numberStatStatisticCard">
            <div className="cercleStatisticCardContent">
              <DeliverySVG />
            </div>
            <div>
              <span>{recoveredNumberOfSends}</span>
              <p>
                {recoveredNumberOfSends === 1
                  ? "Envío realizado"
                  : "Envíos realizados"}
              </p>
            </div>
          </div>
          <StatsSVG className="statStaticCardSvg" />
        </StatisticCardContainer>
      </BoxBorder>
    </li>
  );
}
