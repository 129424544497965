import React from 'react';
import { Text } from './../../styles/textGeneral';
import { SolutionCardContainer } from './styles/sSolutionCard'

export default function SolutionCard({ image, title, text }) {
  return (
    <li>
      <SolutionCardContainer>
        {image}
        <h4>{title}</h4>
        <Text>{text}</Text>
      </SolutionCardContainer>
    </li>
  )
}
