import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../styles/generalStyles';
import { Text } from './../../styles/textGeneral'
import { Button } from './../../styles/buttonGeneral'
import { InputContainer, FormContainer } from "./../../styles/inputGeneral";
import { Link } from 'react-router-dom'
import { LoginAndRegisterContainer } from "./styles/sLoginAndRegister";
import { ErrorText } from "./../../styles/textGeneral"; 
import { useForm } from 'react-hook-form'
import {
  emailFValidator,
} from "../../components/loginAndRegister/objects/formValidators"
import { handleSubmitRecoverPassword } from './algorithms/handleSubmitRecoverPassword'
import { AppContext } from '../../App'

export default function MainRecoverPassword() {
  const [t] = useTranslation("main");
  const { currentUser } = useContext(AppContext);
  let navigate = useNavigate();
  const [sendEmail, setSendEmail] = useState()
  const {
    register, 
    handleSubmit, 
    formState: { errors }, 
    reset
  } = useForm()
  
  const onSubmit = async (data) => {
    await handleSubmitRecoverPassword(data.email, setSendEmail, navigate)
    reset()
  }
  
  useEffect(() => {
    currentUser?.emailVerified && navigate("/home");
  }, [])

  return (
    <main>
      <Wrapper small>
        <LoginAndRegisterContainer>
          <h1>{t("recoverPassword.recoverTitle")}</h1>
          <Text center>{t("recoverPassword.recoverParagraph")}</Text>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <label>{t("signInAndUp.email")}</label>
              <input type={"text"} {...register("email", emailFValidator)}/>
              {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
              {sendEmail === false ? <ErrorText>Email incorrecto o no registrado*</ErrorText> : ""}
            </InputContainer>
            <Button primary large type={"submit"}>
              {t("recoverPassword.recoverButton")} 
            </Button>
          </FormContainer>
          <div className='loginAndRegisterQuestionContent'>
            <Text center>{t("recoverPassword.recoverSupportQuestion")}</Text>
            <Link to="/assistance">{t("recoverPassword.returnSupport")}</Link>
          </div>
        </LoginAndRegisterContainer>
      </Wrapper>
    </main>
  )
}
