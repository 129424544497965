import React, { useEffect } from "react";
import { useReducedState } from "../../hooks/useReducedState";
import { BoxGeneral } from "./../../styles/boxesGeneral";
import { TrackingTimelineCardContainer } from "./styles/sTrackingTimelineCard";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";

export default function TrackingTimelineCard({ state }) {
  const [statusColor, setStatusColor] = useReducedState({
    storage: false,
    route: false,
    delivered: false,
    nodelivered: false,
  });

  useEffect(() => {
    if (state.status === "PARA DESPACHO") {
      setStatusColor({
        storage: true,
        route: false,
        delivered: false,
      });
    } else if (state.status === "EN GESTION") {
      setStatusColor({
        storage: true,
        route: true,
        delivered: false,
      });
    } else if (state.status === "ENTREGADO") {
      setStatusColor({
        storage: true,
        route: true,
        delivered: true,
      });
    } else if (state.status === "MOTIVO" || state.status === "NO DISTRIBUIDO") {
      setStatusColor({
        storage: true,
        route: true,
        delivered: false,
        nodelivered: true,
      });
    }
  }, []);
  return (
    <BoxGeneral shadow>
      <TrackingTimelineCardContainer statusColor={statusColor}>
        <div className="timelineGraphic">
          <div className="timelineContent">
            <div className="circleTimeline deliveredColor"></div>
            <div className="circleTimeline routeColor"></div>
            <div className="circleTimeline storageColor"></div>
            {/* <div className='circleTimeline'></div> */}
          </div>
          <div className="timelineBar"></div>
        </div>
        <div className="timelineDescription">
          {state?.status === "NO DISTRIBUIDO" || state?.status === "MOTIVO" ? (
            <div>
              <p className="deliveredTimelineDescription">
                No entregado{" "}
              </p>
              <span className="dateDeliveredTimeline">
                {statusColor.nodelivered &&
                  `(${capitalize(state.statusDeterminant)})`}
              </span>
            </div>
          ) : (
            <div>
              <p className="deliveredTimelineDescription">
                Entregado{" "}
                <span>
                  {statusColor.delivered &&
                    `(${capitalize(state.statusDeterminant)})`}
                </span>
              </p>
              {statusColor.delivered && (
                <span className="dateDeliveredTimeline">
                  {state?.visitDate}
                </span>
              )}
            </div>
          )}
          <p className="routeTimelineDescription">En tránsito</p>
          <p className="storageTimelineDescription">Asignado para recojo</p>
        </div>
      </TrackingTimelineCardContainer>
    </BoxGeneral>
  );
}
