import React from 'react'
import MainTrackingForHome from './../components/tracking/MainTrackingForHome'

export default function TrackingForHome() {
  return (
    <>
      <MainTrackingForHome />
    </>
  )
}
