export const returnShipmentState = (status, statusDeterminant, userType) => {
  if (!(typeof status === "string")) return "";

  if (userType === "sender") {
    switch (status) {
      case "NO ASIGNADO":
        return "NO ASIGNADO";
      case "PARA DESPACHO":
      case "EN GESTION":
        return "PENDIENTE";
      case "ENTREGADO":
        return "ENTREGADO";
      case "MOTIVO":
        return statusDeterminant;
      case "NO DISTRIBUIDO":
        return statusDeterminant;
      default:
        return "NO EXISTE";
    }
  } else if (userType === "receptor") {
    switch (status) {
      case "PARA DESPACHO":
        return "ASIGNADO PARA RECOJO";
      case "EN GESTION":
        return "EN TRÁNSITO";
      case "ENTREGADO":
        return "ENTREGADO";
      case "MOTIVO":
      case "NO DISTRIBUIDO":
        return "DETENIDO";
      default:
        return "NO EXISTE";
    }
  } else {
    return "NO EXISTE";
  }
};
