import styled, { css } from 'styled-components'

const mixinInput = css`
  background: ${props => props.theme.blue400Color};
  border: 1px solid ${props => props.theme.blue000Color};
  border-radius: 4px;
  padding: 5px 18px;
  height: 40px;
  font-family: ${props => props.theme.brandFont};
  font-size: 0.938em;
  color: ${props => props.theme.gray500Color};
  font-weight: ${props => props.theme.weight.medium};
  transition: all .2s;

  &:hover {
    border: 1px solid ${props => props.theme.blue500Color};
  }

  &:focus {
    color: ${props => props.theme.brandClassicBlueColor};
    background: ${props => props.theme.blue000Color};
    outline: none;
    border: 1px solid ${props => props.theme.brandClassicBlueColor};
  }

  &:read-only {
    background: ${props => props.theme.gray0Color};
  }
`

const mixinInputContainer = css`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 15px 0 0 0;
`

export const FormContainer = styled.form`
  label {
    font-size: 0.938em;
    font-weight: ${props => props.theme.weight.medium};
    margin: 0 0 5px 0;
  }

  button {
    margin: 20px 0 0 0;
  }

  .inputCheckboxContent {
    margin: 20px 0;

    p {
      margin: 0 0 0 15px;
    }
  }

  .inputRadioContent {
    margin: 15px 0 0 0;
    
    p {
      margin: 0 40px 0 10px;
    }
  }

  .inputType {
    display: -webkit-box;
    align-items: center;

    @supports(-webkit-appearance: none) or (-moz-appearance: none) {
      input[type='checkbox'],
      input[type='radio'] {
        appearance: none;
        height: 21px;
        outline: none;
        width: 21px;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        border: 2px solid var(--bc, ${props => props.theme.blue500Color});
        background: var(--b, ${props => props.theme.brandWhiteColor});
        transition: background .3s, border-color .3s, box-shadow .2s;
        flex: 1 0 auto;

        &:after {
          content: '';
          display: block;
          left: -1px;
          top: -1px;
          position: absolute;
          transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
        }

        &:checked {
          --b: ${props => props.theme.brandClassicBlueColor};
          --bc: ${props => props.theme.brandClassicBlueColor};
          --d-o: .3s;
          --d-t: .6s;
          --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
        }

        &:disabled {
          --b: ${props => props.theme.gray400Color};
          cursor: not-allowed;
          opacity: .9;
          &:checked {
            --b: ${props => props.theme.blue000Color};
            --bc: ${props => props.theme.gray400Color};
          }
          & + label {
            cursor: not-allowed;
          }
        }

        &:hover {
          &:not(:checked) {
            &:not(:disabled) {
              --bc: ${props => props.theme.brandClassicBlueColor};
            }
          }
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(39, 94, 254, .3);
        }

        &:not(.switch) {
          width: 21px;
          &:after {
            opacity: var(--o, 0);
          }
          &:checked {
            --o: 1;
          }
        }

        & + label {
          font-size: 14px;
          line-height: 21px;
          display: inline-block;
          vertical-align: top;
          cursor: pointer;
          margin-left: 4px;
        }
      }

      input[type='checkbox'] {
        &:not(.switch) {
          border-radius: 7px;

          &:after {
            width: 5px;
            height: 9px;
            border: 2px solid ${props => props.theme.brandWhiteColor};
            border-top: 0;
            border-left: 0;
            left: 7px;
            top: 4px;
            transform: rotate(var(--r, 20deg));
          }

          &:checked {
            --r: 43deg;
          }
        }

        &.switch {
          width: 38px;
          border-radius: 11px;
          &:after {
            left: 2px;
            top: 2px;
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: var(--ab, ${props => props.theme.blue500Color});
            transform: translateX(var(--x, 0));
          }

          &:checked {
            --ab: ${props => props.theme.brandWhiteColor};
            --x: 17px;
          }

          &:disabled {
            &:not(:checked) {
              &:after {
                opacity: .6;
              }
            }
          }
        }
      }

      input[type='radio'] {
        border-radius: 50%;
        &:after {
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: ${props => props.theme.brandWhiteColor};
          opacity: 0;
          transform: scale(var(--s, .7));
        }
        
        &:checked {
          --s: .5;
        }
      }
    }
  }

  .formSubtitle {
    margin: 25px 0 2px 0;
    font-size: 0.938em;
    font-weight: ${props => props.theme.weight.semiBold};
  }

  .firstInputBlock {
    margin: 0 0 10px 0;
  }
  
  @media(min-width:1200px) {
    .inputContainerDuplo {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }

    .inputContainerTriple {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      align-items: end;
    }
  }
`

export const InputContainer = styled.div`
  ${mixinInputContainer}
  
  input {
    ${mixinInput}
  }
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }

  span {
    color: ${props => props.theme.red500Color};
    font-size: 0.813em;
    font-weight: ${props => props.theme.weight.medium};
    margin: 5px 0 0 0;
  }
`

export const InputSelect = styled.div`
  ${mixinInputContainer}

  select {
    ${mixinInput}
  }
`
