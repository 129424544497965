import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "./../../styles/boxesGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { TabModShippingContainer } from "./styles/sTabModShipping";

//Import ALGORITHMS
import { recoverRequestsInRealTime } from "./algorithms/recoverRequestsInRealTime";

//Import firebase utils
import { useFirestore } from "reactfire";

export default function TabModShipping({
  shipments,
  setShipments,
  realTime,
  tableType,
}) {
  const [t] = useTranslation("main");
  const db = useFirestore();
  const [waitShipments, setWaitShipments] = useState(null);
  const [confirmShipments, setConfirmShipments] = useState(null);

  useEffect(() => {
    let unsubscribeWait =
      realTime &&
      tableType === "wait" &&
      recoverRequestsInRealTime(db, setWaitShipments, tableType);
    let unsubscribeConfirm =
      realTime &&
      tableType === "confirmation" &&
      recoverRequestsInRealTime(db, setConfirmShipments, tableType);
    return () => {
      unsubscribeWait && unsubscribeWait();
      unsubscribeConfirm && unsubscribeConfirm();
    };
  }, []);

  return (
    <TabModShippingContainer>
      {!realTime && (
        <Table>
          <thead>
            <tr className="tableHead">
              <th>{t("table.receptor")}</th>
              <th>{t("table.telephone")}</th>
              <th>{t("table.address")}</th>
              <th>{t("table.district")}</th>
              <th>{t("table.province")}</th>
              <th>{t("table.description")}</th>
              <th>{t("table.trackingID")}</th>
              <th>{t("table.height")}</th>
              <th>{t("table.large")}</th>
              <th>{t("table.width")}</th>
              <th>{t("table.weight")}</th>
              <th>{t("table.quantity")}</th>
            </tr>
            {Object.values(shipments).map((s) => {
              return (
                <tr key={s.id}>
                  <th>{s.data && s.data.name}</th>
                  <th>{s.data && s.data.phone}</th>
                  <th>{s.data && s.data.address}</th>
                  <th>{s.data && s.data.desc_ubigeo_inei}</th>
                  <th>{s.data && s.data.desc_prov_inei}</th>
                  <th>{s.data && s.data.packetDescription}</th>
                  <th>{s.data && s.data.receptorCode}</th>
                  <th>{s.data && s.data.height}</th>
                  <th>{s.data && s.data.large}</th>
                  <th>{s.data && s.data.width}</th>
                  <th>{s.data && s.data.weight}</th>
                  <th>{s.data && s.data.numberOfPackets}</th>
                </tr>
              );
            })}
          </thead>
        </Table>
      )}
      {realTime && waitShipments && (
        <Table>
          <thead>
            <tr className="tableHead">
              <th>{t("table.receptor")}</th>
              <th>{t("table.telephone")}</th>
              <th>{t("table.address")}</th>
              <th>{t("table.district")}</th>
              <th>{t("table.province")}</th>
              <th>{t("table.description")}</th>
              <th>{t("table.trackingID")}</th>
              <th>{t("table.remitoID")}</th>
              <th>{t("table.height")}</th>
              <th>{t("table.large")}</th>
              <th>{t("table.width")}</th>
              <th>{t("table.weight")}</th>
              <th>{t("table.quantity")}</th>
            </tr>
          </thead>
          {waitShipments.map((s) => {
            return (
              <tr key={s.receptorCode}>
                <th>{s?.name}</th>
                <th>{s?.phone}</th>
                <th>{s?.address}</th>
                <th>{s?.desc_ubigeo_inei}</th>
                <th>{s?.desc_prov_inei}</th>
                <th>{s?.packetDescription}</th>
                <th>{s?.receptorCode}</th>
                <th>{s?.senderCode}</th>
                <th>{s?.height}</th>
                <th>{s?.large}</th>
                <th>{s?.width}</th>
                <th>{s?.weight}</th>
                <th>{s?.numberOfPackets}</th>
              </tr>
            );
          })}
        </Table>
      )}
      {realTime && confirmShipments && (
        <Table>
          <thead>
            <tr className="tableHead">
              <th>{t("table.receptor")}</th>
              <th>{t("table.telephone")}</th>
              <th>{t("table.address")}</th>
              <th>{t("table.district")}</th>
              <th>{t("table.province")}</th>
              <th>{t("table.description")}</th>
              <th>{t("table.trackingID")}</th>
              <th>{t("table.remitoID")}</th>
              <th>{t("table.transactionCode")}</th>
            </tr>
          </thead>
          {confirmShipments.map((s) => {
            return (
              <tr key={s.receptorCode}>
                <th>{s?.name}</th>
                <th>{s?.phone}</th>
                <th>{s?.address}</th>
                <th>{s?.desc_ubigeo_inei}</th>
                <th>{s?.desc_prov_inei}</th>
                <th>{s?.packetDescription}</th>
                <th>{s?.receptorCode}</th>
                <th>{s?.senderCode}</th>
                <th>{s?.transactionCode}</th>
              </tr>
            );
          })}
        </Table>
      )}
    </TabModShippingContainer>
  );
}
