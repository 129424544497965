import styled from 'styled-components';

export const TrackingPackageCardContainer = styled.div`
  width: ${props => props.width};

  p {
    font-size: 1em;
    font-weight: ${props => props.theme.weight.semiBold};
    border-bottom: 2px solid ${props => props.theme.brandCeruleanColor};
    padding: 0 10px 7px 0;
    width: fit-content;
  }

  @media(min-width:768px) {
    width: calc((${props => props.width} + 100px));
  }
`
