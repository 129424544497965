import React from 'react'
import { Wrapper } from "./../../styles/generalStyles";
import { Text, Title3, Title1 } from "./../../styles/textGeneral";
import { Button } from "./../../styles/buttonGeneral";
import { Link } from "react-router-dom";
import { MainError404Container } from './styles/sMainError404'
import { ReactComponent as PageNotFound } from './../../images/page-not-found.svg'

export default function MainError404() {
  return (
    <main>
      <Wrapper morePages>
        <MainError404Container>
          <div>
            <Title1>404</Title1>
            <Title3 as="h2">Oops!</Title3>
            <Title3 as="h2" className='secondH2'>Página no encontrada</Title3>
            <Text>Esta página no existe o a sido removida.</Text>
            <Text paragraphLanding>Realize una de las acciones a continuación mientras arreglamos el problema.</Text>
            <Link to="/">
              <Button primary large>Regresar al inicio</Button>
            </Link>
            <Link to="/help-center">Contactar con el Centro de asistencia</Link>
          </div>
          <div>
            <PageNotFound />
          </div>
        </MainError404Container>
      </Wrapper>
    </main>
  )
}
