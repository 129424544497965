import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth"

export const handleResetPassword = (auth, actionCode, continueUrl, newPassword, navigate) => {
  verifyPasswordResetCode(auth, actionCode)
  .then((email) => {
    confirmPasswordReset(auth, actionCode, newPassword)
    .then((resp) => {
      console.log("Password Reset Confirmed :")
      navigate("/login", { replace: true })
    })
    .catch((err) => {
      console.error(err.message)
      navigate("/login", { replace: true })
    })
  })
  .catch((err) => {
    console.error(err.message)
    navigate("/login", { replace: true })
  })
}