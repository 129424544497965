import { colors, fonts} from './fundations'

const theme = () => {
  return({
    /* Colors */
    blackColor: colors.color.black,
    backgroundColor: colors.color.background,
    baseColor: colors.color.base,
    brandClassicBlueColor: colors.color.brand.classicBlue,
    brandLightBlueColor: colors.color.brand.lightBlue,
    brandCeruleanColor: colors.color.brand.ceruleanBlue,
    brandWhiteColor: colors.color.brand.white,
    brandGrayColor: colors.color.brand.gray,
    gray100Color: colors.color.gray.gray100,
    gray200Color: colors.color.gray.gray200,
    gray400Color: colors.color.gray.gray400,
    gray500Color: colors.color.gray.gray500,
    blue000Color: colors.color.blue.blue000,
    blue100Color: colors.color.blue.blue100,
    blue200Color: colors.color.blue.blue200,
    blue300Color: colors.color.blue.blue300,
    blue400Color: colors.color.blue.blue400,
    blue500Color: colors.color.blue.blue500,
    red000Color: colors.color.red.red000,
    red500Color: colors.color.red.red500,
    shadowCard: colors.shadow.card,

    /* Fonts */
    brandFont: fonts.brandFont,
    secondaryFont: fonts.secondaryFont,
    weight: fonts.weight,
  })
}
export default theme