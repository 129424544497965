import React, { useEffect, useContext } from "react";
import { AppContext } from "../../App";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next"
import { Wrapper } from './../../styles/generalStyles';
import { Text } from './../../styles/textGeneral'
import { Button } from './../../styles/buttonGeneral'
import { InputContainer, FormContainer } from "./../../styles/inputGeneral";
import { Link } from 'react-router-dom'
import { LoginAndRegisterContainer } from "./styles/sLoginAndRegister";

export default function MainEmailVerify() {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);
  const location = useLocation()
  
  useEffect(() => {
    currentUser?.emailVerified && navigate("/home");
  }, []);
  return (
    <main>
      <Wrapper small>
        <LoginAndRegisterContainer>
          <h1>{t("emailVerify.emailVerifyTitle")}</h1>
          <Text center>{t("emailVerify.emailVerifyParagraph")}</Text>
          <span className='emailVerificaction'>{location?.state?.email ? location.state.email : 'Tu bandeja de entrada de correo'}</span>
          <div className='loginAndRegisterQuestionContent'>
            <Text center>{t("emailVerify.emailVerifyParagraphTwo")}</Text>
            <Link to="/login">{t("recoverPassword.confirmMessage.returnLoginButton")}</Link>
          </div>
        </LoginAndRegisterContainer>
      </Wrapper>
    </main>
  );
}
