import React from 'react'
import { Wrapper } from './../../styles/generalStyles';
import { BoxGeneral } from './../../styles/boxesGeneral'
import TrackingPackageCard from './../general/cards/TrakingPackageCard'

export default function MainTrackingForHome() {
  return (
    <main className='base'>
      <Wrapper home>
        <BoxGeneral>
          <TrackingPackageCard />
        </BoxGeneral>
      </Wrapper>
    </main>
  )
}
