import styled, { css } from 'styled-components';

export const MainLandingSectionContainer = styled.section`
  text-align: center;

  h2 {
    padding: 100px 0 30px 0;
  }

  h3 {
    margin: 50px 0 20px 0;
  }
  
  button {
    margin: 30px 0 2px 0;
    position: relative;
    z-index: 1000;
  }

  .subSubTitle {
    margin: 0 0 30px 0;
    font-size: 1.05em;
    font-weight: ${props => props.theme.weight.medium};
  }
  
  .doYouNeedHelpLanding {
    padding: 50px 0 0 0;
  }

  .wave {
    position: absolute;
    bottom: -82px;
    left: 0;
    transform: rotateX(180deg);
    width: 100%;
  }

  .imagePackagingCardsUnlist,
  .managemmentCardsUnlist {
    li > article {
      margin: 0 0 30px 0;
    }
  }

  .businessClientCardsUnlist {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-items: center;
    align-items: center;
    margin: 50px 0;
  }

  ${props => props.lightBlue && css`
    color: ${props => props.theme.backgroundColor};
    margin: -1px 0 0 0;
    position: relative;
    background: ${props => props.theme.brandClassicBlueColor};

    @media(min-width:1200px) {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 50px;
        margin: 0 65px;
      }

      li:nth-child(3) {
        margin: 25px 0 0 0;
      }

      li:nth-child(2n) {
        margin: 50px 0 0 0;
      }

      button {
        width: 265px;
      }
    }
  `}

  @media(min-width:768px) {
    h2 {
      padding: 150px 0 35px 0;
    }

    .wave {
      bottom: -170px;
    }
  }

  @media(min-width:1024px) {
    .wave {
      bottom: -228px;
    }

    .managemmentCardsUnlist {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 50px;
      width: 800px;
      margin: 0 auto;
    }

    .managemmentCardsUnlist {
      li > article {
        margin: 0;
      }
    }

    .secondPartMCL {
      margin: 0 auto 20px auto;
    }
  }

  @media(min-width:1200px) {
    h2 {
      padding: 180px 15% 50px 15%;
    }

    h3 {
      margin: 80px 0 30px 0;
    }

    .subSubTitle  {
      margin: 0 0 50px 0;
    }

    p {
      font-size: 1.150em;
    }

    button {
      width: 305px;
    }

    .doYouNeedHelpLanding {
      padding: 100px 0 20px 0;
    }

    .wave {
      bottom: -300px;
    }

    .imagePackagingCardsUnlist {
      li > article {
        margin: 0;
      }
    }

    .imagePackagingCardsUnlist {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin: 100px 0 0 0;
      column-gap: 50px;
    }

    .textLanding {
      display: inline-block;
      width: 800px;
      text-align: center;
    }

    .businessClientCardsUnlist {
      grid-template-columns: repeat(6, 1fr);
      margin: 60px 0;
    }
  }
`;
