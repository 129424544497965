import React from "react";
import { ModalWrapper } from "../../../styles/generalStyles";
import { Button } from "../../../styles/buttonGeneral";
import { sendDataToDB } from "../../newPackage/algorithms/sendDataToDB";
import { Text, Title3 } from "../../../styles/textGeneral";

export default function ConfirmUploadData({
  paddingModal,
  closeModalConfirm,
  setConfirmState,
  dataOfService,
  db,
  currentUser,
  setLoading,
  setSendedStatus,
  setDataOfService,
  setComplete,
  setDataPackaging,
  dataOfUser,
  setPackageInfo,
  packageLimit,
  setModalConfirmIsOpen
}) {
  const uploadData = (e) => {
    setConfirmState(true); 
    if(!(dataOfUser.typeOfUser === "B2C")) {
      sendDataToDB(
        e,
        dataOfService,
        db,
        currentUser,
        setLoading,
        setSendedStatus,
        dataOfUser
      );
    } 
    setPackageInfo({amount: dataOfService.amount})
    setModalConfirmIsOpen(false);
  }
  return (
    <ModalWrapper error paddingModal={paddingModal}>
      <Title3>¿Estás seguro que deseas enviar este pedido?</Title3>
      <Text>Recuerda que no podrás modificarlo después de aceptar</Text>
      <div>
          <Button primary large onClick={uploadData}>
            Sí
          </Button>
          <br />
          <Button secondary large onClick={closeModalConfirm}>
            No
          </Button>
      </div>
    </ModalWrapper>
  );
}
