import React from 'react';
import { BusinessClientCardContainer } from './styles/sBusinessClientCard'

export default function BusinessClientCard({ image, name }) {
  return (
    <li>
      <BusinessClientCardContainer src={image} alt={name} />
    </li>
  )
}
