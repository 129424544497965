import styled from 'styled-components';

export const MainProfileContainer = styled.div`
  @media(min-width:1200px) {
    display: grid;
    grid-template-columns: 275px 1fr;
    gap: 15px;
  }
`

export const MainProfileDetails = styled.div`
  border-bottom: 1px solid ${props => props.theme.gray400Color};
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;

  .cercleUserMainProfile {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    background: ${props => props.theme.gray100Color};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px auto;
    overflow: hidden;

    img {
      width: 80px;
      height: 80px;
    }
  }

  svg {
    fill: ${props => props.theme.blackColor};
    width: 40px;
    height: 40px;
  }

  p {
    text-align: center;
    font-weight: ${props => props.theme.weight.medium};
  }
`

export const DetailsListContent = styled.ul`
  margin: 0 20px 20px 20px;

  svg {
    fill: ${props => props.theme.blackColor};
    margin: 0 10px 0 0;
  }

  li {
    display: flex;
    margin: 0 0 15px 0;

    &:last-child {
      margin: 0;
    }
  }

  @media(min-width:1200px) {
    margin: 20px 0;
  }
`
