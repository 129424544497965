import { collection, query, where, getDocs } from "firebase/firestore";

export const searchSpecificUser = async (
  db,
  uid = "",
  email = "",
  setUserToChangeType
) => {
  let userRecovered = null;

  if (uid.length === 28) {
    userRecovered = await getDocs(
      query(collection(db, "users"), where("uid", "==", uid))
    );
  }
  if (!userRecovered) {
    if (email.length > 5) {
      userRecovered = await getDocs(
        query(collection(db, "users"), where("email", "==", email))
      );
      if (userRecovered.empty) {
        userRecovered = await getDocs(
          query(collection(db, "users"), where("bussinesEmail", "==", email))
        );
      }
    } else {
      setUserToChangeType(false);
    }
  }

  setUserToChangeType(
    userRecovered && !userRecovered.empty ? userRecovered.docs[0].data() : false
  );
};