import { collection, query, where, getDocs } from "firebase/firestore";

export const requestRemito = async (db, localRemito, setRemito) => {
  if (!localRemito || typeof localRemito !== "string") return false;
  const querySnapshot = await getDocs(
    query(collection(db, "senders"), where("senderCode", "==", localRemito))
  );
  setRemito({
    recovered: querySnapshot.docs[0].data(),
    remitoId: querySnapshot.docs[0].id,
  });
};
