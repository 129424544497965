import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Wrapper } from './../../styles/generalStyles';
import { Text } from './../../styles/textGeneral';
import { Button } from './../../styles/buttonGeneral';
import { InputContainer, FormContainer } from "./../../styles/inputGeneral";
import { Link } from 'react-router-dom'
import { LoginAndRegisterContainer } from "./styles/sLoginAndRegister";

export default function MainRecoverPasswordCM() {
  const [t] = useTranslation("main");
  const location = useLocation()

  return (
    <main>
      <Wrapper small>
        <LoginAndRegisterContainer>
          <h1>{t("recoverPassword.recoverTitle")}</h1>
          <Text center>{t("recoverPassword.confirmMessage.cMParagraphOne")}</Text>
          <span className='emailVerificaction'>{location?.state?.email_recover ? location.state.email_recover : 'Tu bandeja de entrada de correo'}</span>
          <div className='loginAndRegisterQuestionContent'>
            <Text center>{t("recoverPassword.confirmMessage.cMParagraphTwo")}</Text>
            <Link to="/login">{t("recoverPassword.confirmMessage.returnLoginButton")}</Link>
          </div>
        </LoginAndRegisterContainer>
      </Wrapper>
    </main>
  )
}
