import React from "react";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4 } from "./../../styles/textGeneral";
import { DetailsTrackingCardContainer } from "./styles/sDetailsTrackingCard";

//Import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";

export default function DetailsTrackingCard({ details }) {
  const firebaseBaseTime = details?.sendNumberGenenerateDate && 
    new Date(details?.sendNumberGenenerateDate.seconds * 1000 + details?.sendNumberGenenerateDate.nanoseconds  / 1000000);
  const date = firebaseBaseTime ? firebaseBaseTime.toLocaleString('es-ES') : null;
  return (
    <BoxGeneral shadow>
      <Title4>Detalles del envío</Title4>
      <DetailsTrackingCardContainer>
        <p>
          Producto: <span>{capitalize(details?.packetDescription)}</span>
        </p>
        <p>
          N° envío: <span>{details?.receptorCode}</span>
        </p>
        <p>
          Destinatario: <span>{capitalize(details?.name)}</span>
        </p>
        <p>
          Telefono: <span>+51 {details?.phone}</span>
        </p>
        <p>
          Dirección:{" "}
          <span>
            {capitalize(details?.address)},{" "}
            {capitalize(details?.desc_ubigeo_inei)},{" "}
            {capitalize(details?.desc_prov_inei)},{" "}
            {capitalize(details?.desc_dep_inei)}, Perú
          </span>
        </p>
        {date && (
          <p>
            Fecha de generación del envío: <span>{capitalize(date)}</span>
          </p>
        )}
        {details?.fechaGestion && (
          <p>
            Fecha de gestión: <span>{capitalize(details?.fechaGestion)}</span>
          </p>)
        }
      </DetailsTrackingCardContainer>
    </BoxGeneral>
  );
}
