import styled from 'styled-components';

export const MainHomeContainer = styled.div`
  @media(min-width:1200px) {
    .gridDesktopContent {
      display: grid;
      grid-template-columns:40% 1fr;
      gap: 15px;
    }
  }
`

export const MainHomeProfileContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 0.938em;
    font-weight: ${props => props.theme.weight.semiBold};
  }

  svg {
    fill: ${props => props.theme.blackColor};
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @media(min-width:1200px) {
    p {
      font-size: 1.050em;
    }
  }
`;
