import React from 'react'
import MainRecoverPasswordCM from '../components/loginAndRegister/MainRecoverPasswordCM'
import HeaderLanding from './../components/general/header/HeaderLanding';

export default function RecoverPasswordCM() {
  return (
    <>
      <HeaderLanding />
      <MainRecoverPasswordCM />
    </>
  )
}
