import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  background: ${props => props.background || 'transparent'};
  
  @media(min-width:360px) {
    padding: 0 20px;
  }

  @media(min-width:375px) {
    padding: 0 25px;
  }

  @media(min-width:768px) {
    padding: 0 18%;
  }

  @media(min-width:1024px) {
    padding: 0 15%;
  }

  @media(min-width:1200px) {
    padding: 0;
    margin: ${props => props.margin || '0 auto'};
    width: ${props => props.width || '1100px'};
  }

  ${props => props.home && css`
    margin: 75px 0 20px 0;
  `}

  ${props => props.morePages && css`
    position: relative;
    top: 70px;
  `}

  ${props => props.loginAndRegister && css`
    @media(min-width:1024px) {
      margin: 0 auto;
      padding: 0;
      width: auto;
    }
  `}

  ${props => props.small && css`
    @media(min-width:1024px) {
      margin: 0 auto;
      padding: 0;
      width: 400px;
    }
  `}
`

export const ModalWrapper = styled.div`
  padding: ${props => props.paddingModal};
  color: ${props =>props.theme.blackColor};

  h2 {
    font-size: 1.1em;
    text-align: center;
    margin: 0 0 20px 0;
  }

  h3 {
    font-size: 0.938em;
    text-align: center;
    line-height: 1.5em;
    margin: 0 0 10px 0;
    font-weight: ${props => props.theme.weight.semiBold};
  }

  button {
    margin: 30px 0 0 0 !important;
  }

  ${props => props.error && css`
    h2 {
      color: ${props => props.theme.red500Color};
    }
  `}
`
