import React from 'react';
import MainProfile from './../components/profile/MainProfile';

export default function Profile() {
  return (
    <>
      <MainProfile />
    </>
  )
}
