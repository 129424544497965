import React, { useState } from 'react';
import { Title1, Text } from './../../styles/textGeneral'
import { Button } from './../../styles/buttonGeneral'
import { CoverPageContainer, CoverPageImages, WavesCoverPageContainer, FlexDesktop } from './styles/sCoverPage'
import { Wrapper } from './../../styles/generalStyles'
import { ReactComponent as Wave1 } from './../../images/waves/wave-1.svg'
import { ReactComponent as Wave2 } from './../../images/waves/wave-2.svg'
import { ReactComponent as Wave } from './../../images/waves/wave.svg'
import MainModal from './../modal/MainModal';
import TrakingPackageCard from './../general/cards/TrakingPackageCard'

export default function CoverPage({ images }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  return (
    <CoverPageContainer>
      <Wrapper>
        <FlexDesktop>
          <div className='flexDesktopOne'>
            <div className='transparent'></div>
            <div className='descriptionCoverPageContent'>
              <Title1 classicBlue><strong><em>Te conectamos con tus clientes</em> de forma rápida y simple</strong></Title1>
              <Text><mark>Courier</mark> | <mark>Última Milla</mark> | <mark>Logística</mark></Text>
              <Button primary medium onClick={openModal}>Seguimiento de envíos</Button>
            </div>
          </div>
          <CoverPageImages>
            {
              images.map((image) =>
                <li key={image.alt}>
                  <img src={image.image} alt={image.alt} />
                </li>
              )
            }
          </CoverPageImages>
        </FlexDesktop>
      </Wrapper>
      <WavesCoverPageContainer>
        <Wave2 className='wave wave2' />
        <Wave1 className='wave wave1' />
        <Wave className='wave wave3' />
      </WavesCoverPageContainer>
      <MainModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        isClose={closeModal}
        background="#F8FAFD"
        width="auto"
        height="auto"
        borderRadius="8px"
        fillColor="#262A2E"
      >
        <TrakingPackageCard width="300px" paddingModal="15px 20px" />
      </MainModal>
    </CoverPageContainer>
  )
}
