import React from 'react'
import MainChangePassword from './../components/loginAndRegister/MainChangePassword'
import HeaderLanding from './../components/general/header/HeaderLanding';

export default function ChangePassword() {
  return (
    <>
      <HeaderLanding />
      <MainChangePassword />
    </>
  )
}
