import styled from 'styled-components';

export const HelpServiceCardContainer = styled.button`
  background: ${props => props.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  border-radius: 8px;
  cursor: pointer;
  transition: .2s;

  svg {
    width: 32px;
    fill: ${props => props.theme.blackColor};
  }

  h2 {
    font-size: 1em;
    text-align: center;
    margin: 25px 0 20px 0;
  }

  &:hover {
    color: ${props => props.colorHover};
    box-shadow: ${props => props.theme.shadowCard};

    svg {
      fill: ${props => props.colorHover};
    }
  }
  `
