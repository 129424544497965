import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";

export const recoverRequestsInRealTime = (
  db,
  setRealTimeShipments,
  tableType
) => {
  let q = null;
  if (tableType === "wait") {
    q = query(
      collection(db, "receptors"),
      where("payState", "==", "EN ESPERA"),
      orderBy("sendNumberGenenerateDate", "desc"),
      limit(15)
    );
  } else if (tableType === "confirmation") {
    q = query(
      collection(db, "receptors"),
      where("payState", "==", "PAGADO"),
      orderBy("sendNumberGenenerateDate", "desc"),
      limit(15)
    );
  }
  return q && onSnapshot(q, (querySnapshot) => {
    const waitReceptors = [];
    querySnapshot.docs.map((doc) => {
      waitReceptors.push(doc.data());
    });
    setRealTimeShipments(waitReceptors);
  });
};
