import React, { useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Wrapper } from './../../styles/generalStyles';
import { Text } from './../../styles/textGeneral'
import { Button } from './../../styles/buttonGeneral'
import { InputContainer, FormContainer } from "./../../styles/inputGeneral";
import { Link } from 'react-router-dom'
import { LoginAndRegisterContainer } from "./styles/sLoginAndRegister";
import { useForm } from 'react-hook-form';
import { passwordFValidator } from '../../components/loginAndRegister/objects/formValidators';
import { ErrorText } from '../../styles/textGeneral';
import { handleResetPassword } from './algorithms/handleResetPassword';
import { AppContext } from '../../App';

export default function MainChangePassword() {
  const [t] = useTranslation("main");
  const navigate = useNavigate();
  const auth = getAuth();
  const [searchParams] = useSearchParams();
  let actionCode = searchParams.get("oobCode");
  let continueUrl = searchParams.get("continueUrl");

  const {
    register, 
    handleSubmit, 
    formState: { errors },
    reset
  } = useForm()

  const onSubmit = async (data) => {
    await handleResetPassword(
      auth, 
      actionCode, 
      continueUrl, 
      data.newPassword, 
      navigate
    );
    reset()
  }

  return (
    <main>
      <Wrapper small>
        <LoginAndRegisterContainer>
          <h1>{t("recoverPassword.recoverTitle")}</h1>
          <Text center>Ingresa una nueva contraseña para continuar</Text>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <label>{t("signInAndUp.newPassword")}</label>
              <input type={"password"} {...register("newPassword", passwordFValidator)}/>
              {errors.newPassword && <ErrorText>{errors.newPassword.message}</ErrorText>}
              {}
            </InputContainer>
            <Button primary large type={"submit"}>Guardar</Button>
          </FormContainer>
          <div className='loginAndRegisterQuestionContent'>
            <Text center>¿Recordaste tu contraseña?</Text>
            <Link to="/login">Regresar al Inicio de sesión</Link>
          </div>
        </LoginAndRegisterContainer>
      </Wrapper>
    </main>
  )
}
