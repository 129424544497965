import styled, { css } from "styled-components";

export const BoxGeneral = styled.section`
  padding: 10px;
  background: ${(props) => props.background || props.theme.brandWhiteColor};
  margin: 10px 0 0 0;
  border-radius: 4px;
  height: fit-content;

  h2 {
    margin: 0 0 10px 0;
    line-height: 1.25em;
  }

  h5 {
    margin: 18px 0 0 0;
  }

  ul li {
    margin: 0 0 10px 0;
  }

  .titleWithArrow {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    cursor: pointer;

    svg {
      margin: 0 10px 0 0;
    }

    h2 {
      margin: 0;
    }
  }

  .tableContent {
    overflow: scroll;
    padding: 0 0 10px 0;
    height: calc(100vh - 220px);

    @media (min-width: 768px) {
      height: calc(100vh - 270px);
    }

    @media (min-width: 1200px) {
      height: calc(100vh - 220px);
    }
  }

  ${(props) =>
    props.shadow &&
    css`
      box-shadow: ${(props) => props.theme.shadowCard};
    `}

  ${(props) =>
    props.table &&
    css`
      overflow-x: scroll;
      margin: 10px 0 80px 0;
    `}

  ${(props) =>
    props.onlyButtons &&
    css`
      display: flex;

      button:last-child {
        margin: 0 0 0 10px;
      }
    `}

  ${(props) =>
    props.buttonsTable &&
    css`
      position: fixed;
      bottom: 0;
      left: 20px;
      right: 20px;

      @media (min-width: 375px) {
        left: 25px;
        right: 25px;
      }

      @media (min-width: 768px) {
        left: 18%;
        right: 18%;
      }

      @media (min-width: 1024px) {
        left: 15%;
        right: 15%;
      }

      @media (min-width: 1200px) {
        width: 970px;
        left: auto;
        right: auto;
      }
    `}

  @media(min-width:768px) {
    padding: 20px;

    h2 {
      margin: 0 0 20px 0;
    }

    ${(props) =>
      props.onlyButtons &&
      css`
        display: flex;

        button {
          margin: 0 10px 0 10%;
        }

        button:last-child {
          margin: 0 10% 0 10px;
        }
      `}

    ${(props) =>
      props.table &&
      css`
        margin: 10px 0 100px 0;
      `}
    
    ${(props) =>
      props.oneButton &&
      css`
        button {
          width: 400px;
          margin: 0 auto;
          display: block;
        }
      `}
  }

  @media (min-width: 1200px) {
    margin: 15px 0 0 0;

    ${(props) =>
      props.table &&
      css`
        margin: 15px 0 100px 0;
      `}
  }
`;

export const BoxBorder = styled.div`
  border: 1px solid ${(props) => props.theme.gray200Color};
  padding: 10px;
  border-radius: 4px;

  @media (min-width: 768px) {
    padding: 15px;
  }
`;

export const D50Percent = styled.div`
  ${(props) =>
    props.top10 &&
    css`
      margin: 10px 0 0 0;
    `}

  @media (min-width: 1200px) {
    width: calc(50% - 10px);
  }
`;

export const Table = styled.table`
  font-size: 0.875em;
  border-collapse: collapse;
  margin: 5px 0 0 0;

  th {
    font-weight: ${(props) => props.theme.weight.regular};
    border-bottom: 1px solid ${(props) => props.theme.gray200Color};
    padding: 0 10px 5px 10px;
  }

  .tableHead th {
    font-weight: ${(props) => props.theme.weight.semiBold};
    border-bottom: 1px solid ${(props) => props.theme.brandClassicBlueColor};
  }

  td {
    padding: 10px;
    max-width: 150px;
    display: table-cell;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  th,
  td {
    text-align: center;
  }

  thead tr:nth-child(odd) {
    background-color: transparent;

    &:hover {
      background: #f5f5fd;
    }
  }

  thead tr:nth-child(even) {
    background-color: ${(props) => props.theme.blue300Color};

    &:hover {
      background: #d4e3f1;
    }
  }

  .tableNumber {
    font-weight: ${(props) => props.theme.weight.medium};
  }

  @media (min-width: 768px) {
    th {
      padding: 5px 15px;
    }

    td {
      padding: 10px 15px;
    }
  }

  @media (min-width: 1200px) {
    width: -webkit-fill-available;
  }
`;


export const Details = styled.details`
  summary {
    list-style: none;
    cursor: pointer;
  }

  h2 {
    margin: 0;
  }

  .textBeforeTitle {
    margin: 10px 0 0 0;
  }
`
