import styled from 'styled-components';

export const MPUploadContainer = styled.div`
  @media(min-width:1200px) {
    .buttonUpload {
      width: 285px;
      margin: 0 auto;
      display: block;
    }
  }
`

export const MPUploadContent = styled.div`
  p {
    margin: 0 0 10px 0;
  }

  button {
    margin: 0 0 30px 0;
  }

  @media(min-width:1200px) {
    button {
      width: 250px;
    }
  }
`

export const UploadContent = styled.div`
  position: relative;
  background: ${props => props.theme.blue300Color};
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40vh;
  justify-content: center;

  button {
    margin: 10px 0;
    width: auto;
  }

  span {
    font-size: 0.813em;
    color: ${props => props.theme.gray500Color};
  }
`

export const MPUploadDropSpace = styled.div`
  height: 40vh;
  width: 100%;
  position: absolute;
  cursor: pointer;
`