import React from 'react'
import MainShippingHistory from './../components/shippingHistory/MainShippingHistory'

export default function ShippingHistory() {
  return (
    <>
      <MainShippingHistory />
    </>
  )
}
