import { collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";

export const recoverRecentShipments = async (
  db,
  currentUser,
  setRecoveredRecentShipments,
  mounted
) => {
  const querySnapshot = await getDocs(
    query(
      collection(db, "receptors"),
      where("senderUid", "==", currentUser.uid),
      orderBy("sendNumberGenenerateDate", "desc"),
      limit(3)
    )
  );
  let listOfShipments = []
  querySnapshot.docs.map(s=>{
      listOfShipments.push(s.data())
  })
  mounted.current && setRecoveredRecentShipments(listOfShipments)
};
