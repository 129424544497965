import styled from 'styled-components';

export const NavbarHomeItemContainer = styled.div`
  display: flex;
  padding: 10px 25px;
  align-items: center;
  color: ${props => props.theme.backgroundColor};
  margin: 0 0 10px 0;
  cursor: pointer;
  border-radius: 4px;

  svg {
    fill: ${props => props.theme.backgroundColor};
    margin: 0 15px 0 0;
  }

  p {
    font-weight: ${props => props.theme.weight.semiBold};
  }

  &:hover {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.brandClassicBlueColor};

    svg {
      fill: ${props => props.theme.brandClassicBlueColor};
    }
  }

  @media(min-width:1200px) {
    border-radius: 4px 0 0 4px;

    &:hover {
      background: ${props => props.theme.baseColor};
    }
  }
`;
