import React from "react";
import { BoxGeneral } from "../../styles/boxesGeneral";
import { Title4, Text } from "./../../styles/textGeneral";
import { Link } from "react-router-dom";
import { DetailsTrackingCardContainer } from "./styles/sDetailsTrackingCard";
import { NoDeliveredCardContainer } from "./styles/sNoDeliveredCard";

//import ALGORITHMS
import { capitalize } from "../../algorithms/general/capitalize";

export default function NoDeliveredCard({ state }) {
  return (
    <BoxGeneral shadow>
      <Title4>Estado del envío</Title4>
      <NoDeliveredCardContainer>
        <h3>{state?.status === "MOTIVO"? "No entregado":"No distribuido"}</h3>
        <DetailsTrackingCardContainer>
          <p>
            Motivo: <span>{capitalize(state?.statusDeterminant)}</span>
          </p>
        </DetailsTrackingCardContainer>
        <Text className="noDeliveredDescription">
          Para más información revise nuestros{" "}
          <Link to="/terms">Términos de uso</Link> del servicio o contácte a un
          asistente.
        </Text>
        <Link to="/assistance" className="contactSupport">
          Contactar con un asistente
        </Link>
      </NoDeliveredCardContainer>
    </BoxGeneral>
  );
}
