import { doc, setDoc} from 'firebase/firestore'

export const updateBusinessProfile = (db, data, uid) => {
    try {
        const {address, bussinesPhone, companyName, corporativeEmail, ruc, ubigeo} = data
        const docUserRef = doc(db, 'users', uid)
        setDoc(docUserRef, {
            address,
            bussinesPhone,
            bussinesName: companyName,
            department: ubigeo.desc_dep_inei,
            bussinesEmail: corporativeEmail,
            RUC: ruc,
            ubigeo,
        }, 
        { merge: true}).then(() => {
            console.log("Business Profile Updated")
            window.location.reload()
        }).catch(err => {
            console.error(err.message);
        })

    } catch (err) {
        console.error(err.message)
    }
}