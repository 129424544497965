import React from 'react'
import { Wrapper } from "./../../styles/generalStyles";
import { HelpCenterCover, ServiceCardsUnlist } from './styles/sMainHelpCenter'
import HelpServiceCard from './HelpServiceCard';
import { ReactComponent as PhoneSVG } from './../../icons/phone-2.svg'
import { ReactComponent as TermsSVG } from './../../icons/terms.svg'
import { ReactComponent as KeySVG } from './../../icons/key.svg'

const serviceCardsList = [
  {
    icon: <PhoneSVG />,
    title: 'Contacta con un asistente',
    description: 'Resuelve tus dudas con una persona encargada',
    background: '#FEE9F2',
    colorHover: '#8B0740',
    link: '/assistance'
  },
  {
    icon: <TermsSVG />,
    title: 'Términos de uso',
    description: 'Accede a los términos de uso de nuestros servicios',
    background: '#FEFEE9',
    colorHover: '#757509',
    link: '/terms'
  },
  {
    icon: <KeySVG />,
    title: 'Privacidad',
    description: 'Accede a las políticas de privacidad de nuestros servicios',
    background: '#E9FEFB',
    colorHover: '#077262',
    link: '/terms'
  },
]

export default function MainHelpCenter() {
  return (
    <main>
      <HelpCenterCover>
        <Wrapper>
          <span>Hola</span>
          <h1>¿Cómo podemos ayudarte?</h1>
        </Wrapper>
      </HelpCenterCover>
      <Wrapper>
        <ServiceCardsUnlist>
          {
            serviceCardsList.map((card) => <HelpServiceCard key={card.title} {...card} />)
          }
        </ServiceCardsUnlist>
      </Wrapper>
    </main>
  )
}
