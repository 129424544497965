import styled from 'styled-components';

export const UnlistPaymentMetods = styled.ul`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  
  li > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: 120px;
  }

  p {
    margin: 0 0 15px 0;
    font-weight: ${props => props.theme.weight.medium};
  }

  li:hover > button {
    transition: .2s all;
    border: 1px solid ${props => props.theme.brandClassicBlueColor};
    color: ${props => props.theme.brandClassicBlueColor};
    cursor: pointer;
  }

  @media(min-width:768px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  @media(min-width:1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
`
