import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { doc, writeBatch } from "firebase/firestore";
import { capitalize } from "../../../algorithms/general/capitalize";

export const handleRegisterWithEmail = async(
  db,
  navigate,
  data,
  setLoading,
  setRegisterErrors,
  mounted
) => {
  const auth = getAuth();
  await createUserWithEmailAndPassword(auth, data.personalEmail, data.password)
    .then(async (result) => {
      const user = result.user;
      if (user) {
        await updateProfile(auth.currentUser, {
          displayName: capitalize(data.name), //Cambio de displayName
          phoneNumber: data?.personalPhone,
        });
        await sendData(db, data, user, auth, navigate, setLoading); //Envío de datos a la db de firestore
      }
    })
    .catch((error) => {
      mounted.current && setLoading(false);
      setRegisterErrors(error.code);
      console.log(error.message);
    });
};

const sendData = async (db, data, user, auth, navigate, setLoading) => {
  const thisUserRef = doc(db, "users", user.uid);
  const batch = writeBatch(db);
  const {
    DNI,
    RUC,
    address,
    bussinesEmail,
    bussinesName,
    bussinesPhone,
    name,
    personalPhone,
    ubigeo,
  } = data;
  try {
    batch.set(
      thisUserRef,
      {
        name: {
          prevName: name ? name : null,
          firstName: null,
          lastName: null,
        },
        email: user.email,
        uid: user.uid,
        DNI: DNI ? DNI : null,
        personalPhone: personalPhone ? personalPhone : null,
        RUC: RUC ? RUC : null,
        address: address ? address : null,
        bussinesEmail: bussinesEmail ? bussinesEmail : null,
        bussinesName: bussinesName ? bussinesName : null,
        bussinesPhone: bussinesPhone ? bussinesPhone : null,
        department:
          ubigeo && ubigeo.desc_dep_inei ? ubigeo.desc_dep_inei : null,
        ubigeo: ubigeo ? ubigeo : null,
        typeOfUser: "B2C",
      },
      { merge: true }
    );

    await batch
      .commit()
      .then(async () => {
        console.log("Document successfully updated!");
        await emailVerificaction(auth, navigate);
        setLoading(false);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  } catch (error) {
    console.error("Error updating document: ", error);
    await sendData(db, data, user, auth, navigate); //Reenvío en caso de error
  }
};

export const emailVerificaction = async (auth, navigate) => {
  let actionCodeSettings = {
    // Configuración de urls de redirección de correo
    url:
      (process.env.NODE_ENV !== "production"
        ? "http://localhost:3000/login/?email="
        : "https://serviciopuntual.com.pe/login/?email=") +
      auth.currentUser?.email,
    // dynamicLinkDomain: 'https://serviciopuntual.com.pe/login'
  };

  await sendEmailVerification(auth.currentUser, actionCodeSettings) //Envio de email verificador de correo
    .then(async () => {
      let userAuth = auth.currentUser;
      await signOut(auth).then(() => {
        navigate("/email-verify", {
          replace: true,
          state: {
            email: userAuth?.email ?? "Tu bandeja de entrada de correo",
          },
        });
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
