import React from 'react'
import MainAdministrator from './../components/administrator/MainAdministrator'

export default function Administrator() {
  return (
    <>
      <MainAdministrator />
    </>
  )
}
