import styled from 'styled-components';

export const SolutionCardContainer = styled.article`
  background: ${props => props.theme.backgroundColor};
  border-radius: 8px;
  padding: 40px 15px;
  color: ${props => props.theme.blackColor};
  margin: 0 0 30px 0;

  svg {
    height: 110px;
    width: auto;
  }

  h4 {
    margin: 40px 0 30px 0;
    text-align: center;
  }

  @media(min-width:768px) {
    padding: 40px 25px;
  }

  @media(min-width:1200px) {
    margin: 0 0 0 0;

    h4 {
      font-size: 1.150em;
    }

    p {
      font-size: 1.050em;
    }
  }
`;
