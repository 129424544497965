import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { createXlsxFromData } from "../../../algorithms/general/createXlsxFromData";

export const recoverPacketsPerRange = async (db, timestamp) => {
  const recoveredReceptors = await getDocs(
    query(
      collection(db, "receptors"),
      where("sendNumberGenenerateDate", ">", new Date(timestamp.from)),
      where("sendNumberGenenerateDate", "<", new Date(timestamp.to)),
      orderBy("sendNumberGenenerateDate", "desc")
    )
  );
  let listOfReceptors = [];
  recoveredReceptors.docs.map((r) => {
    listOfReceptors.push(r.data());
  });
  createXlsxFromData(listOfReceptors, "segmentOfPackets", "admin");
};
